import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Provider } from "react-redux";
import store from "./store/Store";
import { SnackbarProvider } from "notistack";
import AuthLayout from "./components/layout/AuthLayout";
import { BrowserRouter } from "react-router-dom";
import { LinearProgress, Slide } from "@mui/material";
import { ChakraProvider } from "@chakra-ui/react";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);
root.render(
	<>
		<GoogleOAuthProvider clientId="75494261748-2bd805dh2egvadj6hjl06eaqnvjc2aso.apps.googleusercontent.com">
			<SnackbarProvider
				maxSnack={1}
				transitionDuration={{ enter: 300, exit: 500 }}
				style={{ marginTop: "3.5rem" }}
				autoHideDuration={2000}
				preventDuplicate
			>
				<ChakraProvider toastOptions={{ toastSpacing: "0.5rem" }}>
					<BrowserRouter>
						<React.StrictMode>
							<Provider store={store}>
								<AuthLayout>
									<App />
								</AuthLayout>
							</Provider>
						</React.StrictMode>
					</BrowserRouter>
				</ChakraProvider>
			</SnackbarProvider>
		</GoogleOAuthProvider>
	</>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
