import React, { useState, useEffect } from "react";
import {
	CloseButton,
	createStandaloneToast,
	useToast as useChakraToast,
} from "@chakra-ui/react";
import { IconButton, LinearProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { enqueueSnackbar, useSnackbar } from "notistack";
// import React, { useEffect } from "react";

export type ToastVariant = "info" | "warning" | "success" | "error" | "default";

type SnackBarProps = {
	message: string;
	variant: ToastVariant;
	isCloseable: boolean;
};
const { ToastContainer, toast } = createStandaloneToast();

export const useCustomToast = () => {
	// const toast = useChakraToast();
	const [progress, setProgress] = useState(0);
	const [isIncreasing, setIsIncreasing] = useState(true);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	let progressUpdater: NodeJS.Timeout | null = null;

	 React.useEffect(() => {
			const timer = setInterval(() => {
				setProgress((oldProgress) => {
					if (oldProgress === 100) {
						return 0;
					}
					const diff = Math.random() * 10;
					return Math.min(oldProgress + diff, 100);
				});
			}, 1500);

			return () => {
				clearInterval(timer);
			};
		}, []);

	const showToast = (
		message: string,
		variant: ToastVariant,
		isCloseable: boolean
	) => {
		const handleClose = () => {
			if (progressUpdater) {
				clearInterval(progressUpdater);
			}
			closeSnackbar(key);
		};

		const key = enqueueSnackbar(`${message}`, {
			variant: variant,
			anchorOrigin: { vertical: "top", horizontal: "left" },
			style: {
				marginTop: "3rem",
			},
			autoHideDuration: 3000,
			action: (key) => (
				<>
					<IconButton
						aria-label="close"
						color="inherit"
						sx={{ p: 0.5 }}
						onClick={handleClose}
					>
						<CloseIcon />
					</IconButton>
					
				</>
			),
		});

		
	};

	return showToast;
};
