import { Box, Button, Paper } from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import CustomNoRowsOverlay from "../../../components/Data/NoRowsOverlayComponent";
import { setFormTeamId, setId } from "../../../store/FormSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import {
  useCustomToast,
  ToastVariant,
} from "../../../components/Methods/SnackBar";
import { RootState } from "../../../store/Store";
import {
  FetchDataSuccessAction,
  FetchDataFailureAction,
  fetchData,
} from "../../../store/actions";
import { employeeAssignedFeedbackForm } from "../../../Service/Apis";

interface AssignedFormsTypes {
  form_team_id: string;
  is_employee_feedback: boolean;
  is_manager_feedback: boolean;
  project_name: string;
  manager_name: string;
  is_360: boolean;
  form_id: string;
  team_id: string;
}
const FeedBackFormDashboard = () => {
  const [assignedForms, setAssignedForms] = useState<AssignedFormsTypes[]>([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();

  const showToast = useCustomToast();
  const handleShowToast = (
    message: string,
    variant: ToastVariant,
    isCloseable: boolean
  ) => {
    showToast(message, variant, isCloseable);
  };

  const rows: any[] = assignedForms.map((form, index) => ({
    ...form,
    index: index,
    sr_no: index + 1,
  }));

  const columns: GridColDef[] = [
    {
      field: "sr_no",
      headerName: "Sr. No",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Sr No.
        </strong>
      ),
      width: 95,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "form_name",
      headerName: "Form Name",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Form Name
        </strong>
      ),
      width: 210,
      align: "left",
      headerAlign: "center",
      headerClassName: "header-background",
    },

    {
      field: "manager_name",
      headerName: "Manager Name",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Manager Name
        </strong>
      ),
      width: 155,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "employee_deadline",
      headerName: "Deadline",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Deadline
        </strong>
      ),
      width: 155,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "action",
      headerName: "Actions",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Actions
        </strong>
      ),
      width: 155,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
      renderCell: (params: any) => (
        <Button
          size="small"
          onClick={() => {
            dispatch(setFormTeamId(params.row.form_team_id));
            localStorage.setItem("formTeamId", params.row.form_team_id);
            localStorage.setItem("teamId", params.row.team_id);
            dispatch(setId(params.row.form_id));
            localStorage.setItem("formid", params.row.form_id);
            // dispatch(setId(params.row.response_id));
            localStorage.setItem(
              "feedbackRole",
              JSON.stringify({
                is_employee_feedback: true,
                is_manager_feedback: false,
              })
            );
            navigate("/fillForm");
          }}
        >
          Go To Form
        </Button>
      ),
    },
  ];

  useEffect(() => {
    const fetchAssignedForms = async () => {
      const response = await dispatch2(
        fetchData(`${employeeAssignedFeedbackForm}`)
      );
      setAssignedForms(response as AssignedFormsTypes[]);
    };
    fetchAssignedForms();
  }, []);
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Paper
          sx={{
            width: "fit-content",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <DataGrid
            autoHeight
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            disableColumnMenu
            sx={{
              width: "100%",
              height: "100%",
              "--DataGrid-overlayHeight": "50vh",
            }}
            pagination
            pageSizeOptions={[5, 10, 15]}
            rows={rows}
            columns={columns}
            // disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            slots={{
              toolbar: GridToolbar,
              noRowsOverlay: CustomNoRowsOverlay,
              noResultsOverlay: CustomNoRowsOverlay,
            }}
            slotProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                printOptions: { disableToolbarButton: true },
                style: { margin: "0.5rem" },
              },
            }}
            getRowId={(row) => row.index}
          />
        </Paper>
      </Box>
    </>
  );
};

export default FeedBackFormDashboard;
