import { ReactNode, useEffect, useState } from "react";

import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";

import { useNavigate } from "react-router-dom";

import Footer from "../../components/Page Component/Footer";
import Navbar from "../../components/Page Component/Navbar";

import {
  addSkills,
  createDataPoint,
  delteSkillQuestion,
  skillQuestionsDetail,
  updateSkillsData,
} from "../../Service/Apis";

import {
  ToastVariant,
  useCustomToast,
} from "../../components/Methods/SnackBar";

import { setInPageLoading } from "../../store/LoadingSlice";
import { RootState } from "../../store/Store";
import {
  FetchDataFailureAction,
  FetchDataSuccessAction,
  deleteData,
  fetchData,
  postData,
  putData,
} from "../../store/actions";
import Loading from "../../components/Loading/Loading";
import {
  DataPointsAddOrEditType,
  intialStateDataPointsAdd,
} from "./Types/DataPointsTypes";
import NumberInput from "../../components/NumberInput/NumberInput";

const AddDataPoints = ({ switchToFillDataPointTab }: any) => {
  const [dataPointData, setDataPointData] = useState<DataPointsAddOrEditType>(
    intialStateDataPointsAdd
  );

  const [questionDeleteIndex, setQuestionDeleteIndex] = useState<number | null>(
    null
  );
  const [edit, setEdit] = useState<boolean>(false);

  const navigate = useNavigate();

  const showToast = useCustomToast();

  const dispatch = useDispatch();
  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();

  //   const skillId = localStorage.getItem("skillId");
  const isloading = useSelector((state: any) => state.loading.inPageLoading);

  const handleShowToast = (
    message: string,
    variant: ToastVariant,
    isCloseable: boolean
  ) => {
    showToast(message, variant, isCloseable);
  };

  // Function to handle skill name change.
  const handleDataPointNameChange = (value: string) => {
    setDataPointData((prevData) => ({ ...prevData, data_point_name: value }));
  };

  const handleDataPointDescriptionChange = (value: string) => {
    setDataPointData((prevData) => ({
      ...prevData,
      data_point_description: value,
    }));
  };

  // Function to handle question name change.
  const handleQuestionChange = (index: number, value: string) => {
    const newQuestions = [...dataPointData.questions];
    newQuestions[index] = {
      ...newQuestions[index],
      data_point_question: value,
    };
    setDataPointData((prevData) => ({ ...prevData, questions: newQuestions }));
  };

  const handleQuestionBenchMarkValue = (index: number, value: number) => {
    value === undefined || value === null ? (value = 0) : (value = value);
    const newQuestions = [...dataPointData.questions];
    newQuestions[index] = { ...newQuestions[index], bench_mark_value: value };
    setDataPointData((prevData) => ({ ...prevData, questions: newQuestions }));
  };

  const handleInverseChange = (index: number, checked: boolean) => {
    const newQuestions = [...dataPointData.questions];
    newQuestions[index] = { ...newQuestions[index], inverse: checked };
    setDataPointData((prevData) => ({ ...prevData, questions: newQuestions }));
  };

  //Add new question.
  const handleAddQuestion = () => {
    setDataPointData((prevData) => ({
      ...prevData,
      questions: [
        ...prevData.questions,
        {
          data_point_question_id: "",
          data_point_question: "",
          inverse: false,
          bench_mark_value: 0,
          created_at: "",
        },
      ],
    }));
  };

  // Remove question.
  const handleRemoveQuestion = async (index: number) => {
    const newQuestions = [...dataPointData.questions];
    const data_point_question_id = newQuestions[index].data_point_question_id;

    if (dataPointData.questions.length === 1) {
      handleShowToast("Cannot delete last question", "error", true);
      return;
    } else {
      if (data_point_question_id !== "") {
        try {
          //   const response = await dispatch2(
          //     deleteData(`${delteSkillQuestion}${data_point_question_id}/`)
          //   );
          newQuestions.splice(index, 1);
          handleShowToast("Question delted!", "success", true);
        } catch (error) {
          console.log(error);
        }
      } else if (
        data_point_question_id === undefined ||
        data_point_question_id === ""
      ) {
        newQuestions.splice(index, 1);
        handleShowToast("Question delted!", "success", true);
      }
      setDataPointData((prevData) => ({
        ...prevData,
        questions: newQuestions,
      }));
    }
  };

  // Add Skill API Call.
  const addDataPoint = async () => {
    const data = dataPointData;
    console.log(data);
    try {
      const response = await dispatch2(postData(`${createDataPoint}`, data));
      handleShowToast(`${data.data_point_name}  Added!`, "success", true);
      switchToFillDataPointTab();
      // navigate("/datapoints");
    } catch (error) {
      console.log(error);
    }
  };

  // Edit Skill API call.
  const editDataPoint = async () => {
    const data = dataPointData;
    console.log(data);
    try {
      //   const response = await dispatch2(
      //     putData(`${updateSkillsData}${data.data_point_id}/`, data)
      //   );
      handleShowToast(
        `${data.data_point_name} Skill Updated!`,
        "success",
        true
      );
      // navigate("/datapoints");
    } catch (error) {
      console.log(error);
    }
  };

  // Helper function to add or edit skill
  const handleAddDataPoint = (e: any) => {
    e.preventDefault();
    if (edit) {
      editDataPoint();
    } else {
      addDataPoint();
    }
  };

  // API call to get all skills.
  useEffect(() => {
    const fetchAllSkills = async () => {
      try {
        // dispatch(setInPageLoading(true));
        // const response: any = await dispatch2(
        //   fetchData(`${skillQuestionsDetail}${skillId}`)
        // );
        // setDataPointData(response[0] as DataPointsAddOrEditType);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setInPageLoading(false));
      }
    };
    // if (skillId) {
    //   fetchAllSkills();
    //   setEdit(true);
    // }
  }, []);

  if (isloading) {
    return (
      <>
        <Loading isLoading={isloading as boolean} />
      </>
    );
  }

  return (
    <>
      <Box sx={{ mt: 0, mb: 0 }}>
        <Box
          component={"form"}
          onSubmit={handleAddDataPoint}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Box>
            {/* <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            > */}
            {/* <Box sx={{ display: "flex", alignItems: "center", m: 1, p: 1 }}>
                <Typography fontWeight={"bold"} sx={{width : "12rem"}}>Department :</Typography>
                <Autocomplete
                  size="small"
                  //   isOptionEqualToValue={(option, value) => option.id === value.id}
                  getOptionLabel={(option) => option}
                  //   value={employee}
                  //   onChange={handleEmployeeChange}
                  id="employee-autocomplete"
                  options={["IT", "Marketing", "HR"]}
                  sx={{ width: "20rem" }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
              <Box>
                <FormGroup>
                  <FormControlLabel
                    control={<Switch defaultChecked readOnly />}
                    label="Multiple Projects"
                  />
                </FormGroup>
              </Box>
            </Box> */}
            {/* <Box
              sx={{
                display: "flex",
                alignItems: "center",
                m: 1,
                p: 1,
              }}
            >
              <Typography fontWeight={"bold"} sx={{width : "12rem"}}>Projects :</Typography>
              <Autocomplete
                size="small"
                //   isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option}
                //   value={employee}
                //   onChange={handleEmployeeChange}
                id="employee-autocomplete"
                options={["IT", "Marketing", "HR"]}
                sx={{ width: "20rem" }}
                renderInput={(params) => <TextField {...params} />}
              />
            </Box> */}
            <Card sx={{ width: "75vw", m: 2, p: 2 }} elevation={0}>
              <Box sx={{ p: 1 }}>
                <TextField
                  id={`data-point-name`}
                  variant="standard"
                  sx={{ width: "100%" }}
                  required
                  label="Data Point Name"
                  placeholder="Enter your Data Point here"
                  value={dataPointData?.data_point_name}
                  onChange={(e) => handleDataPointNameChange(e.target.value)}
                />
              </Box>
              <Box sx={{ p: 1 }}>
                <TextField
                  id={`data-point-description`}
                  variant="standard"
                  sx={{ width: "100%" }}
                  required
                  label="Data Point Description"
                  placeholder="Enter your Data Point Description here"
                  value={dataPointData?.data_point_description}
                  onChange={(e) =>
                    handleDataPointDescriptionChange(e.target.value)
                  }
                />
              </Box>
            </Card>
            <Box sx={{ mx: 5 }}>
              <Typography fontWeight={"bold"} fontSize={13} color={"error"}>
                Note : If inverse switch is on, benchmark value is treated as
                ceiling/max value.
              </Typography>
            </Box>
            <Box sx={{ width: "75vw", mx: 4, p: 2 }}>
              {dataPointData.questions &&
                dataPointData.questions.map((question, index) => (
                  <Box
                    key={index}
                    sx={{ display: "flex", alignItems: "center", m: 0, p: 2 }}
                  >
                    <Typography fontWeight={"bold"} sx={{ width: "10rem" }}>
                      Question {index + 1}:{" "}
                    </Typography>
                    <TextField
                      id={`question-${index}`}
                      variant="standard"
                      sx={{ width: "90%" }}
                      value={question.data_point_question}
                      required
                      onChange={(e) =>
                        handleQuestionChange(index, e.target.value)
                      }
                    />
                    <Box>
                      <Typography
                        sx={{
                          width: "15rem",
                          display: "flex",
                          justifyContent: "center",
                        }}
                        fontSize={11}
                        fontWeight={"bold"}
                      >
                        Bench Mark Value
                      </Typography>
                      <NumberInput
                        // key={keyUpdate}
                        // name="paid_leaves"
                        min={0}
                        aria-label="Demo number input"
                        placeholder="Input a value..."
                        value={question.bench_mark_value}
                        // step={0.5}
                        max={100}
                        onChange={(e: any, val: number) => {
                          e.preventDefault();
                          handleQuestionBenchMarkValue(index, val);
                        }}
                      />
                    </Box>
                    <FormGroup sx={{ ml: 1 }}>
                      <FormControlLabel
                        sx={{ display: "flex", alignItems: "center" }}
                        control={
                          <Switch
                            checked={question.inverse}
                            onChange={(e) =>
                              handleInverseChange(index, e.target.checked)
                            }
                          />
                        }
                        label={
                          <Typography fontSize={14} fontWeight={"bold"}>
                            Inverse
                          </Typography>
                        }
                      />
                    </FormGroup>
                    <IconButton
                      onClick={() => {
                        setQuestionDeleteIndex(index);
                      }}
                    >
                      <DeleteIcon color="primary" />
                    </IconButton>
                    <Dialog
                      id={`dialog-box-question-delete-${index}`}
                      open={index === questionDeleteIndex}
                    >
                      <DialogTitle fontWeight={"bold"}>
                        Delete Question
                      </DialogTitle>
                      <DialogContent>
                        <Typography>
                          Are you sure you want to delete this question?
                        </Typography>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          id={`delete-button-question-${index}`}
                          onClick={() => {
                            handleRemoveQuestion(index);
                            setQuestionDeleteIndex(null);
                          }}
                          sx={{ mr: 1 }}
                          color="error"
                        >
                          Delete
                        </Button>
                        <Button
                          id={`cancel-button-question-${index}`}
                          onClick={() => setQuestionDeleteIndex(null)}
                          sx={{ ml: 1 }}
                        >
                          Cancel
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Box>
                ))}
              <Box sx={{ display: "flex", justifyContent: "right", m: 1 }}>
                <Button
                  id={`add-question-button`}
                  variant="outlined"
                  size="small"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={handleAddQuestion}
                >
                  Add Question
                </Button>
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              {!edit ? (
                <Button
                  id={`add-data-point-button`}
                  variant="contained"
                  type="submit"
                >
                  Add Data Point
                </Button>
              ) : (
                <Button
                  id={`update-data-point-button`}
                  type="submit"
                  variant="contained"
                >
                  Update
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AddDataPoints;
