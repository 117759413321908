import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface LoadState {
  isLoading: boolean;
  inPageLoading: boolean;
  count: number;
}

const initialState: LoadState = {
  isLoading: false,
  inPageLoading: false,
  count: 100,
};

const loadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setInPageLoading: (state, action: PayloadAction<boolean>) => {
      state.inPageLoading = action.payload;
    },
    setCounts: (state, action: PayloadAction<number>) => {
      state.count = action.payload;
    },
  },
});

export const { setIsLoading, setInPageLoading, setCounts } =
  loadingSlice.actions;

export default loadingSlice;
