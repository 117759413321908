import { useEffect, useMemo, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { deleteTeam, showTeams } from "../../Service/Apis";
import NothingToDisplayImage from "../../asset/NothingToDisplayImage.png";
import EmployeeAndManager from "../../components/Employee/EmployeeAndManager";
import {
  ToastVariant,
  useCustomToast,
} from "../../components/Methods/SnackBar";
import Footer from "../../components/Page Component/Footer";
import Navbar from "../../components/Page Component/Navbar";
import Skeletons from "../../components/Skeleton/Skeletons";
import { setProjectNameState } from "../../store/FormSlice";
import { setIsLoading } from "../../store/LoadingSlice";
import { RootState } from "../../store/Store";
import {
  FetchDataFailureAction,
  FetchDataSuccessAction,
  deleteData,
  fetchData,
} from "../../store/actions";
import { styled } from "@mui/material/styles";
import CustomNoRowsOverlay from "../../components/Data/NoRowsOverlayComponent";
import NothingToDisplayComponent from "../../components/Data/NothingToShowComponent";

const CreateTeam = () => {
  const [teamDetails, setTeamDetails] = useState<any[]>([]); //for storing team details
  const [showemp, setShowemp] = useState<Boolean>(false);
  const [count, setCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [openDeleted3, setOpenDeleted3] = useState<number | null>(null);

  const isloading = useSelector((state: any) => state.loading.isLoading);
  const counts = useSelector((state: any) => state.loading.count);

  const dispatch = useDispatch();
  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();

  // const success = useSelector((state: any) => state.http.data);

  const navigate = useNavigate();

  const showToast = useCustomToast();

  const filteredData = useMemo(() => {
    if (searchTerm === "") {
      return teamDetails;
    }
    return teamDetails.filter((team) =>
      team.project_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [teamDetails, searchTerm]);

  const rows = useMemo(() => {
    return filteredData.map((employee, index) => ({
      ...employee,
      teamIndex: index + 1,
    }));
  }, [filteredData]);

  const columns: GridColDef[] = [
    {
      field: "teamIndex",
      headerName: "Sr. No",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 17, textAlign: "center" }}
        >
          Sr No.
        </strong>
      ),
      flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "project_name",
      headerName: "Project Name",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ paddingRight: 2, fontSize: 17, textAlign: "center" }}
        >
          Project Name
        </strong>
      ),
      flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "view",
      headerName: "View",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 17, textAlign: "center" }}
        >
          View
        </strong>
      ),
      flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
      renderCell: (params: any) => (
        <Button size="small" onClick={() => goToEdit(params.row.team_id)}>
          View
        </Button>
      ),
    },
    {
      field: "edit",
      headerName: " Edit",
      flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 17, textAlign: "center" }}
        >
          Edit
        </strong>
      ),
      renderCell: (params: any) => (
        <Button
          size="small"
          onClick={() => {
            navigate("/editTeam");
            dispatch(setProjectNameState(params.row.team_id));
            localStorage.setItem("projectName", params.row.team_id);
          }}
        >
          Edit
        </Button>
      ),
    },
    {
      field: "delete",
      headerName: "Delete",
      flex: 1,
      headerAlign: "center",
      headerClassName: "header-background",
      align: "center",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 17, textAlign: "center" }}
        >
          Delete
        </strong>
      ),
      renderCell: (params: any) => (
        <>
          <Button
            size="small"
            onClick={() => handleOpenDelete3(params.row.teamIndex)}
          >
            Delete
          </Button>
          <Dialog
            open={openDeleted3 === params.row.teamIndex}
            onClose={handleCLoseDelete3}
            sx={{ m: 1 }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <IconButton onClick={() => handleCLoseDelete3()}>
                <CloseIcon />
              </IconButton>
            </Box>
            <DialogTitle>Confirmation</DialogTitle>
            <DialogContent>
              <Typography>Do you want to delete?</Typography>
            </DialogContent>
            <DialogActions>
              <Button
                id={`no_button_${params.row.teamIndex + 1}`}
                onClick={handleCLoseDelete3}
              >
                No
              </Button>
              <Button
                id={`yes_button_${params.row.teamIndex + 1}`}
                variant="contained"
                onClick={(e) => {
                  handleTeamDelete(e, params.row.team_id);
                  handleCLoseDelete3();
                }}
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </>
      ),
    },
  ];

  const handleShowToast = (
    message: string,
    variant: ToastVariant,
    isCloseable: boolean
  ) => {
    showToast(message, variant, isCloseable);
  };

  const handleOpenDelete3 = (index: number) => {
    setOpenDeleted3(index);
  };
  const goToEdit = (data: string) => {
    dispatch(setProjectNameState(data));

    navigate("/viewTeam");
  };
  const handleCLoseDelete3 = () => {
    setOpenDeleted3(null);
  };

  const handleclickEmp = () => {
    setShowemp(!showemp);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
  };

  //for deleting a certain team
  const handleTeamDelete = (e: any, team_id: string) => {
    e.preventDefault();
    try {
      const response = dispatch2(deleteData(`${deleteTeam}${team_id}`))
        .then((response) => {
          handleShowToast("Team Deleted Successfully !", "success", true);
          setCount(count + 1);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  //shows all the teams
  useEffect(() => {
    const fetchTeamsData = async () => {
      try {
        dispatch(setIsLoading(true));
        const response = await dispatch2(
          fetchData(`${showTeams}?is_360=False`)
        );

        setTeamDetails(response as any[]);
        dispatch(setIsLoading(false));
      } catch (error) {
        console.log(error);
        dispatch(setIsLoading(false));
      }
    };
    fetchTeamsData();
  }, [count, counts]);

  if (isloading) {
    return (
      <>
        <Skeletons />
      </>
    );
  }

  return (
    <>
      <Box>
        <Navbar />
      </Box>
      <Container sx={{ mt: 12, mb: 10 }}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box sx={{ m: 2, p: 2, width: "55vw" }}>
            <Box sx={{ display: "flex", justifyContent: "center", m: 1, p: 1 }}>
              <Typography id="Team_Details_Heading" variant="h3">
                Team Details
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                m: 1,
                p: 1,
              }}
            >
              <Button
                id="employee_team_switch_button"
                variant="outlined"
                size="small"
                onClick={handleclickEmp}
              >
                {showemp ? "Back to teams" : "All Employee Details"}
              </Button>

              <Button
                id="CreateTeam"
                variant="contained"
                size="small"
                // onClick={handleOpen}
                onClick={() => {
                  navigate("/addTeam");
                }}
              >
                Create Team
              </Button>
            </Box>
            {showemp ? (
              <EmployeeAndManager />
            ) : (
              <>
                {/* <Box
                  component="form"
                  onSubmit={(e) => e.preventDefault()}
                  sx={{ m: 2, display: "flex", justifyContent: "right", p: 1 }}
                >
                  <TextField
                    
                    label="Search"
                    variant="outlined"
                    value={searchTerm}
                    onChange={handleSearch}
                    size="small"
                    sx={{ mr: 2, width: "40%" }}
                    inputProps={{
                      readOnly: false,
                    }}
                  />
                  <Button
                    type="submit"
                    size="small"
                    
                    variant="text"
                    sx={{ m: 0 }}
                  >
                    Search
                  </Button>
                </Box> */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    m: 2,
                    p: 1,
                    // width: "55vw",
                  }}
                >
                  {rows.length === 0 ? (
                    <>
                      {" "}
                      <Box
                        id="no_data_image_card"
                        // elevation={11}
                        sx={{
                          m: 1,
                          p: 1,
                          // "&:hover": {
                          // 	boxShadow: "0px 0px 10px 2px rgba(0, 100, 0, 0.5)",
                          // },
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          // boxShadow: 20,
                          // height: "100%",
                        }}
                      >
                        <NothingToDisplayComponent />
                      </Box>
                    </>
                  ) : (
                    <Paper sx={{ width: "100%" }}>
                      <DataGrid
                        autoHeight
                        initialState={{
                          pagination: {
                            paginationModel: {
                              pageSize: 10,
                            },
                          },
                        }}
                        disableColumnMenu
                        sx={{
                          width: "100%",
                          height: "100%",
                          "--DataGrid-overlayHeight": "50vh",
                        }}
                        pagination
                        pageSizeOptions={[5, 10, 15]}
                        rows={rows}
                        columns={columns}
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        slots={{
                          toolbar: GridToolbar,
                          noRowsOverlay: CustomNoRowsOverlay,
                          noResultsOverlay: CustomNoRowsOverlay,
                        }}
                        slotProps={{
                          toolbar: {
                            csvOptions: { disableToolbarButton: true },
                            showQuickFilter: true,
                            printOptions: { disableToolbarButton: true },
                            style: { margin: "0.5rem" },
                          },
                        }}
                        getRowId={(row) => row.team_id}
                      />
                    </Paper>
                  )}
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Container>
      <Box>
        <Footer />
      </Box>
    </>
  );
};

export default CreateTeam;
