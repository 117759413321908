import React, { useState, useEffect, useMemo } from "react";
import {
	Box,
	Paper,
	Typography,
	CircularProgress,
	IconButton,
	Select,
	MenuItem,
	Pagination,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import {
	fetchData,
	FetchDataFailureAction,
	FetchDataSuccessAction,
} from "../../../store/actions";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { useCustomToast } from "../../Methods/SnackBar";
import { getProjectBugsData } from "../../../Service/Apis";
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
} from "recharts";
import { saveAs } from "file-saver";
import MonthButtonGroup from "../../utilization/MonthsButtonGroup";
import { RootState } from "../../../store/Store";
import ProjectSprintFilter from "../ProjectFilter/ProjectSprintFilter";

const CHART_HEIGHT = 400;
const PROJECT_COLORS: { [key: string]: string } = {};
const ITEMS_PER_PAGE = 5; // Number of sprints per page

const getProjectColor = (projectName: string) => {
	if (!PROJECT_COLORS[projectName]) {
		PROJECT_COLORS[projectName] = `#${Math.floor(
			Math.random() * 16777215
		).toString(16)}`;
	}
	return PROJECT_COLORS[projectName];
};

const ProjectBugsChart = () => {
	const dispatch2: ThunkDispatch<
		RootState,
		null,
		FetchDataSuccessAction | FetchDataFailureAction
	> = useDispatch();
	const [bugsData, setBugsData] = useState<any>([]);
	const [selectedFormat, setSelectedFormat] = useState<string>("excel");
	const [month, setMonth] = useState<string>("1");
	const [loading, setLoading] = useState(false);
	const [downloading, setDownloading] = useState(false);
	const [selectedProjects, setSelectedProjects] = useState<string[]>([]);
	const [selectedSprints, setSelectedSprints] = useState<string[]>([]);
	const [page, setPage] = useState(1);
	const showToast = useCustomToast();

	const nrole = useSelector((state: any) => state.auth.selectedRole);
	const paramsData = {
		role: nrole,
	};

	const fetchBugsData = async (selectedMonth: string) => {
		try {
			setLoading(true);
			const res = await dispatch2(
				fetchData(`${getProjectBugsData}${selectedMonth}`, paramsData)
			);
			setMonth(selectedMonth);
			setBugsData(res);
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchBugsData("1");
	}, []);

	const handleMonthChange = (selectedMonth: string) => {
		fetchBugsData(selectedMonth);
	};

	const buttonData = [
		{ label: "1M", onClick: () => handleMonthChange("1") },
		{ label: "3M", onClick: () => handleMonthChange("3") },
		{ label: "6M", onClick: () => handleMonthChange("6") },
		{ label: "1Y", onClick: () => handleMonthChange("12") },
	];

	const onDownload = async () => {
		try {
			setDownloading(true);
			const downloadParams = {
				...paramsData,
				download: selectedFormat,
			};
			const res = await dispatch2(
				fetchData(`${getProjectBugsData}${month}`, downloadParams, {
					responseType: "blob",
				})
			);
			saveAs(
				res as any,
				`project_bugs.${selectedFormat === "excel" ? "xlsx" : selectedFormat}`
			);
		} catch (error: any) {
			console.error("Error downloading file:", error);
			showToast(error, "error", true);
		} finally {
			setDownloading(false);
		}
	};

	const getAvailableProjects = (data: any[]) => {
		return data.map((project) => project.project_name);
	};

	const getAvailableSprints = (data: any[]) => {
		const sprints = new Set<string>();
		data.forEach((project) => {
			project.sprints.forEach((sprint: any) => {
				sprints.add(sprint.sprint_name);
			});
		});
		return Array.from(sprints);
	};

	useEffect(() => {
		if (bugsData.length > 0) {
			const projects = getAvailableProjects(bugsData);
			const sprints = getAvailableSprints(bugsData);
			setSelectedProjects(projects);
			setSelectedSprints(sprints);
		}
	}, [bugsData]);

	const filteredData = React.useMemo(() => {
		return bugsData
			.filter((project: any) => selectedProjects.includes(project.project_name))
			.map((project: any) => ({
				...project,
				sprints: project.sprints.filter((sprint: any) =>
					selectedSprints.includes(sprint.sprint_name)
				),
			}));
	}, [bugsData, selectedProjects, selectedSprints]);

	const transformDataForChart = (data: any[]) => {
		// Extract all unique sprint names
		const allSprints = new Set<string>();
		data.forEach((project) => {
			project.sprints.forEach((sprint: any) => {
				allSprints.add(sprint.sprint_name);
			});
		});

		// Create data points for each sprint
		return Array.from(allSprints).map((sprintName) => {
			const dataPoint: any = { sprint_name: sprintName };
			data.forEach((project) => {
				const sprint = project.sprints.find(
					(s: any) => s.sprint_name === sprintName
				);
				if (sprint) {
					dataPoint[project.project_name] = sprint.bug_count || 0;
				}
			});
			return dataPoint;
		});
	};

	const transformedData = useMemo(
		() => transformDataForChart(filteredData),
		[filteredData]
	);

	const paginatedData = useMemo(() => {
		const start = (page - 1) * ITEMS_PER_PAGE;
		return transformedData.slice(start, start + ITEMS_PER_PAGE);
	}, [transformedData, page]);

	const handlePageChange = (
		event: React.ChangeEvent<unknown>,
		value: number
	) => {
		setPage(value);
	};

	const chartBars = useMemo(() => {
		return selectedProjects.map((projectName: string) => (
			<Bar
				key={projectName}
				dataKey={projectName}
				name={projectName}
				fill={getProjectColor(projectName)}
				maxBarSize={80} // Increased bar width
				barSize={60} // Minimum bar width
			/>
		));
	}, [selectedProjects]);

	return (
		<Paper sx={{ m: 2, p: 4 }}>
			<Box display="flex" justifyContent="space-between" alignItems="center">
				<Box
					display="flex"
					justifyContent="center"
					alignItems="center"
					flexDirection="column"
					gap={2}
				>
					<Typography variant="h4">Project Bugs Analysis</Typography>\{" "}
					<MonthButtonGroup buttonData={buttonData} />
				</Box>

				<Box
					display="flex"
					justifyContent="center"
					alignItems="center"
					flexDirection="column"
					gap={2}
				>
					<Box mb={3}>
						<ProjectSprintFilter
							projects={getAvailableProjects(bugsData)}
							selectedProjects={selectedProjects}
							sprints={getAvailableSprints(bugsData)}
							selectedSprints={selectedSprints}
							onProjectChange={setSelectedProjects}
							onSprintChange={setSelectedSprints}
							projectData={bugsData}
						/>
					</Box>
					{downloading ? (
						<Box
							display="flex"
							justifyContent="center"
							alignItems="center"
							gap={2}
						>
							<CircularProgress size="1rem" />
							<Typography>Downloading...</Typography>
						</Box>
					) : (
						<Box
							display="flex"
							justifyContent="center"
							alignItems="center"
							gap={2}
						>
							<Select
								value={selectedFormat}
								onChange={(e) => setSelectedFormat(e.target.value)}
								size="small"
							>
								<MenuItem value="excel">Excel</MenuItem>
								<MenuItem value="csv">CSV</MenuItem>
							</Select>
							<IconButton onClick={onDownload}>
								<DownloadForOfflineIcon />
							</IconButton>
						</Box>
					)}
				</Box>
			</Box>

			{loading ? (
				<Box
					display="flex"
					justifyContent="center"
					alignItems="center"
					height={400}
				>
					<CircularProgress />
				</Box>
			) : (
				<>
					<ResponsiveContainer width="100%" height={CHART_HEIGHT}>
						<BarChart
							data={paginatedData} // Changed from transformedData to paginatedData
							height={CHART_HEIGHT}
							margin={{ top: 10, right: 30, left: 0, bottom: 100 }}
							barGap={5} // Add gap between bars in a group
							barCategoryGap={20} // Add gap between bar groups
						>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis
								dataKey="sprint_name"
								height={20} // Reduced height since we don't need space for labels
							/>
							<YAxis />
							<Tooltip />
							<Legend
								verticalAlign="bottom"
								height={80}
								wrapperStyle={{
									paddingTop: "30px",
									bottom: 0,
								}}
							/>
							{chartBars}
						</BarChart>
					</ResponsiveContainer>

					{/* Add pagination controls */}
					<Box display="flex" justifyContent="center" mt={2}>
						<Pagination
							count={Math.ceil(transformedData.length / ITEMS_PER_PAGE)}
							page={page}
							onChange={handlePageChange}
							color="primary"
							size="large"
						/>
					</Box>
				</>
			)}
		</Paper>
	);
};

export default ProjectBugsChart;
