import React, { useEffect, useMemo, useState } from "react";

import {
  Autocomplete,
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import axios from "axios";
import useAxiosInterceptor from "../../../components/layout/AxiosInstance";

import { enqueueSnackbar } from "notistack";

import {
  ShowForms,
  assign360Forms,
  assignFormsToTeams,
  createTeam,
  deleteForm,
  showTeams,
  viewUsers,
} from "../../../Service/Apis";
import { setId } from "../../../store/FormSlice";
import Navbar from "../../../components/Page Component/Navbar";
import Footer from "../../../components/Page Component/Footer";
import NothingToDisplayImage from "../../asset/NothingToDisplayImage.png";
import { setIsLoading } from "../../../store/LoadingSlice";
import NothingToDisplayComponent from "../../../components/Data/NothingToShowComponent";
import {
  ToastVariant,
  useCustomToast,
} from "../../../components/Methods/SnackBar";
import {
  FetchDataFailureAction,
  FetchDataSuccessAction,
  deleteData,
  fetchData,
  postData,
  putData,
} from "../../../store/actions";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../../../store/Store";
import { postDataSuccess } from "../../../store/AxiosSlice";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import CustomNoRowsOverlay from "../../../components/Data/NoRowsOverlayComponent";
// import { param } from "jquery";

interface Form {
  form_id: string;
  form_name: string;
  created_by: string;
  manager_deadline: string;
  employee_deadline: string;
}

interface Employeetype {
  id: string;
  employee_code: string;
  first_name: string;
  last_name: string;
  gender: string;
  roles: [];
  designation: string;
  date_of_birth: string;
  date_of_joining: string;
  date_of_confirmation: string;
  date_of_exit: string;
  email: string;
  password: string;
}

const initialState: Employeetype = {
  id: "",
  employee_code: "",
  first_name: "",
  last_name: "",
  gender: "",
  roles: [],
  designation: "",
  date_of_birth: "",
  date_of_joining: "",
  date_of_confirmation: "",
  date_of_exit: "",
  email: "",
  password: "",
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  // boxShadow: 24,
  overflow: "auto",
  height: "80%",
  p: 0,
  borderRadius: "25px",
};

const ThreeSixtyCreatedForms = () => {
  const [form, setForm] = useState<Form[]>([]); //setting state of the form
  const [count, setCount] = useState(0); //for triggering useeffect
  const [formId, setFormId] = useState<string>("");
  const [send, setSend] = useState<boolean[]>([]);
  const [openMenuIndex, setOpenMenuIndex] = useState<number | null>(null);
  const [openDeleteIndex, setOpenDeleteIndex] = useState<number | null>(null);
  const [openConfirmBox, setOpenConfirmBox] = useState<number | null>(null);

  const [getTeamsData, setGetTeamsData] = useState([
    {
      team_id: "",
      manager: "",
      manager_name: "",
      project_name: "",
      team: [
        {
          team_id: "",
          team_member_id: "",
          employee_id: "",
          employee_name: "",
        },
      ],
    },
  ]);

  const [sendTeams, setSendTeams] = useState<any[]>([
    // {
    //   team_id: "",
    // },
  ]);
  const [employeesData, setEmployeesData] = useState<Employeetype[]>([]);
  const [employee, setEmployee] = useState<Employeetype>(initialState);
  const [manager, setManager] = useState<Employeetype>(initialState);
  const [organizationLeader, setOrganizationLeader] =
    useState<Employeetype>(initialState);
  const [peers, setPeers] = useState<Employeetype[]>([]);
  const [teamId, setTeamId] = useState<string>("");

  const dispatch = useDispatch();
  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();

  const showToast = useCustomToast();

  const Navigate = useNavigate();

  const handleShowToast = (
    message: string,
    variant: ToastVariant,
    isCloseable: boolean
  ) => {
    showToast(message, variant, isCloseable);
  };

  const handleOpenMenu = (index: number) => {
    setOpenMenuIndex(index);
  };

  // Function to close the menu modal
  const handleCloseMenu = () => {
    setOpenMenuIndex(null);
    setSendTeams([]);
    setSend([]);
  };

  // Function to open the delete confirmation dialog
  const handleOpenDelete = (index: number) => {
    setOpenDeleteIndex(index);
  };

  // Function to close the delete confirmation dialog
  const handleCloseDelete = () => {
    setOpenDeleteIndex(null);
  };

  const newtoken = useSelector((state: any) => state.auth.token);
  const isloading = useSelector((state: any) => state.loading.isLoading);

  const axiosInstance = useAxiosInterceptor();

  //deleting a particular form
  const handleFormDelete = (e: any, form_id: string) => {
    e.preventDefault();
    const token = localStorage.getItem("token");

    try {
      const response = dispatch2(deleteData(`${deleteForm}${form_id}`))
        .then((response) => {
          handleShowToast("Form Deleted Successfully !", "success", true);
          setCount(count + 1);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
    handleCloseDelete();
  };

  //sending forms to particular teams
  // const handleTeamSend = (e: any, team_id: string, index: number) => {
  //   e.preventDefault();
  //   setSend((prevSend) => {
  //     const updatedSend = [...prevSend];
  //     updatedSend[index] = !updatedSend[index];
  //     return updatedSend;
  //   });

  //   // Update the list of selected teams based on the updated send array
  //   setSendTeams((prevTeams) => {
  //     if (send[index]) {
  //       // If the team is selected, remove it from the list
  //       return prevTeams.filter((team) => team.team_id !== team_id);
  //     } else {
  //       // If the team is not selected, add it to the list
  //       return [...prevTeams, { team_id: team_id }];
  //     }
  //   });
  // };

  const handleEmployeeChange = (e: any, values: any) => {
    setEmployee(values);
  };

  const handleManagerChange = (e: any, values: any) => {
    setManager(values);
  };
  const handleOrganizationLeaderChange = (e: any, values: any) => {
    setOrganizationLeader(values);
  };
  const handlePeersChange = (e: any, values: any) => {
    setPeers(values);
  };

  const handleOpenConfirmBox = (e: any, index: number) => {
    if (
      employee === initialState ||
      manager === initialState ||
      organizationLeader === initialState ||
      peers.length === 0
    ) {
      handleShowToast("Please Fill All Fields !", "error", true);
      return;
    }
    if (peers.length > 5) {
      handleShowToast("Maximum 5 peers can be selected.", "error", true);
      return;
    }
    setOpenConfirmBox(index);
  };
  const handleConfirmFormSend = () => {};
  //post for forms
  const handleFormSend = async (e: any, index: number, form_id: string) => {
    e.preventDefault();

    var data = {
      project_name: null,
      manager: manager?.id,
      organization_leader: organizationLeader?.id,
      is_360: true,
      employee_360: employee?.id,
      peer_one: peers[0]?.id,
      peer_two: peers[1]?.id,
      peer_three: peers[2]?.id,
      peer_four: peers[3]?.id,
      peer_five: peers[4]?.id,
      team: peers?.map((peer) => {
        return {
          employee_id: peer?.id,
        };
      }),
    };

    const response = await axios
      .post(`${createTeam}`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        formToTeamSend(form_id, res.data.team_id);
        handleCloseMenu();
        setEmployee(initialState);
        setManager(initialState);
        setOrganizationLeader(initialState);
        setPeers([]);
      })
      .catch((error) => {
        console.log(error);
      });
    // try {
    //   const response = await dispatch2(postData(`${createTeam}`, data))
    //     .then((response: any) => {
    //       // formToTeamSend(form_id, response.data.team_id);
    //     })
    //     .catch((response) => {
    //     });
    // } catch (error) {
    //   console.log(error);
    // }
  };

  const formToTeamSend = async (form_id: string, team_id: string) => {
    var data = {
      form_id: form_id,
      team_ids: [team_id],
    };

    try {
      const response = await dispatch2(postData(assign360Forms, data))
        .then((response) => {
          handleShowToast("Form Sent Successfully !", "success", true);
          dispatch(postDataSuccess(null));
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const rows = form.map((form, index) => {
    return { ...form, index: index, sr_no: index + 1 };
  });

  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: "Sr No.",
        field: "sr_no",
        align: "center",
        headerAlign: "center",
        headerClassName: "header-background",
        width: 125,
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Sr No.
          </strong>
        ),
      },
      {
        headerName: "Form Name",
        field: "form_name",
        align: "left",
        headerAlign: "center",
        headerClassName: "header-background",
        width: 195,
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Form Name
          </strong>
        ),
        renderCell: (params) => (
          <>
            <Link
              id={`${params.row.form_name}_${params.row.index}`}
              to="/viewform"
              onClick={() => {
                dispatch(setId(params.row.form_id));
                localStorage.setItem("formid", params.row.form_id);
              }}
            >
              {params.row.form_name}
            </Link>
          </>
        ),
      },
      // {
      //   headerName: "Manager Deadline",
      //   field: "manager_deadline",
      //   align: "center",
      //   headerAlign: "center",
      //   headerClassName: "header-background",
      //   width: 165,
      //   renderHeader: () => (
      //     <strong className="custom-header" style={{ fontSize: 15 }}>
      //       Manager Deadline
      //     </strong>
      //   ),
      // },
      {
        headerName: "Employee Deadline",
        field: "employee_deadline",
        align: "center",
        headerAlign: "center",
        headerClassName: "header-background",
        width: 195,
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Employee Deadline
          </strong>
        ),
      },
      {
        headerName: "Options",
        field: "options",
        align: "center",
        headerAlign: "center",
        headerClassName: "header-background",
        width: 175,
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Options
          </strong>
        ),
        renderCell: (params) => (
          <>
            <Button
              id={`upload_form_${params.row.index}`}
              variant="contained"
              size="small"
              sx={{ m: 1 }}
              onClick={(e) => {
                // handleCloseNavMenu(params.row.index);
                e.preventDefault();
                handleOpenMenu(params.row.index);
                setFormId(params.row.form_id);
                setOpenConfirmBox(null);
              }}
            >
              Upload
            </Button>
            <Modal
              open={openMenuIndex === params.row.index}
              onClose={() => {
                handleCloseMenu();
              }}
            >
              <>
                <Card elevation={12} sx={style}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                    }}
                  >
                    <IconButton onClick={() => handleCloseMenu()}>
                      <CloseIcon />
                    </IconButton>
                  </Box>
                  <Box>
                    <Box sx={{ m: 1, p: 0 }}>
                      {/* <Card> */}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          m: 1,
                          p: 1,
                        }}
                      >
                        <Typography
                          variant="h4"
                          id="teams_heading"
                          fontWeight={"bold"}
                        >
                          Upload Details
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          // display: "flex",
                          justifyContent: "center",
                          m: 1,
                          p: 1,
                        }}
                      >
                        <Box
                          sx={{ display: "flex", alignItems: "center", p: 1 }}
                        >
                          <InputLabel
                            sx={{ width: "12rem", fontWeight: "bold" }}
                          >
                            Employee :{" "}
                          </InputLabel>
                          <Autocomplete
                            size="small"
                            // disablePortal
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            getOptionLabel={(option) =>
                              option.first_name + " " + option.last_name
                            }
                            value={employee}
                            onChange={handleEmployeeChange}
                            id="employee-autocomplete"
                            options={employeesData}
                            sx={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Box>
                        <Box
                          sx={{ display: "flex", alignItems: "center", p: 1 }}
                        >
                          <InputLabel
                            sx={{ width: "12rem", fontWeight: "bold" }}
                          >
                            Manager :{" "}
                          </InputLabel>
                          <Autocomplete
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            size="small"
                            // disablePortal
                            getOptionLabel={(option) =>
                              option.first_name + " " + option.last_name
                            }
                            value={manager}
                            onChange={handleManagerChange}
                            id="manager-autocomplete"
                            options={employeesData.filter((emp) =>
                              emp.roles.some((role) => role === "Manager")
                            )}
                            sx={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Box>
                        <Box
                          sx={{ display: "flex", alignItems: "center", p: 1 }}
                        >
                          <InputLabel
                            sx={{ width: "12rem", fontWeight: "bold" }}
                          >
                            Organization Leader :{" "}
                          </InputLabel>
                          <Autocomplete
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            size="small"
                            // disablePortal
                            getOptionLabel={(option) =>
                              option.first_name + " " + option.last_name
                            }
                            id="organization-leader-autocomplete"
                            value={organizationLeader}
                            onChange={handleOrganizationLeaderChange}
                            options={employeesData.filter((emp) =>
                              emp.roles.some((role) => role === "Admin")
                            )}
                            sx={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Box>
                        <Box
                          sx={{ display: "flex", alignItems: "center", p: 1 }}
                        >
                          <InputLabel
                            sx={{ width: "12rem", fontWeight: "bold" }}
                          >
                            Peers :{" "}
                          </InputLabel>
                          <Autocomplete
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            size="small"
                            multiple
                            // disablePortal
                            getOptionLabel={(option) =>
                              option.first_name + " " + option.last_name
                            }
                            id="peers-autocomplete"
                            value={peers}
                            onChange={handlePeersChange}
                            options={employeesData}
                            filterOptions={(options, { inputValue }) => {
                              return options.filter(
                                (option) =>
                                  option?.id !== employee.id &&
                                  option?.id !== manager.id &&
                                  option?.id !== organizationLeader.id &&
                                  (
                                    option.first_name.toLowerCase() +
                                    " " +
                                    option.last_name.toLowerCase()
                                  ).includes(inputValue.toLowerCase())
                              );
                            }}
                            sx={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          m: 2,
                          p: 1,
                        }}
                      >
                        <Button
                          id={`form_upload_to_teams`}
                          variant="contained"
                          size="small"
                          type="submit"
                          onClick={(e) => {
                            // handleFormSend(
                            //   e,
                            //   params.row.index,
                            //   params.row.form_id
                            // );
                            handleOpenConfirmBox(e, params.row.index);
                          }}
                        >
                          Proceed To Upload
                        </Button>
                        <Dialog
                          open={openConfirmBox === params.row.index}
                          onClose={() => setOpenConfirmBox(null)}
                          sx={{
                            m: 1,
                            "& .MuiDialog-paper": {
                              width: "30rem",
                              borderRadius: "25px",
                            },
                            // backdropFilter: "blur(5px) sepia(5%)",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "flex-end",
                            }}
                          >
                            <IconButton onClick={() => setOpenConfirmBox(null)}>
                              <CloseIcon />
                            </IconButton>
                          </Box>
                          <DialogTitle>
                            <Typography fontWeight={"bold"} fontSize={22}>
                              Confirm to Upload
                            </Typography>
                          </DialogTitle>
                          <DialogContent sx={{ m: 2, p: 1 }}>
                            <Box>
                              <Typography fontWeight={600}>
                                Please confirm total users and peer count for
                                form to be uploaded
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Typography
                                sx={{ width: "4rem" }}
                                fontSize={16}
                                fontWeight={"bold"}
                              >
                                Total :
                              </Typography>
                              <Typography>{peers.length + 3}</Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Typography
                                sx={{ width: "4rem" }}
                                fontSize={16}
                                fontWeight={"bold"}
                              >
                                Peers :
                              </Typography>
                              <Typography>{peers.length}</Typography>
                            </Box>
                          </DialogContent>
                          <DialogActions>
                            <Button
                              id={`deny_form_send_${params.row.index}`}
                              onClick={() => setOpenConfirmBox(null)}
                              sx={{ m: 1 }}
                            >
                              Cancel
                            </Button>
                            <Button
                              id={`send_form_to_people_${params.row.index}`}
                              variant="contained"
                              sx={{ m: 1 }}
                              onClick={(e) => {
                                handleFormSend(
                                  e,
                                  params.row.index,
                                  params.row.form_id
                                );
                              }}
                            >
                              Upload
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </Box>
                    </Box>
                  </Box>
                </Card>
              </>
            </Modal>
            <Button
              id={`form_delete_${params.row.index}`}
              variant="contained"
              size="small"
              sx={{
                m: 1,
              }}
              onClick={() => {
                handleOpenDelete(params.row.index);
              }}
            >
              Delete
            </Button>

            <Dialog
              open={openDeleteIndex === params.row.index}
              onClose={handleCloseDelete}
              sx={{
                m: 1,
                "& .MuiDialog-paper": {
                  width: "30rem",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <IconButton onClick={() => handleCloseDelete()}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <DialogTitle>Confirmation</DialogTitle>
              <DialogContent>
                <Typography>Do you want to delete?</Typography>
              </DialogContent>
              <DialogActions>
                <Button
                  id={`deny_form_delete_${params.row.index}`}
                  onClick={handleCloseDelete}
                >
                  No
                </Button>
                <Button
                  id={`allow_form_delete_${params.row.index}`}
                  variant="contained"
                  onClick={(e) => {
                    handleFormDelete(e, params.row.form_id);
                  }}
                >
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
          </>
        ),
      },
    ],
    [form, rows]
  );

  //shows teams when click on upload button
  useEffect(() => {
    dispatch(setIsLoading(true));
    const fetchTeamData = async () => {
      try {
        const response = await dispatch2(fetchData(showTeams));
        setGetTeamsData(response as any);

        dispatch(setIsLoading(false));
      } catch (error) {
        console.log(error);
        dispatch(setIsLoading(false));
      }
    };
    fetchTeamData();
  }, []);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        dispatch(setIsLoading(true));
        const response = await dispatch2(fetchData(`${viewUsers}`));
        setEmployeesData(response as Employeetype[]);
        dispatch(setIsLoading(false));
      } catch (error) {
        console.log(error);
        dispatch(setIsLoading(false));
      }
    };

    fetchUserDetails();
  }, [count]);

  //shows forms
  useEffect(() => {
    dispatch(setIsLoading(true));

    const fetchForms = async () => {
      try {
        const response = await dispatch2(fetchData(`${ShowForms}?is_360=True`));
        setForm(response as Form[]);
        dispatch(setIsLoading(false));
      } catch (error) {
        console.log(error);
        dispatch(setIsLoading(false));
      }
    };
    fetchForms();
  }, [count]);

  return (
    <>
      <Box>
        <Box>
          <Box sx={{ display: "flex", justifyContent: "center", m: 2, p: 2 }}>
            <Typography id="form_details" variant="h4">
              Form Details
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", m: 1, p: 1 }}>
            <Paper sx={{ m: 2 }} style={{ width: "fit-content" }}>
              <DataGrid
                autoHeight
                getRowId={(row) => row.form_id}
                disableColumnMenu
                sx={{
                  width: "100%",
                  height: "100%",
                  "--DataGrid-overlayHeight": "50vh",
                }}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pagination
                pageSizeOptions={[5, 10, 15]}
                rows={rows}
                columns={columns}
                disableColumnSelector
                disableDensitySelector
                slots={{
                  toolbar: GridToolbar,
                  noResultsOverlay: CustomNoRowsOverlay,
                  noRowsOverlay: CustomNoRowsOverlay,
                }}
                slotProps={{
                  toolbar: {
                    csvOptions: { disableToolbarButton: true },
                    showQuickFilter: true,
                    printOptions: { disableToolbarButton: true },
                    style: { margin: "0.5rem" },
                  },
                }}
              />
            </Paper>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ThreeSixtyCreatedForms;
