import React, { useState, useEffect } from "react";
import Navbar from "../../components/Page Component/Navbar";
import {
	Box,
	Paper,
	TextField,
	MenuItem,
	Select,
	FormControl,
	InputLabel,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Button,
	FormHelperText,
	CircularProgress,
	Chip,
	IconButton,
} from "@mui/material";
import Footer from "../../components/Page Component/Footer";
import axios from "axios";
import { ThunkDispatch } from "redux-thunk";
import {
	fetchData,
	FetchDataFailureAction,
	FetchDataSuccessAction,
} from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { getAllSkills, getEmployeeSkill } from "../../Service/Apis";
import { months } from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";
import { RootState } from "../../store/Store";
import { makeStyles } from "@material-ui/core/styles";
import Loading from "../../components/Loading/Loading";
import { setIsLoading } from "../../store/LoadingSlice";
import NothingToDisplayComponent from "../../components/Data/NothingToShowComponent";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles({
	tableHead: {
		fontWeight: "bold",
	},
	tableCell: {
		borderRight: "1px solid #ddd",
	},
});
type skils = {
	skill_id: string;
	skill_name: string;
};
type employee = {
	employee_name: string;
	skill_percentage: number;
	utilization: number;
};
const SkilledEmployee = () => {
	const classes = useStyles();
	const [skills, setSkills] = useState<skils[]>([]);
	const [employees, setEmployees] = useState<employee[]>([]);
	const [searchQuery, setSearchQuery] = useState("");
	const [sortOrder, setSortOrder] = useState("desc");
	const [loading, setLoading] = useState(false);
	const [isloading, setIsLoading] = useState(true);
	const dispatch = useDispatch();
	const dispatch2: ThunkDispatch<
		RootState,
		null,
		FetchDataSuccessAction | FetchDataFailureAction
	> = useDispatch();

	useEffect(() => {
		// Fetch skills from API
		try {
			setIsLoading(true);
			dispatch2(fetchData(`${getAllSkills}`))
				.then((res) => {
					if (res && Array.isArray(res)) {
						setSkills(res);
					} else {
						console.error("Invalid response format:", res);
					}
				})
				.catch((error) => {
					console.error("Error fetching skills:", error);
				});
		} catch (e) {
			console.error(e);
		} finally {
			setIsLoading(false);
		}
	}, [dispatch2]);

	const formik = useFormik({
		initialValues: {
			selectedSkills: [],
			selectedMonth: "1",
			maxUtilization: "",
		},
		validationSchema: Yup.object({
			selectedSkills: Yup.array().min(1, "At least one skill is required"),
			selectedMonth: Yup.string().required("Month is required"),
			maxUtilization: Yup.number()
				.required("Max utilization is required")
				.max(100),
		}),
		onSubmit: (values) => {
			// Handle form submission
			setLoading(true);

			const params = {
				skill_id: values.selectedSkills,
				months: values.selectedMonth,
				max_utilization: values.maxUtilization,
			};
			// Fetch employee skills based on form values
			dispatch2(fetchData(`${getEmployeeSkill}`, params))
				.then((res) => {
					if (res && Array.isArray(res)) {
						setEmployees(res);
					} else {
						console.error("Invalid response format:", res);
					}
				})
				.catch((error) => {
					console.error("Error fetching employee skills:", error);
				})
				.finally(() => {
					setLoading(false);
				});
		},
	});

	const handleDelete = (skillToDelete: string) => {
		formik.setFieldValue(
			"selectedSkills",
			formik.values.selectedSkills.filter((skill) => skill !== skillToDelete)
		);
	};

	const handleSearch = (event: any) => {
		setSearchQuery(event.target.value);
	};

	const handleSort = () => {
		const newSortOrder = sortOrder === "desc" ? "asc" : "desc";
		setSortOrder(newSortOrder);
	};

	const filteredEmployees = employees
		.filter((employee) =>
			employee.employee_name.toLowerCase().includes(searchQuery.toLowerCase())
		)
		.sort((a, b) => {
			if (sortOrder === "asc") {
				return a.skill_percentage - b.skill_percentage;
			} else {
				return b.skill_percentage - a.skill_percentage;
			}
		});

	return (
		<>
			<Navbar />
			<Box
				sx={{
					mt: 8,
					ml: 5,
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Box
					sx={{
						width: "70vw",
						mt: 12,
						mb: 10,
					}}
				>
					{isloading ? (
						<>
							<Loading isLoading={isloading as boolean} />
						</>
					) : (
						<Paper sx={{ m: 2, p: 4, background: "#f2f2f2" }}>
							<form onSubmit={formik.handleSubmit}>
								<FormControl
									fullWidth
									margin="normal"
									error={
										formik.touched.selectedSkills &&
										Boolean(formik.errors.selectedSkills)
									}
								>
									<InputLabel id="selectedSkills-label">Skills</InputLabel>
									<Select
										label="Skills"
										labelId="selectedSkills-label"
										id="selectedSkills"
										name="selectedSkills"
										multiple
										value={formik.values.selectedSkills}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
									>
										<MenuItem value="">
											<em>Select skills</em>
										</MenuItem>
										{skills.map((skill) => (
											<MenuItem key={skill.skill_id} value={skill.skill_id}>
												{skill.skill_name}
											</MenuItem>
										))}
									</Select>
									<Box
										sx={{
											display: "flex",
											flexWrap: "wrap",
											gap: 0.5,
											margin: 2,
										}}
									>
										{formik.values.selectedSkills.map((value) => (
											<Chip
												key={value}
												label={
													skills.find((skill) => skill.skill_id === value)
														?.skill_name
												}
												onDelete={() => handleDelete(value)}
												deleteIcon={<CloseIcon />}
											/>
										))}
									</Box>
									{formik.touched.selectedSkills &&
										formik.errors.selectedSkills && (
											<FormHelperText>
												{formik.errors.selectedSkills}
											</FormHelperText>
										)}
								</FormControl>

								<FormControl
									fullWidth
									margin="normal"
									error={
										formik.touched.selectedMonth &&
										Boolean(formik.errors.selectedMonth)
									}
								>
									<InputLabel id="selectedMonth-label">Month</InputLabel>
									<Select
										label="Month"
										labelId="selectedMonth-label"
										id="selectedMonth"
										name="selectedMonth"
										value={formik.values.selectedMonth}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
									>
										{[1, 3, 6, 12].map((month, index) => (
											<MenuItem key={index} value={month}>
												{month}
											</MenuItem>
										))}
									</Select>
									{formik.touched.selectedMonth &&
										formik.errors.selectedMonth && (
											<FormHelperText>
												{formik.errors.selectedMonth}
											</FormHelperText>
										)}
								</FormControl>

								<TextField
									fullWidth
									margin="normal"
									id="maxUtilization"
									name="maxUtilization"
									label="Max Utilization"
									value={formik.values.maxUtilization}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										formik.touched.maxUtilization &&
										Boolean(formik.errors.maxUtilization)
									}
									helperText={
										formik.touched.maxUtilization &&
										formik.errors.maxUtilization
									}
								/>

								<Button
									color="primary"
									variant="contained"
									fullWidth
									type="submit"
								>
									Submit
								</Button>
							</form>
							{employees.length <= 0 && !loading ? (
								<>
									<NothingToDisplayComponent />
								</>
							) : (
								<Box sx={{ mt: 4 }}>
									<TextField
										label="Search"
										value={searchQuery}
										onChange={handleSearch}
									/>
									<TableContainer component={Paper}>
										<Table>
											<TableHead>
												<TableRow>
													<TableCell>Employee Name</TableCell>
													<TableCell>Skill Percentage</TableCell>
													<TableCell>Utilization</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{loading ? (
													<TableRow>
														<TableCell colSpan={3}>
															<Box
																sx={{
																	display: "flex",
																	justifyContent: "center",
																	alignItems: "center",
																	height: "100px", // Adjust height as needed
																}}
															>
																<CircularProgress size={40} />
															</Box>
														</TableCell>
													</TableRow>
												) : (
													filteredEmployees.map((employee, index) => (
														<TableRow key={index}>
															<TableCell className={classes.tableCell}>
																{employee.employee_name}
															</TableCell>
															<TableCell className={classes.tableCell}>
																{employee.skill_percentage}%
															</TableCell>
															<TableCell>{employee.utilization}%</TableCell>
														</TableRow>
													))
												)}
											</TableBody>
										</Table>
									</TableContainer>
								</Box>
							)}
						</Paper>
					)}
				</Box>
			</Box>
			<Footer />
		</>
	);
};

export default SkilledEmployee;
