import React, { useEffect, useState } from "react";
import {
	Box,
	Button,
	Card,
	Checkbox,
	FormControlLabel,
	IconButton,
	Modal,
	Paper,
	Typography,
	Grid,
	Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SendIcon from "@mui/icons-material/Send";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { useDispatch } from "react-redux";
import { useCustomToast } from "../../components/Methods/SnackBar";
import {
	FetchDataFailureAction,
	FetchDataSuccessAction,
	postData,
	postDataSuccess,
} from "../../store/actions";
import CustomNoRowsOverlay from "../../components/Data/NoRowsOverlayComponent";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../../store/Store";
import {
	assignDesignatedDocument,
	getDesignationAssignedDocument,
} from "../../Service/Apis";

interface Team {
	team_id: string;
	project_name: string;
	manager_name: string;
}

interface Designation {
	id: string;
	designation_name: string;
}

interface DesignationAssignmentProps {
	open: boolean;
	onClose: () => void;
	formId: string;
	teams: Team[];
	onSwitchBack: () => void;
}

const style = {
	position: "absolute" as "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "80%", // Adjusted width
	maxWidth: "900px", // Maximum width
	height: "90%",
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 4,
	borderRadius: 2,
	overflow: "auto", // Ensure content is scrollable
};

/**
 * A component that handles the assignment of forms to specific team designations.
 *
 * @component
 * @param {Object} props - Component props
 * @param {boolean} props.open - Controls the visibility of the modal
 * @param {() => void} props.onClose - Callback function to close the modal
 * @param {string} props.formId - ID of the form to be assigned
 * @param {Array<Team>} props.teams - Array of teams available for assignment
 * @param {() => void} props.onSwitchBack - Callback function to switch back to teams view
 *
 * @remarks
 * This component provides a two-step process:
 * 1. Select a team from the available teams list
 * 2. Select one or more designations from the selected team
 *
 * The component features:
 * - Data grid displays for both teams and designations
 * - Real-time designation fetching based on team selection
 * - Form validation before submission
 * - Toast notifications for success/error feedback
 * - Responsive layout with Material-UI components
 *
 * @example
 * ```tsx
 * <DesignationAssignment
 *   open={modalOpen}
 *   onClose={handleClose}
 *   formId="form123"
 *   teams={teamsList}
 *   onSwitchBack={handleSwitchBack}
 * />
 * ```
 */
const DesignationAssignment: React.FC<DesignationAssignmentProps> = ({
	open,
	onClose,
	formId,
	teams,
	onSwitchBack,
}) => {
	const [selectedTeam, setSelectedTeam] = useState<string | null>(null);
	const [designations, setDesignations] = useState<Designation[]>([]);
	const [selectedDesignations, setSelectedDesignations] = useState<string[]>(
		[]
	);
	const dispatch2: ThunkDispatch<
		RootState,
		null,
		FetchDataSuccessAction | FetchDataFailureAction
	> = useDispatch();

	const dispatch = useDispatch();
	const showToast = useCustomToast();

	useEffect(() => {
		const fetchDesignations = async () => {
			if (selectedTeam) {
				try {
					const response = await fetch(
						`${getDesignationAssignedDocument}${selectedTeam}`
					);
					const data = await response.json();
					setDesignations(data);
				} catch (error) {
					console.error("Error fetching designations:", error);
					showToast("Failed to fetch designations.", "error", true); // Use toast for error
				}
			} else {
				setDesignations([]);
			}
		};

		fetchDesignations();
	}, [selectedTeam]);

	const handleTeamSelect = (teamId: string) => {
		if (selectedTeam === teamId) {
			// If clicking on already selected team, deselect it
			setSelectedTeam(null);
		} else {
			// If clicking on a new team, select it
			setSelectedTeam(teamId);
		}
		// Clear selected designations when changing teams
		setSelectedDesignations([]);
	};

	const handleDesignationSelect = (designationId: string) => {
		setSelectedDesignations((prev) =>
			prev.includes(designationId)
				? prev.filter((id) => id !== designationId)
				: [...prev, designationId]
		);
	};

	const handleFormSend = async () => {
		if (!selectedTeam) {
			showToast("Please select a team.", "error", true);
			return;
		}

		if (selectedDesignations.length === 0) {
			showToast("Please select at least one designation.", "error", true);
			return;
		}

		const data = {
			form_id: formId,
			team_id: selectedTeam,
			designation_ids: selectedDesignations,
		};

		try {
			await dispatch2(postData(assignDesignatedDocument, data));
			showToast("Form Assigned Successfully!", "success", true);
			onClose(); // Close the modal after successful assignment
		} catch (error: any) {
			console.error("Error assigning form:", error);
			showToast("Error assigning form: " + error.message, "error", true);
		}
	};

	const teamRows = teams.map((team, index) => ({
		...team,
		id: team.team_id,
		sr_no: index + 1,
	}));

	const teamColumns: GridColDef[] = [
		{ field: "sr_no", headerName: "Sr No.", width: 70 },
		{ field: "project_name", headerName: "Team Name", width: 150 },
		{ field: "manager_name", headerName: "Manager", width: 150 },
		{
			field: "actions",
			headerName: "Select",
			width: 100,
			renderCell: (params) => (
				<Button
					variant={
						params.row.team_id === selectedTeam ? "contained" : "outlined"
					}
					onClick={() => handleTeamSelect(params.row.team_id)}
				>
					{params.row.team_id === selectedTeam ? "Undo" : "Select"}
				</Button>
			),
		},
	];

	const designationRows = designations.map((designation, index) => ({
		...designation,
		id: designation.id,
		sr_no: index + 1,
	}));

	const designationColumns: GridColDef[] = [
		{ field: "sr_no", headerName: "Sr No.", width: 70 },
		{ field: "designation_name", headerName: "Designation", width: 200 },
		{
			field: "select",
			headerName: "Select",
			width: 100,
			renderCell: (params) => (
				<Checkbox
					checked={selectedDesignations.includes(params.row.id)}
					onChange={() => handleDesignationSelect(params.row.id)}
				/>
			),
		},
	];

	return (
		<Modal open={open} onClose={onClose}>
			<Card elevation={12} sx={style}>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						mb: 3,
					}}
				>
					<Button
						variant="outlined"
						onClick={onSwitchBack}
						startIcon={<ArrowBackIcon />}
					>
						Back to Teams View
					</Button>
					<Typography variant="h5" fontWeight="bold">
						Assign Form to Designations
					</Typography>
					<IconButton onClick={onClose}>
						<CloseIcon />
					</IconButton>
				</Box>

				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Paper elevation={3} sx={{ p: 2, borderRadius: 2 }}>
							<Typography variant="subtitle1" fontWeight="bold" mb={2}>
								Step 1: Select Team
							</Typography>
							<DataGrid
								autoHeight
								rows={teamRows}
								columns={teamColumns}
								disableColumnMenu
								density="comfortable"
								pageSizeOptions={[5]}
								initialState={{
									pagination: { paginationModel: { pageSize: 5 } },
								}}
								sx={{
									"& .MuiDataGrid-cell": {
										borderColor: "rgba(0, 0, 0, 0.12)",
									},
									"& .MuiDataGrid-columnHeaders": {
										backgroundColor: "#f5f5f5",
									},
									height: "300px",
								}}
							/>
						</Paper>
					</Grid>

					<Grid item xs={12}>
						<Paper elevation={3} sx={{ p: 2, borderRadius: 2 }}>
							<Typography variant="subtitle1" fontWeight="bold" mb={2}>
								Step 2: Select Designations
								{selectedTeam ? "" : " (Select a team first)"}
							</Typography>
							<DataGrid
								autoHeight
								rows={designationRows}
								columns={designationColumns}
								disableColumnMenu
								density="comfortable"
								pageSizeOptions={[5]}
								initialState={{
									pagination: { paginationModel: { pageSize: 5 } },
								}}
								sx={{
									"& .MuiDataGrid-cell": {
										borderColor: "rgba(0, 0, 0, 0.12)",
									},
									"& .MuiDataGrid-columnHeaders": {
										backgroundColor: "#f5f5f5",
									},
									height: "300px",
									opacity: selectedTeam ? 1 : 0.7,
								}}
							/>
						</Paper>
					</Grid>
				</Grid>

				<Divider sx={{ my: 3 }} />

				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						gap: 2, // Add some gap between the buttons
					}}
				>
					<Button
						variant="contained"
						onClick={handleFormSend}
						startIcon={<SendIcon />}
						disabled={!selectedTeam || selectedDesignations.length === 0}
						sx={{ flexGrow: 1 }} // Make buttons take equal width
					>
						Assign Form
					</Button>
				</Box>
			</Card>
		</Modal>
	);
};

export default DesignationAssignment;
