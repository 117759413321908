import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./AuthSlice";
import personSlice from "./Personslice";
import FormSlice from "./FormSlice";
import loadingSlice from "./LoadingSlice";
import NavbarSlice from "./NavbarSlice";
import ModalSlice from "./ModalSlice";
import AxiosSlice from "./AxiosSlice";
import SkillsSlice from "./SkillsSlice";

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    person: personSlice.reducer,
    form: FormSlice.reducer,
    loading: loadingSlice.reducer,
    navbar: NavbarSlice.reducer,
    modal: ModalSlice.reducer,
    axios: AxiosSlice.reducer,
    skills: SkillsSlice.reducer,
  },
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
