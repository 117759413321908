import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ThunkDispatch } from "@reduxjs/toolkit";
import { deleteSpecificEmployee, showTeams } from "../../Service/Apis";
import {
  ToastVariant,
  useCustomToast,
} from "../../components/Methods/SnackBar";
import Footer from "../../components/Page Component/Footer";
import Navbar from "../../components/Page Component/Navbar";
import { setIsLoading } from "../../store/LoadingSlice";
import { RootState } from "../../store/Store";
import {
  FetchDataFailureAction,
  FetchDataSuccessAction,
  deleteData,
  fetchData,
} from "../../store/actions";

const ViewTeam = () => {
  const [teamDetails, setTeamDetails] = useState<any[]>([]); //for storing team details
  const [count, setCount] = useState(0);
  const isloading = useSelector((state: any) => state.loading.isLoading);
  const counts = useSelector((state: any) => state.loading.count);
  const projectname =
    useSelector((state: any) => state.form.projectName) ||
    localStorage.getItem("projectName");

  const dispatch = useDispatch();
  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();

  // const success = useSelector((state: any) => state.http.data);

  const navigate = useNavigate();

  const showToast = useCustomToast();

  const handleShowToast = (
    message: string,
    variant: ToastVariant,
    isCloseable: boolean
  ) => {
    showToast(message, variant, isCloseable);
  };

  //delete a certain employee
  const handleEmployeeDelete = (
    e: any,
    team_id: string,
    employee_id: string,
    roleIndex: number,
    teamindex: number
  ) => {
    e.preventDefault();

    const totalEmployees = teamDetails[teamindex].team.length;

    if (roleIndex > -1 && totalEmployees > 1) {
      try {
        const response = dispatch2(
          deleteData(
            `${deleteSpecificEmployee}${team_id}&employee_id=${employee_id}`
          )
        )
          .then((response) => {
            setCount(count + 1);
            handleShowToast("Employee Deleted Successfully !", "success", true);
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    } else if (totalEmployees <= 1) {
      handleShowToast("Cannot Delete Last Employee.", "error", true);
    }
  };

  //shows all the teams
  useEffect(() => {
    const fetchTeamsData = async () => {
      try {
        dispatch(setIsLoading(true));
        const response = await dispatch2(
          fetchData(`${showTeams}?team_id=${projectname}`)
        )
          .then((response) => {
            setTeamDetails(response as any[]);
            dispatch(setIsLoading(false));
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
        dispatch(setIsLoading(false));
      }
    };
    fetchTeamsData();
  }, [count, counts]);
  return (
    <>
      <Box>
        <Navbar />
      </Box>
      <Box sx={{ mt: 12, mb: 10 }}>
        <Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Typography fontWeight={"bold"} variant="h4">
              {teamDetails[0]?.project_name}
            </Typography>
          </Box>
          <Box sx={{ m: 2, p: 2 }}>
            <Box sx={{ display: "flex", justifyContent: "center", m: 1, p: 1 }}>
              <Typography fontWeight={"bold"} sx={{ m: 1 }}>
                Manager :{" "}
              </Typography>
              <Typography sx={{ m: 1, ml: 1, width: "20rem" }}>
                {teamDetails[0]?.manager_name}
              </Typography>
            </Box>
            <Box sx={{ m: 2, p: 2 }}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography fontWeight={"bold"} sx={{ m: 1 }}>
                  Employees :{" "}
                </Typography>
                <Box sx={{ width: "20rem" }}>
                  <TableContainer
                    component={Paper}
                    sx={{ width: "fit-content" }}
                  >
                    <TableHead className="header-background">
                      <TableRow>
                        <TableCell
                          sx={{ textAlign: "center", fontWeight: "bold" }}
                        >
                          Sr. No.
                        </TableCell>
                        <TableCell
                          sx={{ textAlign: "center", fontWeight: "bold" }}
                        >
                          Name
                        </TableCell>
                        <TableCell
                          sx={{ textAlign: "center", fontWeight: "bold" }}
                        >
                          Designation
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {teamDetails[0]?.team?.map((emp: any, index: number) => {
                        return (
                          <TableRow>
                            <TableCell sx={{ textAlign: "center" }}>
                              {index + 1 + ") "}
                            </TableCell>
                            <TableCell sx={{ textAlign: "center" }}>
                              {emp?.employee_name}
                            </TableCell>
                            <TableCell sx={{ textAlign: "center" }}>
                              {emp?.designation}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </TableContainer>
                  <Box
                    sx={{ display: "flex", justifyContent: "center", pt: 3 }}
                  >
                    <Button
                      id="backButton"
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        navigate("/createteam");
                      }}
                    >
                      Back
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box>
        <Footer />
      </Box>
    </>
  );
};

export default ViewTeam;
