import React, { useEffect, useState } from "react";
import {
	Badge,
	Box,
	Button,
	Card,
	Container,
	Grid,
	Tab,
	Tabs,
	Typography,
} from "@mui/material";

import { enqueueSnackbar } from "notistack";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import useAxiosInterceptor from "../../components/layout/AxiosInstance";
import { useDispatch, useSelector } from "react-redux";
import {
	ShowForms,
	ShowParticularForms,
	ViewUser,
	viewHierarchicalFormall,
} from "../../Service/Apis";
import { setFields } from "../../store/Personslice";
import {
	setFormTeamId,
	setId,
	setManager,
	setRole,
} from "../../store/FormSlice";
import { setSelectedRole } from "../../store/AuthSlice";
import { setIsLoading } from "../../store/LoadingSlice";
import Navbar from "../../components/Page Component/Navbar";
import Footer from "../../components/Page Component/Footer";
import Skeletons from "../../components/Skeleton/Skeletons";
import HRDashboard from "./HRDashboard";
import ManagerDashboaard from "./ManagerDashboaard";
import AdminDashboard from "./AdminDashboard";
import NothingToDisplayImage from "../../asset/NothingToDisplayImage.png";
import HrReviewForm from "./HrReviewForm";
import EmployeePreviousResponses from "../Form View/EmployeePreviousResponses";
import { setRoles } from "./../../store/AuthSlice";
import {
	FetchDataFailureAction,
	FetchDataSuccessAction,
	fetchData,
} from "../../store/actions";
import { RootState } from "../../store/Store";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { CustomTabPanel, a11yProps } from "../../components/TabPanel/TabPanel";
import ThreeSixtyEmployeeDashboard from "./ThreeSixtyForm/ThreeSixtyEmployeeDashboard";
import {
	ToastVariant,
	useCustomToast,
} from "../../components/Methods/SnackBar";
import FeedBackFormDashboard from "./FeedBackForm/FeedBackFormDashboard";
import NothingToDisplayComponent from "../../components/Data/NothingToShowComponent";

interface FormType {
	project_name: string;
	team_id: string;
	forms: [];
}

const Dashboard = () => {
	const [form, setForm] = useState<FormType[]>([
		{
			project_name: "",
			team_id: "",
			forms: [],
		},
	]);
	const [value, setValue] = React.useState(0);
	const [formCount, setFormCount] = useState<number | null>(null);

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const newToken =
		useSelector((state: any) => state.auth.token) ||
		localStorage.getItem("token");
	const isLoading = useSelector((state: any) => state.loading.isLoading);
	const filledForms = useSelector((state: any) => state.form.filledFormId);

	const axiosInstance = useAxiosInterceptor();
	const emailId = localStorage.getItem("email");

	const dispatch2: ThunkDispatch<
		RootState,
		null,
		FetchDataSuccessAction | FetchDataFailureAction
	> = useDispatch();

	const showToast = useCustomToast();
	const handleShowToast = (
		message: string,
		variant: ToastVariant,
		isCloseable: boolean
	) => {
		showToast(message, variant, isCloseable);
	};
	const selectedRole = useSelector((state: any) => state.auth.selectedRole);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		const tempElement = document.createElement("div");
		tempElement.innerHTML = event.currentTarget.innerHTML;
		const tabLabel = tempElement.innerText;

		// Clean up the temporary element
		tempElement.remove();
		setValue(newValue);
		handleShowToast(`Switched to ${tabLabel}`, "info", true);
	};

	//Navigate to View Responses Submitted by Employee.
	const handleViewResponse = (e: any, formId: string, formTeamId: string) => {
		e.preventDefault();
		dispatch(setFormTeamId(formTeamId));
		localStorage.setItem("formTeamId", formTeamId);
		dispatch(setId(formId));
		localStorage.setItem("formid", formId);
		navigate("/showresponse");
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				const token = localStorage.getItem("token");
				const authToken = newToken.access ?? token;
				console.log("authToken", authToken);
				if (authToken) {
					const response = await axiosInstance.get(`${ViewUser}${emailId}`, {
						headers: { Authorization: `Bearer ${authToken}` },
					});
					const person = response.data[0];

					dispatch(setFields(person));
					if (person && person.roles) {
						localStorage.setItem("role", JSON.stringify(person.roles));
						dispatch(setRoles(person?.roles));
						dispatch(setRole(person?.roles[0]));
						if (!selectedRole) {
							dispatch(setSelectedRole(person?.roles[0]));
						}
					}
				}
			} catch (error: any) {
				console.error(error);
			}
		};
		if (emailId && newToken) {
			fetchData();
		}
	}, [emailId, newToken]);

	useEffect(() => {
		dispatch(setIsLoading(true));
		const token = localStorage.getItem("token");
		axiosInstance
			.get(ShowParticularForms, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			})
			.then((res) => {
				setForm(res.data);
				setFormCount(
					res.data.reduce(
						(count: any, formItem: any) =>
							count +
							formItem.forms.filter(
								(form: any) => form.final_submit_done === false
							).length,
						0
					)
				);
				dispatch(setIsLoading(false));
			});
	}, []);

	// useEffect(() => {
	// 	dispatch(setIsLoading(true));
	// 	const token = localStorage.getItem("token");
	// 	axiosInstance
	// 		.get(viewHierarchicalFormall, {
	// 			headers: {
	// 				"Content-Type": "application/json",
	// 				Authorization: `Bearer ${token}`,
	// 			},
	// 		})
	// 		.then((res) => {
	// 			setForm(prevFormData => [...prevFormData, ...res.data]);
	// 			dispatch(setIsLoading(false));
	// 		});
	// }, []);

	//To Navigate to Fill Form.
	const handleSubmit = (
		formId: string,
		formTeamId: string,
		manager_name: string
	) => {
		dispatch(setId(formId));
		localStorage.setItem("formid", formId);
		dispatch(setFormTeamId(formTeamId));
		localStorage.setItem("formTeamId", formTeamId);
		localStorage.setItem(
			"feedbackRole",
			JSON.stringify({
				is_employee_feedback: false,
				is_manager_feedback: false,
			})
		);
		dispatch(setManager(manager_name));
		navigate("/fillForm");
	};

	//Check for role changed and switch between Dashboards.
	const newRole = useSelector((state: any) => state.auth.selectedRole);

	if (newRole === "HR" || newRole === "hr" || newRole === "Hr") {
		return <HRDashboard />;
	}

	if (newRole === "Manager" || newRole === "manager" || newRole === "MANAGER") {
		return <ManagerDashboaard />;
	}

	if (newRole === "ADMIN" || newRole === "Admin" || newRole === "admin") {
		return <AdminDashboard />;
	}

	if (isLoading) {
		return <Skeletons />;
	}

	return (
		<>
			<Navbar />
			<Box sx={{ mt: 12, mb: 10 }}>
				<Tabs
					centered
					value={value}
					onChange={handleChange}
					textColor="inherit"
					indicatorColor="secondary"
					aria-label="secondary tabs example"
				>
					<Tab
						{...a11yProps(0)}
						label={
							<Badge
								sx={{ p: 0.6 }}
								color="primary"
								badgeContent={formCount !== 0 ? formCount : null}
							>
								Appraisal
							</Badge>
						}
					></Tab>
					<Tab {...a11yProps(1)} label="360 &deg;" />
					<Tab {...a11yProps(2)} label="Feedback Form" />
				</Tabs>
				<CustomTabPanel value={value} index={0}>
					<Container sx={{}}>
						<Grid
							id="grid_container"
							container
							spacing={8}
							sx={{
								justifyContent: "center",
								display: "flex",
								// mt: 0,
							}}
						>
							{form.length === 0 ? (
								<Grid
									id="no_data_image_grid"
									item
									xs={12}
									sx={{ display: "flex", justifyContent: "center" }}
								>
									<Box
										id="no_data_image_card"
										// elevation={11}
										sx={{
											m: 1,
											p: 1,
											// "&:hover": {
											// 	boxShadow: "0px 0px 10px 2px rgba(0, 100, 0, 0.5)",
											// },
											display: "flex",
											flexDirection: "column",
											justifyContent: "center",
											alignItems: "center",
											// boxShadow: 20,
											// height: "100%",
										}}
									>
										<NothingToDisplayComponent />
									</Box>
								</Grid>
							) : (
								form.map((team: any) =>
									team.forms.map((form: any, index: number) => (
										<Grid
											id={`grid_${index + 1}`}
											item
											xs={12}
											sm={4}
											md={4.5}
											key={form.form_id}
											sx={{
												display: "flex",
												// justifyContent: "center",
											}}
										>
											<Card
												id={`form_card_${index + 1}`}
												elevation={11}
												sx={{
													m: 1,
													p: 1,
													display: "flex",
													flexDirection: "column",
													justifyContent: "space-between",
													height: "100%",
													width: "100%",
												}}
											>
												<Box
													sx={{
														display: "flex",
														justifyContent: "center",
														m: 1,
														p: 1,
													}}
												>
													<Typography
														fontWeight={"bold"}
														variant="h6"
														id={`form_name_${index + 1}`}
													>
														{form.form_name}
													</Typography>
												</Box>
												<Box sx={{ m: 1, p: 1 }}>
													<Box sx={{ display: "flex" }}>
														<Typography fontWeight={"bold"} sx={{ m: 1 }}>
															Team :{" "}
														</Typography>
														<Typography
															paragraph
															sx={{ m: 1 }}
															id={`team_name_${index + 1}`}
														>
															{team.project_name}
														</Typography>
													</Box>
													<Box sx={{ display: "flex" }}>
														<Typography fontWeight={"bold"} sx={{ m: 1 }}>
															Employee Deadline :{" "}
														</Typography>
														<Typography
															paragraph
															sx={{ m: 1 }}
															id={`employee_deadline_${index + 1}`}
														>
															{form.employee_deadline}
														</Typography>
													</Box>
													<Box sx={{ display: "flex" }}>
														<Typography fontWeight={"bold"} sx={{ m: 1 }}>
															Managed By :{" "}
														</Typography>
														<Typography
															paragraph
															sx={{ m: 1 }}
															id={`manager_name_${index + 1}`}
														>
															{team.manager}
														</Typography>
													</Box>
												</Box>
												<Box
													sx={{
														display: "flex",
														justifyContent: "center",
														m: 1,
														p: 1,
													}}
												>
													{!form.final_submit_done ? (
														<Button
															id={`go_to_form_button_${index + 1}`}
															variant="contained"
															size="small"
															onClick={() => {
																handleSubmit(
																	form.form_id,
																	form.form_team_id,
																	team.manager
																);
																localStorage.setItem(
																	"Role360",
																	JSON.stringify({
																		isEmployee: false,
																		isManager: false,
																		isOrganizationLeader: false,
																		isPeer: false,
																	})
																);
															}}
														>
															Go to Form
														</Button>
													) : (
														<Button
															id={`view_response_button_${index + 1}`}
															variant="contained"
															size="small"
															onClick={(e) => {
																handleViewResponse(
																	e,
																	form.form_id,
																	form.form_team_id
																);
															}}
														>
															View Response
														</Button>
													)}
												</Box>
											</Card>
										</Grid>
									))
								)
							)}
						</Grid>
					</Container>
				</CustomTabPanel>
				<CustomTabPanel value={value} index={1}>
					<ThreeSixtyEmployeeDashboard />
				</CustomTabPanel>
				<CustomTabPanel value={value} index={2}>
					<FeedBackFormDashboard />
				</CustomTabPanel>
			</Box>

			<Footer />
		</>
	);
};

export default Dashboard;
