import {
  Box,
  Button,
  Card,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { startOfMonth } from "date-fns";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import NothingToDisplayComponent from "../../components/Data/NothingToShowComponent";
import NumberInput from "../../components/NumberInput/NumberInput";
import { DataPointEmployeeResponses, data } from "./Types/DataPointsTypes";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import {
  assinedDataPoints,
  fillDataPoint,
  viewUsers,
} from "../../Service/Apis";
import { setIsLoading } from "../../store/LoadingSlice";
import { RootState } from "../../store/Store";
import {
  FetchDataSuccessAction,
  FetchDataFailureAction,
  fetchData,
  postData,
  DataPayload,
} from "../../store/actions";
import {
  useCustomToast,
  ToastVariant,
} from "../../components/Methods/SnackBar";
import Loading from "../../components/Loading/Loading";

const FillDataPoint = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [responseDate, setResponseDate] = useState<Dayjs | null>(
    dayjs().subtract(1, "month").startOf("month")
  );
  const [assignedDataPoints, setAssignedDataPoints] = useState<
    DataPointEmployeeResponses[]
  >([]);
  const [selectedDataPoint, setSelectedDataPoint] =
    useState<DataPointEmployeeResponses | null>(null);
  const [keyUpdate, setKeyUpdate] = useState(0);

  // Redux Hooks
  const dispatch = useDispatch();
  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();

  const showToast = useCustomToast();

  const isLoading = useSelector((state: any) => state.loading.isLoading);

  const handleShowToast = (
    message: string,
    variant: ToastVariant,
    isCloseable: boolean
  ) => {
    showToast(message, variant, isCloseable);
  };

  // Open Dialog Function
  const handleDialogOpen = (dataPoint: any) => {
    setSelectedDataPoint(dataPoint);
    setOpenDialog(true);
    setKeyUpdate(keyUpdate + 1);
  };

  // Close Dialog Function
  const handleDialogClose = () => {
    setSelectedDataPoint((prevData: any) => {
      const updatedQuestions = prevData?.questions
        ? [...prevData.questions]
        : [];
      if (updatedQuestions.length !== 0) {
        updatedQuestions.map((question) => {
          question.data_point_response = null;
        });
      }
      return { ...prevData, questions: updatedQuestions };
    });
    setOpenDialog(false);
  };

  const handleNumberInputChange = (e: any, newValue: number, index: number) => {
    const roundedValue = newValue ? parseFloat(newValue.toFixed(1)) : 0;
    // setSelectedDataPoint(null);
    setSelectedDataPoint((prevData: any) => {
      const updatedQuestions = [...prevData.questions];
      if (updatedQuestions[index]) {
        updatedQuestions[index].data_point_response = roundedValue;
      }

      return { ...prevData, questions: updatedQuestions };
    });
  };

  const handleSave = async () => {
    const data = selectedDataPoint?.questions.map((question) => ({
      data_point_assign_id: selectedDataPoint?.data_point_assign_id,
      date: responseDate?.format("YYYY-MM-DD"),
      data_point_question_id: question.data_point_question_id,
      data_point_response: question.data_point_response,
    }));

    console.log(data);

    const hasNullOrUndefinedResponse = data?.some(
      (question) =>
        question.data_point_response === null ||
        question.data_point_response === undefined
    );

    if (hasNullOrUndefinedResponse) {
      handleShowToast("Please fill all fields !", "error", true);
      return;
    }

    try {
      dispatch(setIsLoading(true));
      const response = await dispatch2(
        postData(`${fillDataPoint}`, data as DataPayload)
      );
      handleShowToast("Response recorded !", "success", true);
      console.log(response);

      setSelectedDataPoint(null);
      setKeyUpdate(keyUpdate + 1);
      handleDialogClose();
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  useEffect(() => {
    // API Call get the User Details.
    const fetchDataPointsResponse = async () => {
      try {
        dispatch(setIsLoading(true));
        const response = await dispatch2(fetchData(`${assinedDataPoints}`));
        setAssignedDataPoints(response as DataPointEmployeeResponses[]);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setIsLoading(false));
      }
    };

    fetchDataPointsResponse();
  }, []);

  if (isLoading) {
    return (
      <>
        <Loading isLoading={isLoading as boolean} />
      </>
    );
  }

  return (
    <Container sx={{}}>
      <Grid
        id="grid_container"
        container
        spacing={8}
        sx={{
          justifyContent: "center",
          display: "flex",
          // mt: 0,
        }}
      >
        {assignedDataPoints.length === 0 ? (
          <Grid
            id="no_data_image_grid"
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Box
              id="no_data_image_card"
              // elevation={11}
              sx={{
                m: 1,
                p: 1,
                // "&:hover": {
                // 	boxShadow: "0px 0px 10px 2px rgba(0, 100, 0, 0.5)",
                // },
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                // boxShadow: 20,
                // height: "100%",
              }}
            >
              <NothingToDisplayComponent />
            </Box>
          </Grid>
        ) : (
          assignedDataPoints.map((data: any, index: number) => (
            <Grid
              id={`grid_${index + 1}`}
              item
              xs={12}
              sm={4}
              md={4}
              sx={{
                display: "flex",
                // justifyContent: "center",
              }}
              key={index}
            >
              <Card
                id={`form_card_${index + 1}`}
                elevation={3}
                sx={{
                  m: 1,
                  p: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "100%",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    p: 1,
                  }}
                >
                  <Typography
                    fontWeight={"bold"}
                    variant="h6"
                    id={`form_name_${index + 1}`}
                  >
                    {data.data_point_name}
                  </Typography>
                </Box>
                <Box sx={{ p: 1 }}>
                  <Box sx={{ display: "flex" }}>
                    <Typography fontWeight={"bold"} sx={{ m: 1 }}>
                      Team :{" "}
                    </Typography>
                    <Typography
                      paragraph
                      sx={{ m: 1 }}
                      id={`team_name_${index + 1}`}
                    >
                      {data.project_name}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <Typography fontWeight={"bold"} sx={{ m: 1 }}>
                      Department :{" "}
                    </Typography>
                    <Typography
                      paragraph
                      sx={{ m: 1 }}
                      id={`employee_deadline_${index + 1}`}
                    >
                      {data.department_name}
                    </Typography>
                  </Box>
                  {/* <Box sx={{ display: "flex" }}>
                    <Typography fontWeight={"bold"} sx={{ m: 1 }}>
                      Month & Year :{" "}
                    </Typography>
                    <Typography
                      paragraph
                      sx={{ m: 1 }}
                      id={`manager_name_${index + 1}`}
                    >
                      {team.manager}
                    </Typography>
                  </Box> */}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    // m: 1,
                    p: 1,
                  }}
                >
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => handleDialogOpen(data)}
                  >
                    Go To Form
                  </Button>
                  <Dialog
                    id={`fullscreen-dialog-${index}`}
                    open={openDialog}
                    onClose={handleDialogClose}
                    fullScreen
                    sx={{ overflow: "auto" }}
                  >
                    {selectedDataPoint && (
                      <>
                        {/* Dialog Title */}
                        <DialogTitle
                          id={`dialog-title-${index}`}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            m: 2,
                            p: 2,
                          }}
                        >
                          <Typography
                            id={`dialog-title-typography-${index}`}
                            fontWeight={"bold"}
                            variant="h4"
                            color={"#007300"}
                          >
                            {`Questions for ${selectedDataPoint.data_point_name}`}
                          </Typography>
                        </DialogTitle>
                        {/* Dialog Content */}
                        <DialogContent
                          id={`dialog-content-${index}`}
                          sx={{ ml: 5, mr: 5, mt: 2, p: 2 }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "right",
                              mb: 2,
                              // p: 1,
                            }}
                          >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <DatePicker
                                  label="From"
                                  views={["month", "year"]}
                                  sx={{
                                    ".MuiInputLabel-root": {
                                      transform:
                                        "translate(14px, 8px) scale(1)",
                                    },
                                    ".MuiInputLabel-shrink": {
                                      transform:
                                        "translate(14px, -6px) scale(0.75)",
                                    },
                                    ".MuiInputBase-root .MuiInputBase-input": {
                                      /* Your styles here */
                                      height: "0.55rem",
                                      width: "8rem",
                                    },
                                    mx: 1,
                                  }}
                                  shouldDisableDate={(date) => {
                                    const currentDate = dayjs();
                                    // Check if the date is in the current month and year
                                    const isCurrentMonthYear =
                                      date.isSame(currentDate, "month") &&
                                      date.isSame(currentDate, "year");
                                    // Disable the date if it's in the current month and year
                                    return isCurrentMonthYear;
                                  }}
                                  value={responseDate}
                                  onChange={(newValue) =>
                                    setResponseDate(
                                      newValue
                                        ? dayjs(startOfMonth(newValue.toDate()))
                                        : null
                                    )
                                  }
                                  disableFuture
                                />
                              </Box>
                            </LocalizationProvider>
                          </Box>
                          {selectedDataPoint.questions.map(
                            (question: any, questionIndex: number) => (
                              // Question Card
                              <Card
                                key={questionIndex}
                                id={`question-card-${index}-${questionIndex}`}
                                sx={{
                                  mb: 2,
                                  p: 2,
                                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", // Add boxShadow here
                                  border: "1px solid #ccc",
                                }}
                                elevation={5}
                              >
                                {/* Question Text */}
                                <Box
                                  id={`question-text-${index}-${questionIndex}`}
                                >
                                  <Typography fontWeight={"bold"} variant="h6">
                                    {questionIndex + 1 + ") "}
                                    {question.data_point_question}
                                  </Typography>
                                </Box>
                                {/* Rating */}
                                <Box
                                  id={`rating-box-${index}-${questionIndex}`}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    m: 1.5,
                                    p: 1,
                                  }}
                                >
                                  <NumberInput
                                    key={keyUpdate}
                                    name="paid_leaves"
                                    min={0}
                                    aria-label="Demo number input"
                                    placeholder="Input a value..."
                                    value={question.data_point_response}
                                    max={100}
                                    onChange={(e: any, val: number) => {
                                      // e.preventDefault();
                                      handleNumberInputChange(
                                        e,
                                        val,
                                        questionIndex
                                      );
                                    }}
                                  />
                                </Box>
                              </Card>
                            )
                          )}
                        </DialogContent>
                        {/* Dialog Actions */}
                        <DialogActions
                          id={`dialog-actions-${index}`}
                          sx={{ m: 2, p: 2 }}
                        >
                          <Button
                            id={`save-button-${index}`}
                            variant="contained"
                            onClick={() => {
                              handleSave();
                            }}
                          >
                            Save
                          </Button>
                          <Button
                            id={`close-button-${index}`}
                            variant="outlined"
                            onClick={handleDialogClose}
                          >
                            Close
                          </Button>
                        </DialogActions>
                      </>
                    )}
                  </Dialog>
                </Box>
              </Card>
            </Grid>
          ))
        )}
      </Grid>
    </Container>
  );
};

export default FillDataPoint;
