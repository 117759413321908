import axios from "axios";
import { useNavigate } from "react-router-dom";

const useAxiosInterceptor = () => {
  const navigate = useNavigate();
  let isLoggedIn = true; // Set this flag to true initially

  const handleUnauthorized = () => {
    if (isLoggedIn) {
      isLoggedIn = false; // Set the flag to false after the first 401 response
      // enqueueSnackbar("Kindly Login Again !", {
      //   variant: "default",
      //   anchorOrigin: { vertical: "top", horizontal: "left" },
      //   autoHideDuration: 2000,
      // });

      navigate("/logout");
    }
  };

  // Create an instance of Axios
  const axiosInstance = axios.create();

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    (config) => {
      // Modify config, such as adding headers, before the request is sent
      return config;
    },
    (error) => {
      // Handle request error
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  axiosInstance.interceptors.response.use(
    (response) => {
      // Return the response if it's successful
      return response;
    },
    (error) => {
      // Handle response error
      if (error.response && error.response.status === 401) {
        handleUnauthorized();
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

export default useAxiosInterceptor;
