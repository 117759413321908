import React, { useEffect } from "react";
import {
	DataPayload,
	FetchDataFailureAction,
	FetchDataSuccessAction,
	fetchData,
	patchData,
} from "../../store/actions";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { setIsLoading } from "../../store/LoadingSlice";
import { RootState } from "../../store/Store";
import { ViewUser } from "../../Service/Apis";

import { useDispatch, useSelector } from "react-redux";
import {
	setIsLoggedIn,
	setRoles,
	setSelectedRole,
} from "./../../store/AuthSlice";
import { useNavigate, useLocation } from "react-router-dom";
import { getSidebarItems } from "../Data/data";
import axios from "axios";

interface LayoutProps {
	children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const sidebarItems = useSelector((state: any) => state.navbar.sidebarItems);
	const isLoggedIn = useSelector((state: any) => state.auth.isLoggedIn);
	const dispatch2: ThunkDispatch<
		RootState,
		null,
		FetchDataSuccessAction | FetchDataFailureAction
	> = useDispatch();
	const email = localStorage.getItem("email");

	const roles = useSelector((state: RootState) => state.auth.roles);
	useEffect(() => {
		const token = localStorage.getItem("token");
		const isAuthenticated = token !== null;
		dispatch(setIsLoggedIn(isAuthenticated));

		const fetchUserData = async () => {
			try {
				if (!token) return;

				dispatch(setIsLoading(true));
				const response = await dispatch2(fetchData(`${ViewUser}${email}`));
				const userData = response; // Extract the user data payload

				dispatch(setRoles(userData[0]?.roles)); // Dispatch the roles to Redux

				dispatch(setIsLoading(false));
			} catch (error) {
				console.error("Error fetching user data:", error);
				dispatch(setIsLoading(false));
			}
		};

		if (!isAuthenticated && !email) {
			navigate("/login");
		} else {
			fetchUserData();
		}
	}, [dispatch, navigate, location]);

	const checkAuthorization = (roleArray: string[]): boolean => {
		const userRoles = roleArray.map((role: string) => role.toLowerCase());
		const authorizedItems = getSidebarItems(userRoles);

		const currentRoute = window.location.pathname.replace("/", "");

		const isAuthorized =
			currentRoute === "dashboard" ||
			currentRoute === "profile" ||
			currentRoute === "viewform" ||
			currentRoute === "fillForm" ||
			currentRoute === "managerreview" ||
			currentRoute === "reviewForm" ||
			currentRoute === "logout" ||
			currentRoute === "editform" ||
			currentRoute === "showresponse" ||
			currentRoute === "showspecificresponse" ||
			currentRoute === "showspecificresponsehr" ||
			currentRoute === "managerreview" ||
			currentRoute === "download" ||
			currentRoute === "downloadadmin" ||
			currentRoute === "employeepreviousresponse" ||
			currentRoute === "addEmployee" ||
			currentRoute === "viewEmployee" ||
			currentRoute === "addTeam" ||
			currentRoute === "editTeam" ||
			currentRoute === "viewTeam" ||
			currentRoute === "forgotpassword" ||
			currentRoute === "showthreesixtyformresponse" ||
			currentRoute === "employee360previousresponse" ||
			currentRoute === "addoreditskills" ||
			currentRoute === "showfeedbackresponse" ||
			// currentRoute === "datapoints" ||
			currentRoute === "utilization" ||
			currentRoute === "skill-based-employee";
		currentRoute === "historical-appraisal" ||
			authorizedItems.some((item: any) => item.route === currentRoute);
		return isAuthorized;
	};

	const handleUnauthorizedError = () => {
		navigate("/login");
	};

	useEffect(() => {
		const axiosInstance = axios.create();

		// Register the response error handler interceptor
		const errorInterceptor = axiosInstance.interceptors.response.use(
			(response) => response,
			(error) => {
				if (error.response?.status === 401) {
					handleUnauthorizedError();
				}
				return Promise.reject(error);
			}
		);

		return () => {
			// Cleanup the interceptor when the component unmounts
			axiosInstance.interceptors.response.eject(errorInterceptor);
		};
	}, []);

	return <>{children}</>;
};

export default Layout;
