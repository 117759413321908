import React from "react";

import { Button, Container, Typography } from "@mui/material";

import Review from "./Review";
import Navbar from "../../components/Page Component/Navbar";
import Footer from "../../components/Page Component/Footer";
import Sidebar from "../../components/Page Component/Sidebar";

const SuperAdminreview = () => {
  return (
    <>
      <Container>
        <Navbar />
        <Review />
        <Footer />
        {/* <Typography>
      

      </Typography> */}
      </Container>
    </>
  );
};

export default SuperAdminreview;
