import * as React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Box from "@mui/material/Box";
import { ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";

interface buttonDataProps {
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  label: string;
}

interface MonthButtonGroupProps {
  buttonData: buttonDataProps[];
}

export default function MonthButtonGroup({
  buttonData,
}: MonthButtonGroupProps) {
  const [alignment, setAlignment] = React.useState(buttonData[0].label);

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setAlignment(newAlignment);
  };

  const control = {
    value: alignment,
    onChange: handleChange,
    exclusive: true,
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "& > *": {
          m: 1,
        },
      }}
    >
      <ToggleButtonGroup size="small" {...control} aria-label="Small sizes">
        {buttonData.map((data) => (
          <ToggleButton
            key={data.label}
            onClick={data.onClick}
            value={data.label}
            selected={alignment === data.label}
          >
            <Typography>{data.label}</Typography>
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Box>
  );
}
