import { useEffect, useMemo, useState } from "react";
import {
	Box,
	CircularProgress,
	IconButton,
	MenuItem,
	Paper,
	Select,
	Typography,
	Checkbox,
	FormControlLabel,
	Grid,
	Switch,
} from "@mui/material";
import {
	fetchData,
	FetchDataFailureAction,
	FetchDataSuccessAction,
} from "../../../store/actions";
import { getNonComplianceData } from "../../../Service/Apis";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../../../store/Store";
import { useDispatch, useSelector } from "react-redux";
import MonthButtonGroup from "../../utilization/MonthsButtonGroup";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { saveAs } from "file-saver";
import { useCustomToast } from "../../Methods/SnackBar";
import BarChartComponent from "../../charts/BarChartComponent";

const NonComplianceChart = () => {
	const dispatch2: ThunkDispatch<
		RootState,
		null,
		FetchDataSuccessAction | FetchDataFailureAction
	> = useDispatch();
	const [nonComplianceData, setNonComplianceData] = useState<any>([]);
	const [teamName, setTeamName] = useState<string>("");
	const [month, setMonth] = useState<string>("1");
	const [selectedFormat, setSelectedFormat] = useState<string>("excel");
	const showToast = useCustomToast();
	const [loading, setLoading] = useState(false);
	const [downloading, setDownloading] = useState(false);
	const [selectedEmployees, setSelectedEmployees] = useState<string[]>([]);
	const [selectAll, setSelectAll] = useState(true);

	const nrole = useSelector((state: any) => state.auth.selectedRole);
	const paramsData = {
		role: nrole,
	};

	const fetchNonComplianceData = async (selectedMonth: string) => {
		try {
			setLoading(true);
			const res = await dispatch2(
				fetchData(`${getNonComplianceData}${selectedMonth}`, paramsData)
			);
			setMonth(selectedMonth);
			setNonComplianceData(res);
			const allEmployeeNames = res?.teams
				? res?.teams?.flatMap((issue: any) =>
						issue?.employees.map((assignee: any) => assignee.employee_name)
				  ) || []
				: res?.issues?.flatMap((issue: any) =>
						issue?.assignees.map((assignee: any) => assignee.assignee_name)
				  ) || [];
			setSelectedEmployees(allEmployeeNames);
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchNonComplianceData("1");
	}, []);

	const handleMonthChange = (selectedMonth: string) => {
		fetchNonComplianceData(selectedMonth);
	};

	const buttonData = [
		{ label: "1M", onClick: () => handleMonthChange("1") },
		{ label: "3M", onClick: () => handleMonthChange("3") },
		{ label: "6M", onClick: () => handleMonthChange("6") },
		{ label: "1Y", onClick: () => handleMonthChange("12") },
	];

	const computedData = useMemo(() => {
		// Handle data for employee role (second format)
		if (Array.isArray(nonComplianceData?.issues || nonComplianceData)) {
			const sourceData = nonComplianceData?.issues || nonComplianceData;
			const data = sourceData.flatMap((issue: any) =>
				issue.assignees.length > 0
					? issue.assignees.map((assignee: any) => ({
							assignee_name: assignee?.assignee_name,
							week: issue?.week_end,
							issue_count: assignee?.issue_count,
					  }))
					: [
							{
								week: issue?.week_end,
								assignee_name: "No Assignees",
								issue_count: 0,
							},
					  ]
			);

			const groupedData = data.reduce((acc: any, curr: any) => {
				const weekData = acc.find((item: any) => item.week === curr.week) || {
					week: curr.week,
				};
				weekData[curr.assignee_name] = curr.issue_count;
				if (!acc.includes(weekData)) {
					acc.push(weekData);
				}
				return acc;
			}, []);

			return groupedData;
		}

		// Handle data for other roles (first format with teams)
		if (!nonComplianceData || !nonComplianceData.teams) {
			return [];
		}

		const selectedTeam =
			nonComplianceData.teams.find(
				(team: any) => team.team_name === teamName
			) || nonComplianceData.teams[0];

		setTeamName(selectedTeam?.team_name);

		if (selectedTeam) {
			const data = selectedTeam.issues.flatMap((issue: any) =>
				issue.assignees.length > 0
					? issue.assignees.map((assignee: any) => ({
							assignee_name: assignee?.assignee_name,
							week: issue?.week_end,
							issue_count: assignee?.issue_count,
					  }))
					: [
							{
								week: issue?.week_end,
								assignee_name: "No Assignees",
								issue_count: 0,
							},
					  ]
			);

			const groupedData = data.reduce((acc: any, curr: any) => {
				const weekData = acc.find((item: any) => item.week === curr.week) || {
					week: curr.week,
				};
				weekData[curr.assignee_name] = curr.issue_count;
				if (!acc.includes(weekData)) {
					acc.push(weekData);
				}
				return acc;
			}, []);

			return groupedData;
		}
		return [];
	}, [nonComplianceData, teamName]);

	const onDownload = async () => {
		try {
			setDownloading(true);
			if (nrole.toLowerCase() !== "employee") {
				if (!teamName) {
					throw new Error("Please select a team");
				}
			}

			const downloadParams = {
				...paramsData,
				download: selectedFormat,
			};
			const res = await dispatch2(
				fetchData(`${getNonComplianceData}${month}`, downloadParams, {
					responseType: "blob",
				})
			);
			saveAs(
				res,
				`non-compliance.${selectedFormat === "excel" ? "xlsx" : selectedFormat}`
			);
		} catch (error: any) {
			console.error("Error downloading file:", error);
			showToast(error, "error", true);
		} finally {
			setDownloading(false);
		}
	};

	// Helper function to get all employee names
	const getAllEmployeeNames = () => {
		return nonComplianceData?.teams
			? nonComplianceData?.teams?.flatMap((issue: any) =>
					issue.employees.map((assignee: any) => assignee.employee_name)
			  ) || []
			: nonComplianceData?.issues?.flatMap((issue: any) =>
					issue.assignees.map((assignee: any) => assignee.assignee_name)
			  ) || [];
	};

	// Update handleEmployeeSelection to manage selectAll state
	const handleEmployeeSelection = (employeeName: string) => {
		const newSelectedEmployees = selectedEmployees.includes(employeeName)
			? selectedEmployees.filter((name) => name !== employeeName)
			: [...selectedEmployees, employeeName];

		setSelectedEmployees(newSelectedEmployees);

		// Update selectAll state based on whether all employees are selected
		const allEmployeeNames = getAllEmployeeNames();
		const uniqueEmployees = Array.from(
			new Set(computedData.map((item: any) => item.employee_name))
		);

		if (newSelectedEmployees.length === uniqueEmployees.length) {
			setSelectAll(true);
		}
		if (newSelectedEmployees.length === 0) {
			setSelectAll(false);
		}
	};

	// Replace handleSwitchChange with simpler logic
	const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const checked = event.target.checked;
		setSelectAll(checked);

		if (checked) {
			// Select all employees
			setSelectedEmployees(getAllEmployeeNames());
		} else {
			// Deselect all employees
			setSelectedEmployees([]);
		}
	};

	return (
		<>
			<Paper sx={{ m: 2, p: 4 }}>
				<Box display="flex" justifyContent="space-between" alignItems="center">
					<Box
						display="flex"
						justifyContent="center"
						alignItems="center"
						flexDirection="column"
						gap={2}
					>
						<Typography variant="h4">Non-Compliance Chart</Typography>
						<MonthButtonGroup buttonData={buttonData} />
					</Box>
					<Box
						display="flex"
						justifyContent="center"
						alignItems="center"
						flexDirection="column"
						gap={2}
					>
						{nonComplianceData?.teams && (
							<Box
								display="flex"
								justifyContent="center"
								alignItems="center"
								gap={2}
							>
								<Typography
									variant="h6"
									width={200}
									sx={{
										fontSize: "1rem",
										display: "inline",
									}}
								>
									Select Team
								</Typography>
								<Select
									labelId="team-select-label"
									id="team-select"
									value={teamName}
									label="Team"
									size="small"
									onChange={(e) => setTeamName(e.target.value)}
									sx={{
										width: "100%",
									}}
								>
									{nonComplianceData?.teams?.map((team: any) => (
										<MenuItem key={team.team_id} value={team.team_name}>
											{team.team_name}
										</MenuItem>
									))}
								</Select>
							</Box>
						)}

						<Box
							display="flex"
							justifyContent="center"
							gap={2}
							alignItems="center"
						>
							{downloading ? (
								<Box
									display="flex"
									justifyContent="center"
									alignItems="center"
									gap={2}
								>
									<CircularProgress size="1rem" />
									<Typography>Downloading...</Typography>
								</Box>
							) : (
								<Box
									display="flex"
									justifyContent="center"
									alignItems="center"
									gap={2}
								>
									<Box
										display="flex"
										justifyContent="center"
										alignItems="center"
										gap={2}
									>
										<Typography
											variant="h6"
											width={200}
											sx={{
												fontSize: "1rem",
												display: "inline",
											}}
										>
											Select format
										</Typography>
										<Select
											labelId="format-select-label"
											id="forma-select"
											value={selectedFormat}
											label="format"
											size="small"
											onChange={(e) => setSelectedFormat(e.target.value)}
											sx={{
												width: "100%",
											}}
										>
											<MenuItem key="excel" value="excel">
												excel
											</MenuItem>
											<MenuItem key="csv" value="csv">
												csv
											</MenuItem>
											<MenuItem key="json" value="json">
												json
											</MenuItem>
										</Select>
									</Box>
									<IconButton
										onClick={() => {
											onDownload();
										}}
									>
										<DownloadForOfflineIcon />
									</IconButton>
								</Box>
							)}
						</Box>
					</Box>
				</Box>
				<Box
					display="flex"
					justifyContent="flex-end"
					alignItems="center"
					gap={2}
					m={2}
					width="100%"
				>
					<Typography>Select All Employees</Typography>
					<Switch
						checked={selectAll}
						onChange={handleSwitchChange}
						color="primary"
					/>
				</Box>
				{loading ? (
					<Box
						display="flex"
						justifyContent="center"
						alignItems="center"
						width="100%"
						height={200}
					>
						<CircularProgress />
					</Box>
				) : (
					<Box
						display="flex"
						justifyContent="center"
						alignItems="center"
						flexDirection="column"
					>
						<BarChartComponent
							data={computedData || []}
							YAxisLabel="Issue Count"
							selectedEmployees={selectedEmployees}
							onEmployeeSelection={handleEmployeeSelection}
						/>
					</Box>
				)}
			</Paper>
		</>
	);
};

export default NonComplianceChart;
