import React from "react";

interface TooltipPayloadItem {
	name: string;
	value: string | number;
}

interface TooltipProps {
	active: boolean;
	payload: TooltipPayloadItem[];
	label: string;
}

export const titleCase = (str: any) => {
	if (str == null) return ""; // Handle null or undefined
	str = str.toString(); // Ensure str is a string
	str = str.replaceAll("_", " "); // Replace underscores with spaces
	return str.toLowerCase().replace(/\b(\w)/g, (s: any) => s.toUpperCase());
};

export const CustomTooltip = ({ active, payload, label }: any) => {
	if (!active || !label || payload.length === 0) return null;
	if (active && payload?.length) {
		const uniqueNames = new Set();

		// Calculate average
		// const values = payload.map((ele: any) => parseFloat(ele?.value) || 0);

		const values: number[] = payload.map(
			(ele: TooltipPayloadItem) => parseFloat(ele?.value?.toString()) || 0
		);
		const average: number =
			values.reduce((a: number, b: number) => a + b, 0) / values.length;

		return (
			<div className="bg-warning p-3 shadow">
				<span>{label}</span>
				<br />
				<small className="text-secondary" style={{ fontWeight: "bold" }}>
					Weekly Average: {average.toFixed(2)}
				</small>
				<br />
				{payload.map((ele: any, index: number) => {
					if (uniqueNames.has(ele?.name)) return null;
					uniqueNames.add(ele?.name);
					return (
						<React.Fragment key={index}>
							<small className="text-secondary">
								{titleCase(ele?.name)} : {parseFloat(ele?.value)?.toFixed(2)}
							</small>
							<br />
						</React.Fragment>
					);
				})}
			</div>
		);
	}
	return null;
};

export const RenderLegend = (props: any) => {
	const { payload } = props;
	const colors = ["#0000FF", "#ff223f"];
	return (
		<div className="d-flex justify-content-end">
			{payload.map((entry: any, index: number) => (
				<>
					{/* <FaSquareFull className="mx-2" size={18} color={colors[index]} /> */}
					<span className="mx-2" key={`item-${index}`}>
						{entry.value}
					</span>
				</>
			))}
		</div>
	);
};
