import React, { useState, useEffect, useMemo } from "react";
import {
	Box,
	Paper,
	Typography,
	CircularProgress,
	IconButton,
	Select,
	MenuItem,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import Pagination from "@mui/material/Pagination";

import {
	fetchData,
	FetchDataFailureAction,
	FetchDataSuccessAction,
} from "../../../store/actions";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { useCustomToast } from "../../Methods/SnackBar";
import { getProjectEffortData } from "../../../Service/Apis";
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
} from "recharts";
import { saveAs } from "file-saver";
import MonthButtonGroup from "../../utilization/MonthsButtonGroup";
import { RootState } from "../../../store/Store";
import ProjectSprintFilter from "../ProjectFilter/ProjectSprintFilter";

const ITEMS_PER_PAGE = 5;
const CHART_HEIGHT = 400;
const PROJECT_COLORS: { [key: string]: string } = {};

const getProjectColor = (projectName: string) => {
	if (!PROJECT_COLORS[projectName]) {
		PROJECT_COLORS[projectName] = `#${Math.floor(
			Math.random() * 16777215
		).toString(16)}`;
	}
	return PROJECT_COLORS[projectName];
};

const ProjectEffortChart = () => {
	const dispatch2: ThunkDispatch<
		RootState,
		null,
		FetchDataSuccessAction | FetchDataFailureAction
	> = useDispatch();
	const [projectData, setProjectData] = useState<any>([]);
	const [selectedFormat, setSelectedFormat] = useState<string>("excel");
	const [month, setMonth] = useState<string>("1");
	const [loading, setLoading] = useState(false);
	const [downloading, setDownloading] = useState(false);
	const [selectedProjects, setSelectedProjects] = useState<string[]>([]);
	const [selectedSprints, setSelectedSprints] = useState<string[]>([]);
	const [page, setPage] = useState(1);
	const showToast = useCustomToast();

	const nrole = useSelector((state: any) => state.auth.selectedRole);
	const paramsData = {
		role: nrole,
	};

	const fetchProjectData = async (selectedMonth: string) => {
		try {
			setLoading(true);
			const res = await dispatch2(
				fetchData(`${getProjectEffortData}${selectedMonth}`, paramsData)
			);
			setMonth(selectedMonth);
			setProjectData(res);
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchProjectData("1");
	}, []);

	const handleMonthChange = (selectedMonth: string) => {
		fetchProjectData(selectedMonth);
	};

	const buttonData = [
		{ label: "1M", onClick: () => handleMonthChange("1") },
		{ label: "3M", onClick: () => handleMonthChange("3") },
		{ label: "6M", onClick: () => handleMonthChange("6") },
		{ label: "1Y", onClick: () => handleMonthChange("12") },
	];

	const onDownload = async () => {
		try {
			setDownloading(true);
			const downloadParams = {
				...paramsData,
				download: selectedFormat,
			};
			const response = await dispatch2(
				fetchData(`${getProjectEffortData}${month}`, downloadParams, {
					responseType: "blob",
				})
			);
			saveAs(
				response as any,
				`project_effort.${selectedFormat === "excel" ? "xlsx" : selectedFormat}`
			);
		} catch (error: any) {
			console.error("Error downloading file:", error);
			showToast(error, "error", true);
		} finally {
			setDownloading(false);
		}
	};

	const getAvailableProjects = (data: any[]) => {
		return data.map((project) => project.project_name);
	};

	const getAvailableSprints = (data: any[]) => {
		const sprints = new Set<string>();
		data.forEach((project) => {
			project.sprints.forEach((sprint: any) => {
				sprints.add(sprint.sprint_name);
			});
		});
		return Array.from(sprints);
	};

	useEffect(() => {
		if (projectData.length > 0) {
			const projects = getAvailableProjects(projectData);
			const sprints = getAvailableSprints(projectData);
			setSelectedProjects(projects);
			setSelectedSprints(sprints);
		}
	}, [projectData]);

	const filteredData = React.useMemo(() => {
		interface Sprint {
			sprint_name: string;
			total_issues?: number;
			with_story_points?: number;
			without_story_points?: number;
			total_hours?: number;
		}

		interface Project {
			project_name: string;
			sprints: Sprint[];
		}

		return projectData
			.filter((project: Project) =>
				selectedProjects.includes(project.project_name)
			)
			.map(
				(project: Project): Project => ({
					...project,
					sprints: project.sprints.filter((sprint: Sprint) =>
						selectedSprints.includes(sprint.sprint_name)
					),
				})
			);
	}, [projectData, selectedProjects, selectedSprints]);

	// Update transformDataForChart function to include total_time_spent
	const transformDataForChart = (data: any[]) => {
		// Extract all unique sprint names
		const allSprints = new Set<string>();
		data.forEach((project) => {
			project.sprints.forEach((sprint: any) => {
				allSprints.add(sprint.sprint_name);
			});
		});

		// Create data points for each sprint
		return Array.from(allSprints).map((sprintName) => {
			const dataPoint: any = { sprint_name: sprintName };
			data.forEach((project) => {
				const sprint = project.sprints.find(
					(s: any) => s.sprint_name === sprintName
				);
				if (sprint) {
					dataPoint[`${project.project_name}_total_issues`] =
						sprint.total_issues || 0;
					dataPoint[`${project.project_name}_with_points`] =
						sprint.with_story_points || 0;
					dataPoint[`${project.project_name}_without_points`] =
						sprint.without_story_points || 0;
					// Convert seconds to hours by dividing by 3600
					dataPoint[`${project.project_name}_time_spent`] =
						sprint.total_time_spent ? Math.round(sprint.total_time_spent) : 0;
				}
			});
			return dataPoint;
		});
	};

	// Update CustomTooltip component
	const CustomTooltip = ({ active, payload, label }: any) => {
		if (active && payload && payload.length > 0) {
			return (
				<Box sx={{ bgcolor: "white", p: 2, border: "1px solid #ccc" }}>
					<Typography variant="subtitle2">{label}</Typography>
					{payload.map((entry: any) => {
						const projectName = entry.dataKey.split("_")[0];
						const metricType = entry.dataKey.split("_").slice(1).join("_");

						return (
							<Box key={entry.dataKey}>
								<Typography color={entry.color}>
									{projectName}: {entry.value}
									{metricType === "time_spent"
										? " hours spent"
										: metricType === "with_points"
										? " issues with points"
										: metricType === "without_points"
										? " issues without points"
										: metricType === "total_issues"
										? " total issues"
										: ""}
								</Typography>
							</Box>
						);
					})}
				</Box>
			);
		}
		return null;
	};

	const paginatedData = useMemo(() => {
		const chartData = transformDataForChart(filteredData);
		const start = (page - 1) * ITEMS_PER_PAGE;
		return chartData.slice(start, start + ITEMS_PER_PAGE);
	}, [filteredData, page]);

	const handlePageChange = (
		event: React.ChangeEvent<unknown>,
		value: number
	) => {
		setPage(value);
	};

	const chartBars = useMemo(() => {
		return selectedProjects.map((projectName: string) => (
			<React.Fragment key={projectName}>
				<Bar
					dataKey={`${projectName}_with_points`}
					name={`${projectName} (With Points)`}
					fill={getProjectColor(projectName)}
					maxBarSize={60} // Changed from barSize to maxBarSize
				/>
				<Bar
					dataKey={`${projectName}_without_points`}
					name={`${projectName} (Without Points)`}
					fill={getProjectColor(projectName + "_without")}
					maxBarSize={60} // Changed from barSize to maxBarSize
				/>
				<Bar
					dataKey={`${projectName}_time_spent`}
					name={`${projectName} (Time Spent)`}
					fill={getProjectColor(projectName + "_time")}
					maxBarSize={60} // Changed from barSize to maxBarSize
				/>
			</React.Fragment>
		));
	}, [selectedProjects]);

	return (
		<Paper sx={{ m: 2, p: 4 }}>
			<Box display="flex" justifyContent="space-between" alignItems="center">
				<Box
					display="flex"
					justifyContent="center"
					alignItems="center"
					flexDirection="column"
					gap={2}
				>
					<Typography variant="h4">Project Effort Analysis</Typography>
					<MonthButtonGroup buttonData={buttonData} />
				</Box>
				<Box
					display="flex"
					justifyContent="center"
					alignItems="center"
					flexDirection="column"
					gap={2}
				>
					<Box mb={3}>
						<ProjectSprintFilter
							projects={getAvailableProjects(projectData)}
							selectedProjects={selectedProjects}
							sprints={getAvailableSprints(projectData)}
							selectedSprints={selectedSprints}
							onProjectChange={setSelectedProjects}
							onSprintChange={setSelectedSprints}
							projectData={projectData}
						/>
					</Box>
					{downloading ? (
						<Box
							display="flex"
							justifyContent="center"
							alignItems="center"
							gap={2}
						>
							<CircularProgress size="1rem" />
							<Typography>Downloading...</Typography>
						</Box>
					) : (
						<Box
							display="flex"
							justifyContent="center"
							alignItems="center"
							gap={2}
						>
							<Select
								value={selectedFormat}
								onChange={(e) => setSelectedFormat(e.target.value)}
								size="small"
							>
								<MenuItem value="excel">Excel</MenuItem>
								<MenuItem value="csv">CSV</MenuItem>
							</Select>
							<IconButton onClick={onDownload}>
								<DownloadForOfflineIcon />
							</IconButton>
						</Box>
					)}
				</Box>
			</Box>
			{loading ? (
				<Box
					display="flex"
					justifyContent="center"
					alignItems="center"
					height={400}
				>
					<CircularProgress />
				</Box>
			) : (
				<>
					<ResponsiveContainer width="100%" height={CHART_HEIGHT}>
						<BarChart data={paginatedData}>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="sprint_name" />
							<YAxis />
							<Tooltip content={<CustomTooltip />} />
							{chartBars}
						</BarChart>
					</ResponsiveContainer>
					<Box display="flex" justifyContent="center" mt={2}>
						<Pagination
							count={Math.ceil(
								transformDataForChart(filteredData).length / ITEMS_PER_PAGE
							)}
							page={page}
							onChange={handlePageChange}
							color="primary"
						/>
					</Box>
				</>
			)}
		</Paper>
	);
};

export default ProjectEffortChart;
