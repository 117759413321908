import { useEffect, useRef, useState } from "react";

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Rating,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { enqueueSnackbar } from "notistack";

import {
  fillFormany,
  finalSubmit,
  getResponseId,
  viewHierarchicalForm,
} from "../../Service/Apis";
import Footer from "../../components/Page Component/Footer";
import Navbar from "../../components/Page Component/Navbar";
import groupQuestionsByCategory from "../../components/functions/categoryFunc";
import useAxiosInterceptor from "../../components/layout/AxiosInstance";
import { setIsLoading } from "../../store/LoadingSlice";
import { labels } from "../Dashboard/ReviewForm";
import SaveFillForm from "./SaveFillForm";
import {
  ToastVariant,
  useCustomToast,
} from "../../components/Methods/SnackBar";
import {
  FetchDataFailureAction,
  FetchDataSuccessAction,
  fetchData,
  postData,
  putData,
} from "../../store/actions";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../../store/Store";
import { postDataSuccess } from "../../store/AxiosSlice";

interface FormType {
  form_id: string;
  form_name: string;
  form_description: string;
  created_by: string;
  employee_deadline: string;
  manager_deadline: string;
  is_uploaded: boolean;
  is_appraisal: boolean;
  is_hierarchical: boolean;
  teams: [
    {
      form_team_id: string;
      project_name: string;
      manager_name: string;
      form_id: string;
      team_id: string;
    }
  ];
  sections: [
    {
      section_id: string;
      form_id: string;
      section: string;
      categories: [
        {
          category_id: string;
          section_id: string;
          category: string;
          is_rating_required: boolean;
          questions: [
            {
              question_id: string;
              category_id: string;
              form_id: string;
              question: string;
              answer_type: string;
              answer_choices: [
                {
                  option_id: string;
                  answer_value: string;
                }
              ];
            }
          ];
        }
      ];
    }
  ];
}

interface Response {
  sections: Section[];
}

interface Section {
  section_id: string;
  categories: Category[];
}

interface Category {
  category_id: string;
  questions: Question[];
}

interface Question {
  question_id: string;
  employee_comment: string;
  employee_response: string;
  employee_rating: string;
}

interface Response1 {
  section_id: string;
  category_id: string;
  question_id: string;
  employee_response: string;
  employee_comment: string;
  employee_rating: string;
}

interface SpecialCharErrors {
  [sectionIndex: number]: {
    [categoryIndex: number]: {
      [questionIndex: number]: {
        employee_response: boolean;
        employee_comment: boolean;
      };
    };
  };
}

interface MinCharacterError {
  [sectionIndex: number]: {
    [categoryIndex: number]: {
      [questionIndex: number]: {
        employee_response: boolean;
        employee_comment: boolean;
      };
    };
  };
}

interface SpacesErrors {
  [sectionIndex: number]: {
    [categoryIndex: number]: {
      [questionIndex: number]: {
        employee_response: boolean;
        employee_comment: boolean;
      };
    };
  };
}

interface HoverEffect {
  [sectionIndex: number]: {
    [categoryIndex: number]: {
      [questionIndex: number]: {
        employee_rating: number;
      };
    };
  };
}
const initialSpecialCharErrors: SpecialCharErrors = {};
const initialMinCharacterErrors: MinCharacterError = {};
const initialSpacesErrors: SpacesErrors = {};
const initialHoverEffect: HoverEffect = {};

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  width: "100%",
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .00)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const FIllHeiForm = () => {
  const [fillForm, setFillForm] = useState<FormType[]>([]);

  const [response, setResponse] = useState<Response>({
    sections: fillForm.map((formItem) => ({
      section_id: formItem.form_id,
      categories: formItem.sections.map((sectionItem) => ({
        category_id: sectionItem.section_id,
        questions: sectionItem.categories.map((categoryItem) => ({
          question_id: categoryItem.category_id,
          employee_comment: "",
          employee_response: "",
          employee_rating: "",
        })),
      })),
    })),
  });

  const [specialCharErrors, setSpecialCharErrors] = useState<SpecialCharErrors>(
    initialSpecialCharErrors
  );
  const [hoverEffect, setHoverEffect] =
    useState<HoverEffect>(initialHoverEffect);

  const [spacesErrors, setSpacesErrors] =
    useState<SpacesErrors>(initialSpacesErrors);
  const [minCharacterError, setMinCharacterError] = useState<MinCharacterError>(
    initialMinCharacterErrors
  );
  const [flag, setFlag] = useState<boolean>(false);
  const [empRating, setEmpRating] = useState<boolean[][][]>([]);
  const [isSpecialCharError, setIsSpecialCharError] = useState<boolean>(false);
  const [isSpacesError, setIsSpacesError] = useState<boolean>(false);
  const [isMinCharacterError, setIsMinCharacterError] =
    useState<boolean>(false);
  const [value, setValue] = useState<number | null>(0);
  const [responseId, setResponseId] = useState<Object>({
    response_id: "",
  });
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const showToast = useCustomToast();

  const dispatch = useDispatch();
  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();

  const isloading = useSelector((state: any) => state.loading.isLoading);

  const hasSpecialCharacters = (input: string): boolean => {
    const specialCharacterPattern = /[#^*_+\=\;:"\\|<>/]/;
    return specialCharacterPattern.test(input);
  };

  const handleShowToast = (
    message: string,
    variant: ToastVariant,
    isCloseable: boolean
  ) => {
    showToast(message, variant, isCloseable);
  };

  const handleChange = (
    sectionIndex: number,
    categoryIndex: number,
    questionIndex: number,
    section_id: string,
    category_id: string,
    question_id: string,
    field: keyof Question,
    value: string,
    answer_type: string
  ) => {
    const containsOnlySpaces = /^\s*$/.test(value);

    setResponse((prevResponse) => {
      // Initialize sections if not defined
      const updatedSections = prevResponse.sections
        ? [...prevResponse.sections]
        : [];

      // Initialize categories if not defined for the specific section
      const updatedCategories = updatedSections[sectionIndex]?.categories
        ? [...updatedSections[sectionIndex].categories]
        : [];

      // Initialize questions if not defined for the specific category
      const updatedQuestions = updatedCategories[categoryIndex]?.questions
        ? [...updatedCategories[categoryIndex].questions]
        : [];

      // Update the specific field in the question object
      updatedQuestions[questionIndex] = {
        ...updatedQuestions[questionIndex],
        [field]: value,
        question_id, // Assign the provided question_id to the question object
      };

      // Update the questions array in the category object
      updatedCategories[categoryIndex] = {
        ...updatedCategories?.[categoryIndex],
        category_id, // Assign the provided category_id to the category object
        questions: updatedQuestions,
      };

      // Update the categories array in the section object
      updatedSections[sectionIndex] = {
        ...updatedSections[sectionIndex],
        section_id, // Assign the provided section_id to the section object
        categories: updatedCategories,
      };

      // Update the specialCharErrors state
      const updatedSpecialCharErrors = { ...specialCharErrors };
      const sectionErrors = updatedSpecialCharErrors[sectionIndex] || {};
      const categoryErrors = sectionErrors[categoryIndex] || {};
      const questionErrors = categoryErrors[questionIndex] || {
        employee_response: false,
        employee_comment: false,
      };

      const updatedSpacesErrors = { ...spacesErrors };
      const sectionSpacesErrors = updatedSpacesErrors[sectionIndex] || {};
      const categorySpacesErrors = sectionSpacesErrors[categoryIndex] || {};
      const questionSpacesErrors = categorySpacesErrors[questionIndex] || {
        employee_response: false,
      };

      const updatedMinCharacterError = { ...minCharacterError };
      const sectionMinCharatcerError =
        updatedMinCharacterError[sectionIndex] || {};
      const categoryMinCharacterErorr =
        sectionMinCharatcerError[categoryIndex] || {};
      const questionMinCharacterError = categoryMinCharacterErorr[
        questionIndex
      ] || {
        // employee_comment: false,
        employee_response: false,
      };

      // Check for special characters in employee_response and employee_comment
      if (field === "employee_response") {
        if (answer_type === "short_answer") {
          questionErrors.employee_response = hasSpecialCharacters(value);
        }
        questionSpacesErrors.employee_response = containsOnlySpaces;
        if (answer_type === "short_answer") {
          questionMinCharacterError.employee_response = value.length < 3;
        }
      } else if (field === "employee_comment") {
        questionErrors.employee_comment = hasSpecialCharacters(value);
        // questionMinCharacterError.employee_comment = value.length < 3;
      }

      // Update the specialCharErrors state
      categoryErrors[questionIndex] = questionErrors;
      sectionErrors[categoryIndex] = categoryErrors;
      updatedSpecialCharErrors[sectionIndex] = sectionErrors;

      setSpecialCharErrors(updatedSpecialCharErrors);

      categorySpacesErrors[questionIndex] = questionSpacesErrors;
      sectionSpacesErrors[categoryIndex] = categorySpacesErrors;
      updatedSpacesErrors[sectionIndex] = sectionSpacesErrors;

      // setSpacesErrors(updatedSpacesErrors);

      categoryMinCharacterErorr[questionIndex] = questionMinCharacterError;
      sectionMinCharatcerError[categoryIndex] = categoryMinCharacterErorr;
      updatedMinCharacterError[sectionIndex] = sectionMinCharatcerError;

      // setMinCharacterError(updatedMinCharacterError);

      let isAnySpecialCharError = false;
      Object.values(updatedSpecialCharErrors).forEach((categories) => {
        Object.values(categories).forEach((questions) => {
          Object.values(questions).forEach((error) => {
            if (error.employee_response || error.employee_comment) {
              isAnySpecialCharError = true;
            }
          });
        });
      });

      // let isAnySpacesError = false;
      // Object.values(updatedSpacesErrors).forEach((categories) => {
      //   Object.values(categories).forEach((questions) => {
      //     Object.values(questions).forEach((error) => {
      //       if (error.employee_response) {
      //         isAnySpacesError = true;
      //       }
      //     });
      //   });
      // });

      // let isMinCharacterErrors = false;
      // Object.values(updatedMinCharacterError).forEach((categories) => {
      //   Object.values(categories).forEach((questions) => {
      //     Object.values(questions).forEach((error) => {
      //       if (error.employee_response) {
      //         isMinCharacterErrors = true;
      //       }
      //     });
      //   });
      // });

      // setIsMinCharacterError(isMinCharacterErrors);
      // setIsSpacesError(isAnySpacesError);
      setIsSpecialCharError(isAnySpecialCharError);

      return {
        ...prevResponse,
        sections: updatedSections,
      };
    });
  };

  // Set the employee rating to false initially.

  const initializeEmpRatingState = (response: Response) => {
    const empRatingArray: boolean[][][] = [];

    response?.sections?.forEach((section, sectionIndex) => {
      const sectionEmpRating: boolean[][] = [];

      section?.categories?.forEach((category, categoryIndex) => {
        const categoryEmpRating: boolean[] = [];

        category?.questions?.forEach((question) => {
          const hasEmployeeRating =
            question?.hasOwnProperty("employee_rating") &&
            question.employee_rating !== null &&
            question.employee_rating !== "";
          categoryEmpRating.push(hasEmployeeRating);
        });

        sectionEmpRating.push(categoryEmpRating);
      });

      empRatingArray.push(sectionEmpRating);
    });

    setEmpRating(empRatingArray);
  };

  const navigate = useNavigate();

  const token = localStorage.getItem("token");

  // const success = useSelector((state: any) => state.http.data);

  const empId = useSelector((state: any) => state.person.perstate.id);

  const formId =
    useSelector((state: any) => state.form.id) ||
    localStorage.getItem("formid");
  const formTeamId =
    useSelector((state: any) => state.form.formTeamId) ||
    localStorage.getItem("formTeamId");

  const axiosInstance = useAxiosInterceptor();

  const appraiseeFirstName = useSelector(
    (state: any) => state.form.first_name || localStorage.getItem("first_name")
  );

  const appraiseeLastName = useSelector(
    (state: any) => state.form.last_name || localStorage.getItem("last_name")
  );

  const managerName = useSelector(
    (state: any) => state.form.manager || localStorage.getItem("manager")
  );

  const employeeCode =
    useSelector((state: any) => state.person.perstate.employee_code) ||
    localStorage.getItem("employee_code");

  const designation =
    useSelector((state: any) => state.person.perstate.designation) ||
    localStorage.getItem("designation");

  const handleSubmit = (e: any, form_team_id: string) => {
    e.preventDefault();

    handleShowToast("Please Save Form First.", "info", true);
  };

  const handleSave = (e: any, form_team_id: string) => {
    e.preventDefault();
    // Call the postFillForm function to submit the form
    // if (!isFormValid || isSpecialCharError) {
    //   return;
    // }
    // Check if every field of empRating is true

    postFillForm(form_team_id);
  };

  //for submitting form
  const postFillForm = async (form_team_id: string) => {
    const responseToArray = (response: Response): Response1[] => {
      const responseArray: Response1[] = [];

      response.sections.forEach((section) => {
        if (section) {
          const { section_id } = section;

          section.categories.forEach((category) => {
            if (category) {
              const { category_id } = category;

              category.questions.forEach((question) => {
                const {
                  question_id,
                  employee_response,
                  employee_comment,
                  employee_rating,
                } = question || {};

                if (question_id !== undefined) {
                  const response1: Response1 = {
                    section_id,
                    category_id,
                    question_id,
                    employee_response: employee_response,
                    employee_comment:
                      employee_comment !== undefined ? employee_comment : "",
                    employee_rating: employee_rating,
                  };
                  responseArray.push(response1);
                }
              });
            }
          });
        }
      });

      return responseArray;
    };
    const data = {
      form_team_id: formTeamId,
      response: responseToArray(response),
      final_response: {},
    };

    // let isOneField = false;

    const isOneField = data.response.some((item) => {
      return (
        item.employee_rating !== undefined ||
        item.employee_response !== undefined ||
        item.employee_comment !== undefined ||
        (item.employee_rating !== null && item.employee_rating !== "") ||
        (item.employee_response !== null && item.employee_response !== "") ||
        (item.employee_comment !== null && item.employee_comment !== "")
      );
    });

    if (!isOneField) {
      handleShowToast("Please fill atleast one field to save !", "error", true);
      return;
    }

    try {
      const response = await dispatch2(postData(fillFormany, data))
        .then((response) => {
          handleShowToast("Form Saved Succcessfully !", "success", true);
          setIsSaved(!isSaved);
          // window.location.reload();
          navigate("/dashboard");
          // dispatch(postDataSuccess(null));
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error: any) {
      console.log("Error:", error);
      handleShowToast(`${error.response.data.message}`, "error", true);
    }
  };

  //fetches the particular form data

  useEffect(() => {
    const fetchFormData = async () => {
      try {
        dispatch(setIsLoading(true));
        const response = await dispatch2(
          fetchData(`${viewHierarchicalForm}${formId}`)
        );
        setFillForm(response as FormType[]);
        dispatch(setIsLoading(false));
      } catch (error: any) {
        console.log("Error:", error);
      }
    };

    fetchFormData();
  }, []);

  useEffect(() => {
    initializeEmpRatingState(response);
  }, [response]);

  useEffect(() => {
    const fetchResponse = async () => {
      try {
        const response = await dispatch2(
          fetchData(`${getResponseId}${formTeamId}`)
        );
        setResponseId(response as any);
      } catch (error) {
        console.log(error);
      }
    };
    fetchResponse();
  }, [isSaved]);

  return (
    <>
      <Navbar />

      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "center", sm: "center", md: "center" },
          mt: 12,
          mb: 10,
        }}
      >
        {fillForm.map((res: any, formIndex: number) => (
          <Box
            component="form"
            onSubmit={(e) => {
              handleSubmit(e, res.teams[0].form_team_id);
              // setFormTeamId(res.teams[0].form_team_id);
            }}
            sx={{ width: "75%" }}
            key={res.teams.form_team_id}
          >
            <FormControl>
              <Box
                sx={{
                  mt: 1,
                  pt: 2,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography
                  id="form_heading"
                  fontWeight={"bold"}
                  letterSpacing={3}
                  variant="h4"
                  sx={{ textDecoration: "underline" }}
                >
                  {res.form_name}
                </Typography>
              </Box>
              <Box
                sx={{
                  m: 1,
                  mt: 2,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="h5" id="form_description">
                  {res.form_description ? `(${res.form_description})` : ""}
                </Typography>
              </Box>
              <Box
                sx={{ m: 2, display: "flex", justifyContent: "space-between" }}
              >
                <Box>
                  <Box sx={{ display: "flex", alignItems: "center", m: 1 }}>
                    <Typography
                      sx={{ width: "9rem" }}
                      fontWeight={"bold"}
                      id="appraisee_name"
                    >
                      Appraisee :{" "}
                    </Typography>
                    <Typography>
                      {" "}
                      {appraiseeFirstName + " " + appraiseeLastName}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", m: 1 }}>
                    <Typography
                      sx={{ width: "9rem" }}
                      fontWeight={"bold"}
                      id="manager_name"
                    >
                      Manager Name :{" "}
                    </Typography>
                    <Typography>{managerName}</Typography>
                  </Box>
                </Box>
                <Box>
                  <Box sx={{ display: "flex", alignItems: "center", m: 1 }}>
                    <Typography
                      sx={{ width: "9rem" }}
                      fontWeight={"bold"}
                      id="employee_code"
                    >
                      Employee Code :{" "}
                    </Typography>
                    <Typography>{employeeCode}</Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", m: 1 }}>
                    <Typography
                      sx={{ width: "9rem" }}
                      fontWeight={"bold"}
                      id="designation"
                    >
                      Designation :{" "}
                    </Typography>
                    <Typography>{designation}</Typography>
                  </Box>
                </Box>
              </Box>
              <Divider />
              <Box>
                {res.sections.map((section: any, sectionIndex: number) => {
                  return (
                    <Box sx={{ m: 2, width: "75vw" }}>
                      <Accordion sx={{ m: 2, p: 0 }}>
                        <AccordionSummary sx={{ width: "100%" }}>
                          <Box sx={{}}>
                            <Typography
                              variant="h5"
                              fontWeight={"bold"}
                              id={`section_${sectionIndex + 1}`}
                            >
                              {String.fromCharCode(
                                sectionIndex + "A".charCodeAt(0)
                              ) +
                                ") " +
                                section.section}
                            </Typography>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box sx={{ m: 2, p: 2 }}>
                            {section.categories.map(
                              (category: any, categoryIndex: number) => {
                                return (
                                  <Box>
                                    <Box>
                                      <Typography
                                        variant="h5"
                                        fontWeight={"bold"}
                                        id={`section_${
                                          sectionIndex + 1
                                        }_category_${categoryIndex + 1}`}
                                      >
                                        {String.fromCharCode(
                                          categoryIndex + "a".charCodeAt(0)
                                        ) +
                                          ". " +
                                          category.category}
                                      </Typography>
                                    </Box>
                                    <Box sx={{ m: 2, p: 2 }}>
                                      {category.questions.map(
                                        (
                                          question: any,
                                          questionIndex: number
                                        ) => {
                                          return (
                                            <Box key={question.question_id}>
                                              <Box>
                                                <Typography
                                                  fontWeight={"bold"}
                                                  fontSize={19}
                                                  variant="h6"
                                                  id={`section_${
                                                    sectionIndex + 1
                                                  }_category_${
                                                    categoryIndex + 1
                                                  }_question_${
                                                    questionIndex + 1
                                                  }`}
                                                >
                                                  {questionIndex + 1 + "."}{" "}
                                                  {question.question}
                                                </Typography>
                                              </Box>
                                              <Box sx={{ ml: 2, mr: 2 }}>
                                                {question.answer_type ===
                                                "short_answer" ? (
                                                  <Box>
                                                    <TextField
                                                      required
                                                      id={`employee_response_section_${
                                                        sectionIndex + 1
                                                      }_category__${
                                                        categoryIndex + 1
                                                      }_question_${
                                                        questionIndex + 1
                                                      }`}
                                                      name={`employee_response`}
                                                      size="small"
                                                      maxRows={4}
                                                      fullWidth
                                                      multiline
                                                      value={
                                                        response?.sections[
                                                          sectionIndex
                                                        ]?.categories[
                                                          categoryIndex
                                                        ]?.questions[
                                                          questionIndex
                                                        ]?.employee_response
                                                      }
                                                      // rows={1}
                                                      sx={{
                                                        m: 2,
                                                        overflow: "auto",
                                                      }}
                                                      style={{ height: "auto" }}
                                                      InputProps={{
                                                        style: {
                                                          minHeight: "unset",
                                                        },
                                                      }}
                                                      onChange={(e) => {
                                                        handleChange(
                                                          sectionIndex,
                                                          categoryIndex,
                                                          questionIndex,
                                                          section.section_id,
                                                          category.category_id,
                                                          question.question_id,
                                                          "employee_response",
                                                          e.target.value,
                                                          question.answer_type
                                                        );
                                                      }}
                                                      helperText={
                                                        <>
                                                          {specialCharErrors[
                                                            sectionIndex
                                                          ]?.[categoryIndex]?.[
                                                            questionIndex
                                                          ]
                                                            ?.employee_response && (
                                                            <Typography
                                                              color={"error"}
                                                            >
                                                              Special Characters
                                                              not allowed.
                                                            </Typography>
                                                          )}
                                                          {spacesErrors[
                                                            sectionIndex
                                                          ]?.[categoryIndex]?.[
                                                            questionIndex
                                                          ]
                                                            ?.employee_response && (
                                                            <Typography
                                                              color={"error"}
                                                            >
                                                              Only Spaces not
                                                              allowed.
                                                            </Typography>
                                                          )}
                                                          {minCharacterError[
                                                            sectionIndex
                                                          ]?.[categoryIndex]?.[
                                                            questionIndex
                                                          ]
                                                            ?.employee_response && (
                                                            <Typography
                                                              color={"error"}
                                                            >
                                                              Minimum 3
                                                              characters
                                                              required.
                                                            </Typography>
                                                          )}
                                                        </>
                                                      }
                                                    />
                                                  </Box>
                                                ) : (
                                                  <Box>
                                                    <Box
                                                      sx={{
                                                        display: "block",
                                                        alignItems: "center",
                                                        m: 1,
                                                      }}
                                                    >
                                                      <RadioGroup
                                                        id={`employee_response_section_${
                                                          sectionIndex + 1
                                                        }_category__${
                                                          categoryIndex + 1
                                                        }_question_${
                                                          questionIndex + 1
                                                        }`}
                                                        name={`employee_response`}
                                                        value={
                                                          response?.sections[
                                                            sectionIndex
                                                          ]?.categories[
                                                            categoryIndex
                                                          ]?.questions[
                                                            questionIndex
                                                          ]?.employee_response
                                                        }
                                                        onChange={(e) => {
                                                          handleChange(
                                                            sectionIndex,
                                                            categoryIndex,
                                                            questionIndex,
                                                            section.section_id,
                                                            category.category_id,
                                                            question.question_id,
                                                            "employee_response",
                                                            e.target.value,
                                                            question.answer_type
                                                          );
                                                        }}
                                                      >
                                                        {question.answer_choices &&
                                                          question.answer_choices.map(
                                                            (
                                                              ans: any,
                                                              answerValueIndex: number
                                                            ) => (
                                                              <FormControlLabel
                                                                id={`employee_response_section_${
                                                                  sectionIndex +
                                                                  1
                                                                }_category__${
                                                                  categoryIndex +
                                                                  1
                                                                }_question_${
                                                                  questionIndex +
                                                                  1
                                                                }_answer_value_${
                                                                  answerValueIndex +
                                                                  1
                                                                }`}
                                                                required
                                                                key={
                                                                  ans.answer_id
                                                                }
                                                                value={
                                                                  ans.answer_value
                                                                }
                                                                control={
                                                                  <Radio
                                                                    id={`employee_response_section_${
                                                                      sectionIndex +
                                                                      1
                                                                    }_category__${
                                                                      categoryIndex +
                                                                      1
                                                                    }_question_${
                                                                      questionIndex +
                                                                      1
                                                                    }_answer_value_${
                                                                      answerValueIndex +
                                                                      1
                                                                    }_radio_button`}
                                                                  />
                                                                }
                                                                label={
                                                                  ans.answer_value
                                                                }
                                                              />
                                                            )
                                                          )}
                                                      </RadioGroup>
                                                    </Box>
                                                    <Box
                                                      sx={{
                                                        display: "flex",
                                                        m: 1,
                                                        p: 1,
                                                      }}
                                                    >
                                                      <Typography
                                                        fontWeight={"bold"}
                                                        paragraph
                                                        sx={{ width: "11rem" }}
                                                      >
                                                        Comments :{" "}
                                                      </Typography>
                                                      <TextField
                                                        id={`employee_comment_section_${
                                                          sectionIndex + 1
                                                        }_category__${
                                                          categoryIndex + 1
                                                        }_question_${
                                                          questionIndex + 1
                                                        }`}
                                                        name="employee_comment"
                                                        fullWidth
                                                        multiline
                                                        maxRows={4}
                                                        // rows={2}
                                                        style={{
                                                          height: "auto",
                                                          overflow: "auto",
                                                        }}
                                                        value={
                                                          response?.sections[
                                                            sectionIndex
                                                          ]?.categories[
                                                            categoryIndex
                                                          ]?.questions[
                                                            questionIndex
                                                          ]?.employee_comment
                                                        }
                                                        onChange={(e) => {
                                                          handleChange(
                                                            sectionIndex,
                                                            categoryIndex,
                                                            questionIndex,
                                                            section.section_id,
                                                            category.category_id,
                                                            question.question_id,
                                                            "employee_comment",
                                                            e.target.value,
                                                            question.answer_type
                                                          );
                                                        }}
                                                        helperText={
                                                          <>
                                                            {specialCharErrors[
                                                              sectionIndex
                                                            ]?.[
                                                              categoryIndex
                                                            ]?.[questionIndex]
                                                              ?.employee_comment && (
                                                              <Typography
                                                                color={"error"}
                                                              >
                                                                Special
                                                                Characters not
                                                                allowed.
                                                              </Typography>
                                                            )}
                                                            {minCharacterError[
                                                              sectionIndex
                                                            ]?.[
                                                              categoryIndex
                                                            ]?.[questionIndex]
                                                              ?.employee_comment && (
                                                              <Typography
                                                                color={"error"}
                                                              >
                                                                Minimum 3
                                                                characters
                                                                required.
                                                              </Typography>
                                                            )}
                                                          </>
                                                        }
                                                      />
                                                    </Box>
                                                  </Box>
                                                )}
                                                <Box
                                                  sx={{
                                                    display: "flex",
                                                    m: 1,
                                                    p: 1,
                                                  }}
                                                >
                                                  <Typography
                                                    fontWeight={"bold"}
                                                    paragraph
                                                    sx={{
                                                      width: "8.5rem",
                                                      mt: 0.5,
                                                    }}
                                                  >
                                                    Rating :{" "}
                                                  </Typography>
                                                  <Rating
                                                    id={`employee_rating_section_${
                                                      sectionIndex + 1
                                                    }_category__${
                                                      categoryIndex + 1
                                                    }_question_${
                                                      questionIndex + 1
                                                    }`}
                                                    size="large"
                                                    precision={0.5}
                                                    onChange={(e, newValue) => {
                                                      handleChange(
                                                        sectionIndex,
                                                        categoryIndex,
                                                        questionIndex,
                                                        section.section_id,
                                                        category.category_id,
                                                        question.question_id,
                                                        "employee_rating",
                                                        newValue?.toString() ||
                                                          "",
                                                        question.answer_type
                                                      );
                                                    }}
                                                    onChangeActive={(
                                                      event,
                                                      newHover
                                                    ) => {
                                                      // Update the hoverEffect state when the user hovers over the question
                                                      setHoverEffect(
                                                        (prevHoverEffect) => {
                                                          const updatedHoverEffect =
                                                            {
                                                              ...prevHoverEffect,
                                                            };
                                                          // Ensure that the nested objects are initialized properly
                                                          updatedHoverEffect[
                                                            sectionIndex
                                                          ] =
                                                            updatedHoverEffect[
                                                              sectionIndex
                                                            ] || {};
                                                          updatedHoverEffect[
                                                            sectionIndex
                                                          ][categoryIndex] =
                                                            updatedHoverEffect[
                                                              sectionIndex
                                                            ][categoryIndex] ||
                                                            {};
                                                          // Set the employee_rating value for the corresponding question
                                                          updatedHoverEffect[
                                                            sectionIndex
                                                          ][categoryIndex][
                                                            questionIndex
                                                          ] = {
                                                            employee_rating:
                                                              newHover,
                                                          };
                                                          return updatedHoverEffect;
                                                        }
                                                      );
                                                    }}
                                                    value={parseFloat(
                                                      response?.sections[
                                                        sectionIndex
                                                      ]?.categories[
                                                        categoryIndex
                                                      ]?.questions[
                                                        questionIndex
                                                      ]?.employee_rating
                                                    )}
                                                    className="rating-color"
                                                    aria-required="true"
                                                  />
                                                  {!response?.sections[
                                                    sectionIndex
                                                  ]?.categories[
                                                    categoryIndex
                                                  ]?.questions[
                                                    questionIndex
                                                  ]?.hasOwnProperty(
                                                    "employee_rating"
                                                  ) &&
                                                    flag && (
                                                      <Typography
                                                        sx={{ m: 0.5 }}
                                                        color={"error"}
                                                      >
                                                        This field is required.
                                                      </Typography>
                                                    )}
                                                  {value !== null && (
                                                    <Typography
                                                      sx={{
                                                        textAlign: "center",
                                                        ml: 2,
                                                      }}
                                                    >
                                                      {
                                                        labels[
                                                          hoverEffect[
                                                            sectionIndex
                                                          ]?.[categoryIndex]?.[
                                                            questionIndex
                                                          ]?.employee_rating !==
                                                          null
                                                            ? hoverEffect[
                                                                sectionIndex
                                                              ]?.[
                                                                categoryIndex
                                                              ]?.[questionIndex]
                                                                ?.employee_rating
                                                            : value
                                                        ]
                                                      }
                                                    </Typography>
                                                  )}
                                                </Box>
                                              </Box>
                                            </Box>
                                          );
                                        }
                                      )}
                                    </Box>
                                  </Box>
                                );
                              }
                            )}
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  );
                })}
              </Box>
              <Box sx={{ display: "flex", justifyContent: "right" }}>
                <Button
                  id="save_button"
                  size="large"
                  variant="outlined"
                  // disabled={!allRatingsFilled}
                  onClick={(e) => {
                    handleSave(e, res.teams[0].form_team_id);
                  }}
                  disabled={
                    isSpecialCharError //|| isSpacesError || isMinCharacterError
                  }
                  sx={{ m: 1 }}
                >
                  Save
                </Button>
              </Box>
            </FormControl>
          </Box>
        ))}
      </Box>
      <Footer />
    </>
  );
};

export default FIllHeiForm;
