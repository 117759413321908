import React, { useEffect, useMemo, useState } from "react";

import {
  AppBar,
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Container,
  Dialog,
  Divider,
  Fade,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  Modal,
  Paper,
  Popover,
  Popper,
  Slide,
  Switch,
  SwitchProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MenuItem from "@mui/material/MenuItem";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import DeleteIcon from "@mui/icons-material/Delete";
import { FormControl } from "@mui/base";
import {
  ThemeProvider,
  createTheme,
  makeStyles,
  styled,
} from "@mui/material/styles";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { enqueueSnackbar } from "notistack";

import axios from "axios";
import axiosInstance from "../../components/layout/AxiosInstance";
import useAxiosInterceptor from "../../components/layout/AxiosInstance";

import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import dayjs, { Dayjs } from "dayjs";

import { format } from "date-fns";

import { Console } from "console";

import { date } from "../Form Add/AddForm";
import FileUploadComponent from "../../components/Excel/FileUpload";
import NothingToDisplayImage from "../../asset/NothingToDisplayImage.png";

import { setisOpen } from "../../store/ModalSlice";
import capitalizeFirstLetter from "../../components/functions/NameFunc";
import Footer from "../../components/Page Component/Footer";
import Navbar from "../../components/Page Component/Navbar";
import Sidebar from "../../components/Page Component/Sidebar";
import {
  DeleteEmp,
  activeStatusChange,
  registerUser,
  updateEmployee,
  viewUsers,
} from "../../Service/Apis";
import { setIsLoading } from "../../store/LoadingSlice";
import Skeletons from "../../components/Skeleton/Skeletons";
import {
  ToastVariant,
  useCustomToast,
} from "../../components/Methods/SnackBar";
import {
  DataPayload,
  FetchDataFailureAction,
  FetchDataSuccessAction,
  deleteData,
  fetchData,
  patchData,
  postData,
} from "../../store/actions";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../../store/Store";
import { setEmpId } from "../../store/FormSlice";
import { postDataSuccess } from "../../store/AxiosSlice";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import CustomNoRowsOverlay from "../../components/Data/NoRowsOverlayComponent";
import Loading from "../../components/Loading/Loading";
import NothingToDisplayComponent from "../../components/Data/NothingToShowComponent";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  //   border: "2px solid #000",
  p: 4,
};

type initialemp = {
  first_name: string;
  last_name: string;
  role: string[];
  email: string;
  password: string;
};

const initialStateEmployee = {
  id: "",
  employee_code: "",
  first_name: "",
  last_name: "",
  gender: "",
  roles: [],
  designation: "",
  date_of_birth: "",
  date_of_joining: "",
  date_of_confirmation: "",
  date_of_exit: "",
  email: "",
  password: "",
};
const AddEmployee = () => {
  const [open, setOpen] = React.useState(false); //Open Add Employee Modal.
  const [addEmployee, setAddEmployee] = useState(initialStateEmployee); //State for add employee.
  const [roles, setRoles] = useState<string[]>([]); // Set Roles.
  const [getEmployee, setGetEmployee] = useState<
    (typeof initialStateEmployee)[]
  >([]); // State to get the employee details.
  const [dateOfJoining, setDateOfJoining] = React.useState<Dayjs | null>(
    dayjs(`${date}`)
  );
  const [dateOfBirth, setDateOfBirth] = React.useState<Dayjs | null>(
    dayjs(`${date}`)
  );

  const [openEdit, setOpenEdit] = useState(true); //Enable Edit.
  const [showPassword, setShowPassword] = useState(false); //Show Password.
  const [passwordFocused, setPasswordFocused] = useState(false); //Focus Password.
  const [isValidPassword, setIsValidPassword] = useState<{
    specialCharacter: boolean;
    oneUpperCase: boolean;
    oneLowerCase: boolean;
    oneNumber: boolean;
    minCharacter: boolean;
    spacesError: boolean;
  }>({
    specialCharacter: false,
    oneUpperCase: false,
    oneLowerCase: false,
    oneNumber: false,
    minCharacter: false,
    spacesError: false,
  }); // Validation for password.

  const [editRole, setEditRole] = useState(false);
  const [personData, setPersonData] = useState<{
    employee_code: string;
    first_name: string;
    last_name: string;
    gender: string;
    roles: string[];
    designation: string;
    email: string;
    password: string;
    date_of_joining: string;
    date_of_birth: string;
  }>({
    employee_code: "",
    first_name: "",
    last_name: "",
    gender: "",
    roles: [],
    designation: "",
    email: "",
    password: "",
    date_of_joining: "",
    date_of_birth: "",
  }); // State to edit employee details.

  const IOSSwitch = styled((props: SwitchProps) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : theme.palette.primary,
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const [viewModal1, setViewModal1] = useState<number | null>(null); //Open View Modal.
  const [editModal2, setEditModal2] = useState<number | null>(null); // Delete Modal.
  const [activeStatusModal, setActiveStatusModal] = useState<number | null>(
    null
  ); // Delete Modal.
  const [searchTerm, setSearchTerm] = useState("");

  const [editEmployee, setEditEmployee] = useState();

  const [count, setCount] = useState(0); // To call API again.

  const [selectedGender, setSelectedGender] = useState("");
  const [otherGender, setOtherGender] = useState(""); //To store Other Gender.

  const dispatch = useDispatch();
  const Navigate = useNavigate();

  const [isSpecialCharError, setIsSpecialCharError] = useState(false);
  const [isSpecialCharError2, setIsSpecialCharError2] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isS, setIsS] = useState(false); //Password Validation Error.

  const [nameError, setNameError] = useState<boolean[]>([]); // Name Validation.
  const [anchorEl, setAnchorEl] = useState(null);

  const isloading = useSelector((state: any) => state.loading.isLoading);

  const isOpen = useSelector((state: any) => state.modal.isOpen);
  const counts = useSelector((state: any) => state.loading.count);

  const showToast = useCustomToast();

  // const success = useSelector((state: any) => state?.http?.data);
  const filteredData = useMemo(() => {
    if (searchTerm === "") {
      return getEmployee;
    }
    return getEmployee.filter((employee) =>
      Object.values(employee).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [getEmployee, searchTerm]);

  const rows = useMemo(() => {
    return filteredData.map((employee, index) => ({
      ...employee,
      name: employee.first_name + " " + employee.last_name,
      index: index,
    }));
  }, [filteredData]);

  const columns: GridColDef[] = [
    {
      field: "employee_code",
      headerName: "Employee Code",
      width: 135,
      //   flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
      renderHeader: () => (
        <strong className="custom-header" style={{ fontSize: 15 }}>
          Employee Code
        </strong>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      width: 165,
      //   flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
      renderHeader: () => (
        <strong className="custom-header" style={{ fontSize: 15 }}>
          Name
        </strong>
      ),
      //   width: 400,
    },
    {
      field: "roles",
      headerName: "Roles",
      width: 175,
      //   flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
      renderHeader: () => (
        <strong className="custom-header" style={{ fontSize: 15 }}>
          Roles
        </strong>
      ),
      //   width: 400,
    },
    {
      field: "designation",
      headerName: "Designation",
      width: 185,
      //   flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
      renderHeader: () => (
        <strong className="custom-header" style={{ fontSize: 15 }}>
          Designation
        </strong>
      ),
      //   width: 400,
    },
    {
      field: "Options",
      headerName: "Employee Code",
      width: 165,
      //   flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
      renderHeader: () => (
        <strong className="custom-header" style={{ fontSize: 15 }}>
          Options
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Button
            id={`view_button_${params.row.index + 1}`}
            // onClick={() => {
            //   handleOpenModal1(index);
            //   setPersonData(getEmployee[index]);
            //   setOpenEdit(false);
            // }}
            onClick={() => {
              Navigate("/viewEmployee");
              dispatch(setEmpId(params.row.email.email));
              localStorage.setItem("viewEmail", params.row.email);
            }}
            color="primary"
          >
            View
          </Button>
          <Button
            id={`delete_button_${params.row.index + 1}`}
            onClick={() => {
              handleOpenModal2(params.row.index);
            }}
            color="primary"
          >
            Delete
          </Button>
          <Modal
            open={editModal2 === params.row.index}
            onClose={handleCloseModal2}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            slotProps={{
              backdrop: {
                style: {
                  backgroundColor: "transparent",
                },
              },
            }}
          >
            <Card elevation={11} sx={style}>
              <Typography
                id={`confirmation_${params.row.index + 1}`}
                variant="h6"
                component="h2"
                fontWeight={"bold"}
              >
                Confirmation
              </Typography>
              <Typography
                id={`confirmation_content_${params.row.index + 1}`}
                sx={{ mt: 2 }}
              >
                Do you want to delete?
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "right",
                  alignItems: "center",
                  m: 1,
                  p: 1,
                }}
              >
                <Button
                  id={`no_button_${params.row.index + 1}`}
                  onClick={handleCloseModal2}
                >
                  No
                </Button>
                <Button
                  id={`yes_button_${params.row.index + 1}`}
                  variant="contained"
                  onClick={() => {
                    handleDelete(params.row.email);
                  }}
                >
                  Yes
                </Button>
              </Box>
            </Card>
          </Modal>
        </>
      ),
      //   width: 400,
    },
    {
      field: "is_active",
      headerName: "Status",
      width: 110,
      //   flex: 1,
      align: "right",
      headerAlign: "center",
      headerClassName: "header-background",
      renderHeader: () => (
        <strong className="custom-header" style={{ fontSize: 15 }}>
          Status
        </strong>
      ),
      renderCell: (params) => (
        <>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <FormControlLabel
              control={
                <IOSSwitch
                  sx={{ m: 1 }}
                  checked={params.row.is_active}
                  onChange={(e) => setActiveStatusModal(params.row.index)}
                />
              }
              label=""
            />
            <Modal
              open={activeStatusModal === params.row.index}
              onClose={() => {
                setActiveStatusModal(null);
              }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              slotProps={{
                backdrop: {
                  style: {
                    backgroundColor: "transparent",
                  },
                },
              }}
            >
              <Card elevation={11} sx={style}>
                <Typography
                  id={`confirmation_${params.row.index + 1}`}
                  variant="h6"
                  component="h2"
                  fontWeight={"bold"}
                >
                  Confirmation
                </Typography>
                <Typography
                  id={`confirmation_content_${params.row.index + 1}`}
                  sx={{ mt: 2 }}
                >
                  Do you want to change the status of {params.row.name} from{" "}
                  {params.row.is_active ? "Active" : "Deactive"} to{" "}
                  {!params.row.is_active ? "Active" : "Deactive"}?
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "right",
                    alignItems: "center",
                    m: 1,
                    p: 1,
                  }}
                >
                  <Button
                    id={`no_button_${params.row.index + 1}`}
                    onClick={() => {
                      setActiveStatusModal(null);
                    }}
                  >
                    No
                  </Button>
                  <Button
                    id={`yes_button_${params.row.index + 1}`}
                    variant="contained"
                    onClick={() => {
                      handleActiveStatusChange(
                        params.row.id,
                        params.row.is_active
                      );
                      setActiveStatusModal(null);
                      setCount(count + 1);
                    }}
                  >
                    Yes
                  </Button>
                </Box>
              </Card>
            </Modal>
          </Box>
        </>
      ),
      //   width: 400,
    },
  ];

  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();

  const handleActiveStatusChange = async (
    employeeId: string,
    isActive: boolean
  ) => {
    const data = {
      employee_id: employeeId,
      is_active: isActive,
    };
    try {
      const response = await dispatch2(postData(activeStatusChange, data));
      handleShowToast("Status Changed !", "success", true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setIsSpecialCharError(false);
    setRoles([]);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
  };

  const handleEdit = () => {
    setOpenEdit(!openEdit);
  };

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const handleShowToast = (
    message: string,
    variant: ToastVariant,
    isCloseable: boolean
  ) => {
    showToast(message, variant, isCloseable);
  };

  const handlePasswordFocus = (event: any) => {
    setPasswordFocused(true);
    setAnchorEl(event.currentTarget);
  };

  const handlePasswordBlur = () => {
    setPasswordFocused(false);
    setAnchorEl(null);
  };

  const handleOpenModal1 = (index: number) => {
    setViewModal1(index);
  };

  const handleCloseModal1 = () => {
    setViewModal1(null);
    setNameError([]);
    setIsSpecialCharError2(false);
  };

  const handleOpenModal2 = (index: number) => {
    setEditModal2(index);
  };

  const handleCloseModal2 = () => {
    setEditModal2(null);
  };

  const token = localStorage.getItem("token");

  //To add role
  const handleRoles = (event: SelectChangeEvent<typeof roles>) => {
    const selectedRoles = event.target.value as string[];

    setRoles(selectedRoles);
  };
  //To delete role.
  const handleDeleteRole = (role: string) => {
    const updatedRoles = roles.filter((r) => r !== role);
    setRoles(updatedRoles);
  };

  //To Edit Role.
  const handlePersonRoles = (e: SelectChangeEvent<string>) => {
    const { value } = e.target;
    if (!personData.roles.includes(value)) {
      setPersonData((prevPersonData) => ({
        ...prevPersonData,
        roles: [...prevPersonData.roles, value],
      }));
    }
  };

  //To delete Edit role.
  const handlePersonDeleteRole = (role: string) => {
    setPersonData((prevPersonData) => ({
      ...prevPersonData,
      roles: prevPersonData.roles.filter((r) => r !== role),
    }));
  };

  //To handle Edit State.
  const handlePersonChange = (e: any) => {
    let name, value;
    name = e.target.name;
    value = e.target.value;
    const regex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;
    const hasSpecialChar = regex.test(value);

    setPersonData({ ...personData, [name]: value });
    setIsSpecialCharError2(hasSpecialChar);
  };

  //To handle Add State.
  const handleChange = (event: any, index: number) => {
    event.preventDefault();
    let name, value;
    name = event.target.name;
    value = event.target.value;
    const regex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;
    const hasSpecialChar = regex.test(value);
    const nameRegex = /^[a-zA-Z' ]*$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const passwordRegex =
      /^(?!\s)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+,\-.;:'"<>/?])(?=.*\d)[^\s]{8,}$/;

    const specialCharacter = /^(?=.*[!@#$%^&*()_+,\-.;:'"<>/?])/;
    const oneUpperCase = /^(?=.*[A-Z])/;
    const oneLowerCase = /^(?=.*[a-z])/;
    const oneNumber = /^(?=.*\d)/;
    const noSpaces = /^[^\s]+$/;

    if (name === "password") {
      setIsValidPassword({
        specialCharacter: specialCharacter.test(value),
        oneUpperCase: oneUpperCase.test(value),
        oneLowerCase: oneLowerCase.test(value),
        oneNumber: oneNumber.test(value),
        spacesError: !noSpaces.test(value),
        minCharacter: value.length < 8,
      });
    }

    if (name === "first_name" || "last_name") {
      setIsSpecialCharError(false);
      const isNameValid = !nameRegex.test(value);
      setNameError((prevNameError) => {
        const updatedNameError = [...prevNameError];
        updatedNameError[index] = isNameValid;
        return updatedNameError;
      });
    }
    // setIsSpecialCharError(hasSpecialChar);
    setAddEmployee({ ...addEmployee, [name]: value });
    if (name === "email") {
      setIsSpecialCharError(false);
      const isEmailValid = !emailRegex.test(value);
      setNameError((prevNameError) => {
        const updatedNameError = [...prevNameError];
        updatedNameError[index] = isEmailValid;
        return updatedNameError;
      });
    }

    setErrorMessage("");
  };

  const validatePassword = (value: any) => {
    const isValid: any = {
      specialCharacter: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(value),
      oneUpperCase: /[A-Z]/.test(value),
      oneLowerCase: /[a-z]/.test(value),
      oneNumber: /\d/.test(value),
      spacesError: /^[^\s]+$/.test(value),
      minCharacter: value.length >= 8,
    };

    setIsValidPassword(isValid);

    const firstError = Object.keys(isValid).find((key) => !isValid[key]);
    if (firstError) {
      switch (firstError) {
        case "specialCharacter":
          setErrorMessage(
            "Password must contain at least one special character."
          );
          setIsS(true);
          break;
        case "oneUpperCase":
          setErrorMessage(
            "Password must contain at least one uppercase letter."
          );
          setIsS(true);

          break;
        case "oneLowerCase":
          setErrorMessage(
            "Password must contain at least one lowercase letter."
          );
          setIsS(true);

          break;
        case "oneNumber":
          setErrorMessage("Password must contain at least one number.");
          setIsS(true);

          break;
        case "minCharacter":
          setErrorMessage("Password should be at least 8 characters long.");
          setIsS(true);

          break;
        case "spacesError":
          setErrorMessage("Password should not contain any spaces.");
          setIsS(true);

          break;
        default:
          setErrorMessage("");
      }
    } else {
      setErrorMessage("");
    }
  };

  //To Edit Employee Details API Call.
  const handleSave = async (
    event: React.MouseEvent<HTMLElement> | React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();

    const {
      employee_code,
      first_name,
      last_name,
      gender,
      roles,
      designation,
      email,
      password,
    } = personData;

    const yodaPlusEmailPattern = /^[a-zA-Z0-9._%+-]+@yodaplus\.com$/;
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const validEmail = emailPattern.test(email);

    var data = {
      employee_code: employee_code,
      first_name: first_name,
      last_name: last_name,
      gender: gender === "Other" ? otherGender : gender,
      roles: personData.roles.map((data) => {
        return {
          role: data,
        };
      }),
      designation: designation,
      date_of_birth: dateOfBirth
        ? format(dateOfBirth.toDate(), "yyyy-MM-dd")
        : "",
      date_of_joining: dateOfJoining
        ? format(dateOfJoining.toDate(), "yyyy-MM-dd")
        : "",
    };

    //for editing a particular employee
    try {
      const response = dispatch2(patchData(`${updateEmployee}${email}`, data))
        .then((response) => {
          handleShowToast("Successfully Updated Profile Info", "success", true);
          dispatch(postDataSuccess(null));

          setCount(count + 1);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }

    setAddEmployee(initialStateEmployee);
    setRoles([]);
    setOpen(false);
  };

  //adding a new  employee
  const handleSubmit = async (event: any) => {
    event.preventDefault();

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const {
      employee_code,
      first_name,
      last_name,
      gender,
      designation,
      email,
      password,
    } = addEmployee;

    const validEmail = emailPattern.test(email);

    validatePassword(password);

    if (errorMessage !== "" || isS === true) {
      return;
    }

    const passwordRegex =
      /^(?!\s)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+,\-.;:'"<>/?])(?=.*\d).{8,}$/;

    var data = {
      employee_code: employee_code,
      first_name: first_name,
      last_name: last_name,
      gender: gender === "Other" ? otherGender : gender,
      roles: roles.map((data) => {
        return {
          role: data,
        };
      }),
      designation: designation,
      date_of_birth: dateOfBirth
        ? format(dateOfBirth.toDate(), "yyyy-MM-dd")
        : "",
      date_of_joining: dateOfJoining
        ? format(dateOfJoining.toDate(), "yyyy-MM-dd")
        : "",
      email: email,
      password: password,
    };
    try {
      const response = dispatch2(postData(`${registerUser}`, data)).then(
        (response) => {
          setCount(count + 1);
          handleShowToast("Employee Added Successfully", "success", true);
          setCount(count + 1);
          setAddEmployee(initialStateEmployee);
          setRoles([]);
          setNameError([]);
          setOpen(false);
          setErrorMessage("");
          dispatch(postDataSuccess(null));

          setIsValidPassword({
            spacesError: false,
            specialCharacter: false,
            minCharacter: false,
            oneLowerCase: false,
            oneNumber: false,
            oneUpperCase: false,
          });
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  //deleting employee
  const handleDelete = async (email: string) => {
    try {
      const response = await dispatch2(deleteData(`${DeleteEmp}${email}`))
        .then((response) => {
          handleShowToast("Employee deleted", "success", true);
          setCount(count + 1);
          handleCloseModal2();
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  //for showing all the employeess
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        dispatch(setIsLoading(true));
        const response: DataPayload = await dispatch2(
          fetchData(`${viewUsers}`)
        );
        dispatch(setIsLoading(false));
        setGetEmployee(response as (typeof initialStateEmployee)[]);
      } catch (error) {
        console.log(error);
        dispatch(setIsLoading(false));
        setRoles([]);
        setAddEmployee(initialStateEmployee);
        setDateOfBirth(dayjs(date));
        setDateOfJoining(dayjs(date));
        setNameError([]);
      }
    };

    fetchUserDetails();
  }, [count, counts]);

  useEffect(() => {
    handleClose();
    dispatch(setisOpen(false));
    setCount(count + 1);
  }, [isOpen]);

  if (isloading) {
    return (
      <>
        <Loading isLoading={isloading as boolean} />
      </>
    );
  }

  return (
    <>
      <Box>
        <Navbar />
      </Box>

      {/* EMPLOYEE DETAILS */}

      <Box sx={{ mt: 12, mb: 10, width: "98vw" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "center", sm: "center", md: "center" },
            m: 1,
            p: 1,
            mb: 8,
            pb: 1.5,
            width: "100%",
            // overflow: "auto ",
          }}
        >
          <Box sx={{}}>
            <Box
              sx={{ display: "flex", justifyContent: "center", m: 1, pb: 2.5 }}
            >
              <Typography fontWeight={"bold"} variant="h3" sx={{ p: 1 }}>
                Employee Details
              </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "right", m: 3 }}>
              <Button
                id="addEmployeeButton"
                variant="contained"
                size="small"
                onClick={() => {
                  Navigate("/addEmployee");
                }}
                sx={{}}
              >
                Add Employee
              </Button>
            </Box>
            <Box sx={{}}>
              {rows.length === 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    width: "55vw",
                    justifyContent: "center",
                    alignItems: "center",
                    p: 2,
                  }}
                >
                  <NothingToDisplayComponent />
                </Box>
              ) : (
                <Paper sx={{ m: 2 }} style={{ width: "fit-content" }}>
                  <DataGrid
                    autoHeight
                    getRowId={(row) => row.id}
                    disableColumnMenu
                    sx={{
                      width: "100%",
                      height: "100%",
                      "--DataGrid-overlayHeight": "50vh",
                    }}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 10,
                        },
                      },
                    }}
                    pagination
                    pageSizeOptions={[5, 10, 15]}
                    rows={rows}
                    columns={columns}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    slots={{
                      toolbar: GridToolbar,
                      noResultsOverlay: CustomNoRowsOverlay,
                      noRowsOverlay: CustomNoRowsOverlay,
                    }}
                    slotProps={{
                      toolbar: {
                        csvOptions: { disableToolbarButton: true },
                        showQuickFilter: true,
                        printOptions: { disableToolbarButton: true },
                        style: { margin: "0.5rem" },
                      },
                    }}
                  />
                </Paper>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box>
        <Footer />
      </Box>
    </>
  );
};

export default AddEmployee;
