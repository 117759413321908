import React, { useEffect, useState } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { format } from "date-fns";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { RootState } from "../../store/Store";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Button,
} from "@mui/material";
import {
	fetchData,
	FetchDataFailureAction,
	FetchDataSuccessAction,
} from "../../store/actions";
import {
	allEmployeeDocuments,
	deleteEmployeeDocuments,
} from "../../Service/Apis";
import {
	ToastVariant,
	useCustomToast,
} from "../../components/Methods/SnackBar";
import Loading from "../../components/Loading/Loading";
import NothingToDisplayComponent from "../../components/Data/NothingToShowComponent";

interface Document {
	id: string;
	employee: string;
	employee_name: string;
	skill_document: {
		document_id: string;
		document_name: string;
	};
	hr_completion_date: string | null;
	employee_completion_date: string | null;
	employee_start_date: string | null;
	employee_completion_status: boolean;
	assigned_at: string;
}

const UploadedDocuments = () => {
	const [documents, setDocuments] = useState<Document[]>([]);
	const [loading, setLoading] = useState(true);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const [deleteId, setDeleteId] = useState<string | null>(null);

	const showToast = useCustomToast();

	const dispatch2: ThunkDispatch<
		RootState,
		null,
		FetchDataSuccessAction | FetchDataFailureAction
	> = useDispatch();

	const handleShowToast = (
		message: string,
		variant: ToastVariant,
		isCloseable: boolean
	) => {
		showToast(message, variant, isCloseable);
	};

	const handleDeleteClick = (id: string) => {
		setDeleteId(id);
		setOpenConfirmDialog(true);
	};

	const handleConfirmDelete = async () => {
		if (deleteId) {
			try {
				await axios.delete(`${deleteEmployeeDocuments}?id=${deleteId}`, {
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`,
					},
				});
				setDocuments((prevDocs) =>
					prevDocs.filter((doc) => doc.id !== deleteId)
				);
				handleShowToast(
					"Document assignment deleted successfully",
					"success",
					true
				);
			} catch (error) {
				console.error("Error deleting document:", error);
				handleShowToast("Error deleting document", "error", true);
			}
		}
		setOpenConfirmDialog(false);
		setDeleteId(null);
	};

	const columns: GridColDef[] = [
		{ field: "documentName", headerName: "Document Name", width: 200 },
		{ field: "employeeName", headerName: "Employee Name", width: 200 },
		{
			field: "hr_completion_date",
			headerName: "HR Completion Date",
			width: 200,
			valueFormatter: (params) =>
				params.value ? format(new Date(params.value), "yyyy-MM-dd") : "-",
		},
		{
			field: "employee_start_date",
			headerName: "Start Date",
			width: 200,
			valueFormatter: (params) =>
				params.value ? format(new Date(params.value), "yyyy-MM-dd") : "-",
		},
		{
			field: "employee_completion_date",
			headerName: "Completion Date",
			width: 200,
			valueFormatter: (params) =>
				params.value ? format(new Date(params.value), "yyyy-MM-dd") : "-",
		},
		{
			field: "actions",
			headerName: "Actions",
			width: 100,
			renderCell: (params) => (
				<IconButton
					onClick={() => handleDeleteClick(params.row.id)}
					color="error"
					size="small"
				>
					<DeleteIcon />
				</IconButton>
			),
		},
	];

	const rows = documents.map((doc) => ({
		id: doc.id,
		documentName: doc.skill_document.document_name,
		employeeName: doc.employee_name,
		hr_completion_date: doc.hr_completion_date,
		employee_start_date: doc.employee_start_date,
		employee_completion_date: doc.employee_completion_date,
	}));

	useEffect(() => {
		const fetchDocuments = async () => {
			try {
				setLoading(true);
				const response = await dispatch2(fetchData(allEmployeeDocuments));
				setDocuments(response as Document[]);
			} catch (error) {
				console.error("Error fetching documents:", error);
				handleShowToast("Error fetching documents", "error", true);
			} finally {
				setLoading(false);
			}
		};

		fetchDocuments();
	}, []);

	if (loading) {
		return <Loading isLoading={loading} />;
	}

	return (
		<Box sx={{ height: "100%", width: "100%", p: 2, marginLeft: "10rem" }}>
			<Typography variant="h4" sx={{ mb: 2, textAlign: "center" }}>
				Uploaded Documents
			</Typography>
			{documents.length === 0 ? (
				<NothingToDisplayComponent />
			) : (
				<DataGrid
					rows={rows}
					columns={columns}
					autoHeight
					components={{
						Toolbar: GridToolbar,
					}}
					componentsProps={{
						toolbar: {
							showQuickFilter: true,
							quickFilterProps: { debounceMs: 500 },
						},
					}}
				/>
			)}
			<Dialog
				open={openConfirmDialog}
				onClose={() => setOpenConfirmDialog(false)}
			>
				<DialogTitle>Confirm Delete</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Are you sure you want to delete this document assignment?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpenConfirmDialog(false)}>Cancel</Button>
					<Button onClick={handleConfirmDelete} color="error" autoFocus>
						Delete
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
};

export default UploadedDocuments;
