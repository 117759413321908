import React, { useEffect, useMemo, useState } from "react";

import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";

import { setInPageLoading, setIsLoading } from "../../store/LoadingSlice";
import { RootState } from "../../store/Store";
import {
  FetchDataFailureAction,
  FetchDataSuccessAction,
  fetchData,
  postData,
} from "../../store/actions";

import {
  addAttendanceRecord,
  listAttendanceRecords,
  viewUsers,
} from "../../Service/Apis";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import NothingToDisplayComponent from "../../components/Data/NothingToShowComponent";
import {
  ToastVariant,
  useCustomToast,
} from "../../components/Methods/SnackBar";
import NumberInput from "../../components/NumberInput/NumberInput";
import { AttendanceType } from "./Types/AttendaceTypes";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import CustomNoRowsOverlay from "../../components/Data/NoRowsOverlayComponent";
import { startOfMonth } from "date-fns";
import Loading from "../../components/Loading/Loading";

interface Employeetype {
  id: string;
  employee_code: string;
  first_name: string;
  last_name: string;
  gender: string;
  roles: [];
  designation: string;
  date_of_birth: string;
  date_of_joining: string;
  date_of_confirmation: string;
  date_of_exit: string;
  email: string;
  password: string;
}

const ReviewAttendance = () => {
  // State Hooks
  const [employeesData, setEmployeesData] = useState<Employeetype[]>([]);
  const [employee, setEmployee] = useState<Employeetype | null>(null);

  const [fromDate, setFromDate] = React.useState<Dayjs | null>(null);
  const [toDate, setToDate] = React.useState<Dayjs | null>(null);
  const [attendance, setAttendance] = useState<AttendanceType[]>([]);
  const [edit, setEdit] = useState<Boolean>(false);
  const [forceUpdateKey, setForceUpdateKey] = useState(0);
  // Redux Hooks
  const dispatch = useDispatch();
  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();

  const showToast = useCustomToast();

  const isLoading = useSelector((state: any) => state.loading.isLoading);

  const handleShowToast = (
    message: string,
    variant: ToastVariant,
    isCloseable: boolean
  ) => {
    showToast(message, variant, isCloseable);
  };
  //Handle Employee Change Function
  const handleEmployeeChange = (e: any, values: any) => {
    setEmployee(values);
  };

  const handleNumberInputChange = (
    e: any,
    newValue: number | null,
    propertyName: keyof AttendanceType
  ) => {
    e.preventDefault();
    const roundedValue = newValue ? parseFloat(newValue?.toFixed(1)) : 0;
    setAttendance((prev) => [{ ...prev[0], [propertyName]: roundedValue }]);
    setForceUpdateKey(forceUpdateKey + 1);
  };

  const handleSave = async (e: any) => {
    e.preventDefault();
    console.log(attendance);

    try {
      dispatch(setIsLoading(true));
      const response = await dispatch2(
        postData(`${addAttendanceRecord}`, attendance[0])
      );
      handleShowToast(
        "Attendance record added successfully !",
        "success",
        true
      );
      setEmployee(null);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  const rows = attendance.map((data, index) => {
    return {
      ...data,
      index: index,
      sr_no: index + 1,
      month_year: dayjs(data.date).format("MMM-YYYY"),
    };
  });

  const columns: GridColDef[] = useMemo(() => {
    return [
      {
        field: "sr_no",
        headerName: "Sr. No.",
        width: 90,
        align: "center",
        headerAlign: "center",
        headerClassName: "header-background",
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Sr. No.
          </strong>
        ),
      },
      {
        field: "employee_name",
        headerName: "Employee Name",
        align: "center",
        width: 160,
        headerAlign: "center",
        headerClassName: "header-background",
        hide: true,
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Employee Name
          </strong>
        ),
      },
      {
        field: "month_year",
        headerName: "Employee Name",
        align: "center",
        width: 120,
        headerAlign: "center",
        headerClassName: "header-background",
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Month-Year
          </strong>
        ),
      },
      {
        field: "paid_leaves",
        headerName: "Paid Leaves",
        align: "center",
        width: 110,
        headerAlign: "center",
        headerClassName: "header-background",
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Paid Leaves
          </strong>
        ),
      },
      {
        field: "sick_leaves",
        headerName: "Sick Leaves",
        align: "center",
        width: 110,
        headerAlign: "center",
        headerClassName: "header-background",
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Sick Leaves
          </strong>
        ),
      },
      {
        field: "leave_without_pay",
        headerName: "Leave Without Pay",
        width: 160,
        align: "center",
        headerAlign: "center",
        headerClassName: "header-background",
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Leave Without Pay
          </strong>
        ),
      },
      {
        field: "regularized_days",
        headerName: "Regularization Requests",
        width: 195,
        align: "center",
        headerAlign: "center",
        headerClassName: "header-background",
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Regularization Requests
          </strong>
        ),
      },
    ];
  }, [rows]);

  useEffect(() => {
    // API Call get the User Details.
    const fetchUserDetails = async () => {
      try {
        dispatch(setIsLoading(true));
        const response = await dispatch2(fetchData(`${viewUsers}`));
        setEmployeesData(response as Employeetype[]);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setIsLoading(false));
      }
    };

    fetchUserDetails();
  }, []);

  useEffect(() => {
    // API call to get the employee skill responses.
    const fetchAttendance = async () => {
      try {
        dispatch(setInPageLoading(true));
        const response = await dispatch2(
          fetchData(
            `${listAttendanceRecords}${
              employee
                ? `?user_id=${employee.id}${
                    fromDate && toDate
                      ? `&date_from=${fromDate
                          .format("YYYY-MM-DD")
                          .toString()}&date_to=${toDate
                          .format("YYYY-MM-DD")
                          .toString()}`
                      : ""
                  }`
                : fromDate && toDate
                ? `?date_from=${fromDate
                    .format("YYYY-MM-DD")
                    .toString()}&date_to=${toDate
                    .format("YYYY-MM-DD")
                    .toString()}`
                : ""
            }`
          )
        );
        setAttendance(response as AttendanceType[]);
        console.log(response);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setInPageLoading(false));
      }
    };

    // Check whether the employee is selected before making the API call.
    // if (employee) {
    if ((fromDate && toDate) || (!fromDate && !toDate)) fetchAttendance();
    // }
  }, [employee, toDate, fromDate]);

  useEffect(() => {
    setEdit(false);
  }, [employee]);

  // Loading Spinner Component
  if (isLoading) {
    return (
      <>
        <Loading isLoading={isLoading as boolean} />
      </>
    );
  }

  // Main Component

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Autocomplete
              size="small"
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) =>
                option.first_name + " " + option.last_name
              }
              value={employee}
              onChange={handleEmployeeChange}
              id="employee-autocomplete"
              options={employeesData}
              sx={{ width: "40vw", mx: 2, px: 2 }}
              renderInput={(params) => <TextField {...params} />}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <DatePicker
                  label="From"
                  views={["month", "year"]}
                  sx={{
                    ".MuiInputLabel-root": {
                      transform: "translate(14px, 8px) scale(1)",
                    },
                    ".MuiInputLabel-shrink": {
                      transform: "translate(14px, -6px) scale(0.75)",
                    },
                    ".MuiInputBase-root .MuiInputBase-input": {
                      /* Your styles here */
                      height: "0.55rem",
                      width: "8rem",
                    },
                    mx: 1,
                  }}
                  value={fromDate}
                  onChange={(newValue) =>
                    setFromDate(
                      newValue ? dayjs(startOfMonth(newValue.toDate())) : null
                    )
                  }
                  disableFuture
                />
                <DatePicker
                  label="To"
                  sx={{
                    ".MuiInputLabel-root": {
                      transform: "translate(14px, 8px) scale(1)",
                    },
                    ".MuiInputLabel-shrink": {
                      transform: "translate(14px, -6px) scale(0.75)",
                    },
                    ".MuiInputBase-root .MuiInputBase-input": {
                      /* Your styles here */
                      height: "0.55rem",
                      width: "8rem",
                    },
                    mx: 1,
                  }}
                  views={["month", "year"]}
                  value={toDate}
                  onChange={(newValue) =>
                    setToDate(
                      newValue ? dayjs(startOfMonth(newValue.toDate())) : null
                    )
                  }
                  disableFuture
                />
                <Button
                  size="small"
                  disableElevation
                  disableFocusRipple
                  onClick={() => {
                    setFromDate(null);
                    setToDate(null);
                  }}
                >
                  Clear
                </Button>
              </Box>
            </LocalizationProvider>
          </Box>
          {
            //   <Typography
            //     id="employee-autocomplete-label"
            //
            //     variant="body2"
            //     style={{ marginTop: "16px" }}
            //     sx={{ display: "flex", justifyContent: "center" }}
            //   >
            //     Select an employee to view attendance.
            //   </Typography>
            // ) : (
            <Box sx={{ mt: 2, pt: 2 }}>
              {attendance.length === 0 ? (
                <>
                  <Box
                    sx={{
                      mt: 12,
                      left: "50%",
                      // transform: "translate(-50%, -50%)",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <NothingToDisplayComponent />
                  </Box>
                </>
              ) : (
                <>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      m: 2,
                      p: 2,
                    }}
                  >
                    <Paper>
                      <DataGrid
                        autoHeight
                        disableColumnMenu
                        sx={{
                          width: "100%",
                          height: "100%",
                          "--DataGrid-overlayHeight": "50vh",
                        }}
                        initialState={{
                          pagination: {
                            paginationModel: {
                              pageSize: 12,
                            },
                          },
                        }}
                        pagination
                        pageSizeOptions={[5, 10, 12, 15]}
                        rows={rows}
                        columns={columns}
                        disableColumnSelector
                        disableDensitySelector
                        slots={{
                          toolbar: GridToolbar,
                          noRowsOverlay: CustomNoRowsOverlay,
                          noResultsOverlay: CustomNoRowsOverlay,
                        }}
                        slotProps={{
                          toolbar: {
                            // csvOptions: { disableToolbarButton: true },
                            showQuickFilter: true,
                            // printOptions: { disableToolbarButton: true },
                            style: { margin: "0.5rem" },
                          },
                        }}
                        getRowId={(row) => row.attendance_id}
                      />
                    </Paper>
                    {/* <Box sx={{ width: "100%" }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          m: 1,
                          p: 1,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{ mx: 1, width: "10rem" }}
                            
                            fontWeight={"bold"}
                          >
                            Paid Leaves :
                          </Typography>
                          <NumberInput
                            key={forceUpdateKey}
                            min={0}
                            aria-label="Demo number input"
                            placeholder={edit ? "Type a number…" : null}
                            value={
                              attendance[0].paid_leaves
                                ? attendance[0].paid_leaves
                                : 0
                            }
                            step={0.5}
                            max={100}
                            readOnly={!edit}
                            onChange={(e: any, val: number) => {
                              e.preventDefault();
                              handleNumberInputChange(e, val, "paid_leaves");
                            }}
                          />
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography
                            sx={{ mx: 1, width: "10rem" }}
                            
                            fontWeight={"bold"}
                          >
                            Sick Leaves :
                          </Typography>
                          <NumberInput
                            key={forceUpdateKey}
                            min={0}
                            aria-label="Demo number input"
                            placeholder={edit ? "Type a number…" : null}
                            value={
                              attendance[0]?.sick_leaves
                                ? attendance[0]?.sick_leaves
                                : 0
                            }
                            step={0.5}
                            max={100}
                            readOnly={!edit}
                            onChange={(e: any, val: number) => {
                              e.preventDefault();
                              handleNumberInputChange(e, val, "sick_leaves");
                            }}
                          />
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          m: 1,
                          p: 1,
                        }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography
                            sx={{ mx: 1, width: "10rem" }}
                            
                            fontWeight={"bold"}
                          >
                            Leave Without Pay :
                          </Typography>
                          <NumberInput
                            key={forceUpdateKey}
                            min={0}
                            aria-label="Demo number input"
                            placeholder={edit ? "Type a number…" : null}
                            value={
                              attendance[0]?.leave_without_pay
                                ? attendance[0]?.leave_without_pay
                                : 0
                            }
                            step={0.5}
                            max={100}
                            readOnly={!edit}
                            onChange={(e: any, val: number) => {
                              e.preventDefault();
                              handleNumberInputChange(
                                e,
                                val,
                                "leave_without_pay"
                              );
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{ mx: 1, width: "10rem" }}
                            
                            fontWeight={"bold"}
                          >
                            Present Days :
                          </Typography>
                          <NumberInput
                            key={forceUpdateKey}
                            min={0}
                            aria-label="Demo number input"
                            placeholder={edit ? "Type a number…" : null}
                            value={
                              attendance[0]?.present_days
                                ? attendance[0]?.present_days
                                : 0
                            }
                            step={0.5}
                            max={100}
                            readOnly={!edit}
                            onChange={(e: any, val: number) => {
                              e.preventDefault();
                              handleNumberInputChange(e, val, "present_days");
                            }}
                          />
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          m: 1,
                          p: 1,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            m: 1,
                          }}
                        >
                          <Typography
                            sx={{ mx: 1 }}
                            
                            fontWeight={"bold"}
                          >
                            Regularization Requests :
                          </Typography>
                          <NumberInput
                            key={forceUpdateKey}
                            min={0}
                            aria-label="Demo number input"
                            placeholder={edit ? "Type a number…" : null}
                            value={
                              attendance[0]?.regularized_days
                                ? attendance[0]?.regularized_days
                                : 0
                            }
                            max={100}
                            readOnly={!edit}
                            onChange={(e: any, val: number) => {
                              e.preventDefault();
                              handleNumberInputChange(
                                e,
                                val,
                                "regularized_days"
                              );
                            }}
                          />
                        </Box>
                      </Box>
                    </Box> */}
                  </Box>
                </>
              )}
            </Box>
          }
        </Box>
      </Box>
    </>
  );
};

export default ReviewAttendance;
