import React, { useEffect, useMemo, useState } from "react";

import {
  Box,
  Button,
  CircularProgress,
  Paper
} from "@mui/material";

import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";

import { setInPageLoading } from "../../store/LoadingSlice";
import { RootState } from "../../store/Store";
import {
  FetchDataFailureAction,
  FetchDataSuccessAction,
  fetchData,
} from "../../store/actions";

import { listAttendanceRecords } from "../../Service/Apis";

import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { startOfMonth } from "date-fns";
import dayjs, { Dayjs } from "dayjs";
import CustomNoRowsOverlay from "../../components/Data/NoRowsOverlayComponent";
import NothingToDisplayComponent from "../../components/Data/NothingToShowComponent";
import { AttendanceType } from "./Types/AttendaceTypes";

interface Employeetype {
  id: string;
  employee_code: string;
  first_name: string;
  last_name: string;
  gender: string;
  roles: [];
  designation: string;
  date_of_birth: string;
  date_of_joining: string;
  date_of_confirmation: string;
  date_of_exit: string;
  email: string;
  password: string;
}

const EmployeeAttendance = () => {
  // State Hooks
  const [employeesData, setEmployeesData] = useState<Employeetype[]>([]);
  const [employee, setEmployee] = useState<Employeetype | null>(null);

  const [fromDate, setFromDate] = React.useState<Dayjs | null>(null);
  const [toDate, setToDate] = React.useState<Dayjs | null>(null);

  const [attendance, setAttendance] = useState<AttendanceType[]>([]);
  const [forceUpdateKey, setForceUpdateKey] = useState(0);
  // Redux Hooks
  const dispatch = useDispatch();
  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();

  const isLoading = useSelector((state: any) => state.loading.inPageLoading);

  const rows = attendance.map((data, index) => {
    return {
      ...data,
      index: index,
      sr_no: index + 1,
      month_year: dayjs(data.date).format("MMM-YYYY"),
    };
  });

  const columns: GridColDef[] = useMemo(() => {
    return [
      {
        field: "sr_no",
        headerName: "Sr. No.",
        width: 90,
        align: "center",
        headerAlign: "center",
        headerClassName: "header-background",
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Sr. No.
          </strong>
        ),
      },
      {
        field: "employee_name",
        headerName: "Employee Name",
        align: "center",
        width: 160,
        headerAlign: "center",
        headerClassName: "header-background",
        hide: true,
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Employee Name
          </strong>
        ),
      },
      {
        field: "month_year",
        headerName: "Employee Name",
        align: "center",
        width: 120,
        headerAlign: "center",
        headerClassName: "header-background",
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Month-Year
          </strong>
        ),
      },
      {
        field: "paid_leaves",
        headerName: "Paid Leaves",
        align: "center",
        width: 110,
        headerAlign: "center",
        headerClassName: "header-background",
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Paid Leaves
          </strong>
        ),
      },
      {
        field: "sick_leaves",
        headerName: "Sick Leaves",
        align: "center",
        width: 110,
        headerAlign: "center",
        headerClassName: "header-background",
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Sick Leaves
          </strong>
        ),
      },
      {
        field: "leave_without_pay",
        headerName: "Leave Without Pay",
        width: 160,
        align: "center",
        headerAlign: "center",
        headerClassName: "header-background",
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Leave Without Pay
          </strong>
        ),
      },
      {
        field: "regularized_days",
        headerName: "Regularization Requests",
        width: 195,
        align: "center",
        headerAlign: "center",
        headerClassName: "header-background",
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Regularization Requests
          </strong>
        ),
      },
    ];
  }, [rows]);

  useEffect(() => {
    // API call to get the employee skill responses.
    const fetchAttendance = async () => {
      try {
        dispatch(setInPageLoading(true));
        const response = await dispatch2(
          fetchData(
            `${
              listAttendanceRecords +
              `?is_employee=True` +
              (fromDate && toDate
                ? `&date_from=${fromDate
                    .format("YYYY-MM-DD")
                    .toString()}&date_to=${toDate
                    .format("YYYY-MM-DD")
                    .toString()}`
                : "")
            }`
          )
        );
        setAttendance(response as AttendanceType[]);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setInPageLoading(false));
      }
    };

    // Check whether the employee is selected before making the API call.
    if ((fromDate && toDate) || (!fromDate && !toDate)) fetchAttendance();
  }, [fromDate, toDate]);

  // Loading Spinner Component
  if (isLoading) {
    return (
      <>
        {/* Fullscreen Overlay */}
        <Box
          id="fullscreen-overlay"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backdropFilter: "blur(1.5px)", // Adjust the blur intensity as needed
            zIndex: 9999,
          }}
        />
        {/* Loader Container */}
        <Box
          id="loader-container"
          sx={{
            position: "fixed", // Use fixed positioning for the loader container
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 10000,
          }}
        >
          {/* Loader */}
          <Box
            id="loader"
            sx={{
              position: "relative", // Use relative positioning for the actual loader
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {isLoading && <CircularProgress size={50}  />}
          </Box>
        </Box>
      </>
    );
  }

  // Main Component

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box sx={{ width: "80%" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "right",
              width: "100%",
              mt: 2,
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <DatePicker
                  label="From"
                  views={["month", "year"]}
                  sx={{
                    ".MuiInputLabel-root": {
                      transform: "translate(14px, 8px) scale(1)",
                    },
                    ".MuiInputLabel-shrink": {
                      transform: "translate(14px, -6px) scale(0.75)",
                    },
                    ".MuiInputBase-root .MuiInputBase-input": {
                      /* Your styles here */
                      height: "0.55rem",
                      width: "8rem",
                    },
                    mx: 1,
                  }}
                  value={fromDate}
                  onChange={(newValue) =>
                    setFromDate(
                      newValue ? dayjs(startOfMonth(newValue.toDate())) : null
                    )
                  }
                  disableFuture
                />
                <DatePicker
                  label="To"
                  sx={{
                    ".MuiInputLabel-root": {
                      transform: "translate(14px, 8px) scale(1)",
                    },
                    ".MuiInputLabel-shrink": {
                      transform: "translate(14px, -6px) scale(0.75)",
                    },
                    ".MuiInputBase-root .MuiInputBase-input": {
                      /* Your styles here */
                      height: "0.55rem",
                      width: "8rem",
                    },
                    mx: 1,
                  }}
                  views={["month", "year"]}
                  value={toDate}
                  onChange={(newValue) =>
                    setToDate(
                      newValue ? dayjs(startOfMonth(newValue.toDate())) : null
                    )
                  }
                  disableFuture
                />
                <Button
                  size="small"
                  
                  disableElevation
                  disableFocusRipple
                  onClick={() => {
                    setFromDate(null);
                    setToDate(null);
                  }}
                >
                  Clear
                </Button>
              </Box>
            </LocalizationProvider>
          </Box>

          <Box sx={{ mt: 2, pt: 2 }}>
            {attendance.length === 0 ? (
              <>
                <Box
                  sx={{
                    mt: 12,
                    left: "50%",
                    // transform: "translate(-50%, -50%)",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <NothingToDisplayComponent />
                </Box>
              </>
            ) : (
              <>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    m: 1,
                    p: 1,
                  }}
                >
                  <Paper>
                    <DataGrid
                      autoHeight
                      disableColumnMenu
                      sx={{
                        width: "100%",
                        height: "100%",
                        "--DataGrid-overlayHeight": "50vh",
                      }}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 12,
                          },
                        },
                      }}
                      pagination
                      pageSizeOptions={[5, 10, 12, 15]}
                      rows={rows}
                      columns={columns}
                      disableColumnSelector
                      disableDensitySelector
                      slots={{
                        toolbar: GridToolbar,
                        noRowsOverlay: CustomNoRowsOverlay,
                        noResultsOverlay: CustomNoRowsOverlay,
                      }}
                      slotProps={{
                        toolbar: {
                          // csvOptions: { disableToolbarButton: true },
                          showQuickFilter: true,
                          // printOptions: { disableToolbarButton: true },
                          style: { margin: "0.5rem" },
                        },
                      }}
                      getRowId={(row) => row.attendance_id}
                    />
                  </Paper>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default EmployeeAttendance;
