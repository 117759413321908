import { Badge, Box, Tab, Tabs } from "@mui/material";
import React from "react";
import { CustomTabPanel, a11yProps } from "../../components/TabPanel/TabPanel";
import {
  useCustomToast,
  ToastVariant,
} from "../../components/Methods/SnackBar";
import AddDataPoints from "./AddDataPoints";
import ReviewDataPoints from "./ReviewDataPoints";
import FillDataPoint from "./FillDataPoint";
import EmployeeViewDataPoint from "./EmployeeViewDataPoint";

const FilllAndViewDataPoints = () => {
  const [value, setValue] = React.useState(0);

  const showToast = useCustomToast();
  const handleShowToast = (
    message: string,
    variant: ToastVariant,
    isCloseable: boolean
  ) => {
    showToast(message, variant, isCloseable);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = event.currentTarget.innerHTML;
    const tabLabel = tempElement.innerText;

    // Clean up the temporary element
    tempElement.remove();
    setValue(newValue);
    handleShowToast(`Switched to ${tabLabel}`, "info", true);
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          height: "100%", // Adjust the height as needed
        }}
      >
        <Box
          sx={{
            minWidth: 110, // Set the width for the left sidebar
            bgcolor: "background.paper",
          }}
        >
          <Tabs
            centered
            value={value}
            onChange={handleChange}
            orientation="vertical"
            textColor="inherit"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
          >
            <Tab {...a11yProps(0)} label="Fill Data Point"></Tab>
            <Tab {...a11yProps(1)} label="View Data Point" />
          </Tabs>
        </Box>
        <Box
          sx={{
            flex: 1,
            // display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CustomTabPanel index={0} value={value}>
            <FillDataPoint />
          </CustomTabPanel>
          <CustomTabPanel index={1} value={value}>
            <EmployeeViewDataPoint />
          </CustomTabPanel>
        </Box>
      </Box>
    </>
  );
};

export default FilllAndViewDataPoints;
