import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import {
	TextField,
	Button,
	Box,
	Typography,
	IconButton,
	CircularProgress,
} from "@mui/material";
import axios from "axios";
import {
	documentsByEmployeeId,
	downloadDocument,
	updatedocument,
} from "../../Service/Apis";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../../store/Store";
import {
	fetchData,
	FetchDataFailureAction,
	FetchDataSuccessAction,
	patchData,
} from "../../store/actions";
import DownloadIcon from "@mui/icons-material/Download";
import { format } from "date-fns";
import {
	ToastVariant,
	useCustomToast,
} from "../../components/Methods/SnackBar";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import NothingToDisplayComponent from "../../components/Data/NothingToShowComponent";

interface SkillDocument {
	document_id: string;
	document_name: string;
	file?: string;
}

interface Document {
	id: string;
	skill_document: SkillDocument;
	hr_completion_date?: string;
	employee_completion_date?: string;
	employee_start_date?: string;
	employee_completion_status: boolean;
	assigned_at: string;
	isFromApi?: boolean;
	isSubmitted?: boolean;
}

const EmployeeSkillsDocuments = () => {
	const [documents, setDocuments] = useState<Document[]>([]);
	const [isLoading, setIsLoading] = useState(true); // Add loading state
	const [submitLoading, setSubmitLoading] = useState<string | null>(null);

	const employee_id =
		useSelector((state: any) => state.person.perstate.id) ||
		localStorage.getItem("id");
	const dispatch2: ThunkDispatch<
		RootState,
		null,
		FetchDataSuccessAction | FetchDataFailureAction
	> = useDispatch();

	const showToast = useCustomToast();
	const handleShowToast = (
		message: string,
		variant: ToastVariant,
		isCloseable: boolean
	) => {
		showToast(message, variant, isCloseable);
	};

	const LoadingOverlay = ({ docId }: { docId: string }) => (
		<>
			<Box
				style={{
					position: "absolute",
					top: 0,
					left: 0,
					width: "100%",
					height: "100%",
					backgroundColor: "rgba(255, 255, 255, 0.7)",
					backdropFilter: "blur(1px)",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					zIndex: 999,
				}}
			>
				<CircularProgress size={20} />
			</Box>
		</>
	);

	useEffect(() => {
		setIsLoading(true); // Start loading
		dispatch2(fetchData(`${documentsByEmployeeId}${employee_id}`))
			.then((response) => {
				if (response) {
					const documentsWithApiFlag = response.map((doc: Document) => ({
						...doc,
						// Mark as fromApi if either date exists from API
						isFromApi: !!(
							doc.employee_start_date || doc.employee_completion_date
						),
						// Initialize isSubmitted as false for new documents
						isSubmitted: false,
					}));
					setDocuments(documentsWithApiFlag);
				}
			})
			.catch((error) => {
				console.error("Error fetching documents:", error);
				handleShowToast("Error fetching documents", "error", true);
			})
			.finally(() => {
				setIsLoading(false); // End loading regardless of success/failure
			});
	}, [dispatch2, employee_id]);

	type DateField = "employee_start_date" | "employee_completion_date";
	const handleDateChange = (
		id: string,
		field: DateField,
		value: string
	): void => {
		setDocuments((prevDocuments: Document[]) =>
			prevDocuments.map((doc) =>
				doc.id === id ? { ...doc, [field]: value } : doc
			)
		);
	};

	const getTodayDate = () => {
		return new Date().toISOString().split("T")[0];
	};

	const getMinCompletionDate = (startDate: string | undefined) => {
		if (!startDate) return getTodayDate();
		return new Date(startDate).toISOString().split("T")[0];
	};

	const handleDownload = async (documentId: string, fileName: string) => {
		try {
			const response = await axios.get(`${downloadDocument}${documentId}`, {
				responseType: "blob",
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
			});

			// Create blob link to download
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", fileName);
			document.body.appendChild(link);
			link.click();
			link.remove();

			handleShowToast("Document downloaded successfully", "success", true);
		} catch (error) {
			console.error("Error downloading document:", error);
			handleShowToast("Error downloading document", "error", true);
		}
	};

	const handleSubmit = (id: string): void => {
		const document = documents.find((doc) => doc.id === id);
		if (document) {
			if (!document.employee_start_date || !document.employee_completion_date) {
				handleShowToast(
					"Please enter both start and completion dates",
					"error",
					true
				);
				return;
			}
			setSubmitLoading(id);
			const data = {
				employee_start_date: document.employee_start_date,
				employee_completion_date: document.employee_completion_date,
			};

			dispatch2(patchData(`${updatedocument}?id=${document.id}`, data))
				.then((response) => {
					setDocuments((prevDocs) =>
						prevDocs.map((doc) =>
							doc.id === id ? { ...doc, isSubmitted: true } : doc
						)
					);
					handleShowToast("Dates submitted successfully", "success", true);
				})
				.catch((error) => {
					console.error("Error submitting dates:", error);
					handleShowToast("Error submitting dates", "error", true);
				})
				.finally(() => {
					setSubmitLoading(null); // End loading
				});
		}
	};
	const columns = [
		{ field: "srNo", headerName: "Sr. No", width: 100 },
		{ field: "documentName", headerName: "Document Name", width: 200 },
		{
			field: "download",
			headerName: "Download",
			width: 100,
			renderCell: (params: any) => (
				<IconButton
					onClick={() =>
						handleDownload(params.row.documentId, params.row.documentName)
					}
					color="primary"
				>
					<DownloadIcon />
				</IconButton>
			),
		},
		{
			field: "hr_completion_date",
			headerName: "HR Completion Date",
			width: 200,
			renderCell: (params: any) => (
				<DatePicker
					value={
						params.row.hr_completion_date
							? dayjs(params.row.hr_completion_date)
							: null
					}
					readOnly={true}
					format="DD/MM/YYYY"
					slotProps={{
						textField: {
							size: "small",
							fullWidth: true,
						},
					}}
				/>
			),
		},
		{
			field: "employee_start_date",
			headerName: "Start Date",
			width: 200,
			renderCell: (params: any) => {
				if (params.row.isFromApi) {
					return format(new Date(params.value), "yyyy-MM-dd");
				}
				return params.row.isSubmitted && params.value ? (
					format(new Date(params.value), "yyyy-MM-dd")
				) : (
					<TextField
						type="date"
						value={params.value || ""}
						inputProps={{
							min: getTodayDate(),
						}}
						onChange={(e) =>
							handleDateChange(
								params.row.id,
								"employee_start_date",
								e.target.value
							)
						}
						disabled={params.row.isFromApi || params.row.isSubmitted}
					/>
				);
			},
		},
		{
			field: "employee_completion_date",
			headerName: "Completion Date",
			width: 200,
			renderCell: (params: any) => {
				if (params.row.isFromApi) {
					return format(new Date(params.value), "yyyy-MM-dd");
				}
				return params.row.isSubmitted && params.value ? (
					format(new Date(params.value), "yyyy-MM-dd")
				) : (
					<TextField
						type="date"
						value={params.value || ""}
						inputProps={{
							min: getMinCompletionDate(params.row.employee_start_date),
						}}
						onChange={(e) =>
							handleDateChange(
								params.row.id,
								"employee_completion_date",
								e.target.value
							)
						}
						disabled={
							params.row.isFromApi ||
							params.row.isSubmitted ||
							!params.row.employee_start_date
						}
					/>
				);
			},
		},
		{
			field: "submit",
			headerName: "Submit",
			width: 150,
			renderCell: (params: any) => (
				<Box sx={{ position: "relative" }}>
					<Button
						variant="contained"
						color="primary"
						onClick={() => handleSubmit(params.row.id)}
						disabled={
							params.row.isFromApi ||
							params.row.isSubmitted ||
							submitLoading === params.row.id
						}
					>
						Submit
					</Button>
					{submitLoading === params.row.id && (
						<LoadingOverlay docId={params.row.id} />
					)}
				</Box>
			),
		},
	];

	const rows = documents.map((doc, index) => ({
		id: doc.id,
		srNo: index + 1,
		documentName: doc.skill_document.document_name,
		documentId: doc.skill_document.document_id,
		employee_start_date: doc.employee_start_date,
		employee_completion_date: doc.employee_completion_date,
		hr_completion_date: doc.hr_completion_date,
		isFromApi: doc.isFromApi,
		isSubmitted: doc.isSubmitted,
	}));

	// Add loading view
	if (isLoading) {
		return (
			<>
				{/* Fullscreen Overlay */}
				<Box
					id="fullscreen-overlay"
					style={{
						position: "fixed",
						top: 0,
						left: 0,
						width: "100%",
						height: "100%",
						backdropFilter: "blur(1.5px)",
						zIndex: 9999,
					}}
				/>
				{/* Loader Container */}
				<Box
					id="loader-container"
					sx={{
						position: "fixed",
						top: 0,
						left: 0,
						width: "100%",
						height: "100%",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						zIndex: 10000,
					}}
				>
					{/* Loader */}
					<Box
						id="loader"
						sx={{
							position: "relative",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<CircularProgress size={50} />
					</Box>
				</Box>
			</>
		);
	}

	return (
		<Box>
			<Box
				sx={{
					display: "block",
					justifyContent: "center",
					alignItems: "center",
					// marginLeft:"20rem"
				}}
			>
				<Box margin={2} sx={{ textAlign: "center" }}>
					<Typography variant="h3">Employee Skills Documents</Typography>
				</Box>
				{rows.length === 0 ? (
					<NothingToDisplayComponent />
				) : (
					<Box margin={2} sx={{ textAlign: "center", marginLeft: "25rem" }}>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DataGrid
								rows={rows}
								columns={columns}
								style={{ maxWidth: "75rem" }}
							/>
						</LocalizationProvider>
					</Box>
				)}
			</Box>
		</Box>
	);
};

export default EmployeeSkillsDocuments;
