import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ModelSlice {
	
	isOpen: boolean;
}

const initialState: ModelSlice = {
	
	isOpen:false
};

const modelSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		setisOpen: (state, action: PayloadAction<boolean>) => {
			state.isOpen = action.payload;
		},
		
	},
});

export const { setisOpen,  } = modelSlice.actions;

export default modelSlice;
