import React, { useEffect, useRef, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	InputAdornment,
	Radio,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import { ViewUser, changePassword } from "../../Service/Apis";
import capitalizeFirstLetter from "../../components/functions/NameFunc";
import useAxiosInterceptor from "../../components/layout/AxiosInstance";
import { setIsLoading } from "../../store/LoadingSlice";

import { ThunkDispatch } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import {
	ToastVariant,
	useCustomToast,
} from "../../components/Methods/SnackBar";
import Footer from "../../components/Page Component/Footer";
import Navbar from "../../components/Page Component/Navbar";
import Skeletons from "../../components/Skeleton/Skeletons";
import { RootState } from "../../store/Store";
import {
	DataPayload,
	FetchDataFailureAction,
	FetchDataSuccessAction,
	fetchData,
	patchData,
} from "../../store/actions";
import { User, password } from "./Types/Profiletypes";
import { postDataSuccess } from "../../store/AxiosSlice";
import Loading from "../../components/Loading/Loading";

const style = {
	position: "absolute" as "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	// border: "2px solid #000",
	// boxShadow: 24,
	p: 4,
};

const Profile = () => {
	const [user, setUser] = useState<User>({
		id: 0,
		name: "",
		email: "",
		role: "",
	});
	const [changePass, setChangePass] = useState<password>({
		old_password: "",
		new_password: "",
		confirm_password: "",
	});
	const [teams, setTeams] = useState<any[]>([]);
	const [password, setPassword] = useState<string>("passsword");
	const [read, setRead] = useState<boolean>(true);
	const [passwordFocused, setPasswordFocused] = useState(false);
	const [passwordFocused1, setPasswordFocused1] = useState(false);
	const [passwordFocused2, setPasswordFocused2] = useState(false);

	const [showPassword, setShowPassword] = useState(false);
	const [showPassword1, setShowPassword1] = useState(false);
	const [showPassword2, setShowPassword2] = useState(false);

	const [open, setOpen] = React.useState(false);

	const handleOpen = () => {
		setOpen(true);
		oldPasswordFieldRef.current?.focus();
	};
	const handleClose = () => {
		setOpen(false);
		setRead(!read);
	};

	const handleChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		// e.preventDefault();
		let name, value;
		name = e.target.name;
		value = e.target.value;

		setUser({ ...user, [name]: value });
	};
	const dispatch = useDispatch();
	const id = localStorage.getItem("id");
	const token = localStorage.getItem("token");
	const email = localStorage.getItem("email");
	const isloading = useSelector((state: any) => state.loading.isLoading);

	const oldPasswordFieldRef = useRef<HTMLInputElement | null>(null);

	const storedRole = localStorage.getItem("role");
	const roleArray = storedRole ? JSON.parse(storedRole) : [];

	const axiosInstance = useAxiosInterceptor();

	const showToast = useCustomToast();

	const dispatch2: ThunkDispatch<
		RootState,
		null,
		FetchDataSuccessAction | FetchDataFailureAction
	> = useDispatch();
	const name = useSelector((state: any) => state.person.perstate.first_name);
	// const success = useSelector((state: any) => state.http.data);

	const handleShowToast = (
		message: string,
		variant: ToastVariant,
		isCloseable: boolean
	) => {
		showToast(message, variant, isCloseable);
	};

	const handleChangePassword = () => {
		setPassword("");
		setRead(!read);
	};
	const handleChangeInPassword = (e: any) => {
		const { name, value } = e.target;
		setChangePass({ ...changePass, [name]: value });
	};

	const handleKeyPress = (event: any) => {
		// Perform your logic here based on the key press event
		if (event.key === "Enter") {
			// Handle the "Enter" key press, e.g., submit the form
		}
	};
	const handlePasswordFocus = () => {
		setPasswordFocused(true);
	};

	const handlePasswordBlur = () => {
		setPasswordFocused(false);
	};

	const handlePasswordFocus1 = () => {
		setPasswordFocused1(true);
	};

	const handlePasswordBlur1 = () => {
		setPasswordFocused1(false);
	};

	const handlePasswordFocus2 = () => {
		setPasswordFocused2(true);
	};

	const handlePasswordBlur2 = () => {
		setPasswordFocused2(false);
	};

	const handleTogglePassword = () => {
		setShowPassword((prevShowPassword) => !prevShowPassword);
	};
	const handleTogglePassword1 = () => {
		setShowPassword1((prevShowPassword) => !prevShowPassword);
	};
	const handleTogglePassword2 = () => {
		setShowPassword2((prevShowPassword) => !prevShowPassword);
	};

	//api for changing password
	const handleSubmit2 = (e: any) => {
		e.preventDefault();
		try {
			const response = dispatch2(
				patchData(`${changePassword}${id}/`, changePass)
			)
				.then((response) => {
					handleShowToast("Password Changed Successfully", "success", true);
					dispatch(postDataSuccess(null));
					handleClose();
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	};

	const dataoffemp = useSelector((state: any) => state.axios.data);
	const errorofemp = useSelector((state: any) => state.axios.error);

	//getting information about the particular employees
	useEffect(() => {
		// const token = localStorage.getItem("token");

		const emailid = localStorage.getItem("email");
		const fetchUserData = async () => {
			try {
				dispatch(setIsLoading(true));
				const response = await dispatch2(fetchData(`${ViewUser}${email}`));
				setTeams(response as any[]);
				dispatch(setIsLoading(false));
			} catch (error) {
				console.log(error);
				dispatch(setIsLoading(false));
			}
		};
		fetchUserData();
	}, []);

	useEffect(() => {
		if (open) {
			oldPasswordFieldRef.current?.focus();
		}
	}, [open]);

	useEffect(() => {
		const fetchMyData = async () => {
			try {
				const response: DataPayload = await dispatch2(
					fetchData(`${ViewUser}${email}`)
				);
			} catch (error) {
				console.log("Error:", error);
			}
		};

		fetchMyData();
	}, [dispatch, email]);

	//if Loading then displaying nothing to display Component
	if (isloading) {
		return (
			<>
				<Loading isLoading={isloading as boolean} />
			</>
		);
	}
	return (
		<>
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
				}}
			>
				{teams.map((team: any) => {
					return (
						<Box sx={{ width: { xs: "100%", sm: "100%", md: "100%" } }}>
							<Box>
								<Box
									sx={{
										display: "flex",
										justifyContent: "center",
										m: 2,
										p: 2,
									}}
								>
									<Typography
										variant="h3"
										fontWeight={"bold"}
										letterSpacing={4}
									>
										Hello {capitalizeFirstLetter(team.first_name)}{" "}
										{capitalizeFirstLetter(team.last_name)},
									</Typography>
								</Box>
								<Box sx={{ display: "flex", justifyContent: "center" }}>
									<Box
										sx={{ m: 1, p: 1, overflow: "auto", width: "fit-content" }}
									>
										<Box
											sx={{
												display: { xs: "block", md: "flex" },
												justifyContent: { xs: "center", md: "space-around" },
											}}
										>
											<Box sx={{ m: 2, display: "flex", alignItems: "center" }}>
												<Typography fontWeight={"bold"} sx={{ width: "14rem" }}>
													Employee Code :
												</Typography>
												<TextField
													fullWidth
													id="name"
													variant="standard"
													size="small"
													inputProps={{ readonly: "true" }}
													value={team.employee_code}
													sx={{ width: "16rem" }}
												/>
											</Box>
											<Box sx={{ m: 2, display: "flex", alignItems: "center" }}>
												<Typography fontWeight={"bold"} sx={{ width: "14rem" }}>
													Email :
												</Typography>
												<TextField
													fullWidth
													id="email"
													variant="standard"
													inputProps={{ readonly: "true" }}
													size="small"
													value={email}
													sx={{ width: "16rem" }}
												/>
											</Box>
										</Box>
										<Box
											sx={{
												display: { xs: "block", sm: "block", md: "flex" },
												justifyContent: "space-around",
											}}
										>
											<Box sx={{ m: 2, display: "flex", alignItems: "center" }}>
												<Typography fontWeight={"bold"} sx={{ width: "14rem" }}>
													Gender :
												</Typography>
												<TextField
													fullWidth
													id="gender"
													variant="standard"
													inputProps={{ readonly: "true" }}
													size="small"
													value={team.gender}
													sx={{ width: "16rem" }}
												/>
											</Box>
											<Box sx={{ m: 2, display: "flex", alignItems: "center" }}>
												<Typography fontWeight={"bold"} sx={{ width: "14rem" }}>
													Date Of Birth :{" "}
												</Typography>
												<TextField
													fullWidth
													id="dob"
													inputProps={{ readonly: "true" }}
													size="small"
													variant="standard"
													value={dayjs(team.date_of_birth).format(
														"DD-MMM-YYYY"
													)}
													sx={{ width: "16rem" }}
												/>
											</Box>
										</Box>
										<Box
											sx={{
												display: { xs: "block", sm: "block", md: "flex" },
												justifyContent: "space-around",
											}}
										>
											<Box sx={{ m: 2, display: "flex", alignItems: "center" }}>
												<Typography fontWeight={"bold"} sx={{ width: "14rem" }}>
													Designation :
												</Typography>
												<TextField
													fullWidth
													id="designation"
													variant="standard"
													inputProps={{ readonly: "true" }}
													size="small"
													value={team.designation}
													sx={{ width: "16rem" }}
												/>
											</Box>
											<Box sx={{ m: 2, display: "flex", alignItems: "center" }}>
												<Typography fontWeight={"bold"} sx={{ width: "14rem" }}>
													Role :{" "}
												</Typography>
												<TextField
													fullWidth
													id="role"
													inputProps={{ readonly: "true" }}
													size="small"
													variant="standard"
													value={roleArray.join(",")}
													sx={{ width: "16rem" }}
												/>
											</Box>
										</Box>
										<Box
											sx={{
												display: { xs: "block", sm: "block", md: "flex" },
												justifyContent: "space-around",
											}}
										>
											<Box
												sx={{
													m: 2,
													display: "flex",
													alignItems: "center",
													mr: { xs: 2, sm: 2, md: 12 },
													ml: { xs: 2, sm: 2, md: 7 },
												}}
											>
												<Typography fontWeight={"bold"} sx={{ width: "14rem" }}>
													Date Of Joining :{" "}
												</Typography>
												<TextField
													fullWidth
													id="doj"
													inputProps={{ readonly: "true" }}
													size="small"
													variant="standard"
													value={dayjs(team.date_of_joining).format(
														"DD-MMM-YYYY"
													)}
													sx={{ width: "16rem" }}
												/>
											</Box>

											<Box
												sx={{
													display: { xs: "block", sm: "block", md: "flex" },
													justifyContent: "space-around",
												}}
											>
												<Box
													sx={{ m: 2, display: "flex", alignItems: "center" }}
												>
													<Typography
														fontWeight={"bold"}
														sx={{ width: "14rem" }}
													>
														Password :{" "}
													</Typography>
													<TextField
														id="password"
														type={"password"}
														size="small"
														variant="standard"
														defaultValue={read ? "password" : ""}
														inputProps={{ readOnly: read }}
														sx={{ width: "16rem" }}
													/>
													<Tooltip title="change password">
														<IconButton
															onClick={() => {
																handleChangePassword();
																handleOpen();
															}}
														>
															<EditIcon />
														</IconButton>
													</Tooltip>

													<Dialog
														open={open}
														onClose={handleClose}
														aria-labelledby="modal-modal-title"
														aria-describedby="modal-modal-description"
														disableEnforceFocus
													>
														<Box component="form" onSubmit={handleSubmit2}>
															<Box
																sx={{
																	display: "flex",
																	justifyContent: "flex-end",
																	alignItems: "flex-end",
																}}
															>
																<IconButton onClick={() => handleClose()}>
																	<CloseIcon />
																</IconButton>
															</Box>
															<DialogTitle
																sx={{
																	display: "flex",
																	justifyContent: "center",
																}}
															>
																<Typography fontWeight={"bold"} variant="h5">
																	Change Password
																</Typography>
															</DialogTitle>
															<DialogContent>
																<Box>
																	<Box
																		sx={{
																			display: "flex",
																			alignitems: "center",
																			m: 2,
																		}}
																	>
																		<Typography
																			fontWeight={"bold"}
																			sx={{ width: "14rem" }}
																		>
																			Old Password :
																		</Typography>
																		<TextField
																			id="old_password"
																			// type={"password"}
																			autoFocus
																			onFocus={handlePasswordFocus}
																			onBlur={handlePasswordBlur}
																			type={showPassword ? "text" : "password"}
																			name="old_password"
																			variant="standard"
																			size="small"
																			required
																			InputProps={{
																				inputProps: { tabIndex: 0 },
																				endAdornment: (
																					<InputAdornment position="end">
																						{passwordFocused && (
																							<IconButton
																								onClick={handleTogglePassword}
																								edge="end"
																								onMouseDown={(e) =>
																									e.preventDefault()
																								}
																							>
																								{showPassword ? (
																									<VisibilityOffIcon />
																								) : (
																									<VisibilityIcon />
																								)}
																							</IconButton>
																						)}
																					</InputAdornment>
																				),
																			}}
																			sx={{ width: "16rem" }}
																			onChange={handleChangeInPassword}
																		/>
																	</Box>
																	<Box
																		sx={{
																			display: "flex",
																			alignitems: "center",
																			m: 2,
																		}}
																	>
																		<Typography
																			fontWeight={"bold"}
																			sx={{ width: "14rem" }}
																		>
																			New Password :
																		</Typography>

																		<TextField
																			required
																			id="name"
																			type={showPassword1 ? "text" : "password"}
																			onFocus={handlePasswordFocus1}
																			onBlur={handlePasswordBlur1}
																			name="new_password"
																			variant="standard"
																			size="small"
																			sx={{ width: "16rem" }}
																			onChange={handleChangeInPassword}
																			helperText="Must be 8 characters and should have at least one special character"
																			InputProps={{
																				endAdornment: (
																					<InputAdornment position="end">
																						{passwordFocused1 && (
																							<IconButton
																								onClick={handleTogglePassword1}
																								edge="end"
																								onMouseDown={(e) =>
																									e.preventDefault()
																								}
																							>
																								{showPassword1 ? (
																									<VisibilityOffIcon />
																								) : (
																									<VisibilityIcon />
																								)}
																							</IconButton>
																						)}
																					</InputAdornment>
																				),
																			}}
																		/>
																	</Box>
																	<Box
																		sx={{
																			display: "flex",
																			alignitems: "center",
																			m: 2,
																		}}
																	>
																		<Typography
																			fontWeight={"bold"}
																			sx={{ width: "14rem" }}
																		>
																			Confirm Password :
																		</Typography>
																		<Tooltip title="Re-enter your password">
																			<TextField
																				required
																				id="name"
																				type={
																					showPassword2 ? "text" : "password"
																				}
																				name="confirm_password"
																				size="small"
																				onFocus={handlePasswordFocus2}
																				onBlur={handlePasswordBlur2}
																				variant="standard"
																				sx={{ width: "16rem" }}
																				onChange={handleChangeInPassword}
																				InputProps={{
																					endAdornment: (
																						<InputAdornment position="end">
																							{passwordFocused2 && (
																								<IconButton
																									onClick={
																										handleTogglePassword2
																									}
																									edge="end"
																									onMouseDown={(e) =>
																										e.preventDefault()
																									}
																								>
																									{showPassword2 ? (
																										<VisibilityOffIcon />
																									) : (
																										<VisibilityIcon />
																									)}
																								</IconButton>
																							)}
																						</InputAdornment>
																					),
																				}}
																			/>
																		</Tooltip>
																	</Box>
																</Box>
															</DialogContent>
															<DialogActions sx={{ m: 2, p: 1 }}>
																<Button onClick={handleClose}>Close</Button>
																<Button
																	type="submit"
																	variant="contained"
																	size="small"
																>
																	Confirm
																</Button>
															</DialogActions>
														</Box>
													</Dialog>
												</Box>
											</Box>
										</Box>
									</Box>
								</Box>
							</Box>

							<Box>
								<Box
									sx={{
										display: "flex",
										justifyContent: "center",
										m: 2,
										p: 2,
									}}
								>
									{team.teams.length === 0 ? (
										<Typography variant="h5">
											You're not working in any team !
										</Typography>
									) : (
										<Box>
											<Box sx={{ m: 1, p: 1 }}>
												<Typography
													fontWeight={"bold"}
													variant="h3"
													// fontFamily={"serif"}
												>
													Your Teams
												</Typography>
											</Box>
											{team.teams.map((data: any, index: number) => {
												return (
													<Box>
														<Box>
															<Box
																sx={{
																	display: "flex",
																	alignItems: "center",
																	m: 1,
																	alignContent: "center",
																	p: 1,
																}}
															>
																<Radio size="small" disabled />
																<Typography
																	variant="h6"
																	letterSpacing={2}
																	sx={{ m: 1 }}
																>
																	{data.project_name}
																</Typography>
															</Box>
														</Box>
													</Box>
												);
											})}
										</Box>
									)}
								</Box>
							</Box>
						</Box>
					);
				})}
			</Box>
		</>
	);
};

export default Profile;
