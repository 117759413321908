// const local = `http://apiEndpointhost:8000`;
// const server = `http://3.109.133.226:8000`;

import exp from "constants";

let apiEndpoint;

if (process.env.NODE_ENV === "development") {
	apiEndpoint = process.env.REACT_APP_API_ENDPOINT_LOCAL;
} else {
	apiEndpoint = process.env.REACT_APP_API_ENDPOINT_PROD;
}

export const Login: string = `${apiEndpoint}/employees/login/`;

export const ViewAllResponses: string = `${apiEndpoint}/responses/view-responses/`;

export const CreateFormUrl: string = `${apiEndpoint}/forms/create-ordinary-form/`;

export const CreateAppraisalForm: string = `${apiEndpoint}/forms/create-hierarchical-form/`;

export const createThreeSixtyForm: string = `${apiEndpoint}/forms/create-360-form/`;

export const viewUsers: string = `${apiEndpoint}/employees/view-users/`;

export const activeStatusChange: string = `${apiEndpoint}/employees/active-status/`;

export const registerUser: string = `${apiEndpoint}/employees/register/`;

export const ViewUser: string = `${apiEndpoint}/employees/view-users/?email=`;

export const ShowForms: string = `${apiEndpoint}/forms/admin-role/list-form-meta/`;

export const ShowParticularForms: string = `${apiEndpoint}/employees/assigned-forms/`;

export const showManagerAssigned360Form: string = `${apiEndpoint}/teams/manager-role/reporting-employees/360/responses/`;

export const showPeerAssigned360Form: string = `${apiEndpoint}/employees/360/assigned-forms/`;

export const showOrganizationLeaderAssigned360Form: string = `${apiEndpoint}/teams/admin-role/reporting-employees/360/responses/`;

export const showEmployeeAssigned360Form: string = `${apiEndpoint}/teams/employee-role/self/360/responses/`;

export const viewForm: string = `${apiEndpoint}/forms/list/ordinary-forms/?form_id=`;

export const viewHierarchicalForm: string = `${apiEndpoint}/forms/list/hierarchical-forms/?form_id=`;

export const view360Forms: string = `${apiEndpoint}/forms/list/360-forms/?form_id=`;

export const viewHierarchicalFormall: string = `${apiEndpoint}/view/hierarchical-Form/`;

export const editForm: string = `${apiEndpoint}/forms/ordinary/edit-form/?form_id=`;

export const editHierarchicalForm: string = `${apiEndpoint}/forms/hierarchical/edit-form/?form_id=`;

export const edit360Form: string = `${apiEndpoint}/forms/360/edit-form/?form_id=`;

export const viewResponse: string = `${apiEndpoint}/viewResponses/?form_id=`;

export const createTeam: string = `${apiEndpoint}/teams/create-team/`;

export const showTeams: string = `${apiEndpoint}/teams/show-teams/`;

export const DeleteEmp: string = `${apiEndpoint}/employees/delete-account/?email=`;

export const updateEmployee: string = `${apiEndpoint}/employees/update-account/?email=`;

export const subordinateDetails: string = `${apiEndpoint}/teams/manager-role/reporting-employees/`;

export const changePassword: string = `${apiEndpoint}/employees/update-account/change-password/`;

export const deleteSpecificEmployee: string = `${apiEndpoint}/teams/team/delete-employee/?team_id=`;

export const deleteTeam: string = ` ${apiEndpoint}/teams/delete-team/?team_id=`;

export const updateTeamNameOrManager: string = `${apiEndpoint}/teams/update-team/?team_id=`;

export const addTeamMember: string = `${apiEndpoint}/teams/team/add-employee/`;

export const viewNotifications: string = `${apiEndpoint}/responses/`;

export const viewNotificationNumber: string = `${apiEndpoint}/responses/`;

export const viewResponseManager: string = `${apiEndpoint}/teams/manager-role/reporting-employees/responses/`;

export const viewResponse360Manager: string = `${apiEndpoint}/teams/manager-role/reporting-employees/360/responses/`;

export const viewResponseManagerSpecific: string = `${apiEndpoint}/supervisor/employees/responses/?response_id=`;

export const HRDash: string = `${apiEndpoint}/responses/hr-role/responses-meta/`;

export const managerReview: string = `${apiEndpoint}/responses/manager-role/fill-save-review/?response_id=`;

export const peerReviewSave: string = `${apiEndpoint}/responses/peer-role/360/fill-save-review/?response_id=`;

export const organizationLeaderReviewSave: string = `${apiEndpoint}/responses/admin-role/360/fill-save-review/?response_id=`;

export const admin360AllReview: string = `${apiEndpoint}/responses/admin-role/360/responses-meta/`;

export const hr360AllReview: string = `${apiEndpoint}/responses/hr-role/360/responses-meta/`;

export const managerFinalSubmit: string = `${apiEndpoint}/responses/manager-role/submit-review/?response_id=`;

export const organizationLeaderFinalSubmit: string = `${apiEndpoint}/responses/admin-role/submit-review/?response_id=`;

export const peerFinalSubmit: string = `${apiEndpoint}/responses/peer-role/submit-review/?response_id=`;

export const Employee_and_manager: string = `${apiEndpoint}/employees/users/reporting-managers/`;

export const deleteForm: string = `${apiEndpoint}/forms/delete-form-master/?form_id=`;

export const fillFormany: string = `${apiEndpoint}/responses/employee-role/fill-form/`;

export const assignFormsToTeams: string = `${apiEndpoint}/forms/assign/forms-to-teams/`;

export const assign360Forms: string = `${apiEndpoint}/forms/assign/360/forms-to-teams/`;

export const addBulkEmployee: string = `${apiEndpoint}/employees/register/bulk-add/`;

export const rankEmployees: string = `${apiEndpoint}/teams/manager-role/employee-rankings/`;

export const rankEmployeesHR: string = `${apiEndpoint}/teams/employee-rankings/?team_id=`;

export const downloadAllReviews: string = `${apiEndpoint}/responses/hr-role/generate-report/`;

export const hrEditResponse: string = `${apiEndpoint}/responses/hr-role/edit-response/?response_id=`;

export const AdminDownload: string = `${apiEndpoint}/responses/admin-role/generate-report/`;

export const adminDash: string = `${apiEndpoint}/responses/admin-role/responses-meta/`;

export const adminPermission: string = `${apiEndpoint}/responses/admin-role/receive-edit-request/`;

export const hrRequestAccess: string = `${apiEndpoint}/responses/hr-role/edit-request/`;

export const allowEditAccess: string = `${apiEndpoint}/responses/admin-role/grant-edit-permission/?response_id=`;

export const denyEditAccess: string = `${apiEndpoint}/responses/admin-role/deny-edit-permission/?response_id=`;

export const allowDenyAccess: string = `${apiEndpoint}/responses/admin-role/deny-edit-permission/?response_id=`;

export const adminEditAccess: string = `${apiEndpoint}/responses/admin-role/edit-response/?response_id=`;

export const deleteQuestionAPI: string = `${apiEndpoint}/forms/delete-question/?question_id=`;

export const deleteSectionAPI: string = `${apiEndpoint}/forms/delete-section/?section_id=`;

export const deleteCategoryAPI: string = `${apiEndpoint}/forms/delete-category/?category_id=`;

export const viewEmployeeSelfResponse: string = `${apiEndpoint}/employees/responses/?form_team_id=`;

export const deleteOptionAPI: string = `${apiEndpoint}/forms/delete-option/?option_id=`;

export const tableManager: string = `${apiEndpoint}/teams/manager-role/employee-rankings/?team_id=`;

export const editEmployeeSelfResponse: string = `${apiEndpoint}/responses/employee-role/edit-response/?response_id=`;

export const getResponseId: string = `${apiEndpoint}/employees/saved-response/?form_team_id=`;

export const finalSubmit: string = `${apiEndpoint}/employees/form/final-submit/?response_id=`;

export const showPreviousFormsEmployee: string = `${apiEndpoint}/employees/outdated-forms/`;

export const showPrevious360FormsEmployee: string = `${apiEndpoint}/employees/360/outdated-forms/`;

export const chnageGoogleToken: string = `${apiEndpoint}/employees/auth/convert-google/`;

export const passwordChange: string = `${apiEndpoint}/employees/forgot-password/`;

export const forgotPasswordEmail: string = `${apiEndpoint}/employees/forgot-password-email/`;

export const adminRatingChange: string = `${apiEndpoint}/responses/admin-hr-role/admin-rating-change/`;

export const logout: string = `${apiEndpoint}/employees/logout/`;

//Skill Set

export const skillQuestionsDetail = `${apiEndpoint}/skillset/questions/detail/`;

export const addSkills = `${apiEndpoint}/skillset/add/`;

export const updateSkillsData = `${apiEndpoint}/skillset/questions/update-skill-questions/`;

export const delteSkillQuestion = `${apiEndpoint}/skillset/questions/delete/`;

export const viewEmployeeSkillResponse = `${apiEndpoint}/skillset/responses/detail/`;

export const editEmployeeSkillResponse = `${apiEndpoint}/skillset/responses/add-or-update/`;

export const allEmployeeSkiillResponses = `${apiEndpoint}/skillset/responses/view-all-responses/`;

export const viewSkillWiseEmployeeResponse = `${apiEndpoint}/skillset/responses/skill-user-reponses/`;

export const viewSkillHistory = `${apiEndpoint}/skillset/responses/history/`;

// Feedback Form

export const listFeedbackForm = `${apiEndpoint}/forms/list/ordinary-forms/?is_hierarchical=False&is_appraisal=False`;

export const createFeedbackForm = `${apiEndpoint}/forms/create-ordinary-form/`;

export const updateFeedBackForm = `${apiEndpoint}/forms/ordinary/edit-form/?form_id=`;

export const employeeAssignedFeedbackForm = `${apiEndpoint}/forms/list/forms-to-teams/employee-feedback/assigned-forms/`;

export const managerAssignedFeedbackForm = `${apiEndpoint}/forms/list/forms-to-teams/manager-feedback/assigned-forms/`;

export const retrieveEmployeeFeedbackResponse = `${apiEndpoint}/employees/feedback/responses/?form_team_id=`;

export const employeeOutdatedFeedbackForms = `${apiEndpoint}/forms/list/forms-to-teams/employee-feedback/outdated-forms/`;

export const managerOutdatedFeedbackForms = `${apiEndpoint}/forms/list/forms-to-teams/manager-feedback/outdated-forms/`;

export const updateEmployeeFeedback = `${apiEndpoint}/responses/employee-role/edit-employee_feedback/?response_id=`;

// Attendance

export const addAttendanceRecord = `${apiEndpoint}/attendance/add/`;

export const listAttendanceRecords = `${apiEndpoint}/attendance/list/`;

export const addBulkAttendanceExcel = `${apiEndpoint}/attendance/add/bulk-add/`;

// Data Point

export const createDataPoint = `${apiEndpoint}/datapoint/create/`;

export const assinedDataPoints = `${apiEndpoint}/datapoint/view-assigned-datapoint/`;

export const fillDataPoint = `${apiEndpoint}/datapoint/fill-response-datapoint/`;

export const viewEmployeeResponsesDataPoint = `${apiEndpoint}/datapoint/view-employee-responses-datapoint/`;

export const viewAllResponsesDataPoint = `${apiEndpoint}/datapoint/view-all-responses-datapoint/`;

export const getUtilizationData = `${apiEndpoint}/jira/utilization/?months=`;

export const getNonComplianceData = `${apiEndpoint}/jira/non-compliance/?months=`;

export const getBugsReportData = `${apiEndpoint}/jira/sync-bugs/?months=`;

export const getBugsAssigneeData = `${apiEndpoint}/jira/bugs-assignee/?months=`;

export const getBreachDueDatesData = `${apiEndpoint}/jira/breached-due-dates/?months=`;

export const getVelocityData = `${apiEndpoint}/jira/velocity/?months=`;

export const getProjectEffortData = `${apiEndpoint}/jira/project-effort/?months=`;

export const getProjectBugsData = `${apiEndpoint}/jira/project-bugs/?months=`;

export const getProjectBreachData = `${apiEndpoint}/jira/project-breach/?months=`;

export const getAllSkills = `${apiEndpoint}/skillset/detail`;

export const getEmployeeSkill = `${apiEndpoint}/skillset/employee-skill-utilization/`;

export const skillstoemployee = `${apiEndpoint}/skillset/add-documents-to-employees/`;

export const getEmployeeSkillDocument = `${apiEndpoint}/skillset/documents-by-skillId/`;

export const skillDocumentUpload = `${apiEndpoint}/skillset/add-documents/`;

export const documentsByEmployeeId = `${apiEndpoint}/skillset/documents-by-employeeId/?employee_id=`;

export const updatedocument = `${apiEndpoint}/skillset/update-employee-skill-document/`;

export const downloadDocument = `${apiEndpoint}/skillset/download-document/?document_id=`;

export const deleteEmployeeDocuments = `${apiEndpoint}/skillset/delete-employee-document/`;

export const allEmployeeDocuments = `${apiEndpoint}/skillset/all-employee-documents/`;

export const assignDesignatedDocument = `${apiEndpoint}/forms/hierarchical/assign-to-designations/`;

export const getDesignationAssignedDocument = `${apiEndpoint}/teams/designations/?team_id=`;

export const getAllDesignations = `${apiEndpoint}/employees/designations/`;

export const getPreviousAppraisalData = `${apiEndpoint}/responses/historical-appraisals/`;

export const getAllEmployeesAndTeams = `${apiEndpoint}/responses/team-employees/`;
