import { Badge, Box, Button, Paper, Tab, Tabs } from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import CustomNoRowsOverlay from "../../../components/Data/NoRowsOverlayComponent";
import { setFormTeamId, setId } from "../../../store/FormSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import {
  useCustomToast,
  ToastVariant,
} from "../../../components/Methods/SnackBar";
import { RootState } from "../../../store/Store";
import {
  FetchDataSuccessAction,
  FetchDataFailureAction,
  fetchData,
} from "../../../store/actions";
import {
  adminDash,
  employeeAssignedFeedbackForm,
  managerAssignedFeedbackForm,
} from "../../../Service/Apis";
import {
  a11yProps,
  CustomTabPanel,
} from "../../../components/TabPanel/TabPanel";

interface ReviewFeedBackFormsTypes {
  form_id: string;
  response_id: string;
  form_team_id: string;
  is_employee_feedback: boolean;
  is_manager_feedback: boolean;
  project_name: string;
  manager_name: string;
  is_360: boolean;
  team_id: string;
}
const FeedBackFormHRDashboard = () => {
  const [value, setValue] = React.useState(0);

  const [employeeFeedbackReview, setEmployeeFeedbackReview] = useState<
    ReviewFeedBackFormsTypes[]
  >([]);
  const [managerFeedbackReview, setManagerFeedbackReview] = useState<
    ReviewFeedBackFormsTypes[]
  >([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();

  const showToast = useCustomToast();
  const handleShowToast = (
    message: string,
    variant: ToastVariant,
    isCloseable: boolean
  ) => {
    showToast(message, variant, isCloseable);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const employeeFeedbackRows: any[] = employeeFeedbackReview.map(
    (form, index) => ({
      ...form,
      index: index,
      sr_no: index + 1,
    })
  );

  const managerFeedbackRows: any[] = managerFeedbackReview.map(
    (form, index) => ({
      ...form,
      index: index,
      sr_no: index + 1,
    })
  );

  const columns: GridColDef[] = [
    {
      field: "sr_no",
      headerName: "Sr. No",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Sr No.
        </strong>
      ),
      width: 95,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "form_name",
      headerName: "Form Name",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Form Name
        </strong>
      ),
      width: 210,
      align: "left",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "employee_name",
      headerName: "Employee Name",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Employee Name
        </strong>
      ),
      width: 155,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "manager_name",
      headerName: "Manager Name",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Manager Name
        </strong>
      ),
      width: 155,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },

    {
      field: "action",
      headerName: "Actions",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Actions
        </strong>
      ),
      width: 155,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
      renderCell: (params: any) => (
        <Button
          size="small"
          variant="contained"
          onClick={() => {
            localStorage.setItem("responseId", params.row.response_id);
            dispatch(setId(params.row.response_id));
            navigate("/showfeedbackresponse");
          }}
        >
          Go To Review
        </Button>
      ),
    },
  ];

  useEffect(() => {
    const fetchEmployeeFeedBackForms = async () => {
      const response = await dispatch2(
        fetchData(`${adminDash}?feedback_form=True&is_employee_feedback=True`)
      );
      setEmployeeFeedbackReview(response as ReviewFeedBackFormsTypes[]);
    };
    fetchEmployeeFeedBackForms();

    const fetchManagerFeedBackForms = async () => {
      const response = await dispatch2(
        fetchData(`${adminDash}?feedback_form=True&is_manager_feedback=True`)
      );
      setManagerFeedbackReview(response as ReviewFeedBackFormsTypes[]);
    };
    fetchManagerFeedBackForms();
  }, []);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          height: "100%", // Adjust the height as needed
        }}
      >
        <Box
          sx={{
            minWidth: 150, // Set the width for the left sidebar
            bgcolor: "background.paper",
          }}
        >
          <Tabs
            centered
            value={value}
            onChange={handleChange}
            orientation="vertical"
            textColor="inherit"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
          >
            <Tab {...a11yProps(0)} label={"Employee Feedback Forms"}></Tab>
            <Tab {...a11yProps(1)} label={"Manager Feedback Forms"} />
          </Tabs>
        </Box>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CustomTabPanel index={0} value={value}>
            <Paper
              sx={{
                width: "fit-content",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <DataGrid
                autoHeight
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                disableColumnMenu
                sx={{
                  width: "100%",
                  height: "100%",
                  "--DataGrid-overlayHeight": "50vh",
                }}
                pagination
                pageSizeOptions={[5, 10, 15]}
                rows={employeeFeedbackRows}
                columns={columns}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                slots={{
                  toolbar: GridToolbar,
                  noRowsOverlay: CustomNoRowsOverlay,
                  noResultsOverlay: CustomNoRowsOverlay,
                }}
                slotProps={{
                  toolbar: {
                    csvOptions: { disableToolbarButton: true },
                    showQuickFilter: true,
                    printOptions: { disableToolbarButton: true },
                    style: { margin: "0.5rem" },
                  },
                }}
                getRowId={(row) => row.index}
              />
            </Paper>
          </CustomTabPanel>
          <CustomTabPanel index={1} value={value}>
            <Paper
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <DataGrid
                autoHeight
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                disableColumnMenu
                sx={{
                  width: "100%",
                  height: "100%",
                  "--DataGrid-overlayHeight": "50vh",
                }}
                pagination
                pageSizeOptions={[5, 10, 15]}
                rows={managerFeedbackRows}
                columns={columns}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                slots={{
                  toolbar: GridToolbar,
                  noRowsOverlay: CustomNoRowsOverlay,
                  noResultsOverlay: CustomNoRowsOverlay,
                }}
                slotProps={{
                  toolbar: {
                    csvOptions: { disableToolbarButton: true },
                    showQuickFilter: true,
                    printOptions: { disableToolbarButton: true },
                    style: { margin: "0.5rem" },
                  },
                }}
                getRowId={(row) => row.index}
              />
            </Paper>
          </CustomTabPanel>
        </Box>
      </Box>
    </>
  );
};

export default FeedBackFormHRDashboard;
