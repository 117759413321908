import React, { useEffect, useMemo, useState } from "react";
import {
	Box,
	Button,
	Card,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Modal,
	Paper,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	Switch,
	FormControlLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import useAxiosInterceptor from "../../components/layout/AxiosInstance";
import { enqueueSnackbar } from "notistack";
import {
	ShowForms,
	assignFormsToTeams,
	deleteForm,
	showTeams,
} from "../../Service/Apis";
import { setId } from "../../store/FormSlice";
import Navbar from "../../components/Page Component/Navbar";
import Footer from "../../components/Page Component/Footer";
import NothingToDisplayImage from "../../asset/NothingToDisplayImage.png";
import { setIsLoading } from "../../store/LoadingSlice";
import NothingToDisplayComponent from "../../components/Data/NothingToShowComponent";
import {
	ToastVariant,
	useCustomToast,
} from "../../components/Methods/SnackBar";
import {
	FetchDataFailureAction,
	FetchDataSuccessAction,
	deleteData,
	fetchData,
	postData,
	putData,
} from "../../store/actions";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../../store/Store";
import { postDataSuccess } from "../../store/AxiosSlice";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import CustomNoRowsOverlay from "../../components/Data/NoRowsOverlayComponent";
import DesignationAssignment from "./DesignationAssignment";

interface Form {
	form_id: string;
	form_name: string;
	created_by: string;
	manager_deadline: string;
	employee_deadline: string;
}

interface Team {
	team_id: string;
	manager: string;
	manager_name: string;
	project_name: string;
	team: any[]; // Adjust type as needed
}

const style = {
	position: "absolute" as "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	bgcolor: "background.paper",
	overflow: "auto",
	height: "80%",
	p: 4,
};

const CreatedFroms = () => {
	const [form, setForm] = useState<Form[]>([]);
	const [count, setCount] = useState(0);
	const [formId, setFormId] = useState<string>("");
	const [send, setSend] = useState<boolean[]>([]);
	const [openMenuIndex, setOpenMenuIndex] = useState<number | null>(null);
	const [openDeleteIndex, setOpenDeleteIndex] = useState<number | null>(null);
	const [assignToDesignation, setAssignToDesignation] = useState(false);
	const [getTeamsData, setGetTeamsData] = useState<Team[]>([
		{
			team_id: "",
			manager: "",
			manager_name: "",
			project_name: "",
			team: [],
		},
	]);
	const [sendTeams, setSendTeams] = useState<any[]>([
		// {
		//   team_id: "",
		// },
	]);
	const dispatch = useDispatch();
	const dispatch2: ThunkDispatch<
		RootState,
		null,
		FetchDataSuccessAction | FetchDataFailureAction
	> = useDispatch();
	const showToast = useCustomToast();
	const Navigate = useNavigate();
	const handleShowToast = (
		message: string,
		variant: ToastVariant,
		isCloseable: boolean
	) => {
		showToast(message, variant, isCloseable);
	};
	const handleOpenMenu = (index: number) => {
		setOpenMenuIndex(index);
	};
	const handleCloseMenu = () => {
		setOpenMenuIndex(null);
		setSendTeams([]);
		setSend([]);
	};
	const handleOpenDelete = (index: number) => {
		setOpenDeleteIndex(index);
	};
	const handleCloseDelete = () => {
		setOpenDeleteIndex(null);
	};
	const newtoken = useSelector((state: any) => state.auth.token);
	const isloading = useSelector((state: any) => state.loading.isLoading);
	const axiosInstance = useAxiosInterceptor();
	const handleFormDelete = (e: any, form_id: string) => {
		e.preventDefault();
		const token = localStorage.getItem("token");

		try {
			const response = dispatch2(deleteData(`${deleteForm}${form_id}`))
				.then((response) => {
					handleShowToast("Form Deleted Successfully !", "success", true);
					setCount(count + 1);
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
		handleCloseDelete();
	};
	const handleTeamSend = (e: any, team_id: string, index: number) => {
		e.preventDefault();
		setSend((prevSend) => {
			const updatedSend = [...prevSend];
			updatedSend[index] = !updatedSend[index];
			return updatedSend;
		});

		// Update the list of selected teams based on the updated send array
		setSendTeams((prevTeams) => {
			if (send[index]) {
				// If the team is selected, remove it from the list
				return prevTeams.filter((team) => team.team_id !== team_id);
			} else {
				// If the team is not selected, add it to the list
				return [...prevTeams, { team_id: team_id }];
			}
		});
	};
	const handleFormSend = (e: any, index: number) => {
		e.preventDefault();

		if (sendTeams.length === 0 || sendTeams === null) {
			handleShowToast(` Please Atleast select one team `, "error", true);
			return;
		}
		var data = {
			form_id: formId,
			team_ids: sendTeams.map((data) => data.team_id),
		};
		if (sendTeams.length > 0 || data.team_ids !== null) {
			try {
				const response = dispatch2(postData(assignFormsToTeams, data))
					.then((response) => {
						handleShowToast("Form Sent Successfully !", "success", true);
						dispatch(postDataSuccess(null));
					})
					.catch((error) => {
						console.log(error);
					});
			} catch (error) {
				console.log(error);
			}
			handleCloseMenu();
			setSend([]);
			setSendTeams([]);
		} else {
		}
	};
	const teamTableRows = getTeamsData.map((team, index) => {
		return { ...team, index: index, sr_no: index + 1 };
	});
	const teamColumns: GridColDef[] = useMemo(
		() => [
			{
				headerName: "Sr No.",
				field: "sr_no",
				align: "center",
				headerAlign: "center",
				headerClassName: "header-background",
				width: 105,
				renderHeader: () => (
					<strong className="custom-header" style={{ fontSize: 15 }}>
						Sr No.
					</strong>
				),
			},
			{
				headerName: "Team Name",
				field: "project_name",
				align: "center",
				headerAlign: "center",
				headerClassName: "header-background",
				width: 165,
				renderHeader: () => (
					<strong className="custom-header" style={{ fontSize: 15 }}>
						Team Name
					</strong>
				),
			},
			{
				headerName: "Managed By",
				field: "manager_name",
				align: "center",
				headerAlign: "center",
				headerClassName: "header-background",
				width: 165,
				renderHeader: () => (
					<strong className="custom-header" style={{ fontSize: 15 }}>
						Managed By
					</strong>
				),
			},
			{
				headerName: "Options",
				field: "options",
				align: "center",
				headerAlign: "center",
				headerClassName: "header-background",
				width: 135,
				renderHeader: () => (
					<strong className="custom-header" style={{ fontSize: 15 }}>
						Options
					</strong>
				),
				renderCell: (params) => (
					<>
						<Box>
							<Button
								id={`team_send_${params.row.index}`}
								size="small"
								onClick={(e) => {
									handleTeamSend(
										e,
										getTeamsData[params.row.index].team_id,
										params.row.index
									);
								}}
							>
								{send[params.row.index] ? "Undo" : "Select"}
							</Button>
						</Box>
					</>
				),
			},
		],
		[getTeamsData, teamTableRows]
	);
	const rows = form.map((form, index) => {
		return { ...form, index: index, sr_no: index + 1 };
	});
	const columns: GridColDef[] = useMemo(
		() => [
			{
				headerName: "Sr No.",
				field: "sr_no",
				align: "center",
				headerAlign: "center",
				headerClassName: "header-background",
				width: 125,
				renderHeader: () => (
					<strong className="custom-header" style={{ fontSize: 15 }}>
						Sr No.
					</strong>
				),
			},
			{
				headerName: "Form Name",
				field: "form_name",
				align: "left",
				headerAlign: "center",
				headerClassName: "header-background",
				width: 195,
				renderHeader: () => (
					<strong className="custom-header" style={{ fontSize: 15 }}>
						Form Name
					</strong>
				),
				renderCell: (params) => (
					<>
						<Link
							id={`${params.row.form_name}_${params.row.index}`}
							to="/viewform"
							onClick={() => {
								dispatch(setId(params.row.form_id));
								localStorage.setItem("formid", params.row.form_id);
							}}
						>
							{params.row.form_name}
						</Link>
					</>
				),
			},
			{
				headerName: "Manager Deadline",
				field: "manager_deadline",
				align: "center",
				headerAlign: "center",
				headerClassName: "header-background",
				width: 165,
				renderHeader: () => (
					<strong className="custom-header" style={{ fontSize: 15 }}>
						Manager Deadline
					</strong>
				),
			},
			{
				headerName: "Employee Deadline",
				field: "employee_deadline",
				align: "center",
				headerAlign: "center",
				headerClassName: "header-background",
				width: 195,
				renderHeader: () => (
					<strong className="custom-header" style={{ fontSize: 15 }}>
						Employee Deadline
					</strong>
				),
			},
			{
				headerName: "Options",
				field: "options",
				align: "center",
				headerAlign: "center",
				headerClassName: "header-background",
				width: 175,
				renderHeader: () => (
					<strong className="custom-header" style={{ fontSize: 15 }}>
						Options
					</strong>
				),
				renderCell: (params) => (
					<>
						<Button
							id={`upload_form_${params.row.index}`}
							variant="contained"
							size="small"
							sx={{ m: 1 }}
							onClick={(e) => {
								e.preventDefault();
								handleOpenMenu(params.row.index);
								setFormId(params.row.form_id);
							}}
						>
							Upload
						</Button>
						<Modal
							open={openMenuIndex === params.row.index}
							onClose={() => {
								handleCloseMenu();
							}}
						>
							<>
								<Card elevation={12} sx={style}>
									<Box
										sx={{
											display: "flex",
											justifyContent: "space-between",
											alignItems: "center",
											mb: 2,
										}}
									>
										<Typography variant="h5" sx={{ fontWeight: "bold" }}>
											Assign Form
										</Typography>
										<IconButton onClick={() => handleCloseMenu()}>
											<CloseIcon />
										</IconButton>
									</Box>

									<Box sx={{ mb: 3 }}>
										<FormControlLabel
											control={
												<Switch
													checked={assignToDesignation}
													onChange={(e) =>
														setAssignToDesignation(e.target.checked)
													}
													name="assignToDesignation"
													color="primary"
												/>
											}
											label={
												<Typography variant="subtitle1">
													{assignToDesignation
														? "Assign to Designations"
														: "Assign to Teams"}
												</Typography>
											}
										/>
									</Box>

									{assignToDesignation ? (
										<Box sx={{ height: "calc(100% - 120px)" }}>
											<DesignationAssignment
												open={openMenuIndex !== null}
												onClose={handleCloseMenu}
												formId={formId}
												teams={getTeamsData}
												onSwitchBack={() => setAssignToDesignation(false)}
											/>
										</Box>
									) : (
										<Box
											sx={{
												display: "flex",
												justifyContent: "center",
												m: 1,
												p: 1,
											}}
										>
											<Paper sx={{ m: 2 }} style={{ width: "fit-content" }}>
												<DataGrid
													autoHeight
													getRowId={(row) => row.team_id}
													disableColumnMenu
													sx={{
														width: "100%",
														height: "100%",
														"--DataGrid-overlayHeight": "50vh",
													}}
													initialState={{
														pagination: {
															paginationModel: {
																pageSize: 10,
															},
														},
													}}
													pagination={true}
													pageSizeOptions={[5, 10, 15]}
													rows={teamTableRows}
													columns={teamColumns}
													disableColumnSelector
													disableDensitySelector
													slots={{
														toolbar: GridToolbar,
														noResultsOverlay: CustomNoRowsOverlay,
														noRowsOverlay: CustomNoRowsOverlay,
													}}
													slotProps={{
														toolbar: {
															csvOptions: {
																disableToolbarButton: true,
															},
															showQuickFilter: true,
															printOptions: {
																disableToolbarButton: true,
															},
															style: { margin: "0.5rem" },
														},
													}}
												/>
											</Paper>
										</Box>
									)}
									<Box
										sx={{
											display: "flex",
											justifyContent: "center",
											m: 2,
											p: 1,
										}}
									>
										<Button
											id={`form_upload_to_teams`}
											variant="contained"
											size="small"
											onClick={(e) => {
												handleFormSend(e, params.row.index);
											}}
										>
											Upload Form
										</Button>
									</Box>
								</Card>
							</>
						</Modal>
						<Button
							id={`form_delete_${params.row.index}`}
							variant="contained"
							size="small"
							sx={{
								m: 1,
							}}
							onClick={() => {
								handleOpenDelete(params.row.index);
							}}
						>
							Delete
						</Button>

						<Dialog
							open={openDeleteIndex === params.row.index}
							onClose={handleCloseDelete}
							sx={{
								m: 1,
								"& .MuiDialog-paper": {
									width: "30rem",
								},
							}}
						>
							<Box
								sx={{
									display: "flex",
									justifyContent: "flex-end",
									alignItems: "flex-end",
								}}
							>
								<IconButton onClick={() => handleCloseDelete()}>
									<CloseIcon />
								</IconButton>
							</Box>
							<DialogTitle>Confirmation</DialogTitle>
							<DialogContent>
								<Typography>Do you want to delete?</Typography>
							</DialogContent>
							<DialogActions>
								<Button
									id={`deny_form_delete_${params.row.index}`}
									onClick={handleCloseDelete}
								>
									No
								</Button>
								<Button
									id={`allow_form_delete_${params.row.index}`}
									variant="contained"
									onClick={(e) => {
										handleFormDelete(e, params.row.form_id);
									}}
								>
									Yes
								</Button>
							</DialogActions>
						</Dialog>
					</>
				),
			},
		],
		[form, rows, assignToDesignation]
	);

	//shows teams when click on upload button
	useEffect(() => {
		dispatch(setIsLoading(true));
		const fetchTeamData = async () => {
			try {
				const response = await dispatch2(
					fetchData(`${showTeams}?is_360=False`)
				);
				setGetTeamsData(response as any);

				dispatch(setIsLoading(false));
			} catch (error) {
				console.log(error);
				dispatch(setIsLoading(false));
			}
		};
		fetchTeamData();
	}, []);

	//shows forms
	useEffect(() => {
		dispatch(setIsLoading(true));

		const fetchForms = async () => {
			try {
				const response = await dispatch2(
					fetchData(`${ShowForms}?is_hierarchical=True`)
				);
				setForm(response as Form[]);
				dispatch(setIsLoading(false));
			} catch (error) {
				console.log(error);
				dispatch(setIsLoading(false));
			}
		};
		fetchForms();
	}, [count]);

	return (
		<>
			<Box>
				<Box>
					<Box sx={{ display: "flex", justifyContent: "center", m: 2, p: 2 }}>
						<Typography id="form_details" variant="h4">
							Form Details
						</Typography>
					</Box>
					<Box sx={{ display: "flex", justifyContent: "center", m: 1, p: 1 }}>
						<Paper sx={{ m: 2 }} style={{ width: "fit-content" }}>
							<DataGrid
								autoHeight
								getRowId={(row) => row.form_id}
								disableColumnMenu
								sx={{
									width: "100%",
									height: "100%",
									"--DataGrid-overlayHeight": "50vh",
								}}
								initialState={{
									pagination: {
										paginationModel: {
											pageSize: 10,
										},
									},
								}}
								pagination
								pageSizeOptions={[5, 10, 15]}
								rows={rows}
								columns={columns}
								disableColumnSelector
								disableDensitySelector
								slots={{
									toolbar: GridToolbar,
									noResultsOverlay: CustomNoRowsOverlay,
									noRowsOverlay: CustomNoRowsOverlay,
								}}
								slotProps={{
									toolbar: {
										csvOptions: { disableToolbarButton: true },
										showQuickFilter: true,
										printOptions: { disableToolbarButton: true },
										style: { margin: "0.5rem" },
									},
								}}
							/>
						</Paper>
					</Box>
				</Box>
			</Box>
		</>
	);
};

export default CreatedFroms;
