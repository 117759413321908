import { Badge, Box, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { CustomTabPanel, a11yProps } from "../../components/TabPanel/TabPanel";
import {
  useCustomToast,
  ToastVariant,
} from "../../components/Methods/SnackBar";
import AddDataPoints from "./AddDataPoints";
import ViewAllDataPoints from "./ViewAllDataPoints";
import FilllAndViewDataPoints from "./FilllAndViewDataPoints";
import ReviewDataPoints from "./ReviewDataPoints";

const HRDataPoints = () => {
  const [value, setValue] = React.useState(0);
  const [role, setRole] = useState<string | null>(
    localStorage.getItem("role") || null
  );

  const showToast = useCustomToast();
  const handleShowToast = (
    message: string,
    variant: ToastVariant,
    isCloseable: boolean
  ) => {
    showToast(message, variant, isCloseable);
  };
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = event.currentTarget.innerHTML;
    const tabLabel = tempElement.innerText;

    // Clean up the temporary element
    tempElement.remove();
    setValue(newValue);
    handleShowToast(`Switched to ${tabLabel}`, "info", true);
  };

  const switchToFillDataPointTab = () => {
    setValue(0); // Switch to Fill Data Point tab
  };

  return (
    <>
      <Box>
        <Tabs
          centered
          value={value}
          onChange={handleChange}
          textColor="inherit"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          <Tab
            {...a11yProps(0)}
            label={
              <Badge sx={{ p: 0.6 }} color="primary">
                Fill Data Point
              </Badge>
            }
          ></Tab>
          <Tab {...a11yProps(1)} label="All Data Points" />
        </Tabs>
        <CustomTabPanel value={value} index={0}>
          <FilllAndViewDataPoints />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          {role && role.includes("HR") ? (
            <ViewAllDataPoints
              switchToFillDataPointTab={switchToFillDataPointTab}
            />
          ) : (
            <Box
              sx={{ width: "98vw", display: "flex", justifyContent: "center" }}
            >
              <ReviewDataPoints />
            </Box>
          )}
        </CustomTabPanel>
      </Box>
    </>
  );
};

export default HRDataPoints;
