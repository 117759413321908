import React, { useEffect, useState } from "react";

import {
  Autocomplete,
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  LinearProgress,
  Radio,
  RadioGroup,
  Rating,
  TextField,
  Typography,
} from "@mui/material";

import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";

import { styled } from "@mui/material/styles";

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";

import { setInPageLoading, setIsLoading } from "../../store/LoadingSlice";
import { RootState } from "../../store/Store";
import {
  FetchDataSuccessAction,
  FetchDataFailureAction,
  fetchData,
} from "../../store/actions";

import {
  skillQuestionsDetail,
  viewEmployeeSkillResponse,
  viewSkillWiseEmployeeResponse,
  viewUsers,
} from "../../Service/Apis";

import {
  EmployeeSkillSetType,
  SkillWiseEmployeeResponse,
} from "./Types/SkillTypes";
import NothingToDisplayComponent from "../../components/Data/NothingToShowComponent";
import Loading from "../../components/Loading/Loading";

interface Employeetype {
  id: string;
  employee_code: string;
  first_name: string;
  last_name: string;
  gender: string;
  roles: [];
  designation: string;
  date_of_birth: string;
  date_of_joining: string;
  date_of_confirmation: string;
  date_of_exit: string;
  email: string;
  password: string;
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .00)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const ratingLabel = [
  { label: "Awareness", color: "#FF5C5C" }, // Red
  { label: "Novice", color: "#5C8CFF" }, // Blue
  { label: "Competent", color: "#5CFF6E" }, // Green
  { label: "Professional", color: "#FF9F5C" }, // Orange/Pink
  { label: "Expert", color: "#B85CFF" }, // Violet
];

const ReviewEmployeesSkills = () => {
  // State Hooks
  const [employeesData, setEmployeesData] = useState<Employeetype[]>([]);
  const [skillsData, setSkillsData] = useState<EmployeeSkillSetType[]>([]);

  const [employee, setEmployee] = useState<Employeetype | null>(null);
  const [skill, setSkill] = useState<EmployeeSkillSetType | null>(null);

  const [data, setData] = useState<EmployeeSkillSetType[]>([]);
  const [skillEmployeeResponseData, setSkillEmployeeResponseData] = useState<
    SkillWiseEmployeeResponse[]
  >([]);

  const [selectedSkill, setSelectedSkill] =
    useState<EmployeeSkillSetType | null>(null);
  const [openDialog, setOpenDialog] = useState(false);

  const [isSkillWiseReview, setIsSkillWiseReview] = useState<boolean>(false);
  const [isEmployeeWiseReview, setIsEmployeeWiseReviewq] =
    useState<boolean>(true);

  // Redux Hooks
  const dispatch = useDispatch();
  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();

  const isLoading = useSelector((state: any) => state.loading.inPageLoading);

  const handleRadioChange = (event: any) => {
    const { name, checked } = event.target;
    setIsSkillWiseReview(name === "skillwisereview" ? checked : !checked);
    setIsEmployeeWiseReviewq(
      name === "employeewisereview" ? checked : !checked
    );
    setEmployee(null);
    setSkill(null);
  };

  //Handle Employee Change Function
  const handleEmployeeChange = (e: any, values: any) => {
    setEmployee(values);
  };

  const handleSkillChange = (e: any, values: any) => {
    setSkill(values);
  };

  // Calculate Progress Function
  const calculateProgress = (questions: any) => {
    const attemptedCount = questions.filter(
      (que: any) => que.employee_skill_response !== null
    ).length;
    return (attemptedCount / questions.length) * 100;
  };

  // Open Dialog Function
  const handleDialogOpen = (skill: EmployeeSkillSetType) => {
    setSelectedSkill(skill);
    setOpenDialog(true);
  };

  // Close Dialog Function
  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    // API Call get the User Details.
    const fetchUserDetails = async () => {
      try {
        dispatch(setIsLoading(true));
        const response = await dispatch2(fetchData(`${viewUsers}`));
        setEmployeesData(response as Employeetype[]);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setIsLoading(false));
      }
    };

    fetchUserDetails();

    const fetchSkillsDetails = async () => {
      try {
        dispatch(setInPageLoading(true));
        const response = await dispatch2(fetchData(`${skillQuestionsDetail}`));
        setSkillsData(response as EmployeeSkillSetType[]);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setInPageLoading(false));
      }
    };

    fetchSkillsDetails();
  }, []);

  useEffect(() => {
    // API call to get the employee skill responses.
    const fetchEmployeeSkillResponse = async () => {
      try {
        dispatch(setInPageLoading(true));
        const response = await dispatch2(
          fetchData(`${viewEmployeeSkillResponse}?user_id=${employee?.id}`)
        );
        setData(response as EmployeeSkillSetType[]);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setInPageLoading(false));
      }
    };

    // Check whether the employee is selected before making the API call.
    if (employee) {
      fetchEmployeeSkillResponse();
    }
  }, [employee]);

  useEffect(() => {
    const fetchSkillEmployeeResponse = async () => {
      try {
        dispatch(setInPageLoading(true));
        const response = await dispatch2(
          fetchData(
            `${viewSkillWiseEmployeeResponse}?skill_id=${skill?.skill_id}`
          )
        );
        setSkillEmployeeResponseData(response as SkillWiseEmployeeResponse[]);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setInPageLoading(false));
      }
    };
    if (skill) {
      fetchSkillEmployeeResponse();
    }
  }, [skill]);

  // Loading Spinner Component
  if (isLoading) {
    return (
      <>
        <Loading isLoading={isLoading as boolean} />
      </>
    );
  }

  // Main Component

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box sx={{ width: "75%" }}>
          <Box sx={{ m: 1, p: 1, display: "flex", justifyContent: "right" }}>
            <RadioGroup>
              <Box sx={{ display: "flex" }}>
                <FormControlLabel
                  control={
                    <Radio
                      checked={isEmployeeWiseReview}
                      onChange={handleRadioChange}
                      name="employeewisereview"
                    />
                  }
                  label="Employee Review"
                />
                <FormControlLabel
                  control={
                    <Radio
                      checked={isSkillWiseReview}
                      onChange={handleRadioChange}
                      name="skillwisereview"
                    />
                  }
                  label="Skills Review"
                />
              </Box>
            </RadioGroup>
          </Box>
          {isEmployeeWiseReview ? (
            <Box>
              <Autocomplete
                size="small"
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) =>
                  option.first_name + " " + option.last_name
                }
                value={employee}
                onChange={handleEmployeeChange}
                id="employee-autocomplete"
                options={employeesData}
                sx={{ width: "65vw" }}
                renderInput={(params) => <TextField {...params} />}
              />
              {employee === null ? (
                <Typography
                  id="employee-autocomplete-label"
                  variant="body2"
                  style={{ marginTop: "16px" }}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  Select an employee to view skills.
                </Typography>
              ) : (
                <Box sx={{ mt: 2, pt: 2 }}>
                  {data.length === 0 ? (
                    <>
                      <Box
                        sx={{
                          mt: 12,
                          left: "50%",
                          // transform: "translate(-50%, -50%)",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <NothingToDisplayComponent />
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          mx: 2,
                          mt: 2,
                        }}
                      >
                        <Box sx={{ width: "20%" }}></Box>
                        <Box sx={{ width: "70%", display: "flex" }}>
                          <Box
                            sx={{
                              width: "90%",
                              mr: 2.7,
                              ml: 1,
                              display: "flex",
                              justifyContent: "center",
                              textAlign: "center",
                            }}
                          >
                            {ratingLabel.map((data, index) => {
                              return (
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "20%",
                                    // mr: 1.7,
                                    // mt: -0.8,
                                  }}
                                >
                                  {/* <Box
                        sx={{
                          bgcolor: `${data.color}`,
                          color: "white",
                          m: 0.5,
                          height: "9px",
                          width: "9px",
                        }}
                      >
                        a
                      </Box> */}
                                  <Typography
                                    key={index}
                                    sx={{ mr: 0.3 }}
                                    fontSize={13}
                                    fontWeight={"bold"}
                                  >
                                    {data.label}
                                  </Typography>
                                  {/* <Typography fontWeight={"bold"} fontSize={11} sx={{}}>
                        |
                      </Typography> */}
                                </Box>
                              );
                            })}
                          </Box>
                          <Box sx={{ width: "10%" }}></Box>
                        </Box>
                        <Box sx={{ width: "10%" }}></Box>
                      </Box>
                      {data.map((emp, index) => {
                        return (
                          <Box sx={{ m: 1, p: 1 }}>
                            <Accordion>
                              <AccordionSummary>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    width: "100%",
                                  }}
                                >
                                  <Box sx={{ width: "20%" }}>
                                    <Typography
                                      id={`skill-name-${index}`}
                                      variant="h6"
                                      color="primary"
                                      fontWeight={"bold"}
                                    >
                                      {emp.skill_name}
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      width: "70%",
                                      mx: 2,
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        width: "100%",
                                        position: "relative",
                                      }}
                                    >
                                      <LinearProgress
                                        variant="determinate"
                                        value={parseFloat(
                                          emp.percentage as any
                                        )}
                                        sx={{
                                          flexGrow: 1,
                                          mr: 2,
                                          // backgroundColor: "#E0E0E0",
                                          // "& .MuiLinearProgress-barColorPrimary": {
                                          //   backgroundColor: color,
                                          // },
                                        }}
                                      />
                                      <div
                                        className="skill-info-color"
                                        style={{
                                          position: "absolute",
                                          top: "50%",
                                          transform: `translate(-50%, -50%)`,
                                          left: `${
                                            parseFloat(emp.percentage) > 50
                                              ? parseFloat(emp.percentage) - 1.5
                                              : parseFloat(emp.percentage)
                                          }%`,
                                          width: "12px",
                                          height: "12px",
                                          borderRadius: "50%",
                                        }}
                                      />
                                    </Box>
                                    <Typography
                                      variant="h6"
                                      fontWeight={"bold"}
                                      fontSize={14}
                                    >
                                      {parseFloat(emp.percentage).toFixed(2)}%
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      width: "10%",
                                      justifyContent: "right",
                                    }}
                                  >
                                    <Typography
                                      id={`completion-info-${index}`}
                                      variant="h6"
                                      color={
                                        emp.questions.filter(
                                          (que) =>
                                            que.employee_skill_response !== null
                                        ).length === emp.questions.length
                                          ? "success"
                                          : "error"
                                      }
                                    >
                                      {
                                        emp.questions.filter(
                                          (que) =>
                                            que.employee_skill_response !== null
                                        ).length
                                      }
                                      /{emp.questions.length}
                                    </Typography>
                                  </Box>
                                </Box>
                              </AccordionSummary>
                              <AccordionDetails>
                                {emp.questions.map(
                                  (question, questionIndex) => {
                                    return (
                                      <Box key={questionIndex}>
                                        <Box
                                          key={question.skill_question_id}
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            m: 1,
                                          }}
                                        >
                                          {/* Question Text */}
                                          <Box
                                            id={`question-text-${index}-${questionIndex}`}
                                          >
                                            <Typography
                                              fontWeight={"bold"}
                                              variant="body1"
                                            >
                                              {questionIndex + 1 + ") "}
                                              {question.question}
                                            </Typography>
                                          </Box>
                                          <Box
                                            id={`rating-box-${index}-${questionIndex}`}
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                              // m: 1,
                                              p: 1,
                                            }}
                                          >
                                            {/* Rating */}
                                            <Rating
                                              name={`rating-${index}-${questionIndex}`}
                                              value={parseInt(
                                                question.employee_skill_response ||
                                                  "0"
                                              )}
                                              readOnly
                                              size="large"
                                              className="rating-color"
                                            />
                                          </Box>
                                        </Box>
                                      </Box>
                                    );
                                  }
                                )}
                              </AccordionDetails>
                            </Accordion>
                          </Box>
                        );
                      })}
                    </>
                  )}
                </Box>
              )}
            </Box>
          ) : (
            <Box>
              <Autocomplete
                size="small"
                isOptionEqualToValue={(option, value) =>
                  option.skill_id === value.skill_id
                }
                getOptionLabel={(option) => option.skill_name}
                value={skill}
                onChange={handleSkillChange}
                id="skill-autocomplete"
                options={skillsData}
                sx={{ width: "65vw" }}
                renderInput={(params) => <TextField {...params} />}
              />
              {skill === null ? (
                <Typography
                  id="employee-autocomplete-label"
                  variant="body2"
                  style={{ marginTop: "16px" }}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  Select an Skill to view skills.
                </Typography>
              ) : (
                <Box sx={{ mt: 2, pt: 2 }}>
                  {skillEmployeeResponseData.length === 0 ? (
                    <>
                      <Box
                        sx={{
                          mt: 12,
                          left: "50%",
                          // transform: "translate(-50%, -50%)",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <NothingToDisplayComponent />
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          mx: 2,
                          mt: 2,
                        }}
                      >
                        <Box sx={{ width: "20%" }}></Box>
                        <Box sx={{ width: "70%", display: "flex" }}>
                          <Box
                            sx={{
                              width: "90%",
                              mr: 2.7,
                              ml: 1,
                              display: "flex",
                              justifyContent: "center",
                              textAlign: "center",
                            }}
                          >
                            {ratingLabel.map((data, index) => {
                              return (
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "20%",
                                    // mr: 1.7,
                                    // mt: -0.8,
                                  }}
                                >
                                  {/* <Box
                        sx={{
                          bgcolor: `${data.color}`,
                          color: "white",
                          m: 0.5,
                          height: "9px",
                          width: "9px",
                        }}
                      >
                        a
                      </Box> */}
                                  <Typography
                                    key={index}
                                    sx={{ mr: 0.3 }}
                                    fontSize={13}
                                    fontWeight={"bold"}
                                  >
                                    {data.label}
                                  </Typography>
                                </Box>
                              );
                            })}
                          </Box>
                          <Box sx={{ width: "10%" }}></Box>
                        </Box>
                        <Box sx={{ width: "10%" }}></Box>
                      </Box>
                      {skillEmployeeResponseData[0].employees.map(
                        (emp, index) => {
                          // const progress = calculateProgress(emp.questions);
                          // const linearProgress =
                          //   ((emp.average_employee_response as number) / 5) * 100;
                          return (
                            <Box sx={{ m: 1, p: 1 }}>
                              <Accordion>
                                <AccordionSummary>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      width: "100%",
                                    }}
                                  >
                                    <Box sx={{ width: "20%" }}>
                                      <Typography
                                        id={`skill-name-${index}`}
                                        variant="h6"
                                        color="primary"
                                        fontWeight={"bold"}
                                        fontSize={15.5}
                                      >
                                        {emp.employee_name}
                                      </Typography>
                                    </Box>
                                    <Box
                                      sx={{
                                        width: "70%",
                                        mx: 2,
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          width: "100%",
                                          position: "relative",
                                        }}
                                      >
                                        <LinearProgress
                                          variant="determinate"
                                          value={parseFloat(emp.percentage)}
                                          sx={{
                                            flexGrow: 1,
                                            mr: 2,
                                          }}
                                        />
                                        <div
                                          className="skill-info-color"
                                          style={{
                                            position: "absolute",
                                            top: "50%",
                                            transform: `translate(-50%, -50%)`,
                                            left: `${
                                              parseFloat(emp.percentage) > 50
                                                ? parseFloat(emp.percentage) -
                                                  1.5
                                                : parseFloat(emp.percentage)
                                            }%`,
                                            width: "12px",
                                            height: "12px",
                                            borderRadius: "50%",
                                          }}
                                        />
                                      </Box>
                                      <Typography
                                        variant="h6"
                                        fontWeight={"bold"}
                                        fontSize={14}
                                      >
                                        {parseFloat(emp.percentage).toFixed(2)}%
                                      </Typography>
                                    </Box>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        width: "10%",
                                        justifyContent: "right",
                                      }}
                                    >
                                      <Typography
                                        id={`completion-info-${index}`}
                                        variant="h6"
                                        color={
                                          emp.questions.filter(
                                            (que) =>
                                              que.employee_skill_response !==
                                              null
                                          ).length === emp.questions.length
                                            ? "success"
                                            : "error"
                                        }
                                      >
                                        {
                                          emp.questions.filter(
                                            (que) =>
                                              que.employee_skill_response !==
                                              null
                                          ).length
                                        }
                                        /{emp.questions.length}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                  {emp.questions.map(
                                    (question, questionIndex) => {
                                      return (
                                        <Box key={questionIndex}>
                                          <Box
                                            key={question.skill_question_id}
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "space-between",
                                              m: 1,
                                            }}
                                          >
                                            {/* Question Text */}
                                            <Box
                                              id={`question-text-${index}-${questionIndex}`}
                                            >
                                              <Typography
                                                fontWeight={"bold"}
                                                variant="body1"
                                              >
                                                {questionIndex + 1 + ") "}
                                                {question.question}
                                              </Typography>
                                            </Box>
                                            <Box
                                              id={`rating-box-${index}-${questionIndex}`}
                                              sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                // m: 1,
                                                p: 1,
                                              }}
                                            >
                                              {/* Rating */}
                                              <Rating
                                                name={`rating-${index}-${questionIndex}`}
                                                value={parseInt(
                                                  question.employee_skill_response ||
                                                    "0"
                                                )}
                                                readOnly
                                                size="large"
                                                className="rating-color"
                                              />
                                            </Box>
                                          </Box>
                                        </Box>
                                      );
                                    }
                                  )}
                                </AccordionDetails>
                              </Accordion>
                            </Box>
                          );
                        }
                      )}
                    </>
                  )}
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ReviewEmployeesSkills;
