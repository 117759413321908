import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Card,
  Divider,
  FormControlLabel,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  Rating,
  TextField,
  Typography,
} from "@mui/material";

import { ThunkDispatch } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { listFeedbackForm } from "../../../Service/Apis";
import Footer from "../../../components/Page Component/Footer";
import Navbar from "../../../components/Page Component/Navbar";
import Skeletons from "../../../components/Skeleton/Skeletons";
import useAxiosInterceptor from "../../../components/layout/AxiosInstance";
import { setId } from "../../../store/FormSlice";
import { setIsLoading } from "../../../store/LoadingSlice";
import { RootState } from "../../../store/Store";
import {
  FetchDataFailureAction,
  FetchDataSuccessAction,
  fetchData,
} from "../../../store/actions";
import { FeedBackFormType } from "../Types/FeedBackForm";

const FeedBackViewForm = () => {
  const [viewForm, setViewForm] = useState<FeedBackFormType[]>([]); //state for saving the form
  const [expanded, setExpanded] = React.useState<string | false>("panel1");
  const [open, setOpen] = useState(false);
  const isLoading = useSelector((state: any) => state.loading.isLoading);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const Navigate = useNavigate();

  const dispatch = useDispatch();
  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();

  const formId =
    useSelector((state: any) => state.form.id) ||
    localStorage.getItem("formid");

  const handleOpenDelete2 = () => {
    setOpen(true);
  };
  const handleCLoseDelete2 = () => {
    setOpen(false);
  };

  //fetching the form
  useEffect(() => {
    const fetchFeedBackForm = async () => {
      try {
        dispatch(setIsLoading(true));
        const response = await dispatch2(
          fetchData(`${listFeedbackForm}&form_id=${formId}`)
        );
        setViewForm(response as FeedBackFormType[]);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setIsLoading(false));
      }
    };
    fetchFeedBackForm();
  }, []);

  //  to display skeleton if the page is loading
  if (isLoading) {
    return (
      <>
        <Skeletons />
      </>
    );
  }
  return (
    <>
      <Box>
        <Navbar />
      </Box>

      <Box sx={{ mt: 12, mb: 11, display: "flex", justifyContent: "center" }}>
        {viewForm.map((res) => {
          return (
            <Box key={res.form_id} sx={{ maxWidth: "80vw" }}>
              <Box>
                <RadioGroup>
                  <Box sx={{ display: "flex" }}>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={res.is_employee_feedback}
                          name="employee"
                          readOnly
                        />
                      }
                      label="Employee Feedback Form"
                    />
                    <FormControlLabel
                      control={
                        <Radio
                          checked={res.is_manager_feedback}
                          name="manager"
                          readOnly
                        />
                      }
                      label="Manager Feedback Form"
                    />
                  </Box>
                </RadioGroup>
              </Box>
              <Box
                sx={{
                  mt: 1,
                  pt: 2,
                  display: "flex",
                  justifyContent: "center",
                  width: "100% ",
                }}
              >
                <Typography
                  fontWeight={"bold"}
                  letterSpacing={3}
                  variant="h4"
                  sx={{ textDecoration: "underline" }}
                >
                  {res.form_name}
                </Typography>
              </Box>
              <Box
                sx={{
                  m: 1,
                  mt: 2,
                  mb: 3,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="h5">
                  {res?.form_description
                    ? `(${res?.form_description})`
                    : "(No description)"}
                </Typography>
              </Box>
              <Divider />
              <Box sx={{ m: 3, p: 2 }}>
                {viewForm[0].questions_old_format.map(
                  (question, questionIndex) => {
                    return (
                      <>
                        <Card
                          key={questionIndex}
                          elevation={0}
                          sx={{
                            width: "65vw",
                            m: 2,
                            p: 2,
                            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", // Add boxShadow here
                            // border: "1px solid #ccc",
                          }}
                        >
                          <Box>
                            <Typography
                              fontWeight={"bold"}
                              fontSize={19}
                              variant="h6"
                            >
                              {questionIndex + 1 + ")"} {question.question}
                            </Typography>
                          </Box>
                          <Box sx={{ ml: 2, mr: 2 }}>
                            {question.answer_type === "short_answer" ? (
                              <Box>
                                <TextField
                                  required
                                  name={`descriptive_${questionIndex}`}
                                  size="small"
                                  fullWidth
                                  multiline
                                  rows={1}
                                  sx={{ m: 2 }}
                                  disabled
                                />
                              </Box>
                            ) : question.answer_type === "multichoice" ? (
                              <Box>
                                <Box
                                  sx={{
                                    display: "block",
                                    alignItems: "center",
                                    m: 1,
                                  }}
                                >
                                  <RadioGroup name={`choices_${questionIndex}`}>
                                    {question.answer_choices &&
                                      question.answer_choices.map(
                                        (ans: any) => (
                                          <FormControlLabel
                                            // required
                                            disabled
                                            key={ans.answer_id}
                                            value={ans.answer_value}
                                            control={<Radio />}
                                            label={`${ans.answer_value}`}
                                          />
                                        )
                                      )}
                                  </RadioGroup>
                                </Box>
                              </Box>
                            ) : (
                              <Box>
                                <Box
                                  sx={{
                                    display: "block",
                                    alignItems: "center",
                                    m: 1,
                                  }}
                                >
                                  <Rating disabled size="large" />
                                </Box>
                              </Box>
                            )}
                          </Box>
                        </Card>
                      </>
                    );
                  }
                )}
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  onClick={() => {
                    Navigate("/createdforms");
                  }}
                >
                  Back
                </Button>

                <Button
                  size="large"
                  variant="contained"
                  disabled={viewForm[0].is_uploaded}
                  onClick={() => {
                    Navigate("/editform");
                    dispatch(setId(res.form_id));
                    localStorage.setItem("formid", res.form_id);
                  }}
                >
                  Edit
                </Button>
              </Box>
              <Box>
                <Modal
                  open={open}
                  onClose={handleCLoseDelete2}
                  sx={{
                    m: 1,
                    // width: 400,
                  }}
                >
                  <Card
                    elevation={11}
                    sx={{
                      ml: "5rem",
                      mr: "5rem",
                      m: 2,
                      p: 2,
                      position: "absolute" as "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      borderRadius: "5px",
                    }}
                  >
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                        }}
                      >
                        <IconButton onClick={() => handleCLoseDelete2()}>
                          <CloseIcon />
                        </IconButton>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "center",
                          m: 1,
                        }}
                      >
                        <Typography variant="h4">
                          Teams you have Uploaded form to
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Divider
                        sx={{
                          m: 3,
                          color: "green",
                          height: "1px",
                          backgroundColor: "green",
                        }}
                      />
                    </Box>
                    <Box>
                      <Box sx={{ m: 4 }}>
                        {viewForm[0].teams.map((teams: any, index: number) => {
                          return (
                            <>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Typography variant="h5" fontWeight={"bold"}>
                                  {index + 1}.
                                </Typography>
                                <Typography variant="h5">
                                  {teams.project_name}
                                </Typography>
                              </Box>
                            </>
                          );
                        })}
                      </Box>
                      <Box
                        sx={{
                          m: 2,
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                        }}
                      >
                        <Button onClick={() => handleCLoseDelete2()}>
                          Close
                        </Button>
                      </Box>
                    </Box>
                  </Card>
                </Modal>
              </Box>
            </Box>
          );
        })}
      </Box>
      <Box>
        <Footer />
      </Box>
    </>
  );
};

export default FeedBackViewForm;
