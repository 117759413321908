import { Box, Button, Paper, Tab, Tabs } from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import CustomNoRowsOverlay from "../../../components/Data/NoRowsOverlayComponent";
import { setFormTeamId, setId } from "../../../store/FormSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import {
  useCustomToast,
  ToastVariant,
} from "../../../components/Methods/SnackBar";
import { RootState } from "../../../store/Store";
import {
  FetchDataSuccessAction,
  FetchDataFailureAction,
  fetchData,
} from "../../../store/actions";
import {
  employeeAssignedFeedbackForm,
  managerAssignedFeedbackForm,
  managerOutdatedFeedbackForms,
} from "../../../Service/Apis";
import {
  a11yProps,
  CustomTabPanel,
} from "../../../components/TabPanel/TabPanel";

interface AssignedFormsTypes {
  form_team_id: string;
  is_employee_feedback: boolean;
  is_manager_feedback: boolean;
  project_name: string;
  manager_name: string;
  is_360: boolean;
  form_id: string;
  team_id: string;
}
const FeedBackFormManagerDashboard = () => {
  const [assignedForms, setAssignedForms] = useState<AssignedFormsTypes[]>([]);
  const [previousForms, setPreviousForms] = useState<AssignedFormsTypes[]>([]);
  const [value, setValue] = React.useState(0);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();

  const showToast = useCustomToast();
  const handleShowToast = (
    message: string,
    variant: ToastVariant,
    isCloseable: boolean
  ) => {
    showToast(message, variant, isCloseable);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const assignedFormsRows: any[] = assignedForms.map((form, index) => ({
    ...form,
    index: index,
    sr_no: index + 1,
  }));

  const previousFormsRows: any[] = previousForms.map((form, index) => ({
    ...form,
    index: index,
    sr_no: index + 1,
  }));

  const columns: GridColDef[] = [
    {
      field: "sr_no",
      headerName: "Sr. No",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Sr No.
        </strong>
      ),
      width: 95,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "form_name",
      headerName: "Form Name",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Form Name
        </strong>
      ),
      width: 210,
      align: "left",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "employee_name",
      headerName: "Employee Name",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Employee Name
        </strong>
      ),
      width: 155,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "manager_name",
      headerName: "Manager Name",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Manager Name
        </strong>
      ),
      width: 155,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "employee_deadline",
      headerName: "Deadline",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Deadline
        </strong>
      ),
      width: 155,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "action",
      headerName: "Actions",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Actions
        </strong>
      ),
      width: 155,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
      renderCell: (params: any) => (
        <Button
          size="small"
          onClick={() => {
            dispatch(setFormTeamId(params.row.form_team_id));
            localStorage.setItem("formTeamId", params.row.form_team_id);
            localStorage.setItem("teamId", params.row.team_id);
            localStorage.setItem("employeeId", params.row.employee_id);
            dispatch(setId(params.row.form_id));
            localStorage.setItem("formid", params.row.form_id);
            localStorage.setItem(
              "feedbackRole",
              JSON.stringify({
                is_employee_feedback: false,
                is_manager_feedback: true,
              })
            );
            // dispatch(setId(params.row.response_id));
            navigate("/fillForm");
          }}
        >
          Go To Form
        </Button>
      ),
    },
  ];

  const previousFormsColumns: GridColDef[] = [
    {
      field: "sr_no",
      headerName: "Sr. No",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Sr No.
        </strong>
      ),
      width: 95,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "form_name",
      headerName: "Form Name",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Form Name
        </strong>
      ),
      width: 210,
      align: "left",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "employee_name",
      headerName: "Employee Name",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Employee Name
        </strong>
      ),
      width: 155,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "manager_name",
      headerName: "Manager Name",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Manager Name
        </strong>
      ),
      width: 155,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "employee_deadline",
      headerName: "Deadline",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Deadline
        </strong>
      ),
      width: 155,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "action",
      headerName: "Actions",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Actions
        </strong>
      ),
      width: 155,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
      renderCell: (params: any) => (
        <Button
          size="small"
          variant="contained"
          onClick={() => {
            localStorage.setItem("responseId", params.row.response_id);
            dispatch(setFormTeamId(params.row.form_team_id));
            localStorage.setItem("formTeamId", params.row.form_team_id);
            localStorage.setItem("teamId", params.row.team_id);
            dispatch(setId(params.row.form_id));
            localStorage.setItem("formid", params.row.form_id);
            // dispatch(setId(params.row.response_id));
            navigate("/showfeedbackresponse");
          }}
        >
          Go To Review
        </Button>
      ),
    },
  ];

  useEffect(() => {
    const fetchAssignedForms = async () => {
      const response = await dispatch2(
        fetchData(`${managerAssignedFeedbackForm}`)
      );
      setAssignedForms(response as AssignedFormsTypes[]);
    };
    fetchAssignedForms();

    const fetchPreviousForms = async () => {
      const response = await dispatch2(
        fetchData(`${managerOutdatedFeedbackForms}`)
      );
      setPreviousForms(response as AssignedFormsTypes[]);
    };
    fetchPreviousForms();
  }, []);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          height: "100%", // Adjust the height as needed
        }}
      >
        <Box
          sx={{
            minWidth: 150, // Set the width for the left sidebar
            bgcolor: "background.paper",
          }}
        >
          <Tabs
            centered
            value={value}
            onChange={handleChange}
            orientation="vertical"
            textColor="inherit"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
          >
            <Tab {...a11yProps(0)} label={"Assigned Forms"}></Tab>
            <Tab {...a11yProps(1)} label={"Previous Forms"} />
          </Tabs>
        </Box>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CustomTabPanel index={0} value={value}>
            <Paper
              sx={{
                width: "fit-content",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <DataGrid
                autoHeight
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                disableColumnMenu
                sx={{
                  width: "100%",
                  height: "100%",
                  "--DataGrid-overlayHeight": "50vh",
                }}
                pagination
                pageSizeOptions={[5, 10, 15]}
                rows={assignedFormsRows}
                columns={columns}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                slots={{
                  toolbar: GridToolbar,
                  noRowsOverlay: CustomNoRowsOverlay,
                  noResultsOverlay: CustomNoRowsOverlay,
                }}
                slotProps={{
                  toolbar: {
                    csvOptions: { disableToolbarButton: true },
                    showQuickFilter: true,
                    printOptions: { disableToolbarButton: true },
                    style: { margin: "0.5rem" },
                  },
                }}
                getRowId={(row) => row.index}
              />
            </Paper>
          </CustomTabPanel>
          <CustomTabPanel index={1} value={value}>
            <Paper
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <DataGrid
                autoHeight
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                disableColumnMenu
                sx={{
                  width: "100%",
                  height: "100%",
                  "--DataGrid-overlayHeight": "50vh",
                }}
                pagination
                pageSizeOptions={[5, 10, 15]}
                rows={previousFormsRows}
                columns={previousFormsColumns}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                slots={{
                  toolbar: GridToolbar,
                  noRowsOverlay: CustomNoRowsOverlay,
                  noResultsOverlay: CustomNoRowsOverlay,
                }}
                slotProps={{
                  toolbar: {
                    csvOptions: { disableToolbarButton: true },
                    showQuickFilter: true,
                    printOptions: { disableToolbarButton: true },
                    style: { margin: "0.5rem" },
                  },
                }}
                getRowId={(row) => row.index}
              />
            </Paper>
          </CustomTabPanel>
        </Box>
      </Box>
    </>
  );
};

export default FeedBackFormManagerDashboard;
