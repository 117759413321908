import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import * as React from "react";
import Footer from "../../components/Page Component/Footer";
import Navbar from "../../components/Page Component/Navbar";
import { CustomTabPanel, a11yProps } from "../../components/TabPanel/TabPanel";
import EmployeePreviousResponses from "./EmployeePreviousResponses";
import ThreeSixtyEmployeePreviousResponses from "./ThreeSixtyForm/ThreeSixtyEmployeePreviousResponses";
import {
  ToastVariant,
  useCustomToast,
} from "../../components/Methods/SnackBar";
import FeedBackPreviousResponses from "./FeedBackForm/FeedBackPreviousResponses";

const AllPreviousResponses = () => {
  const [value, setValue] = React.useState(0);

  const showToast = useCustomToast();
  const handleShowToast = (
    message: string,
    variant: ToastVariant,
    isCloseable: boolean
  ) => {
    showToast(message, variant, isCloseable);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = event.currentTarget.innerHTML;
    const tabLabel = tempElement.innerText;

    // Clean up the temporary element
    tempElement.remove();
    setValue(newValue);
    handleShowToast(`Switched to ${tabLabel}`, "info", true);
  };
  return (
    <>
      <Box sx={{ width: "98vw", mt: 10, mb: 10 }}>
        <Box>
          <Navbar />
        </Box>
        <Box>
          <Tabs
            centered
            value={value}
            onChange={handleChange}
            textColor="inherit"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
          >
            <Tab {...a11yProps(0)} label="Appraisal"></Tab>
            <Tab {...a11yProps(1)} label="360 &deg;" />
            <Tab {...a11yProps(2)} label="Feedback Form" />
          </Tabs>
          <CustomTabPanel value={value} index={0}>
            <EmployeePreviousResponses />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <ThreeSixtyEmployeePreviousResponses />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <FeedBackPreviousResponses />
          </CustomTabPanel>
        </Box>
        <Box>
          <Footer />
        </Box>
      </Box>
    </>
  );
};

export default AllPreviousResponses;
