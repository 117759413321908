import React from "react";
import {
	LineChart,
	Line,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	ResponsiveContainer,
	Legend,
} from "recharts";
import { Box, Typography } from "@mui/material";

interface PerformanceData {
	employee_name: string;
	form_name: string;
	team_name: string;
	employee_rating: number;
	manager_average_rating: number | null;
	manager_name: string;
}

interface PerformanceGraphProps {
	data: PerformanceData[];
	employeeName: string;
}

const CustomTooltip = ({ active, payload, label }: any) => {
	if (active && payload && payload.length) {
		return (
			<Box
				sx={{
					bgcolor: "white",
					p: 2,
					border: "1px solid #ccc",
					boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
				}}
			>
				<Typography variant="subtitle1" sx={{ mb: 1, fontWeight: "bold" }}>
					{label}
				</Typography>
				{payload.map((item: any) => {
					const employeeName = item.dataKey;
					const employeeData = item.payload.data[employeeName];

					if (!employeeData) return null;

					return (
						<Box key={employeeName} sx={{ mb: 1.5 }}>
							<Typography style={{ color: item.color, fontWeight: 500 }}>
								{`${employeeName}: ${item.value || "No Rating"}`}
							</Typography>
							<Typography
								sx={{ color: "text.secondary", fontSize: "0.875rem" }}
							>
								{`Manager Rating: ${
									employeeData.manager_rating || "Not Rated"
								}`}
							</Typography>
							<Typography
								sx={{ color: "text.secondary", fontSize: "0.875rem" }}
							>
								{`Manager: ${employeeData.manager_name || "Not Assigned"}`}
							</Typography>
							<Typography
								sx={{ color: "text.secondary", fontSize: "0.875rem" }}
							>
								{`Team: ${employeeData.team_name}`}
							</Typography>
						</Box>
					);
				})}
			</Box>
		);
	}
	return null;
};

const PerformanceGraph: React.FC<PerformanceGraphProps> = ({
	data,
	employeeName,
}) => {
	const getFormattedData = (rawData: PerformanceData[]) => {
		if (!Array.isArray(rawData) || rawData.length === 0) {
			return [];
		}

		const formGroups = rawData.reduce((acc: any, curr: any) => {
			if (!curr.form_name) return acc;

			const key = `${curr.form_name} (${curr.team_name})`;

			if (!acc[key]) {
				acc[key] = {
					form_name: key,
					data: {},
				};
			}

			acc[key].data[curr.employee_name] = {
				employee_rating: curr.employee_rating,
				manager_rating: curr.manager_average_rating,
				manager_name: curr.manager_name,
				team_name: curr.team_name,
				employee_name: curr.employee_name,
			};

			acc[key][curr.employee_name] = curr.employee_rating;

			return acc;
		}, {});

		return Object.values(formGroups);
	};

	return (
		<ResponsiveContainer width="100%" height={400}>
			<LineChart data={getFormattedData(data)}>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis
					dataKey="form_name"
					angle={-45}
					textAnchor="end"
					height={120}
					interval={0}
				/>
				<YAxis
					label={{ value: "Rating", angle: -90, position: "insideLeft" }}
					domain={[0, 5]}
					tickCount={6}
				/>
				<Tooltip content={<CustomTooltip />} />
				<Legend />
				<Line
					type="monotone"
					dataKey={employeeName}
					stroke={`hsl(137.5, 70%, 50%)`}
					strokeWidth={2}
					dot={{ r: 4 }}
					activeDot={{ r: 6 }}
					connectNulls
				/>
			</LineChart>
		</ResponsiveContainer>
	);
};

export default PerformanceGraph;
