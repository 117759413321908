import React, { useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../../store/Store";
import {
	FetchDataSuccessAction,
	FetchDataFailureAction,
	postData,
} from "../../store/actions";
import { getAllDesignations } from "../../Service/Apis";
import { ToastVariant } from "../../components/Methods/SnackBar";

interface Designation {
	id: string;
	designation_name: string;
}

interface DesignationAutocompleteProps {
	designations: Designation[];
	currentDesignation: string | null;
	onDesignationChange: (
		designationId: string | null,
		designationName: string | null
	) => void;
	showToast: (
		message: string,
		variant: ToastVariant,
		isCloseable: boolean
	) => void;
}

const DesignationAutocomplete = React.memo(
	({
		designations,
		currentDesignation,
		onDesignationChange,
		showToast,
	}: DesignationAutocompleteProps) => {
		const [inputValue, setInputValue] = useState("");
		const dispatch2: ThunkDispatch<
			RootState,
			null,
			FetchDataSuccessAction | FetchDataFailureAction
		> = useDispatch();

		const getCurrentDesignation = () => {
			if (!currentDesignation) return null;
			return (
				designations.find((d) => d.designation_name === currentDesignation) ||
				null
			);
		};

		return (
			<Autocomplete
				id="designation"
				options={designations}
				getOptionLabel={(option) => {
					if (typeof option === "string") return option;
					return option.designation_name;
				}}
				value={getCurrentDesignation()}
				inputValue={inputValue}
				onInputChange={(_, newInputValue) => {
					setInputValue(newInputValue);
				}}
				onChange={(_, newValue) => {
					if (!newValue) {
						onDesignationChange(null, null);
						return;
					}

					if (typeof newValue === "string") {
						// Handle creating new designation
						dispatch2(
							postData(`${getAllDesignations}`, {
								designation_name: newValue,
							})
						)
							.then((response: any) => {
								onDesignationChange(response.id, response.designation_name);
								showToast(
									"New designation created successfully!",
									"success",
									true
								);
							})
							.catch((error) => {
								console.error("Error creating designation:", error);
								showToast("Error creating new designation", "error", true);
							});
					} else {
						onDesignationChange(newValue.id, newValue.designation_name);
					}
				}}
				filterOptions={(options, params) => {
					const filtered = options.filter((option) =>
						option.designation_name
							.toLowerCase()
							.includes(params.inputValue.toLowerCase())
					);

					if (params.inputValue !== "" && !filtered.length) {
						filtered.push({
							id: "new",
							designation_name: `Add "${params.inputValue}"`,
						} as Designation);
					}

					return filtered;
				}}
				selectOnFocus
				clearOnBlur
				handleHomeEndKeys
				freeSolo
				renderInput={(params) => (
					<TextField
						{...params}
						required
						size="small"
						sx={{ m: 1, width: "35rem" }}
					/>
				)}
				renderOption={(props, option) => (
					<li {...props}>
						{option.id === "new" ? (
							<strong>{option.designation_name}</strong>
						) : (
							option.designation_name
						)}
					</li>
				)}
			/>
		);
	}
);

DesignationAutocomplete.displayName = "DesignationAutocomplete";

export default DesignationAutocomplete;
