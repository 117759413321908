import {
  Box,
  Container,
  Divider,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";

import Footer from "../../components/Page Component/Footer";
import Navbar from "../../components/Page Component/Navbar";
import Sidebar from "../../components/Page Component/Sidebar";

const AdminAddEmployee = () => {
  return (
    <>
      <Box>
        <Navbar />
      </Box>

      <Box>
        <Container
          sx={{
            display: "flow",
            justifyContent: "center",
            alignItems: "center",
            mt: 2,
          }}
        >
          <Box>
            <Typography variant="h3">Add Employee</Typography>
            <Divider />
          </Box>
          <Box>
            <InputLabel htmlFor="first_name"> First name</InputLabel>
            <TextField name="first_name" variant="outlined" size="small" />
          </Box>
        </Container>
      </Box>
      <Box>
        <Footer />
      </Box>
    </>
  );
};

export default AdminAddEmployee;
