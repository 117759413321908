import React, { PureComponent } from "react";
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
} from "recharts";
import iwanthue from "iwanthue"; // Adjust the path if necessary
import { CustomTooltip } from "../utilization/utils";
import { Checkbox, FormControlLabel, Grid } from "@mui/material";

interface BarChartProps {
	data: any[];
	YAxisLabel: string;
	selectedEmployees?: string[];
	onEmployeeSelection: (employeeName: string) => void;
}

export default class BarChartComponent extends PureComponent<BarChartProps> {
	static defaultProps = {
		data: [],
	};

	getColorMap = (employeeNames: string[]) => {
		const colorCount = Math.max(employeeNames.length, 2); // Ensure at least 2 colors
		const colors = iwanthue(colorCount, {
			colorSpace: "all",
			clustering: "force-vector",
			seed: "employee-colors",
			quality: 100,
		});

		const colorMap: Record<string, string> = {};
		employeeNames.forEach((name, index) => {
			colorMap[name] = colors[index];
		});

		return colorMap;
	};

	render() {
		const { data, YAxisLabel, selectedEmployees, onEmployeeSelection } =
			this.props;

		// Get unique employee names
		const employeeNames = Array.from(
			new Set(
				data.flatMap((item) =>
					Object.keys(item).filter((key) => key !== "week")
				)
			)
		);

		const employeeColorMap = this.getColorMap(employeeNames);

		return (
			<>
				<ResponsiveContainer width="100%" aspect={3}>
					<BarChart
						width={1200}
						height={500}
						data={data}
						margin={{
							top: 5,
							right: 30,
							left: 20,
							bottom: 5,
						}}
					>
						<CartesianGrid strokeDasharray="3 3" />
						<XAxis
							dataKey="week"
							style={{
								fontSize: 10,
							}}
						/>
						<YAxis
							label={{
								value: YAxisLabel,
								angle: -90,
								position: "insideLeft",
							}}
						/>
						<Tooltip
							cursor={{ fill: "transparent" }}
							content={<CustomTooltip />}
						/>
						{/* <Legend /> */}
						{/* Generate a Bar for each selected employee */}
						{employeeNames
							.filter((employeeName) =>
								selectedEmployees?.includes(employeeName)
							)
							.map((employeeName, index) => (
								<Bar
									key={employeeName}
									dataKey={employeeName}
									fill={employeeColorMap[employeeName]}
									name={employeeName}
									stackId="a"
								/>
							))}
					</BarChart>
				</ResponsiveContainer>
				<Grid
					container
					display="flex"
					alignItems="start"
					justifyContent="center"
				>
					{employeeNames.map(
						(employeeName, index) =>
							employeeName !== "No Assignees" && (
								<FormControlLabel
									key={index}
									control={
										<Checkbox
											checked={selectedEmployees?.includes(employeeName)}
											onChange={() => onEmployeeSelection(employeeName)}
											sx={{ color: employeeColorMap[employeeName] }}
										/>
									}
									label={employeeName}
									sx={{ color: employeeColorMap[employeeName] }}
								/>
							)
					)}
				</Grid>
			</>
		);
	}
}
