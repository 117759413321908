import axios, { AxiosResponse } from "axios";
import { enqueueSnackbar } from "notistack";
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { RootState } from "./Store";
import { useNavigate } from "react-router-dom";

//getting token from local storage

// Action types
export const FETCH_DATA_SUCCESS = "FETCH_DATA_SUCCESS";
export const FETCH_DATA_FAILURE = "FETCH_DATA_FAILURE";
export const POST_DATA_SUCCESS = "POST_DATA_SUCCESS";
export const POST_DATA_FAILURE = "POST_DATA_FAILURE";
export const PUT_DATA_SUCCESS = "PUT_DATA_SUCCESS";
export const PUT_DATA_FAILURE = "PUT_DATA_FAILURE";
export const PATCH_DATA_SUCCESS = "PATCH_DATA_SUCCESS";
export const PATCH_DATA_FAILURE = "PATCH_DATA_FAILURE";
export const DELETE_DATA_SUCCESS = "DELETE_DATA_SUCCESS";
export const DELETE_DATA_FAILURE = "DELETE_DATA_FAILURE";

// Data types
export interface DataPayload {
  // Define your data structure here
}

// Action interfaces
export interface FetchDataSuccessAction
  extends Action<typeof FETCH_DATA_SUCCESS> {
  payload: DataPayload;
}

export interface FetchDataFailureAction
  extends Action<typeof FETCH_DATA_FAILURE> {
  payload: string;
}

export interface PostDataSuccessAction
  extends Action<typeof POST_DATA_SUCCESS> {
  payload: DataPayload;
}

export interface PostDataFailureAction
  extends Action<typeof POST_DATA_FAILURE> {
  payload: string;
}

export interface PutDataSuccessAction extends Action<typeof PUT_DATA_SUCCESS> {
  payload: DataPayload;
}

export interface PutDataFailureAction extends Action<typeof PUT_DATA_FAILURE> {
  payload: string;
}

export interface PatchDataSuccessAction
  extends Action<typeof PATCH_DATA_SUCCESS> {
  payload: DataPayload;
}

export interface PatchDataFailureAction
  extends Action<typeof PATCH_DATA_FAILURE> {
  payload: string;
}

export interface DeleteDataSuccessAction
  extends Action<typeof DELETE_DATA_SUCCESS> {
  payload: DataPayload;
}

export interface DeleteDataFailureAction
  extends Action<typeof DELETE_DATA_FAILURE> {
  payload: string;
}

// Action creators
export const fetchDataSuccess = (
  data: DataPayload
): FetchDataSuccessAction => ({
  type: FETCH_DATA_SUCCESS,
  payload: data,
});

export const fetchDataFailure = (error: string): FetchDataFailureAction => ({
  type: FETCH_DATA_FAILURE,
  payload: error,
});

export const postDataSuccess = (data: DataPayload): PostDataSuccessAction => ({
  type: POST_DATA_SUCCESS,
  payload: data,
});

export const postDataFailure = (error: string): PostDataFailureAction => ({
  type: POST_DATA_FAILURE,
  payload: error,
});

export const putDataSuccess = (data: DataPayload): PutDataSuccessAction => ({
  type: PUT_DATA_SUCCESS,
  payload: data,
});

export const putDataFailure = (error: string): PutDataFailureAction => ({
  type: PUT_DATA_FAILURE,
  payload: error,
});

export const patchDataSuccess = (
  data: DataPayload
): PatchDataSuccessAction => ({
  type: PATCH_DATA_SUCCESS,
  payload: data,
});

export const patchDataFailure = (error: string): PatchDataFailureAction => ({
  type: PATCH_DATA_FAILURE,
  payload: error,
});

export const deleteDataSuccess = (
  data: DataPayload
): DeleteDataSuccessAction => ({
  type: DELETE_DATA_SUCCESS,
  payload: data,
});

export const deleteDataFailure = (error: string): DeleteDataFailureAction => ({
  type: DELETE_DATA_FAILURE,
  payload: error,
});

// Async action creators
export const fetchData = (
  url: string,
  data?: any,
  options?: any
): ThunkAction<
  Promise<any>,
  RootState,
  null,
  FetchDataSuccessAction | FetchDataFailureAction
> => {
  return async (dispatch) => {
    const token: string | null = localStorage.getItem("token");

    try {
      const response: AxiosResponse<DataPayload> = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        params: data,
        ...options,
      });
      dispatch(fetchDataSuccess(response.data));
      return response.data; // Return the data
    } catch (error: any) {
      if (error?.response?.status === 401) {
        // Redirect to the login page
        window.location.href = "login";
        console.log(error);
      }
      enqueueSnackbar(
        `${
          error.response.data.message === undefined
            ? "Error Occured !"
            : error.response.data.message
        }`,
        {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "left" },
          autoHideDuration: 1500,
          style: {
            marginTop: "3rem",
          },
        }
      );
      dispatch(
        fetchDataFailure(
          `${
            error.response.data.message === undefined
              ? "Error Occured !"
              : error.response.data.message
          }`
        )
      );
      return Promise.reject(error);
    }
  };
};

export const postData = (
  url: string,
  data: DataPayload
): ThunkAction<
  Promise<void>,
  RootState,
  null,
  PostDataSuccessAction | PostDataFailureAction
> => {
  return async (dispatch) => {
    const token: string | null = localStorage.getItem("token");

    try {
      const response: AxiosResponse<DataPayload> = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(postDataSuccess(response.data));
      return Promise.resolve(); // Return a resolved promise indicating success
    } catch (error: any) {
      if (error?.response?.status === 401) {
        // Redirect to the login page
        console.log(error);
      }
      enqueueSnackbar(
        `${
          error.response.data.message === undefined
            ? "Error Occured !"
            : error.response.data.message
        }`,
        {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "left" },
          autoHideDuration: 1500,
          style: {
            marginTop: "3rem",
          },
        }
      );
      dispatch(
        postDataFailure(
          `${
            error.response.data.message === undefined
              ? "Error Occured !"
              : error.response.data.message
          }`
        )
      );
      return Promise.reject(error); // Return a rejected promise indicating failure
    }
  };
};

export const putData = (
  url: string,
  data: DataPayload
): ThunkAction<
  Promise<void>,
  RootState,
  null,
  PutDataSuccessAction | PutDataFailureAction
> => {
  return async (dispatch) => {
    const token: string | null = localStorage.getItem("token");

    try {
      const response: AxiosResponse<DataPayload> = await axios.put(url, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(putDataSuccess(response.data));
      return Promise.resolve(); // Return a resolved promise indicating success
    } catch (error: any) {
      if (error?.response?.status === 401) {
        // Redirect to the login page
        console.log(error); //
      }
      enqueueSnackbar(
        `${
          error.response.data.message === undefined
            ? "Error Occured !"
            : error.response.data.message
        }`,
        {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "left" },
          autoHideDuration: 1500,
          style: {
            marginTop: "3rem",
          },
        }
      );
      dispatch(
        putDataFailure(
          `${
            error.response.data.message === undefined
              ? "Error Occured !"
              : error.response.data.message
          }`
        )
      );
      return Promise.reject(error); // Return a rejected promise indicating failure
    }
  };
};

export const patchData = (
  url: string,
  data: DataPayload
): ThunkAction<
  Promise<void>,
  RootState,
  null,
  PatchDataSuccessAction | PatchDataFailureAction
> => {
  return async (dispatch) => {
    const token: string | null = localStorage.getItem("token");

    try {
      const response: AxiosResponse<DataPayload> = await axios.patch(
        url,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(patchDataSuccess(response.data));
      return Promise.resolve(); // Return a resolved promise indicating success
    } catch (error: any) {
      if (error?.response?.status === 401) {
        // Redirect to the login page
        console.error(error);
      }
      enqueueSnackbar(
        `${
          error.response.data.message === undefined
            ? "Error Occured !"
            : error.response.data.message
        }`,
        {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "left" },
          autoHideDuration: 1500,
          style: {
            marginTop: "3rem",
          },
        }
      );
      dispatch(
        patchDataFailure(
          `${
            error.response.data.message === undefined
              ? "Error Occured !"
              : error.response.data.message
          }`
        )
      );

      return Promise.reject(error); // Return a rejected promise indicating failure
    }
  };
};

export const deleteData = (
  url: string
): ThunkAction<
  Promise<DataPayload>,
  RootState,
  null,
  DeleteDataSuccessAction | DeleteDataFailureAction
> => {
  return async (dispatch) => {
    const token: string | null = localStorage.getItem("token");

    try {
      const response: AxiosResponse<DataPayload> = await axios.delete(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(deleteDataSuccess(response.data));
      return response.data; // Return the data
    } catch (error: any) {
      if (error?.response?.status === 401) {
        // Redirect to the login page
        console.log(error);
      }
      enqueueSnackbar(
        `${
          error.response.data.message === undefined
            ? "Error Occured !"
            : error.response.data.message
        }`,
        {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "left" },
          autoHideDuration: 1500,
          style: {
            marginTop: "3rem",
          },
        }
      );
      dispatch(
        deleteDataFailure(
          `${
            error.response.data.message === undefined
              ? "Error Occured !"
              : error.response.data.message
          }`
        )
      );
      // Check if the error status code is 401

      return Promise.reject(error);
    }
  };
};
