import { Box, Button, Paper } from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import CustomNoRowsOverlay from "../../../components/Data/NoRowsOverlayComponent";
import { setFormTeamId, setId } from "../../../store/FormSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../../../store/Store";
import {
  FetchDataSuccessAction,
  FetchDataFailureAction,
  fetchData,
} from "../../../store/actions";
import { setIsLoading } from "../../../store/LoadingSlice";
import { employeeOutdatedFeedbackForms } from "../../../Service/Apis";

const FeedBackPreviousResponses = () => {
  const [previousresponses, setPreviousResponses] = useState<any[]>([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();

  const rows: any[] = previousresponses.map((res, index) => ({
    ...res,
    index: index,
    sr_no: index + 1,
  }));

  const columns: GridColDef[] = [
    {
      field: "sr_no",
      headerName: "Sr. No",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Sr No.
        </strong>
      ),
      width: 155,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "form_name",
      headerName: "Form Name",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Form Name
        </strong>
      ),
      width: 155,
      align: "left",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "manager_name",
      headerName: "Manager Name",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Manager Name
        </strong>
      ),
      width: 155,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "employee_deadline",
      headerName: "Deadline",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Deadline
        </strong>
      ),
      width: 155,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "action",
      headerName: "Actions",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Actions
        </strong>
      ),
      width: 155,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
      renderCell: (params: any) => (
        <Button
          size="small"
          
          variant="contained"
          onClick={() => {
            dispatch(setFormTeamId(params.row.form_team_id));
            localStorage.setItem("formTeamId", params.row.form_team_id);
            localStorage.setItem("teamId", params.row.team_id);
            dispatch(setId(params.row.form_id));
            localStorage.setItem("formid", params.row.form_id);
            navigate("/showfeedbackresponse");
          }}
        >
          Go To Review
        </Button>
      ),
    },
  ];

  useEffect(() => {
    const fetchPreviousResponses = async () => {
      try {
        dispatch(setIsLoading(true));
        const response = await dispatch2(
          fetchData(`${employeeOutdatedFeedbackForms}`)
        );
        setPreviousResponses(response as any[]);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setIsLoading(false));
      }
    };

    fetchPreviousResponses();
  }, []);
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Paper
          sx={{
            width: "fit-content",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <DataGrid
            autoHeight
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            disableColumnMenu
            sx={{
              width: "100%",
              height: "100%",
              "--DataGrid-overlayHeight": "50vh",
            }}
            pagination
            pageSizeOptions={[5, 10, 15]}
            rows={rows}
            columns={columns}
            // disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            slots={{
              toolbar: GridToolbar,
              noRowsOverlay: CustomNoRowsOverlay,
              noResultsOverlay: CustomNoRowsOverlay,
            }}
            slotProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                printOptions: { disableToolbarButton: true },
                style: { margin: "0.5rem" },
              },
            }}
            getRowId={(row) => row.index}
          />
        </Paper>
      </Box>
    </>
  );
};

export default FeedBackPreviousResponses;
