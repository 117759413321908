import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DataPayload } from "./actions";
import { RootState } from "./Store";

interface HttpState {
	data: DataPayload | null;
	error: string | null;
}

const initialState: HttpState = {
	data: null,
	error: null,
};

const axiosSlice = createSlice({
	name: "http",
	initialState,
	reducers: {
		fetchDataSuccess: (state, action: PayloadAction<DataPayload>) => {
			state.data = action.payload;
			state.error = null;
		},
		fetchDataFailure: (state, action: PayloadAction<string>) => {
			state.data = null;
			state.error = action.payload;
		},
		postDataSuccess: (state, action: PayloadAction<DataPayload|null>) => {
			state.data = action.payload;
			state.error = null;
		},
		postDataFailure: (state, action: PayloadAction<string>) => {
			state.data = null;
			state.error = action.payload;
		},
		putDataSuccess: (state, action: PayloadAction<DataPayload>) => {
			state.data = action.payload;
			state.error = null;
		},
		putDataFailure: (state, action: PayloadAction<string>) => {
			state.data = null;
			state.error = action.payload;
		},
		patchDataSuccess: (state, action: PayloadAction<DataPayload>) => {
			state.data = action.payload;
			state.error = null;
		},
		patchDataFailure: (state, action: PayloadAction<string>) => {
			state.data = null;
			state.error = action.payload;
		},
		deleteDataSuccess: (state, action: PayloadAction<DataPayload>) => {
			state.data = action.payload;
			state.error = null;
		},
		deleteDataFailure: (state, action: PayloadAction<string>) => {
			state.data = null;
			state.error = action.payload;
		},
	},
});

export const {
	fetchDataSuccess,
	fetchDataFailure,
	postDataSuccess,
	postDataFailure,
	putDataSuccess,
	putDataFailure,
	patchDataSuccess,
	patchDataFailure,
	deleteDataSuccess,
	deleteDataFailure,
} = axiosSlice.actions;

export default axiosSlice;
