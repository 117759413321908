import React, { useState } from "react";
import Navbar from "../components/Page Component/Navbar";
import { Box, Paper, Tabs, Tab } from "@mui/material";
import Footer from "../components/Page Component/Footer";
import UtilizationChart from "../components/jira/utilization/UtilizationChart";
import NonComplianceChart from "../components/jira/nonCompliance/NonComplianceChart";
import BugsReportChart from "../components/jira/bugs/BugsReportChart";
import BreachDueDatesReportChart from "../components/jira/breachDueDates/BreachDueDatesReportChart";
import VelocityChart from "../components/jira/velocity/VelocityChart";
import BugsAssigneeChart from "../components/jira/bugsAssignee/BugsAssigneeChart";
import ProjectBreachChart from "../components/jira/ProjectBreach/ProjectBreachChart";
import ProjectBugsChart from "../components/jira/ProjectBugs/ProjectBugsChart";
import ProjectEffortChart from "../components/jira/ProjectEffort/ProjectEffortChart";
import { useSelector } from "react-redux";
import ProjectSprintChart from "../components/jira/ProjectSprintCHart/ProjectSprintChart";

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

const TabPanel = (props: TabPanelProps) => {
	const { children, value, index, ...other } = props;
	return (
		<div hidden={value !== index} {...other}>
			{value === index && <Box>{children}</Box>}
		</div>
	);
};

const AllCharts = () => {
	const [tabValue, setTabValue] = useState(0);

	const roles = useSelector((state: any) => state.auth.roles);

	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setTabValue(newValue);
	};

	const hasAccessToTabs = roles?.some(
		(role: string) =>
			role.toLowerCase() === "hr" || role.toLowerCase() === "admin"
	);

	return (
		<>
			<Navbar />
			<Box sx={{ width: "98vw", mt: 12, mb: 10 }}>
				<Paper sx={{ m: 2, p: 4 }}>
					{hasAccessToTabs ? (
						<>
							<Box sx={{ borderBottom: 1, borderColor: "divider", mb: 3 }}>
								<Tabs value={tabValue} onChange={handleTabChange}>
									<Tab label="Team Charts" />
									<Tab label="Project Charts" />
									<Tab label="Project weekly Charts" />
								</Tabs>
							</Box>

							<TabPanel value={tabValue} index={0}>
								<UtilizationChart />
								<BugsAssigneeChart />
								<BugsReportChart />
								<BreachDueDatesReportChart />
								<NonComplianceChart />
								<VelocityChart />
							</TabPanel>

							<TabPanel value={tabValue} index={1}>
								<ProjectBreachChart />
								<ProjectBugsChart />
								<ProjectEffortChart />
							</TabPanel>
							{/* <TabPanel value={tabValue} index={2}>
								<ProjectSprintChart />
							</TabPanel> */}
						</>
					) : (
						// For non-HR/Admin users, only show Team Charts
						<Box>
							<UtilizationChart />
							<BugsAssigneeChart />
							<BugsReportChart />
							<BreachDueDatesReportChart />
							<NonComplianceChart />
							<VelocityChart />
						</Box>
					)}
				</Paper>
			</Box>
			<Footer />
		</>
	);
};

export default AllCharts;
