import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AuthState {
	token: string | null;
	isLoggedIn: boolean;
	roles: string[];
	selectedRole: string;
}

const initialState: AuthState = {
	token: localStorage.getItem("token"),
	isLoggedIn: localStorage.getItem("token") ? true : false,
	roles: [],
	selectedRole: localStorage.getItem("selectedRole") || "Employee",
};

const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		setToken: (state, action: PayloadAction<string>) => {
			state.token = action.payload;
		},
		clearToken: (state) => {
			state.token = null;
		},
		setIsLoggedIn: (state, action: PayloadAction<boolean>) => {
			state.isLoggedIn = action.payload;
		},
		setRoles: (state, action: PayloadAction<string[]>) => {
			state.roles = action.payload;
		},
		setSelectedRole: (state, action: PayloadAction<string>) => {
			if (state.roles.includes(action.payload)) {
				state.selectedRole = action.payload;
				localStorage.setItem("selectedRole", action.payload);
			}
		},
	},
});

export const {
	setToken,
	clearToken,
	setIsLoggedIn,
	setRoles,
	setSelectedRole,
} = authSlice.actions;

export default authSlice;
