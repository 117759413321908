import dayjs from "dayjs";

export interface AttendanceType {
  attendance_id: string;
  user_id: string;
  date: string;
  paid_leaves: string;
  sick_leaves: string;
  leave_without_pay: string;
  present_days: string;
  regularized_days: string;
}

export const initialAttendanceState: AttendanceType = {
  attendance_id: "",
  user_id: "",
  date: dayjs(`${new Date().getFullYear()}-${new Date().getMonth()}-01`)
    .format("YYYY-MM-DD")
    .toString(),
  leave_without_pay: "",
  paid_leaves: "",
  present_days: "",
  regularized_days: "",
  sick_leaves: "",
};
