import React, { useMemo } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Box,
} from '@mui/material';

interface FilterProps {
  projects: string[];
  selectedProjects: string[];
  sprints: string[];
  selectedSprints: string[];
  onProjectChange: (selected: string[]) => void;
  onSprintChange: (selected: string[]) => void;
  projectData: any[]; // Add this prop to access the full project data
}

const ProjectSprintFilter = ({
  projects,
  selectedProjects,
  sprints,
  selectedSprints,
  onProjectChange,
  onSprintChange,
  projectData,
}: FilterProps) => {
  const handleProjectChange = (event: any) => {
    const value = event.target.value;
    const newSelectedProjects = value[value.length - 1] === 'all'
      ? selectedProjects.length === projects.length ? [] : projects
      : value;
    
    // Get available sprints for selected projects
    const availableSprints = getSprintsForProjects(newSelectedProjects);
    
    // Update selected sprints to only include available ones
    const newSelectedSprints = selectedSprints.filter(sprint => 
      availableSprints.includes(sprint)
    );
    
    onProjectChange(newSelectedProjects);
    onSprintChange(newSelectedSprints);
  };

  const handleSprintChange = (event: any) => {
    const value = event.target.value;
    const availableSprints = getSprintsForProjects(selectedProjects);
    
    if (value[value.length - 1] === 'all') {
      onSprintChange(selectedSprints.length === availableSprints.length ? [] : availableSprints);
      return;
    }
    onSprintChange(value);
  };

  const getSprintsForProjects = (selectedProjs: string[]) => {
    const sprintsSet = new Set<string>();
    projectData
      .filter(project => selectedProjs.includes(project.project_name))
      .forEach(project => {
        project.sprints.forEach((sprint: any) => {
          sprintsSet.add(sprint.sprint_name);
        });
      });
    return Array.from(sprintsSet);
  };

  const availableSprints = useMemo(() => 
    getSprintsForProjects(selectedProjects),
    [selectedProjects, projectData]
  );

  return (
    <Box display="flex" gap={2}>
      <FormControl sx={{ minWidth: 200 }}>
        <InputLabel>Projects</InputLabel>
        <Select
          multiple
          value={selectedProjects}
          onChange={handleProjectChange}
          renderValue={(selected) => `Selected Projects (${selected.length})`}
        >
          <MenuItem value="all">
            <Checkbox checked={selectedProjects.length === projects.length} />
            <ListItemText primary="Select All" />
          </MenuItem>
          {projects.map((project) => (
            <MenuItem key={project} value={project}>
              <Checkbox checked={selectedProjects.indexOf(project) > -1} />
              <ListItemText primary={project} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl sx={{ minWidth: 200 }}>
        <InputLabel>Sprints</InputLabel>
        <Select
          multiple
          value={selectedSprints}
          onChange={handleSprintChange}
          renderValue={(selected) => `Selected Sprints (${selected.length})`}
        >
          <MenuItem value="all">
            <Checkbox checked={selectedSprints.length === availableSprints.length} />
            <ListItemText primary="Select All" />
          </MenuItem>
          {availableSprints.map((sprint) => (
            <MenuItem key={sprint} value={sprint}>
              <Checkbox checked={selectedSprints.indexOf(sprint) > -1} />
              <ListItemText primary={sprint} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default ProjectSprintFilter;