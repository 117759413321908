import { useEffect, useState } from "react";

import {
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";

import { useNavigate } from "react-router-dom";

import Footer from "../../components/Page Component/Footer";
import Navbar from "../../components/Page Component/Navbar";

import {
  addSkills,
  delteSkillQuestion,
  skillQuestionsDetail,
  updateSkillsData,
} from "../../Service/Apis";

import {
  ToastVariant,
  useCustomToast,
} from "../../components/Methods/SnackBar";

import { setInPageLoading } from "../../store/LoadingSlice";
import { RootState } from "../../store/Store";
import {
  FetchDataFailureAction,
  FetchDataSuccessAction,
  deleteData,
  fetchData,
  postData,
  putData,
} from "../../store/actions";
import Loading from "../../components/Loading/Loading";

interface SkillAddOrEditType {
  skill_id: string;
  skill_name: string;
  created_at: string;
  questions: {
    skill_question_id: string;
    question: string;
    created_at: string;
  }[];
}

const intialStateSkillsAdd: SkillAddOrEditType = {
  skill_id: "",
  skill_name: "",
  created_at: "",
  questions: [
    {
      skill_question_id: "",
      question: "",
      created_at: "",
    },
  ],
};

const SkillsAddOrEdit = () => {
  const [skillData, setSkillData] =
    useState<SkillAddOrEditType>(intialStateSkillsAdd);

  const [questionDeleteIndex, setQuestionDeleteIndex] = useState<number | null>(
    null
  );
  const [edit, setEdit] = useState<boolean>(false);

  const navigate = useNavigate();

  const showToast = useCustomToast();

  const dispatch = useDispatch();
  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();

  const skillId = localStorage.getItem("skillId");
  const isloading = useSelector((state: any) => state.loading.inPageLoading);

  const handleShowToast = (
    message: string,
    variant: ToastVariant,
    isCloseable: boolean
  ) => {
    showToast(message, variant, isCloseable);
  };

  // Function to handle skill name change.
  const handleSkillNameChange = (value: string) => {
    setSkillData((prevData) => ({ ...prevData, skill_name: value }));
  };

  // Function to handle question name change.
  const handleQuestionChange = (index: number, value: string) => {
    const newQuestions = [...skillData.questions];
    newQuestions[index] = { ...newQuestions[index], question: value };
    setSkillData((prevData) => ({ ...prevData, questions: newQuestions }));
  };

  //Add new question.
  const handleAddQuestion = () => {
    setSkillData((prevData) => ({
      ...prevData,
      questions: [
        ...prevData.questions,
        { skill_question_id: "", question: "", created_at: "" },
      ],
    }));
  };

  // Remove question.
  const handleRemoveQuestion = async (index: number) => {
    const newQuestions = [...skillData.questions];
    const skill_question_id = newQuestions[index].skill_question_id;

    if (skillData.questions.length === 1) {
      handleShowToast("Cannot delete last question", "error", true);
      return;
    } else {
      if (skill_question_id !== "") {
        try {
          const response = await dispatch2(
            deleteData(`${delteSkillQuestion}${skill_question_id}/`)
          );
          newQuestions.splice(index, 1);
          handleShowToast("Question delted!", "success", true);
        } catch (error) {
          console.log(error);
        }
      } else if (skill_question_id === undefined || skill_question_id === "") {
        newQuestions.splice(index, 1);
        handleShowToast("Question delted!", "success", true);
      }
      setSkillData((prevData) => ({ ...prevData, questions: newQuestions }));
    }
  };

  // Add Skill API Call.
  const addSkill = async () => {
    const data = skillData;

    try {
      const response = await dispatch2(postData(`${addSkills}`, data));
      handleShowToast(`${data.skill_name} Skill Added!`, "success", true);
      navigate("/profile");
    } catch (error) {
      console.log(error);
    }
  };

  // Edit Skill API call.
  const editSkill = async () => {
    const data = skillData;
    console.log(data);
    try {
      const response = await dispatch2(
        putData(`${updateSkillsData}${data.skill_id}/`, data)
      );
      handleShowToast(`${data.skill_name} Skill Updated!`, "success", true);
      navigate("/profile");
    } catch (error) {
      console.log(error);
    }
  };

  // Helper function to add or edit skill
  const handleAddNewSkill = (e: any) => {
    e.preventDefault();
    if (edit) {
      editSkill();
    } else {
      addSkill();
    }
  };

  // API call to get all skills.
  useEffect(() => {
    const fetchAllSkills = async () => {
      try {
        dispatch(setInPageLoading(true));
        const response: any = await dispatch2(
          fetchData(`${skillQuestionsDetail}${skillId}`)
        );
        setSkillData(response[0] as SkillAddOrEditType);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setInPageLoading(false));
      }
    };
    if (skillId) {
      fetchAllSkills();
      setEdit(true);
    }
  }, []);

  if (isloading) {
    return (
      <>
        <Loading isLoading={isloading as boolean} />
      </>
    );
  }

  return (
    <>
      <Box>
        <Navbar />
      </Box>

      <Box sx={{ mt: 12, mb: 10 }}>
        <Box
          component={"form"}
          onSubmit={handleAddNewSkill}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Box>
            <Card sx={{ width: "75vw", m: 2, p: 2 }} elevation={0}>
              <Box>
                <TextField
                  id={`skill-name`}
                  variant="standard"
                  sx={{ width: "100%" }}
                  required
                  label="Skill Name"
                  placeholder="Enter your skill name here"
                  value={skillData?.skill_name}
                  onChange={(e) => handleSkillNameChange(e.target.value)}
                />
              </Box>
            </Card>
            <Box sx={{ width: "75vw", mx: 4, p: 2 }}>
              {skillData.questions &&
                skillData.questions.map((question, index) => (
                  <Box
                    key={index}
                    sx={{ display: "flex", alignItems: "center", m: 0, p: 2 }}
                  >
                    <Typography fontWeight={"bold"} sx={{ width: "10rem" }}>
                      Question {index + 1}:{" "}
                    </Typography>
                    <TextField
                      id={`question-${index}`}
                      variant="standard"
                      sx={{ width: "90%" }}
                      value={question.question}
                      required
                      onChange={(e) =>
                        handleQuestionChange(index, e.target.value)
                      }
                    />
                    <IconButton
                      onClick={() => {
                        setQuestionDeleteIndex(index);
                      }}
                    >
                      <DeleteIcon color="primary" />
                    </IconButton>
                    <Dialog
                      id={`dialog-box-question-delete-${index}`}
                      open={index === questionDeleteIndex}
                    >
                      <DialogTitle fontWeight={"bold"}>
                        Delete Question
                      </DialogTitle>
                      <DialogContent>
                        <Typography>
                          Are you sure you want to delete this question?
                        </Typography>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          id={`delete-button-question-${index}`}
                          onClick={() => {
                            handleRemoveQuestion(index);
                            setQuestionDeleteIndex(null);
                          }}
                          sx={{ mr: 1 }}
                          color="error"
                        >
                          Delete
                        </Button>
                        <Button
                          id={`cancel-button-question-${index}`}
                          onClick={() => setQuestionDeleteIndex(null)}
                          sx={{ ml: 1 }}
                        >
                          Cancel
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Box>
                ))}
              <Box sx={{ display: "flex", justifyContent: "right", m: 1 }}>
                <Button
                  id={`add-question-button`}
                  variant="outlined"
                  size="small"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={handleAddQuestion}
                >
                  Add Question
                </Button>
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              {!edit ? (
                <Button
                  id={`add-skill-button`}
                  variant="contained"
                  type="submit"
                >
                  Add Skill
                </Button>
              ) : (
                <Button
                  id={`update-skill-button`}
                  type="submit"
                  variant="contained"
                >
                  Update
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box>
        <Footer />
      </Box>
    </>
  );
};

export default SkillsAddOrEdit;
