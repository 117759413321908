import React, { useEffect, useState } from "react";

import {
	AppBar,
	Box,
	Button,
	Card,
	Chip,
	CircularProgress,
	Container,
	Dialog,
	Divider,
	Fade,
	IconButton,
	InputAdornment,
	InputLabel,
	Modal,
	Paper,
	Popover,
	Popper,
	Slide,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Toolbar,
	Tooltip,
	Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MenuItem from "@mui/material/MenuItem";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import DeleteIcon from "@mui/icons-material/Delete";
import { FormControl } from "@mui/base";
import { ThemeProvider, createTheme, makeStyles } from "@mui/material/styles";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { enqueueSnackbar } from "notistack";

import axios from "axios";
import axiosInstance from "../../components/layout/AxiosInstance";
import useAxiosInterceptor from "../../components/layout/AxiosInstance";

import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import dayjs, { Dayjs } from "dayjs";

import { format } from "date-fns";

import { Console } from "console";

import { date } from "../Form Add/AddForm";
import FileUploadComponent from "../../components/Excel/FileUpload";
import NothingToDisplayImage from "../../asset/NothingToDisplayImage.png";

import { setisOpen } from "../../store/ModalSlice";
import capitalizeFirstLetter from "../../components/functions/NameFunc";
import Footer from "../../components/Page Component/Footer";
import Navbar from "../../components/Page Component/Navbar";
import Sidebar from "../../components/Page Component/Sidebar";
import {
	DeleteEmp,
	ViewUser,
	registerUser,
	updateEmployee,
	viewUsers,
} from "../../Service/Apis";
import { setIsLoading } from "../../store/LoadingSlice";
import Skeletons from "../../components/Skeleton/Skeletons";
import { TransitionProps } from "@mui/material/transitions";
import {
	ToastVariant,
	useCustomToast,
} from "../../components/Methods/SnackBar";
import {
	DataPayload,
	FetchDataFailureAction,
	FetchDataSuccessAction,
	deleteData,
	fetchData,
	patchData,
	postData,
} from "../../store/actions";
import { Autocomplete } from "@mui/material";
import { getAllDesignations } from "../../Service/Apis";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../../store/Store";
import { postDataSuccess } from "../../store/AxiosSlice";
import Loading from "../../components/Loading/Loading";
import DesignationAutocomplete from "./DesignationAutocomplete";

const style = {
	position: "absolute" as "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	//   border: "2px solid #000",
	boxShadow: "0px 0px 10px 2px rgba(0, 100, 0, 0.5)",

	p: 4,
};
interface Employee {
	id: string;
	employee_code: string;
	first_name: string;
	last_name: string;
	gender: "Male" | "Female" | "Other"; // You can adjust the gender options as needed
	email: string;
	roles: string[];
	teams: string[]; // This could be an array of team names or IDs
	designation: string;
	designation_id?: string; // Add this property
	date_of_birth: string; // Consider using a Date type if appropriate
	date_of_joining: string; // Consider using a Date type if appropriate
	date_of_confirmation: string | null; // Consider using a Date type if appropriate
	date_of_resignation: string | null; // Consider using a Date type if appropriate
	date_of_exit: string | null; // Consider using a Date type if appropriate
	is_active: boolean;
	is_superuser: boolean;
	is_staff: boolean;
	last_login: string; // Consider using a Date type if appropriate
}

type initialemp = {
	first_name: string;
	last_name: string;
	role: string[];
	email: string;
	password: string;
};

const initialStateEmployee = {
	id: "",
	employee_code: "",
	first_name: "",
	last_name: "",
	gender: "",
	roles: [],
	designation: "",
	date_of_birth: "",
	date_of_joining: "",
	date_of_confirmation: "",
	date_of_exit: "",
	email: "",
	password: "",
};

interface Designation {
	id: string;
	designation_name: string;
}

const ViewEmployee = () => {
	const [viewUserData, setViewUserData] = useState<Employee[]>([]);
	const [open, setOpen] = React.useState(false); //Open Add Employee Modal.
	const [addEmployee, setAddEmployee] = useState(initialStateEmployee); //State for add employee.
	const [roles, setRoles] = useState<string[]>([]); // Set Roles.
	const [designations, setDesignations] = useState<Designation[]>([]);
	const [selectedDesignation, setSelectedDesignation] =
		useState<Designation | null>(null);
	const [getEmployee, setGetEmployee] = useState<
		(typeof initialStateEmployee)[]
	>([]); // State to get the employee details.
	const [dateOfJoining, setDateOfJoining] = React.useState<Dayjs | null>(
		dayjs(`${date}`)
	);
	const [dateOfBirth, setDateOfBirth] = React.useState<Dayjs | null>(
		dayjs(`${date}`)
	);

	const [openEdit, setOpenEdit] = useState(false); //Enable Edit.

	const [personData, setPersonData] = useState<{
		employee_code: string;
		first_name: string;
		last_name: string;
		gender: string;
		roles: string[];
		designation: string;
		email: string;
		password: string;
		date_of_joining: string;
		date_of_birth: string;
	}>({
		employee_code: "",
		first_name: "",
		last_name: "",
		gender: "",
		roles: [],
		designation: "",
		email: "",
		password: "",
		date_of_joining: "",
		date_of_birth: "",
	}); // State to edit employee details.

	const [count, setCount] = useState(0); // To call API again.
	const [otherGender, setOtherGender] = useState(""); //To store Other Gender.

	const dispatch = useDispatch();
	const Navigate = useNavigate();

	const [isSpecialCharError, setIsSpecialCharError] = useState(false);
	const [isSpecialCharError2, setIsSpecialCharError2] = useState(false);

	const [nameError, setNameError] = useState<any>({
		first_name: false,
		last_name: false,
	}); // Name Validation.
	const [anchorEl, setAnchorEl] = useState(null);

	const isloading = useSelector((state: any) => state.loading.isLoading);

	const isOpen = useSelector((state: any) => state.modal.isOpen);
	const counts = useSelector((state: any) => state.loading.count);
	// const success = useSelector((state: any) => state?.axios?.data);
	const email =
		useSelector((state: any) => state.form.empId) ||
		localStorage.getItem("viewEmail");

	const showToast = useCustomToast();

	const dispatch2: ThunkDispatch<
		RootState,
		null,
		FetchDataSuccessAction | FetchDataFailureAction
	> = useDispatch();

	const handleOpen = () => setOpen(true);
	const handleClose = () => {
		setOpen(false);
		setIsSpecialCharError(false);
		setRoles([]);
	};

	const handleEdit = () => {
		setOpenEdit(!openEdit);
	};

	const handleShowToast = (
		message: string,
		variant: ToastVariant,
		isCloseable: boolean
	) => {
		showToast(message, variant, isCloseable);
	};

	const handleSave = async (
		event: React.MouseEvent<HTMLElement> | React.FormEvent<HTMLFormElement>
	) => {
		event.preventDefault();

		const {
			employee_code,
			first_name,
			last_name,
			gender,
			roles,
			designation,
			email,
		} = viewUserData[0];

		const yodaPlusEmailPattern = /^[a-zA-Z0-9._%+-]+@yodaplus\.com$/;
		const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

		const validEmail = emailPattern.test(email);

		var data = {
			employee_code: employee_code,
			first_name: first_name,
			last_name: last_name,
			gender: gender === "Other" ? otherGender : gender,
			roles: viewUserData[0]?.roles?.map((data) => {
				return {
					role: data,
				};
			}),
			designation: viewUserData[0]?.designation_id || "", // Send ID instead of name
			date_of_birth: dateOfBirth
				? format(dateOfBirth.toDate(), "yyyy-MM-dd")
				: "",
			date_of_joining: dateOfJoining
				? format(dateOfJoining.toDate(), "yyyy-MM-dd")
				: "",
		};

		//for editing a particular employee

		try {
			const response = dispatch2(patchData(`${updateEmployee}${email}`, data));
			response
				.then((response) => {
					handleShowToast("Successfully Updated Profile Info", "success", true);
					setCount(count + 1);
					dispatch(postDataSuccess(null));
					Navigate("/employeeinformation");
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}

		setAddEmployee(initialStateEmployee);
		setRoles([]);
		setOpen(false);
	};

	const handlePersonChange = (e: any) => {
		const { value, name } = e.target;
		const nameRegex = /^[a-zA-Z' ]*$/;
		if (name === "first_name" || name === "last_name") {
			setIsSpecialCharError(false);
			const isNameValid = !nameRegex.test(value);

			setNameError((prevNameError: any) => ({
				...prevNameError,
				[name]: isNameValid,
			}));
		}
		setViewUserData((prevUserData) => {
			const updatedUserData = [...prevUserData];
			updatedUserData[0] = {
				...updatedUserData[0],
				[name]: value,
			};
			return updatedUserData;
		});
	};

	const handlePersonRoles = (e: any) => {
		setViewUserData((prevUserData) => {
			const updatedUserData = [...prevUserData];
			if (updatedUserData[0].roles.includes(e.target.value)) {
				handleShowToast("Role already exist", "info", true);
			} else {
				updatedUserData[0].roles = [
					...updatedUserData[0].roles,
					e.target.value,
				];
			}
			return updatedUserData;
		});
	};

	const handlePersonDeleteRole = (e: any, newRole: string, index: number) => {
		const totalRoles = viewUserData[0]?.roles?.length;
		if (newRole.toLowerCase() === "employee") {
			handleShowToast("cannot delete Role Employee", "info", true);
		} else if (index > -1 && totalRoles > 1) {
			setViewUserData((prevUserData) => {
				const updatedUserData = [...prevUserData];
				updatedUserData[0].roles = updatedUserData[0].roles.filter(
					(role) => role !== newRole
				);
				return updatedUserData;
			});
		} else if (totalRoles <= 1) {
			handleShowToast("Cannot Delete Last Role", "info", true);
		}
	};

	const handleDesignationChange = React.useCallback(
		(designationId: string | null, designationName: string | null) => {
			setViewUserData((prevUserData) => {
				const updatedUserData = [...prevUserData];
				updatedUserData[0] = {
					...updatedUserData[0],
					designation: designationName || "",
					designation_id: designationId || "",
				};
				return updatedUserData;
			});
		},
		[]
	);

	//for showing all the employeess
	useEffect(() => {
		const fetchUserDetails = async () => {
			dispatch(setIsLoading(true));
			try {
				const response: DataPayload = await dispatch2(
					fetchData(`${ViewUser}${email}`)
				);
				setViewUserData(response as Employee[]);
				setPersonData(response as typeof personData);
				setGetEmployee(response as typeof getEmployee);
				dispatch(setIsLoading(false));
			} catch (error) {
				console.log(error);
				setRoles([]);
				setAddEmployee(initialStateEmployee);

				setDateOfBirth(dayjs(date));
				setDateOfJoining(dayjs(date));
				setNameError([]);
				dispatch(setIsLoading(false));
			}
		};

		fetchUserDetails();
	}, []);

	useEffect(() => {
		const fetchDesignations = async () => {
			try {
				const response = await dispatch2(fetchData(`${getAllDesignations}`));
				setDesignations(response as Designation[]);
			} catch (error) {
				console.error("Error fetching designations:", error);
			}
		};
		fetchDesignations();
	}, []);

	if (isloading) {
		return (
			<>
				<Loading isLoading={isloading as boolean} />
			</>
		);
	}

	return (
		<>
			<Box>
				<Navbar />
			</Box>
			<Box sx={{ mt: 12, mb: 10, display: "flex", justifyContent: "center" }}>
				<Box>
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
						}}
					>
						<Typography
							id="view_or_edit_heading"
							variant="h4"
							sx={{ mb: 2 }}
							fontWeight={"bold"}
						>
							{openEdit ? "Edit Employee" : "View Employee"}
						</Typography>
					</Box>
					<Box
						component="form"
						onSubmit={(e) => {
							handleSave(e);
						}}
					>
						<FormControl>
							{isSpecialCharError2 && openEdit && (
								<Typography color="error" sx={{ m: 2 }}>
									Special Characters are not allowed.
								</Typography>
							)}
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									p: 1,
								}}
							>
								<InputLabel
									sx={{ p: 0.5, width: "10rem" }}
									htmlFor="employee_code"
								>
									Employee Code :
								</InputLabel>
								<TextField
									id="employee_code"
									required
									name="employee_code"
									variant="outlined"
									size="small"
									value={viewUserData[0]?.employee_code}
									InputProps={{
										readOnly: true,
									}}
									sx={{ m: 1, width: "35rem" }}
									onChange={handlePersonChange}
								/>
							</Box>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									p: 1,
								}}
							>
								<InputLabel
									sx={{ p: 0.5, width: "10rem" }}
									htmlFor="first_name"
								>
									First name :
								</InputLabel>
								<TextField
									id="first_name"
									required
									name="first_name"
									variant="outlined"
									size="small"
									value={viewUserData[0]?.first_name}
									InputProps={{
										readOnly: !openEdit,
									}}
									sx={{ m: 1, width: "35rem" }}
									onChange={handlePersonChange}
									helperText={
										nameError.first_name && (
											<Typography fontSize={14} color="error">
												Please enter valid name.
											</Typography>
										)
									}
								/>
							</Box>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									p: 1,
								}}
							>
								<InputLabel sx={{ p: 0.5, width: "10rem" }} htmlFor="last_name">
									Last name :
								</InputLabel>
								<TextField
									id="last_name"
									required
									name="last_name"
									variant="outlined"
									size="small"
									value={viewUserData[0]?.last_name}
									InputProps={{
										readOnly: !openEdit,
									}}
									sx={{ m: 1, width: "35rem" }}
									onChange={handlePersonChange}
									helperText={
										nameError.last_name && (
											<Typography fontSize={14} color="error">
												Please enter valid name.
											</Typography>
										)
									}
								/>
							</Box>

							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									p: 1,
								}}
							>
								<InputLabel
									sx={{
										p: 0.5,
										width: openEdit ? "9.5rem" : "10rem",
									}}
									htmlFor="gender"
								>
									Gender :
								</InputLabel>
								{openEdit ? (
									<Box>
										<Box
											sx={{
												display: "flex",
												alignItems: "center",
												p: 1,
											}}
										>
											<Select
												id="genderSelect"
												required
												labelId="select-label"
												label="gender"
												placeholder="Select"
												size="small"
												value={viewUserData[0]?.gender}
												name="gender"
												onChange={(e) => {
													handlePersonChange(e);
												}}
												sx={{ m: 1, width: "35rem" }}
											>
												<MenuItem id="Male" value={"Male"}>
													Male
												</MenuItem>
												<MenuItem id="Female" value={"Female"}>
													Female
												</MenuItem>
												<MenuItem id="Other" value={"Other"}>
													Other
												</MenuItem>
											</Select>
										</Box>

										<Box
											sx={{
												display: "flex",
												justifyContent: "right",
												mr: 1,
											}}
										>
											{viewUserData[0]?.gender === "Other" && (
												<TextField
													id="otherGender"
													placeholder="Other.."
													name="gender"
													size="small"
													variant="outlined"
													value={otherGender}
													onChange={(e) => {
														setOtherGender(e.target.value);
													}}
													sx={{ m: 1, width: "35rem" }}
												/>
											)}
										</Box>
									</Box>
								) : (
									<TextField
										id="gen"
										required
										name="gender"
										variant="outlined"
										size="small"
										value={viewUserData[0]?.gender}
										InputProps={{
											readOnly: !openEdit,
										}}
										sx={{ m: 1, width: "35rem" }}
										// onChange={handlePersonChange}
									/>
								)}
							</Box>

							<Box
								sx={{
									display: "flex",
									alignItems: "start",
									p: 1,
								}}
							>
								<Box sx={{ mt: 1.4 }}>
									<InputLabel sx={{ p: 0.5, width: "10rem" }} id="select-label">
										Role :
									</InputLabel>
								</Box>
								{openEdit ? (
									<Box>
										<Select
											id="roleSelection"
											required
											size="small"
											labelId="select-label"
											// value={roles.at(0)}
											value={viewUserData[0]?.roles?.join(", ")}
											label="Role"
											name="roles"
											onChange={handlePersonRoles}
											sx={{ m: 1, width: "35rem" }}
										>
											<MenuItem id="employe" value={"Employee"}>
												Employee
											</MenuItem>
											<MenuItem id="hr" value={"HR"}>
												HR
											</MenuItem>
											<MenuItem id="managerr" value={"Manager"}>
												Manager
											</MenuItem>
											<MenuItem id="admin" value={"Admin"}>
												Admin
											</MenuItem>
										</Select>
										{viewUserData[0]?.roles?.length !== 0 && (
											<Box
												sx={{
													m: 1,
													p: 1,
													display: "flex",
													justifyContent: "center",
												}}
											>
												<TableContainer
													id="role_table_container"
													component={Paper}
													sx={{
														width: "fit-content",
													}}
												>
													<TableHead
														id="role_table_head"
														className="header-background"
													>
														<TableRow>
															<TableCell
																sx={{
																	textAlign: "center",
																	fontWeight: "bold",
																}}
															>
																Role
															</TableCell>
															<TableCell
																sx={{
																	textAlign: "center",
																	fontWeight: "bold",
																}}
															>
																Delete
															</TableCell>
														</TableRow>
													</TableHead>
													<TableBody id="role_table_body">
														{viewUserData[0]?.roles?.map((data, roleIndex) => {
															return (
																<TableRow key={data}>
																	<TableCell
																		sx={{
																			textAlign: "center",
																		}}
																		id={`role_${roleIndex + 1}`}
																	>
																		{data}
																	</TableCell>
																	<TableCell
																		sx={{
																			textAlign: "center",
																		}}
																	>
																		<Box>
																			<Button
																				id={`delete_role_${roleIndex + 1}`}
																				size="small"
																				onClick={(e) =>
																					handlePersonDeleteRole(
																						e,
																						data,
																						roleIndex
																					)
																				}
																			>
																				Delete
																			</Button>
																		</Box>
																	</TableCell>
																</TableRow>
															);
														})}
													</TableBody>
												</TableContainer>
											</Box>
										)}
									</Box>
								) : (
									<TextField
										id="existing_roles"
										required
										name="roles"
										variant="outlined"
										size="small"
										value={viewUserData[0]?.roles.map((val) => val)}
										InputProps={{
											readOnly: !openEdit,
										}}
										sx={{ m: 1, width: "35rem" }}
									/>
								)}
							</Box>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									p: 1,
								}}
							>
								<InputLabel
									sx={{ p: 0.5, width: "10rem" }}
									htmlFor="designation"
								>
									Designation :
								</InputLabel>
								{openEdit ? (
									<DesignationAutocomplete
										designations={designations}
										currentDesignation={viewUserData[0]?.designation || null}
										onDesignationChange={handleDesignationChange}
										showToast={handleShowToast}
									/>
								) : (
									<TextField
										id="designation"
										required
										name="designation"
										variant="outlined"
										size="small"
										value={viewUserData[0]?.designation}
										InputProps={{
											readOnly: true,
										}}
										sx={{ m: 1, width: "35rem" }}
									/>
								)}
							</Box>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									p: 1,
								}}
							>
								<InputLabel sx={{ p: 0.5, width: "10rem" }} htmlFor="email">
									Email :
								</InputLabel>
								<TextField
									id="emmail"
									required
									name="email"
									variant="outlined"
									size="small"
									value={viewUserData[0]?.email}
									InputProps={{
										readOnly: true,
									}}
									sx={{ m: 1, width: "35rem" }}
								/>
							</Box>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									p: 1,
								}}
							>
								<InputLabel
									sx={{ p: 0.5, width: "10.5rem" }}
									htmlFor="Date Of Birth"
								>
									Date Of Birth :
								</InputLabel>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<Tooltip title="Cannot Edit This Field.">
										<DatePicker
											className="datee_picker"
											sx={{
												width: "35rem",
											}}
											label="Date Of Birth"
											value={dayjs(viewUserData[0]?.date_of_birth)}
											onChange={(newValue) => {
												setDateOfBirth(newValue);
											}}
											readOnly={!openEdit}
											format="YYYY-MM-DD"
										/>
									</Tooltip>
								</LocalizationProvider>
							</Box>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									p: 1,
								}}
							>
								<InputLabel
									sx={{ p: 0.5, width: "10.5rem" }}
									htmlFor="Date Of Joining"
								>
									Date Of Joining :
								</InputLabel>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<Tooltip id="err" title="Cannot Edit This Field.">
										<DatePicker
											className="doj_datepicker"
											sx={{
												width: "35rem",
											}}
											label="Date Of Joining"
											value={dayjs(viewUserData[0]?.date_of_joining)}
											onChange={(newValue) => {
												setDateOfJoining(newValue);
											}}
											readOnly={!openEdit}
											format="YYYY-MM-DD"
										/>
									</Tooltip>
								</LocalizationProvider>
							</Box>
							<Box
								sx={{
									display: "flex",
									m: 1,
									alignItems: "end",
									justifyContent: "right",
								}}
							>
								<Button
									id="back_button"
									onClick={() => {
										setOpenEdit(false);
										Navigate("/employeeinformation");
									}}
								>
									Back
								</Button>
								{!openEdit && (
									<Button
										id="edit_button"
										onClick={() => {
											handleEdit();
											setAddEmployee(initialStateEmployee);
											setDateOfJoining(dayjs(viewUserData[0]?.date_of_joining));
											setDateOfBirth(dayjs(viewUserData[0]?.date_of_birth));
										}}
									>
										Edit
									</Button>
								)}
								{openEdit && (
									<Button
										id="save_button"
										onClick={(e) => {
											handleSave(e);
										}}
									>
										Save
									</Button>
								)}
							</Box>
						</FormControl>
					</Box>
				</Box>
			</Box>
			<Box>
				<Footer />
			</Box>
		</>
	);
};

export default ViewEmployee;
