import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Tab,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ThunkDispatch } from "@reduxjs/toolkit";
import {
  addTeamMember,
  deleteSpecificEmployee,
  showTeams,
  updateTeamNameOrManager,
  viewUsers,
} from "../../Service/Apis";
import {
  ToastVariant,
  useCustomToast,
} from "../../components/Methods/SnackBar";
import Footer from "../../components/Page Component/Footer";
import Navbar from "../../components/Page Component/Navbar";
import capitalizeFirstLetter from "../../components/functions/NameFunc";
import useAxiosInterceptor from "../../components/layout/AxiosInstance";
import { postDataSuccess } from "../../store/AxiosSlice";
import { setInPageLoading, setIsLoading } from "../../store/LoadingSlice";
import { RootState } from "../../store/Store";
import {
  FetchDataFailureAction,
  FetchDataSuccessAction,
  deleteData,
  fetchData,
  postData,
  putData,
} from "../../store/actions";
import Skeletons from "../../components/Skeleton/Skeletons";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const EditTeam = () => {
  const [value, setValue] = React.useState(0);

  const [teamDetails, setTeamDetails] = useState<any[]>([]); //for storing team details
  const [usersDetails, setUserDetails] = useState<any[]>([]); //for storing users details

  const [selectedEditEmployees, setSelectedEditEmployees] = useState<string[]>(
    []
  );
  const [empEditId, setEmpEditId] = useState<string[]>([]);

  const [count, setCount] = useState(0);
  const [deleteEmployee, setDeleteEmployee] = useState<number | null>(null);

  const isloading = useSelector((state: any) => state.loading.isLoading);
  const counts = useSelector((state: any) => state.loading.count);
  const projename =
    useSelector((state: any) => state.form.projectName) ||
    localStorage.getItem("projectName");

  const dispatch = useDispatch();
  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();

  const isLoading = useSelector((state: any) => state.loading.isLoading);

  const inPageLoading = useSelector(
    (state: any) => state.loading.inPageLoading
  );

  const navigate = useNavigate();

  const showToast = useCustomToast();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleShowToast = (
    message: string,
    variant: ToastVariant,
    isCloseable: boolean
  ) => {
    showToast(message, variant, isCloseable);
  };

  const handleId = (e: any) => {
    setTeamDetails((previous) => {
      const updatedContent = [...previous];
      updatedContent[0].manager_name = e.target.value;
      const matchingUser = usersDetails.find(
        (user) => user.first_name + " " + user.last_name === e.target.value
      );

      if (matchingUser) {
        // Set the manager property of the first object in updatedContent to the matching user's id
        updatedContent[0].manager = matchingUser.id;
      }
      return updatedContent;
    });
  };

  const handleEmpEdit = (name: string) => {
    const manager = usersDetails.filter(
      (user) =>
        user.first_name + " " + user.last_name === name &&
        setEmpEditId([...empEditId, user.id])
    );
    // empId.push(manager.id);
  };

  const handleChange1 = (event: any) => {
    var value = event.target.value;
    setTeamDetails((previous) => {
      const updatedContent = [...previous];
      updatedContent[0].project_name = value;
      return updatedContent;
    });
  };

  const handleDelete2 = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined,
    index: number
  ) => {
    setSelectedEditEmployees((prevEmployee) => {
      const updatedEmployee = [...prevEmployee];
      const removedEmployee = updatedEmployee.splice(index, 1)[0]; // Get the removed employee name
      // Find the corresponding employee id and remove it from empId
      setEmpEditId((prevEmpId) =>
        prevEmpId.filter((_, idx) => {
          return idx !== index;
        })
      );

      return updatedEmployee;
    });
  };
  const handleEmployeeDelete = (
    e: any,
    employee_id: string,
    roleIndex: number
  ) => {
    e.preventDefault();

    // const totalEmployees = teamDetails[0]?.team.length;
    try {
      const response = dispatch2(
        deleteData(
          `${deleteSpecificEmployee}${teamDetails[0]?.team_id}&employee_id=${employee_id}`
        )
      )
        .then((response) => {
          setCount(count + 1);
          handleShowToast("Employee Deleted Successfully !", "success", true);
        })
        .catch((error) => {
          console.log(error);
        });
      // setCount(count + 1);
      // handleShowToast("Employee Deleted Successfully !", "success", true);
    } catch (error) {
      console.log(error);
    }
    // if (roleIndex > -1 && totalEmployees > 1) {

    // } else if (totalEmployees <= 1) {
    // 	handleShowToast("Cannot Delete Last Employee.", "error", true);
    // }
  };

  //for employee add to team in edit

  const handleEditEmployee = (event: React.ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;

    if (
      value !== teamDetails[0]?.manager_name &&
      !selectedEditEmployees.includes(value as string)
    ) {
      setSelectedEditEmployees((prevSelectedEmployees) => [
        ...prevSelectedEmployees,
        value as string,
      ]);
      handleEmpEdit(value as string);
    }
  };

  //create a new team

  const handleNameUpdate = (e: any) => {
    e.preventDefault();
    var projectData = {
      project_name: teamDetails[0].project_name,
      manager: teamDetails[0].manager,
    };

    try {
      const response = dispatch2(
        putData(
          `${updateTeamNameOrManager}${teamDetails[0]?.team_id}`,
          projectData
        )
      )
        .then((response) => {
          setCount(count + 1);
          handleShowToast(`Updated Successfully !`, "success", true);
          dispatch(postDataSuccess(null));
          navigate("/createteam");
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  //updating the team
  const handleUpdate = (e: any) => {
    e.preventDefault();

    var employeeData = {
      team_id: teamDetails[0].team_id,
      employee_ids: empEditId,
    };
    try {
      const response = dispatch2(postData(`${addTeamMember}`, employeeData))
        .then((response) => {
          setCount(count + 1);
          dispatch(postDataSuccess(null));

          handleShowToast(
            `Added Members Updated Successfully !`,
            "success",
            true
          );
          navigate("/createteam");
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        dispatch(setInPageLoading(true));
        const response = await dispatch2(fetchData(`${viewUsers}`))
          .then((response) => {
            setUserDetails(response as any[]);
            dispatch(setInPageLoading(false));
          })
          .catch((error) => {
            console.log(error);
            dispatch(setInPageLoading(false));
          });
      } catch (error) {
        console.log(error);
        dispatch(setInPageLoading(false));
      }
    };
    fetchUsers();
  }, []);

  //shows all the teams
  useEffect(() => {
    const fetchTeamsData = async () => {
      dispatch(setIsLoading(true));
      try {
        const response = await dispatch2(
          fetchData(`${showTeams}?team_id=${projename}`)
        )
          .then((response) => {
            setTeamDetails(response as any[]);
            dispatch(setIsLoading(false));
          })
          .catch((error) => {
            console.log(error);
            dispatch(setIsLoading(false));
          });
      } catch (error) {
        console.log(error);
        dispatch(setIsLoading(false));
      }
    };
    fetchTeamsData();
  }, [count, counts]);

  if (isLoading) {
    return (
      <>
        <Skeletons />
      </>
    );
  }
  return (
    <>
      <Box>
        <Navbar />
      </Box>
      <Box sx={{ mt: 12, mb: 10 }}>
        <Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Typography fontWeight={"bold"} variant="h4">
              Edit Team Details.
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              m: 2,
              p: 2,
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              textColor="inherit"
              // indicatorColor="secondary"
            >
              <Tab
                label="Change Name or Manager"
                {...a11yProps(0)}
                // sx={{ color: "green" }}
              />
              <Tab
                label="Add or delete Employees"
                {...a11yProps(1)}
                // sx={{ color: "green" }}
              />
            </Tabs>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CustomTabPanel value={value} index={0}>
              <Box component={"form"} onSubmit={handleNameUpdate}>
                <Box sx={{ display: "flex", alignItems: "center", p: 1 }}>
                  <Typography paragraph sx={{ p: 0.5, width: "9rem" }}>
                    Project Name :
                  </Typography>
                  <TextField
                    id="projectname"
                    required
                    name="projectName"
                    size="small"
                    variant="outlined"
                    sx={{ m: 0, width: "15rem" }}
                    value={teamDetails[0]?.project_name}
                    onChange={(e) => {
                      handleChange1(e);
                    }}
                    helperText={
                      teamDetails[0]?.project_name === "" && (
                        <Typography color={"error"}>
                          This is required !
                        </Typography>
                      )
                    }
                  />
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", p: 1 }}>
                  <Typography
                    paragraph
                    sx={{ p: 0.5, width: "9rem", color: "black" }}
                  >
                    Manager :
                  </Typography>
                  <Select
                    id="manager"
                    size="small"
                    required
                    // label="manager"
                    name="manager"
                    sx={{ width: "15rem" }}
                    // value={managerId}
                    value={teamDetails[0]?.manager_name || ""}
                    // defaultValue={teamDetails[0]?.manager_name}
                    onChange={(e) => {
                      handleId(e);
                    }}
                  >
                    {usersDetails
                      .filter((manager) =>
                        manager.roles.some(
                          (data: string) => data.toLowerCase() === "manager" //|| "hr"
                        )
                      )

                      .map((data) => {
                        return (
                          <MenuItem
                            id={`${data.first_name} ${data.last_name}`}
                            key={data.employee_code}
                            value={`${data.first_name} ${data.last_name}`}
                            // onChange={(e) => {
                            // 	handleId(e, data.id);
                            // }}
                          >
                            {data.first_name} {data.last_name}(
                            {data.employee_code})
                          </MenuItem>
                        );
                      })}
                  </Select>
                </Box>
                <Box
                  sx={{ display: "flex", justifyContent: "right", m: 1, p: 0 }}
                >
                  <Button
                    // type="submit"
                    variant="text"
                    size="small"
                    sx={{ m: 1 }}
                    onClick={() => {
                      navigate("/createteam");
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    sx={{ m: 1 }}
                  >
                    Update
                  </Button>
                </Box>
              </Box>
            </CustomTabPanel>
          </Box>
          <Box>
            <CustomTabPanel value={value} index={1}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Box>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Box
                      sx={{
                        // display: "flex",
                        // alignItems: "center",
                        p: 2,
                        m: 2,
                      }}
                    >
                      <Typography
                        fontWeight={"bold"}
                        paragraph
                        sx={{ p: 0.0, width: "11rem" }}
                      >
                        Existing Employees :
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          width: "fit-content",
                          m: 2,
                          p: 2,
                        }}
                      >
                        <TableContainer component={Paper}>
                          <TableHead className="header-background">
                            <TableCell
                              sx={{ textAlign: "center", fontWeight: "bold" }}
                            >
                              Sr. No.
                            </TableCell>
                            <TableCell
                              sx={{ textAlign: "center", fontWeight: "bold" }}
                            >
                              Name
                            </TableCell>
                            <TableCell
                              sx={{ textAlign: "center", fontWeight: "bold" }}
                            >
                              Delete
                            </TableCell>
                          </TableHead>

                          {teamDetails[0]?.team?.map(
                            (emp: any, index: number) => {
                              return (
                                <TableBody>
                                  <TableCell sx={{ textAlign: "center" }}>
                                    {index + 1 + ") "}
                                  </TableCell>
                                  <TableCell sx={{ textAlign: "center" }}>
                                    {capitalizeFirstLetter(emp.employee_name)}
                                  </TableCell>
                                  <TableCell sx={{ textAlign: "center" }}>
                                    <Box sx={{ ml: 1 }}>
                                      <Button
                                        onClick={(e) => {
                                          setDeleteEmployee(index);
                                          // handleEmployeeDelete(
                                          //   e,
                                          //   emp.employee_id,
                                          //   index
                                          // );
                                        }}
                                      >
                                        Delete
                                      </Button>
                                      <Dialog
                                        open={deleteEmployee === index}
                                        onClose={() => setDeleteEmployee(null)}
                                        sx={{
                                          m: 1,
                                          "& .MuiDialog-paper": {
                                            width: "30rem",
                                          },
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "flex-end",
                                          }}
                                        >
                                          <IconButton
                                            id="close"
                                            onClick={() =>
                                              setDeleteEmployee(null)
                                            }
                                          >
                                            <CloseIcon />
                                          </IconButton>
                                        </Box>
                                        <DialogTitle>Confirmation</DialogTitle>
                                        <DialogContent>
                                          <Typography>
                                            Do you want to delete?
                                          </Typography>
                                        </DialogContent>
                                        <DialogActions>
                                          <Button
                                            id="no"
                                            onClick={() =>
                                              setDeleteEmployee(null)
                                            }
                                          >
                                            No
                                          </Button>
                                          <Button
                                            id="yes"
                                            variant="contained"
                                            onClick={(e) => {
                                              handleEmployeeDelete(
                                                e,
                                                emp.employee_id,
                                                index
                                              );
                                              setDeleteEmployee(null);
                                            }}
                                          >
                                            Yes
                                          </Button>
                                        </DialogActions>
                                      </Dialog>
                                    </Box>
                                  </TableCell>
                                </TableBody>
                              );
                            }
                          )}
                        </TableContainer>
                      </Box>
                    </Box>
                  </Box>
                  <Box component={"form"} onSubmit={handleUpdate}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        m: 2,
                        p: 2,
                      }}
                    >
                      <Typography
                        fontWeight={"bold"}
                        paragraph
                        sx={{
                          p: 0.5,
                          width: "11rem",
                        }}
                      >
                        Add Employee :{" "}
                      </Typography>

                      <Select
                        id="details"
                        required
                        size="small"
                        // label="employee"
                        name="employee"
                        // defaultValue={editEmployee}
                        sx={{ width: "15rem" }}
                        onChange={(e: any) => {
                          // handleEditEmployeeId(e);
                          handleEditEmployee(e);
                        }}
                      >
                        {usersDetails
                          .filter(
                            (employee) =>
                              employee.roles.some(
                                (data: string) =>
                                  data.toLowerCase() === "employee" || "hr"
                              ) //forEach((data : string)=> data.toLowerCase() === "employee" || "hr")
                          )
                          .map((data) => {
                            const teamEmployeeIds = teamDetails[0].team.map(
                              (emp: any) => emp.employee_id
                            );

                            if (
                              data.id === teamDetails[0]?.manager ||
                              teamEmployeeIds.includes(data.id) ||
                              empEditId.includes(data.id)
                            ) {
                              return null;
                            }

                            return (
                              <MenuItem
                                id={`${data.first_name} ${data.last_name}`}
                                key={data.id}
                                value={`${data.first_name} ${data.last_name}`}
                                // onChange={(e) => {
                                //   handleEmp(e, data.id);
                                // }}
                              >
                                {data.first_name} {data.last_name}(
                                {data.employee_code})
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </Box>

                    {selectedEditEmployees.length !== 0 && (
                      <Box
                        sx={{
                          m: 1,
                          p: 1,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <TableContainer
                          component={Paper}
                          sx={{
                            width: "fit-content",
                          }}
                        >
                          <TableHead className="header-background">
                            <TableRow>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  fontWeight: "bold",
                                }}
                              >
                                Name
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  fontWeight: "bold",
                                }}
                              >
                                Delete
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          {selectedEditEmployees.map((emp, index) => {
                            return (
                              <TableBody key={index}>
                                <TableRow>
                                  <TableCell
                                    sx={{
                                      textAlign: "center",
                                    }}
                                  >
                                    {emp}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      textAlign: "center",
                                    }}
                                  >
                                    <Box>
                                      <Button
                                        size="small"
                                        onClick={(e) => handleDelete2(e, index)}
                                      >
                                        Delete
                                      </Button>
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            );
                          })}
                        </TableContainer>
                      </Box>
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "right",
                        m: 2,
                        p: 2,
                      }}
                    >
                      <Button
                        size="small"
                        sx={{ m: 1 }}
                        onClick={() => navigate("/createteam")}
                      >
                        Back
                      </Button>
                      <Button
                        size="small"
                        sx={{ m: 1 }}
                        variant="contained"
                        type="submit"
                      >
                        Update
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </CustomTabPanel>
          </Box>
        </Box>
      </Box>
      <Box>
        <Footer />
      </Box>
    </>
  );
};

export default EditTeam;
