import React, { useState, useEffect } from "react";
import {
	LineChart,
	Line,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	ResponsiveContainer,
	Legend,
} from "recharts";
import {
	Box,
	Typography,
	Select,
	MenuItem,
	Checkbox,
	FormControl,
	InputLabel,
	ListItemText,
	OutlinedInput,
	Button,
	Divider,
	SelectChangeEvent,
} from "@mui/material";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../../store/Store";
import {
	fetchData,
	FetchDataFailureAction,
	FetchDataSuccessAction,
} from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import {
	getAllDesignations,
	getAllEmployeesAndTeams,
	getPreviousAppraisalData,
} from "../../Service/Apis";
import Navbar from "../../components/Page Component/Navbar";
import Footer from "../../components/Page Component/Footer";

// Update the interface to include all ratings
interface HistoricalData {
	employee_name: string;
	form_name: string;
	team_name: string;
	employee_rating: number;
	manager_average_rating: number | null;
	admin_average_rating: number | null;
	standard_deviation: number | null;
	manager_name: string;
}

interface Employee {
	id: string;
	first_name: string;
	last_name: string;
	email: string;
	designation: string | null;
}

interface Team {
	id: string;
	name: string;
}

const CustomTooltip = ({ active, payload, label }: any) => {
	if (active && payload && payload.length) {
		return (
			<Box
				sx={{
					bgcolor: "white",
					p: 2,
					border: "1px solid #ccc",
					boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
				}}
			>
				<Typography variant="subtitle1" sx={{ mb: 1, fontWeight: "bold" }}>
					{label}
				</Typography>
				{payload.map((item: any) => {
					const employeeName = item.dataKey;
					const employeeData = item.payload.data[employeeName];

					if (!employeeData) return null;

					return (
						<Box key={employeeName} sx={{ mb: 1.5 }}>
							<Typography style={{ color: item.color, fontWeight: 500 }}>
								{`${employeeName}: ${item.value || "No Rating"}`}
							</Typography>
							<Typography
								sx={{ color: "text.secondary", fontSize: "0.875rem" }}
							>
								{`Manager Rating: ${
									employeeData.manager_rating || "Not Rated"
								}`}
							</Typography>
							<Typography
								sx={{ color: "text.secondary", fontSize: "0.875rem" }}
							>
								{`Manager: ${employeeData.manager_name || "Not Assigned"}`}
							</Typography>
							<Typography
								sx={{ color: "text.secondary", fontSize: "0.875rem" }}
							>
								{`Team: ${employeeData.team_name}`}
							</Typography>
						</Box>
					);
				})}
			</Box>
		);
	}
	return null;
};

interface Designation {
	id: string;
	name: string;
}

// Update the chart component's render method
const HistoricalAppraisalChart: React.FC = () => {
	const [data, setData] = useState<HistoricalData[]>([]); // Initialize with empty array
	const [selectedTeams, setSelectedTeams] = useState<string[]>(["all"]);
	const [selectedEmployees, setSelectedEmployees] = useState<string[]>([]);
	const [teams, setTeams] = useState<Team[]>([]);
	const [employeesByTeam, setEmployeesByTeam] = useState<{
		[key: string]: Employee[];
	}>({});
	const [availableEmployees, setAvailableEmployees] = useState<Employee[]>([]);
	const [designations, setDesignations] = useState<Designation[]>([]);
	const [selectedDesignations, setSelectedDesignations] = useState<string[]>(
		[]
	);

	const dispatch2: ThunkDispatch<
		RootState,
		null,
		FetchDataSuccessAction | FetchDataFailureAction
	> = useDispatch();
	const role = useSelector((state: any) => state.auth.selectedRole);

	useEffect(() => {
		// Fetch teams and employees data
		fetchTeamsAndEmployees();
	}, []);

	useEffect(() => {
		// Fetch historical data with filters
		if (selectedEmployees.length > 0) {
			fetchHistoricalData();
		}
	}, [selectedTeams, selectedEmployees, selectedDesignations]);

	useEffect(() => {
		updateAvailableEmployees();
	}, [selectedTeams, employeesByTeam]);

	const updateAvailableEmployees = () => {
		if (selectedTeams.includes("all")) {
			setAvailableEmployees(employeesByTeam["all"] || []);
		} else {
			const employees = selectedTeams.flatMap(
				(teamId) => employeesByTeam[teamId] || []
			);
			setAvailableEmployees([
				...new Map(employees.map((item) => [item.id, item])).values(),
			]);
		}
	};

	const fetchHistoricalData = async () => {
		try {
			if (selectedEmployees.length === 0) {
				return; // Don't fetch if no selections
			}

			const params = new URLSearchParams();

			// Only add team_ids if not "all" is selected
			if (!selectedTeams.includes("all")) {
				selectedTeams.forEach((team) => {
					if (team && team !== "undefined") {
						params.append("team_ids", team);
					}
				});
			}

			// Filter out undefined and validate employee IDs
			selectedEmployees.forEach((emp) => {
				if (emp && emp !== "undefined") {
					params.append("employee_ids", emp);
				}
			});
			// Filter out undefined and validate designation IDs
			selectedDesignations.forEach((desig) => {
				if (desig && desig !== "undefined") {
					params.append("designation_ids", desig);
				}
			});

			params.append("role", role);

			const response = await dispatch2(
				fetchData(`${getPreviousAppraisalData}?${params}`)
			);

			if (response && Array.isArray(response)) {
				setData(response);
			} else {
				console.error("Invalid response format:", response);
				setData([]);
			}
		} catch (error) {
			console.error("Error fetching historical data:", error);
			setData([]);
		}
	};

	// Update the fetchTeamsAndEmployees function
	const fetchTeamsAndEmployees = async () => {
		try {
			const response = await dispatch2(
				fetchData(`${getAllEmployeesAndTeams}?role=${role}`)
			);

			if (response?.teams && response?.employeesByTeam) {
				setTeams(response.teams);
				setEmployeesByTeam(response.employeesByTeam);
				setDesignations(response.designations || []);

				// Select all employees from 'all' team by default
				const allEmployees = response.employeesByTeam["all"] || [];
				interface SelectChangeEvent<T> {
					target: {
						value: T;
					};
				}

				interface EmployeeId {
					id: string;
				}

				// Selection code with types:
				setSelectedEmployees(allEmployees.map((emp: Employee) => emp.id));
			}
		} catch (error) {
			console.error("Error fetching teams and employees:", error);
		}
	};

	const handleTeamChange = (event: SelectChangeEvent<string[]>) => {
		const newSelectedTeams = event.target.value as string[];

		// If "all" is currently selected and user selects another team
		if (selectedTeams.includes("all") && newSelectedTeams.length > 1) {
			const teamsWithoutAll = newSelectedTeams.filter((team) => team !== "all");
			setSelectedTeams(teamsWithoutAll);

			// Select all employees from the selected teams
			const employeesFromSelectedTeams = teamsWithoutAll.flatMap(
				(teamId) => employeesByTeam[teamId] || []
			);
			const uniqueEmployees = [
				...new Map(
					employeesFromSelectedTeams.map((emp) => [emp.id, emp])
				).values(),
			];
			setSelectedEmployees(uniqueEmployees.map((emp) => emp.id));
			return;
		}

		// If user selects "all"
		if (newSelectedTeams.includes("all")) {
			setSelectedTeams(["all"]);
			const allEmployees = employeesByTeam["all"] || [];
			setSelectedEmployees(allEmployees.map((emp) => emp.id));
			return;
		}

		// Normal team selection
		setSelectedTeams(newSelectedTeams);
		const employeesFromTeams = newSelectedTeams.flatMap(
			(teamId) => employeesByTeam[teamId] || []
		);
		const uniqueEmployees = [
			...new Map(employeesFromTeams.map((emp) => [emp.id, emp])).values(),
		];
		setSelectedEmployees(uniqueEmployees.map((emp) => emp.id));
	};

	// Update the toggleAllEmployees function
	const toggleAllEmployees = (event: React.MouseEvent) => {
		event.stopPropagation(); // Prevent the MenuItem's default click behavior
	};

	const getFormattedData = (rawData: HistoricalData[]) => {
		if (!Array.isArray(rawData) || rawData.length === 0) {
			return [];
		}

		// Group by form name and team name combination
		const formGroups = rawData.reduce((acc: any, curr: any) => {
			if (!curr.form_name) return acc;

			// Create a unique key combining form name and team name
			const key = `${curr.form_name} (${curr.team_name})`;

			if (!acc[key]) {
				acc[key] = {
					form_name: key, // Use the combined name as form_name
					data: {}, // Store all employee data here
				};
			}

			// Store complete employee data
			acc[key].data[curr.employee_name] = {
				employee_rating: curr.employee_rating,
				manager_rating: curr.manager_average_rating,
				manager_name: curr.manager_name,
				team_name: curr.team_name,
				employee_name: curr.employee_name,
			};

			// Also store the rating directly for the Line component
			acc[key][curr.employee_name] = curr.employee_rating;

			return acc;
		}, {});

		return Object.values(formGroups);
	};
	const getActiveEmployees = (
		formattedData: any[],
		selectedEmployees: string[]
	) => {
		const activeEmployees = new Map<string, boolean>();

		// Check which employees have actual data points
		formattedData.forEach((item) => {
			Object.entries(item).forEach(([key, value]) => {
				if (key !== "form_name" && key !== "data" && value !== null) {
					activeEmployees.set(key, true);
				}
			});
		});
		return selectedEmployees.filter((empId) => {
			const employee = availableEmployees.find((e) => e.id === empId);
			if (!employee) return false;
			const employeeName = `${employee.first_name} ${employee.last_name}`;
			return activeEmployees.has(employeeName);
		});
	};
	return (
		<>
			<Box>
				<Navbar />
			</Box>
			<Box sx={{ p: 3, mt: 12 }}>
				{role !== "Employee" ? (
					<Box sx={{ mb: 3, display: "flex", gap: 2 }}>
						<FormControl sx={{ width: 300 }}>
							<InputLabel>Teams</InputLabel>
							<Select
								multiple
								value={selectedTeams}
								onChange={handleTeamChange}
								input={<OutlinedInput label="Teams" />}
								renderValue={(selected) => {
									return teams
										.filter((team) => selected.includes(team.id))
										.map((team) => team.name)
										.join(", ");
								}}
							>
								{teams.map((team) => (
									<MenuItem key={team.id} value={team.id}>
										<Checkbox checked={selectedTeams.includes(team.id)} />
										<ListItemText primary={team.name} />
									</MenuItem>
								))}
							</Select>
						</FormControl>

						<Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
							<FormControl sx={{ width: 300 }}>
								<InputLabel>Employees</InputLabel>
								<Select
									multiple
									value={selectedEmployees}
									onChange={(e) => {
										const value = e.target.value as string[];
										// Check if the selection includes our special "select-all" value
										if (value.includes("select-all")) {
											if (
												selectedEmployees.length === availableEmployees.length
											) {
												setSelectedEmployees([]); // Deselect all
											} else {
												const allEmployeeIds = availableEmployees.map(
													(emp) => emp.id
												);
												setSelectedEmployees(allEmployeeIds); // Select all
											}
										} else {
											setSelectedEmployees(value);
										}
									}}
									input={<OutlinedInput label="Employees" />}
									renderValue={(selected) => {
										if (!Array.isArray(availableEmployees)) return "";
										return availableEmployees
											.filter((emp) => selected.includes(emp.id))
											.map((emp) => `${emp.first_name} ${emp.last_name}`)
											.join(", ");
									}}
								>
									<MenuItem
										value="select-all"
										sx={{ borderBottom: "1px solid #ddd" }}
									>
										<Checkbox
											checked={
												availableEmployees.length > 0 &&
												selectedEmployees.length === availableEmployees.length
											}
											indeterminate={
												selectedEmployees.length > 0 &&
												selectedEmployees.length < availableEmployees.length
											}
										/>
										<ListItemText
											primary={
												selectedEmployees.length === availableEmployees.length
													? "Deselect All Employees"
													: "Select All Employees"
											}
										/>
									</MenuItem>
									<Divider />
									{availableEmployees.map((emp) => (
										<MenuItem key={emp.id} value={emp.id}>
											<Checkbox checked={selectedEmployees.includes(emp.id)} />
											<ListItemText
												primary={`${emp.first_name} ${emp.last_name}`}
											/>
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Box>
						<FormControl sx={{ width: 300 }}>
							<InputLabel>Designations</InputLabel>
							<Select
								multiple
								value={selectedDesignations}
								onChange={(e) => {
									const value = e.target.value as string[];
									// Check if the selection includes our special "select-all" value
									if (value.includes("select-all")) {
										if (selectedDesignations.length === designations.length) {
											setSelectedDesignations([]); // Deselect all
										} else {
											const allDesignationIds = designations.map((d) => d.id);
											setSelectedDesignations(allDesignationIds); // Select all
										}
									} else {
										setSelectedDesignations(value);
									}
								}}
								input={<OutlinedInput label="Designations" />}
								renderValue={(selected) => {
									return designations
										.filter((d) => selected.includes(d.id))
										.map((d) => d.name)
										.join(", ");
								}}
							>
								<MenuItem
									value="select-all"
									sx={{ borderBottom: "1px solid #ddd" }}
								>
									<Checkbox
										checked={
											designations.length > 0 &&
											selectedDesignations.length === designations.length
										}
										indeterminate={
											selectedDesignations.length > 0 &&
											selectedDesignations.length < designations.length
										}
									/>
									<ListItemText
										primary={
											selectedDesignations.length === designations.length
												? "Deselect All Designations"
												: "Select All Designations"
										}
									/>
								</MenuItem>
								<Divider />
								{designations.map((designation) => (
									<MenuItem key={designation.id} value={designation.id}>
										<Checkbox
											checked={selectedDesignations.includes(designation.id)}
										/>
										<ListItemText primary={designation.name} />
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Box>
				) : (
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							mb: 4,
						}}
					>
						<Typography
							variant="h4"
							component="h1"
							sx={{
								fontWeight: 600,
								color: "primary.main",
								borderColor: "primary.main",
								paddingBottom: 1,
								textAlign: "center",
							}}
						>
							Your Past Appraisals
						</Typography>
					</Box>
				)}
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						width: "100%",
						mt: 4,
					}}
				>
					<ResponsiveContainer width="90%" height={500}>
						<LineChart data={getFormattedData(data)}>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="form_name" />
							<YAxis
								label={{ value: "Rating", angle: -90, position: "insideLeft" }}
								domain={[0, 5]}
								tickCount={6}
							/>
							<Tooltip content={<CustomTooltip />} />
							<Legend
								content={({ payload }) => {
									// Filter out payload items that don't have data
									const activePayload = payload?.filter((entry) => {
										const employeeName = entry.value;
										// Check if this employee has any non-null data points
										return getFormattedData(data).some((item: unknown) => {
											const formattedItem = item as { [key: string]: any };
											return (
												formattedItem[employeeName] !== null &&
												formattedItem[employeeName] !== undefined
											);
										});
									});

									return (
										<Box
											sx={{
												width: "100%",
												display: "flex",
												justifyContent: "center",
												padding: "10px 0",
											}}
										>
											<ul
												style={{
													listStyle: "none",
													padding: 0,
													margin: 0,
													display: "flex",
													flexWrap: "wrap",
													justifyContent: "center",
													gap: "15px",
												}}
											>
												{activePayload?.map((entry, index) => (
													<li
														key={`item-${index}`}
														style={{
															display: "inline-flex",
															alignItems: "center",
															color: entry.color,
														}}
													>
														<span
															style={{
																display: "inline-block",
																width: 10,
																height: 10,
																backgroundColor: entry.color,
																marginRight: 5,
															}}
														/>
														{entry.value}
													</li>
												))}
											</ul>
										</Box>
									);
								}}
							/>
							{Array.isArray(availableEmployees) &&
								availableEmployees.length > 0 &&
								getActiveEmployees(
									getFormattedData(data),
									selectedEmployees
								).map((empId, index) => {
									const employee = availableEmployees.find(
										(e) => e.id === empId
									);
									if (!employee) return null;

									const employeeName = `${employee.first_name} ${employee.last_name}`;
									return (
										<Line
											key={empId}
											type="monotone"
											dataKey={employeeName}
											stroke={`hsl(${index * 137.5}, 70%, 50%)`}
											name={employeeName}
											strokeWidth={2}
											dot={{ r: 4 }}
											activeDot={{ r: 6 }}
											connectNulls
										/>
									);
								})}
						</LineChart>
					</ResponsiveContainer>
				</Box>
			</Box>
			<Box>
				<Footer />
			</Box>
		</>
	);
};

export default HistoricalAppraisalChart;
