import React, { FormEvent, useEffect, useState } from "react";

import { Box, maxWidth } from "@mui/system";
import {
	Button,
	Divider,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
	Rating,
	TextField,
	Typography,
} from "@mui/material";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
	AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import { enqueueSnackbar } from "notistack";

import axios from "axios";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import useAxiosInterceptor from "../../components/layout/AxiosInstance";

import {
	managerFinalSubmit,
	managerReview,
	viewResponseManager,
	viewResponseManagerSpecific,
	getPreviousAppraisalData,
} from "../../Service/Apis";
import { labels } from "../Dashboard/ReviewForm";
import Navbar from "../../components/Page Component/Navbar";
import Footer from "../../components/Page Component/Footer";
import {
	ToastVariant,
	useCustomToast,
} from "../../components/Methods/SnackBar";
import {
	FetchDataFailureAction,
	FetchDataSuccessAction,
	fetchData,
	patchData,
	postData,
	putData,
} from "../../store/actions";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../../store/Store";
import Skeletons from "../../components/Skeleton/Skeletons";
import { postDataSuccess } from "../../store/AxiosSlice";
import Modal from "@mui/material/Modal";
import PerformanceGraph from "./PerformanceGraph";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

interface Data {
	team_id: string;
	project_name: string;
	manager_name: string;
	forms: [
		{
			form_id: string;
			form_name: string;
			form_description: string;
			is_hierarchical: boolean;
			is_appraisal: boolean;
			responses: {
				response_id: string;
				employee_id: string;
				employee_code: string;
				employee_name: string;
				employee_designation: string;
				final_submit_done: boolean;
				manager_final_submit_done: boolean;
				response: [
					{
						section_id: string;
						section: string;
						categories: [
							{
								category_id: string;
								category: string;
								questions: [
									{
										question_id: string;
										question_name: string;
										response_question_id: string;
										answer_type: string;
										employee_rating: string;
										employee_response: string;
										employee_comment: string;
										manager_response: string;
										manager_comment: string;
									}
								];
							}
						];
					}
				];
			};
		}
	];
}

interface Response {
	sections: Section[];
}
interface Section {
	section_id: string;
	section: string;
	categories: Category[];
}

interface Category {
	category_id: string;
	category: string;
	questions: Question[];
}

interface Question {
	question_id: string;
	manager_response: string;
	manager_comment: string;
}
interface Response1 {
	section_id: string;
	category_id: string;
	question_id: string;
	manager_response: string;
	manager_comment: string;
}
const initialResponse: Response = {
	sections: [],
};
interface SpecialCharErrors {
	[sectionIndex: number]: {
		[categoryIndex: number]: {
			[questionIndex: number]: {
				employee_response: boolean;
				employee_comment: boolean;
			};
		};
	};
}

interface MinCharacterError {
	[sectionIndex: number]: {
		[categoryIndex: number]: {
			[questionIndex: number]: {
				employee_response: boolean;
				employee_comment: boolean;
			};
		};
	};
}

interface SpacesErrors {
	[sectionIndex: number]: {
		[categoryIndex: number]: {
			[questionIndex: number]: {
				employee_response: boolean;
				employee_comment: boolean;
			};
		};
	};
}

interface HoverEffect {
	[sectionIndex: number]: {
		[categoryIndex: number]: {
			[questionIndex: number]: {
				employee_rating: number;
			};
		};
	};
}
const initialSpecialCharErrors: SpecialCharErrors = {};
const initialMinCharacterErrors: MinCharacterError = {};
const initialSpacesErrors: SpacesErrors = {};
const initialHoverEffect: HoverEffect = {};

const Accordion = styled((props: AccordionProps) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	border: `1px solid ${theme.palette.divider}`,
	"&:not(:last-child)": {
		borderBottom: 0,
	},
	"&:before": {
		display: "none",
	},
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
	<MuiAccordionSummary
		expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
		{...props}
	/>
))(({ theme }) => ({
	backgroundColor:
		theme.palette.mode === "dark"
			? "rgba(255, 255, 255, .05)"
			: "rgba(0, 0, 0, .00)",
	flexDirection: "row-reverse",
	"& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
		transform: "rotate(90deg)",
	},
	"& .MuiAccordionSummary-content": {
		marginLeft: theme.spacing(1),
	},
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(2),
	borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const ManagerAppraisal = () => {
	const [response, setResponse] = useState<Data[]>([]);

	const [managerResponse, setManagerResponse] = useState<Response>({
		sections:
			response?.[0]?.forms?.[0]?.responses?.response?.flatMap((res) => ({
				section_id: "",
				section: "",
				categories: res.categories.map((category) => ({
					category_id: "",
					category: "",
					questions: category.questions.map((question) => ({
						question_id: question.question_id,
						manager_response: "",
						manager_comment: "",
					})),
				})),
			})) ?? [], // Provide an empty array as the default value if response is not available
	});

	const [empRating, setEmpRating] = useState<boolean[][][]>([]);
	const [hoverEffect, setHoverEffect] =
		useState<HoverEffect>(initialHoverEffect);
	const [value, setValue] = useState<number | null>(0);
	const [flag, setFlag] = useState<boolean>(false);

	const response_id =
		useSelector((state: any) => state.form.resId) ||
		localStorage.getItem("resId");
	const axiosInstance = useAxiosInterceptor();
	const token = localStorage.getItem("token");
	const dispatch = useDispatch();
	const showToast = useCustomToast();
	const dispatch2: ThunkDispatch<
		RootState,
		null,
		FetchDataSuccessAction | FetchDataFailureAction
	> = useDispatch();

	const Navigate = useNavigate();

	const [openModal, setOpenModal] = useState(false);
	const [performanceData, setPerformanceData] = useState<any[]>([]);

	// const success = useSelector((state: any) => state.http.data);

	const isLoading = useSelector((state: any) => state.loading.isLoading);

	const setManagerResponseState = (response: Data[] | undefined) => {
		if (!response || !response[0]?.forms?.[0]?.responses?.response) {
			// If response is not available or its nested properties are not present, set an empty array as the state
			setManagerResponse({ sections: [] });
			return;
		}

		const updatedManagerResponse: Response = {
			sections: response[0].forms[0].responses.response.map((res) => ({
				section_id: res.section_id,
				section: res.section,
				categories: res.categories.map((category) => ({
					category_id: category.category_id,
					category: category.category,
					questions: category.questions.map((question) => ({
						question_id: question.question_id,
						manager_response: "",
						manager_comment: "",
					})),
				})),
			})),
		};

		setManagerResponse(updatedManagerResponse);
	};

	const initializeEmpRatingState = (response: Data) => {
		const empRatingArray: boolean[][][] = [];

		response?.forms[0].responses.response.forEach((section, sectionIndex) => {
			const sectionEmpRating: boolean[][] = [];

			section?.categories?.forEach((category, categoryIndex) => {
				const categoryEmpRating: boolean[] = [];

				category?.questions?.forEach((question) => {
					const hasEmployeeRating =
						question?.manager_response !== null &&
						question.manager_response !== "";

					// Initialize the empRating for each question to false
					categoryEmpRating.push(hasEmployeeRating);
				});

				sectionEmpRating.push(categoryEmpRating);
			});

			empRatingArray.push(sectionEmpRating);
		});

		// Set the empRating state using the empRatingArray
		setEmpRating(empRatingArray);
	};

	const handleChange = (
		sectionIndex: number,
		categoryIndex: number,
		questionIndex: number,
		field: keyof Question,
		value: string,
		answer_type: string
	): void => {
		setResponse((prevResponses) => {
			const updatedResponses = [...prevResponses];

			updatedResponses[0].forms[0].responses.response[sectionIndex].categories[
				categoryIndex
			].questions[questionIndex][field] = value;

			// Update manager's response and comment
			// updatedResponses[0].forms[0].responses[0].response[0].section[
			//   sectionIndex
			// ].categories[categoryIndex].questions[questionIndex].manager_response =
			//   "New manager response";

			// updatedResponses[0].forms[0].responses[0].response[0].section[
			//   sectionIndex
			// ].categories[categoryIndex].questions[questionIndex].manager_comment =
			//   "New manager comment";

			return updatedResponses;
		});
	};

	const handleShowToast = (
		message: string,
		variant: ToastVariant,
		isCloseable: boolean
	) => {
		showToast(message, variant, isCloseable);
	};
	const role = useSelector((state: any) => state.auth.selectedRole);
	const handleSubmit = (e: any) => {
		e.preventDefault();

		const isEveryEmpRatingTrue = empRating.every((section) => {
			return section.every((category) => {
				return category.every((questionEmpRating) => {
					return questionEmpRating === true;
				});
			});
		});

		// Now you can use isEveryEmpRatingTrue to conditionally render content or take any other action based on the empRating state.
		if (!isEveryEmpRatingTrue) {
			setFlag(true);
			enqueueSnackbar("Please fill all details", {
				variant: "error",
				anchorOrigin: { vertical: "top", horizontal: "left" },
				autoHideDuration: 1500,
			});
			return;
		}

		handleSave(e);

		try {
			const response = dispatch2(
				patchData(`${managerFinalSubmit}${response_id}`, {})
			)
				.then((response) => {
					handleShowToast("Response Submitted", "success", true);
					dispatch(postDataSuccess(null));
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	};

	const handleSave = (e: any) => {
		e.preventDefault();

		const mappedData: Response1[] =
			response[0]?.forms[0].responses.response.flatMap((section: Section) =>
				section.categories.flatMap((category: Category) =>
					category.questions.map((question: Question) => ({
						section_id: section.section_id,
						category_id: category.category_id,
						question_id: question.question_id,
						manager_response: question.manager_response,
						manager_comment: question.manager_comment,
					}))
				)
			);

		try {
			const response = dispatch2(
				patchData(`${managerReview}${response_id}`, mappedData)
			)
				.then((response) => {
					handleShowToast("Response Saved", "success", true);
					dispatch(postDataSuccess(null));

					Navigate("/dashboard");
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	};

	const fetchPastPerformance = async () => {
		try {
			const params = new URLSearchParams();
			params.append(
				"employee_ids",
				response[0]?.forms[0]?.responses?.employee_id
			);
			params.append("role", "hr");

			const performanceResponse = await dispatch2(
				fetchData(`${getPreviousAppraisalData}?${params}`)
			);

			if (performanceResponse && Array.isArray(performanceResponse)) {
				setPerformanceData(performanceResponse);
				setOpenModal(true);
			}
		} catch (error) {
			console.error("Error fetching past performance:", error);
		}
	};

	const modalStyle = {
		position: "absolute" as "absolute",
		top: "50%",
		left: "50%",
		maxWidth: "80%",
		transform: "translate(-50%, -50%)",
		width: "80%",
		bgcolor: "background.paper",
		boxShadow: 24,
		p: 4,
		borderRadius: 2,
	};

	useEffect(() => {
		const fetchManagerData = async () => {
			try {
				const response = await dispatch2(
					fetchData(`${viewResponseManager}?response_id=${response_id}`)
				);
				setResponse(response as Data[]);

				setManagerResponseState(response as Data[]);
			} catch (error) {
				console.log(error);
			}
		};
		fetchManagerData();
	}, []);

	useEffect(() => {
		if (response) {
			initializeEmpRatingState(response[0]);
		}
	}, [response]);

	if (isLoading) {
		return <Skeletons />;
	}
	return (
		<>
			<Box>
				<Navbar />
			</Box>
			<Box
				sx={{
					mt: 12,
					mb: 10,
					// alignContent: "center",
					justifyContent: { xs: "center", sm: "center", md: "center" },
					display: "flex",
				}}
			>
				{response.map((res: Data, formIndex: number) => (
					<Box
						component="form"
						onSubmit={(e) => {
							handleSubmit(e);
							// setFormTeamId(res.teams[0].form_team_id);
						}}
						sx={{ width: "75%" }}
						key={res.team_id}
					>
						<FormControl>
							<Box
								sx={{
									mt: 1,
									pt: 2,
									display: "flex",
									justifyContent: "center",
								}}
							>
								<Typography
									fontWeight={"bold"}
									letterSpacing={3}
									variant="h4"
									sx={{ textDecoration: "underline" }}
								>
									{res.forms[0]?.form_name}
								</Typography>
							</Box>
							<Box
								sx={{
									m: 1,
									//   mt: 2,
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<Typography variant="h5">
									{res.forms[0].form_description
										? `(${res.forms[0].form_description})`
										: ""}
								</Typography>
							</Box>
							<Box
								sx={{ m: 2, display: "flex", justifyContent: "space-between" }}
							>
								<Box>
									<Box sx={{ display: "flex", alignItems: "center", m: 1 }}>
										<Typography sx={{ width: "9rem" }} fontWeight={"bold"}>
											Appraisee :{" "}
										</Typography>
										<Typography>
											{res.forms[0].responses.employee_name}{" "}
										</Typography>
									</Box>
									<Box sx={{ display: "flex", alignItems: "center", m: 1 }}>
										<Typography sx={{ width: "9rem" }} fontWeight={"bold"}>
											Manager Name :{" "}
										</Typography>
										<Typography>{res.manager_name}</Typography>
									</Box>
								</Box>
								<Box>
									<Box sx={{ display: "flex", alignItems: "center", m: 1 }}>
										<Typography sx={{ width: "9rem" }} fontWeight={"bold"}>
											Employee Code :{" "}
										</Typography>
										<Typography>
											{res.forms[0].responses.employee_code}
										</Typography>
									</Box>
									<Box sx={{ display: "flex", alignItems: "center", m: 1 }}>
										<Typography sx={{ width: "9rem" }} fontWeight={"bold"}>
											Designation :{" "}
										</Typography>
										<Typography>
											{res.forms[0].responses.employee_designation}
										</Typography>
									</Box>
								</Box>
							</Box>
							<Box sx={{ display: "flex", justifyContent: "center", m: 2 }}>
								<Button
									variant="contained"
									color="primary"
									onClick={fetchPastPerformance}
								>
									Show Past Performances
								</Button>
							</Box>
							<Divider />
							<Box>
								{res.forms[0].responses.response.map(
									(section: any, sectionIndex: number) => {
										return (
											<Box sx={{ m: 2, width: "75vw" }}>
												<Accordion sx={{ m: 2, p: 0 }}>
													<AccordionSummary sx={{ width: "100%" }}>
														<Box sx={{}}>
															<Typography
																id={`section_${sectionIndex + 1}`}
																variant="h5"
																fontWeight={"bold"}
															>
																{String.fromCharCode(
																	sectionIndex + "A".charCodeAt(0)
																) +
																	") " +
																	section.section}
															</Typography>
														</Box>
													</AccordionSummary>
													<AccordionDetails>
														<Box sx={{ m: 2, p: 2 }}>
															{section.categories.map(
																(category: any, categoryIndex: number) => {
																	return (
																		<Box>
																			<Box>
																				<Typography
																					id={`section_${
																						sectionIndex + 1
																					}_category_${categoryIndex + 1}`}
																					variant="h5"
																					fontWeight={"bold"}
																				>
																					{String.fromCharCode(
																						categoryIndex + "a".charCodeAt(0)
																					) +
																						". " +
																						category.category}
																				</Typography>
																			</Box>
																			<Box sx={{ m: 2, p: 2 }}>
																				{category.questions.map(
																					(
																						question: any,
																						questionIndex: number
																					) => {
																						return (
																							<Box key={question.question_id}>
																								<Box>
																									<Typography
																										id={`section_${
																											sectionIndex + 1
																										}_category_${
																											categoryIndex + 1
																										}_question_${
																											questionIndex + 1
																										}`}
																										fontWeight={"bold"}
																										fontSize={19}
																										variant="h6"
																									>
																										{questionIndex + 1 + "."}{" "}
																										{question.question_name}
																									</Typography>
																								</Box>
																								<Box sx={{ ml: 2, mr: 2 }}>
																									{question.answer_type ===
																									"short_answer" ? (
																										<Box>
																											<TextField
																												required
																												id={`employee_response_section_${
																													sectionIndex + 1
																												}_category_${
																													categoryIndex + 1
																												}_question_${
																													questionIndex + 1
																												}`}
																												name={`employee_response`}
																												size="small"
																												fullWidth
																												multiline
																												maxRows={4}
																												inputProps={{
																													readonly: "true",
																												}}
																												value={
																													question.employee_response
																												}
																												// rows={1}
																												sx={{
																													m: 2,
																													overflow: "auto",
																												}}
																												style={{
																													height: "auto",
																												}}
																												InputProps={{
																													style: {
																														minHeight: "unset",
																													},
																												}}
																											/>
																										</Box>
																									) : (
																										<Box>
																											<Box
																												sx={{
																													display: "block",
																													alignItems: "center",
																													m: 1,
																												}}
																											>
																												<RadioGroup
																													id={`employee_response_radio_group_section_${
																														sectionIndex + 1
																													}_category_${
																														categoryIndex + 1
																													}_question_${
																														questionIndex + 1
																													}`}
																													name={`employee_response`}
																													value={
																														question.employee_response
																													}
																												>
																													{question.answer_choices &&
																														question.answer_choices.map(
																															(ans: any) => (
																																<FormControlLabel
																																	id={`employee_response_form_control_section_${
																																		sectionIndex +
																																		1
																																	}_category_${
																																		categoryIndex +
																																		1
																																	}_question_${
																																		questionIndex +
																																		1
																																	}`}
																																	required
																																	key={
																																		ans.answer_id
																																	}
																																	value={
																																		ans.answer_value
																																	}
																																	control={
																																		<Radio
																																			id={`employee_response_radio_button_section_${
																																				sectionIndex +
																																				1
																																			}_category_${
																																				categoryIndex +
																																				1
																																			}_question_${
																																				questionIndex +
																																				1
																																			}`}
																																		/>
																																	}
																																	label={
																																		ans.answer_value
																																	}
																																/>
																															)
																														)}
																												</RadioGroup>
																											</Box>
																											<Box
																												sx={{
																													display: "flex",
																													m: 1,
																													p: 1,
																												}}
																											>
																												<Typography
																													fontWeight={"bold"}
																													paragraph
																													sx={{
																														width: "11rem",
																													}}
																												>
																													Comments :{" "}
																												</Typography>
																												<TextField
																													id={`employee_comment_${
																														sectionIndex + 1
																													}_category_${
																														categoryIndex + 1
																													}_question_${
																														questionIndex + 1
																													}`}
																													name="employee_comment"
																													fullWidth
																													multiline
																													// rows={2}
																													maxRows={4}
																													inputProps={{
																														readonly: "true",
																													}}
																													style={{
																														height: "auto",
																														overflow: "auto",
																													}}
																													value={
																														question.employee_comment
																													}
																												/>
																											</Box>
																										</Box>
																									)}
																									<Box
																										sx={{
																											display: "flex",
																											m: 1,
																											p: 1,
																										}}
																									>
																										<Typography
																											fontWeight={"bold"}
																											paragraph
																											sx={{
																												width: "8.5rem",
																												mt: 0.5,
																											}}
																										>
																											Rating :{" "}
																										</Typography>
																										<Rating
																											id={`employee_rating_${
																												sectionIndex + 1
																											}_category_${
																												categoryIndex + 1
																											}_question_${
																												questionIndex + 1
																											}`}
																											size="large"
																											precision={0.5}
																											value={parseFloat(
																												question.employee_rating
																											)}
																											readOnly
																											className="rating-color"
																											aria-required="true"
																										/>
																									</Box>
																									<Box
																										sx={{
																											display: "flex",
																											m: 1,
																											p: 1,
																										}}
																									>
																										<Typography
																											fontWeight={"bold"}
																											paragraph
																											sx={{
																												width: "8.5rem",
																												mt: 0.5,
																											}}
																										>
																											Manager Rating :{" "}
																										</Typography>
																										<Rating
																											id={`manager_rating_${
																												sectionIndex + 1
																											}_category_${
																												categoryIndex + 1
																											}_question_${
																												questionIndex + 1
																											}`}
																											size="large"
																											precision={0.5}
																											// readOnly={
																											//   res.forms[0].responses
																											//     .manager_final_submit_done
																											// }
																											value={parseFloat(
																												question?.manager_response
																											)}
																											onChangeActive={(
																												event,
																												newHover
																											) => {
																												// Update the hoverEffect state when the user hovers over the question
																												setHoverEffect(
																													(prevHoverEffect) => {
																														const updatedHoverEffect =
																															{
																																...prevHoverEffect,
																															};
																														// Ensure that the nested objects are initialized properly
																														updatedHoverEffect[
																															sectionIndex
																														] =
																															updatedHoverEffect[
																																sectionIndex
																															] || {};
																														updatedHoverEffect[
																															sectionIndex
																														][categoryIndex] =
																															updatedHoverEffect[
																																sectionIndex
																															][
																																categoryIndex
																															] || {};
																														// Set the employee_rating value for the corresponding question
																														updatedHoverEffect[
																															sectionIndex
																														][categoryIndex][
																															questionIndex
																														] = {
																															employee_rating:
																																newHover,
																														};
																														return updatedHoverEffect;
																													}
																												);
																											}}
																											onChange={(
																												e,
																												newValue
																											) => {
																												handleChange(
																													sectionIndex,
																													categoryIndex,
																													questionIndex,
																													// section.section_id,
																													// category.category_id,
																													// question.question_id,
																													"manager_response",
																													newValue?.toString() ||
																														"",
																													question.answer_type
																												);
																											}}
																											aria-required="true"
																										/>
																										{(response[0].forms[0]
																											.responses.response[
																											sectionIndex
																										].categories[categoryIndex]
																											.questions[questionIndex]
																											.manager_response ===
																											"" ||
																											response[0].forms[0]
																												.responses.response[
																												sectionIndex
																											].categories[
																												categoryIndex
																											].questions[questionIndex]
																												.manager_response ===
																												null) &&
																											flag && (
																												<Typography
																													sx={{ m: 0.5 }}
																													color={"error"}
																												>
																													This field is
																													required.
																												</Typography>
																											)}
																										{value !== null && (
																											<Typography
																												sx={{
																													textAlign: "center",
																													ml: 2,
																												}}
																											>
																												{
																													labels[
																														hoverEffect[
																															sectionIndex
																														]?.[
																															categoryIndex
																														]?.[questionIndex]
																															?.employee_rating !==
																														null
																															? hoverEffect[
																																	sectionIndex
																															  ]?.[
																																	categoryIndex
																															  ]?.[
																																	questionIndex
																															  ]
																																	?.employee_rating
																															: value
																													]
																												}
																											</Typography>
																										)}
																									</Box>
																									<Box
																										sx={{
																											display: "flex",
																											m: 1,
																											p: 1,
																										}}
																									>
																										<Typography
																											fontWeight={"bold"}
																											paragraph
																											sx={{ width: "11rem" }}
																										>
																											Manager Comments :{" "}
																										</Typography>
																										<TextField
																											id={`manager_comment_${
																												sectionIndex + 1
																											}_category_${
																												categoryIndex + 1
																											}_question_${
																												questionIndex + 1
																											}`}
																											name="manager_comment"
																											fullWidth
																											multiline
																											maxRows={4}
																											// rows={2}
																											//   inputProps={{
																											//     readonly: `${res.forms[0].responses.manager_final_submit_done}`,
																											//   }}
																											style={{
																												overflow: "auto",
																												height: "auto",
																											}}
																											value={
																												question?.manager_comment
																													? question?.manager_comment
																													: managerResponse
																															?.sections[
																															sectionIndex
																													  ]?.categories[
																															categoryIndex
																													  ]?.questions[
																															questionIndex
																													  ]?.manager_comment
																											}
																											onChange={(e) => {
																												handleChange(
																													sectionIndex,
																													categoryIndex,
																													questionIndex,
																													// section.section_id,
																													// category.category_id,
																													// question.question_id,
																													"manager_comment",
																													e.target.value,
																													question.answer_type
																												);
																											}}
																										/>
																									</Box>
																								</Box>
																							</Box>
																						);
																					}
																				)}
																			</Box>
																		</Box>
																	);
																}
															)}
														</Box>
													</AccordionDetails>
												</Accordion>
											</Box>
										);
									}
								)}
							</Box>

							<Modal
								open={openModal}
								onClose={() => setOpenModal(false)}
								aria-labelledby="past-performance-modal"
							>
								<Box sx={modalStyle}>
									<IconButton
										onClick={() => setOpenModal(false)}
										sx={{
											position: "absolute",
											right: 8,
											top: 8,
											color: "grey.500",
										}}
									>
										<CloseIcon />
									</IconButton>
									<Typography variant="h6" component="h2" sx={{ mb: 2, pr: 4 }}>
										Past Performance History
									</Typography>
									<PerformanceGraph
										data={performanceData}
										employeeName={`${response[0]?.forms[0]?.responses?.employee_name}`}
									/>
								</Box>
							</Modal>
							{/* {!res.forms[0].responses.manager_final_submit_done && ( */}
							<Box
								sx={{ display: "flex", justifyContent: "right", m: 1, p: 1 }}
							>
								<Button
									id="save_button_manager"
									size="large"
									variant="outlined"
									sx={{ m: 1 }}
									onClick={handleSave}
								>
									Save
								</Button>
								{!res.forms[0].responses.manager_final_submit_done && (
									<Button
										id="submit_button_manager"
										type="submit"
										size="large"
										variant="contained"
										sx={{ m: 1 }}
										// disabled={!allRatingsFilled}
									>
										Submit
									</Button>
								)}
							</Box>
							{/*)}*/}
						</FormControl>
					</Box>
				))}
			</Box>
			<Box>
				<Footer />
			</Box>
		</>
	);
};

export default ManagerAppraisal;
