import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Button,
  Card,
  Container,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  Select,
  Step,
  StepLabel,
  Stepper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { format } from "date-fns";

import dayjs, { Dayjs } from "dayjs";

import axios from "axios";
import useAxiosInterceptor from "../../components/layout/AxiosInstance";
import axiosInstance from "../../components/layout/AxiosInstance";

import { useNavigate } from "react-router-dom";

import { enqueueSnackbar } from "notistack";

import { getCurrentDate } from "../../components/Date/Date";
import Navbar from "../../components/Page Component/Navbar";
import Sidebar from "../../components/Page Component/Sidebar";
import { CreateFormUrl, showTeams } from "../../Service/Apis";
import Footer from "../../components/Page Component/Footer";
import Skeletons from "../../components/Skeleton/Skeletons";
import { setIsLoading } from "../../store/LoadingSlice";
import {
  ToastVariant,
  useCustomToast,
} from "../../components/Methods/SnackBar";
import {
  FetchDataFailureAction,
  FetchDataSuccessAction,
  fetchData,
  postData,
  putData,
} from "../../store/actions";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../../store/Store";
import { postDataSuccess } from "../../store/AxiosSlice";
import React from "react";

const theme = createTheme({
  palette: {
    primary: {
      main: "#4da64d", // Customize the green color here
    },
  },
});

interface Field {
  id: number;
  name: string;
  label: string;
  required: boolean;
  question_type: string;
  list: string[];
}

interface Question {
  question: string;
  question_category: string;
  answer_type: string;
}

interface Answer {
  answer_value: string;
}
export const date = getCurrentDate();

const AddForm = () => {
  const [formContent, setFormContent] = useState<Field[]>([
    {
      id: 0,
      name: "0",
      label: "",
      required: false,
      question_type: "short_answer",
      list: [],
    },
  ]);
  const [onEdit, setOnEdit] = useState<boolean>(false);
  const [textField, setTextField] = useState<string>("");
  const [editedField, setEditedField] = useState<string>("");
  const [createForm, setCreateForm] = useState({
    form_name: "",
    form_description: "",
    created_by: "",
    employee_deadline: "",
    manager_deadline: "",
  });
  const [question_array, setQuestionArray] = useState<Question[]>([]);
  const [ans_array, setAns_Array] = useState<Answer[]>([]);
  const [empDate, setEmpDate] = useState<Dayjs | null>(dayjs(`${date}`));
  const [managerDate, setManagerDate] = useState<Dayjs | null>(
    dayjs(`${date}`)
  );
  const [questionCategory, setQuestionCategory] = useState<string[]>([]);
  const [questions, setQuestions] = useState({
    question: "",
    question_category: "",
    answer_type: "",
  });
  const [answer, setAnswer] = useState({
    answer_value: "",
  });
  const [activeStep, setActiveStep] = useState(0);

  const [getTeamsData, setGetTeamsData] = useState([
    {
      team_id: "",
      manager: "",
      manager_name: "",
      project_name: "",
      team: [
        {
          team_id: "",
          team_member_id: "",
          employee_id: "",
          employee_name: "",
        },
      ],
    },
  ]);

  const [sendTeams, setSendTeams] = useState<any[]>([
    {
      team_id: "",
    },
  ]);

  const Navigate = useNavigate();

  // const success = useSelector((state: any) => state.http.data);

  const axiosInstance = useAxiosInterceptor();

  const showToast = useCustomToast();

  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();

  const handleShowToast = (
    message: string,
    variant: ToastVariant,
    isCloseable: boolean
  ) => {
    showToast(message, variant, isCloseable);
  };
  const handleQuestionCategoryChange = (index: any, event: any) => {
    const updatedTextFields = [...questionCategory];
    updatedTextFields[index] = event.target.value;
    setQuestionCategory(updatedTextFields);
  };

  const addQuestion = (): void => {
    const field: Field = {
      id: formContent.length,
      name: `question_${formContent.length}`,
      label: "",
      required: false,
      question_type: "short_answer",
      list: [],
    };
    setFormContent((prevFormContent) => [...prevFormContent, field]);
  };

  const deleteQuestion = (index: number): void => {
    const totalQuestions = formContent.length;

    if (index > -1 && totalQuestions > 1) {
      setFormContent((prevFormContent) =>
        prevFormContent.filter((_, i) => i !== index)
      );
    } else if (totalQuestions <= 1) {
      handleShowToast("Cannot Delete Last Question", "info", true);
    }
  };

  const editField = (fieldName: string, fieldLabel: string): void => {
    const formFields = [...formContent];
    const fieldIndex = formFields.findIndex((f) => f.name === fieldName);
    if (fieldIndex > -1) {
      formFields[fieldIndex].label = fieldLabel;
      setFormContent(formFields);
    }
  };

  const editFieldType = (fieldName: string, fieldLabel: string): void => {
    const formFields = [...formContent];
    const fieldIndex = formFields.findIndex((f) => f.name === fieldName);
    if (fieldIndex > -1) {
      formFields[fieldIndex].question_type = fieldLabel;
      setFormContent(formFields);
    }
  };

  const addFieldOption = (fieldName: string, option: string): void => {
    const formFields = [...formContent];
    const fieldIndex = formFields.findIndex((f) => f.name === fieldName);
    if (fieldIndex > -1) {
      if (option && option !== "") {
        formFields[fieldIndex].list = [...formFields[fieldIndex].list, option];
        setFormContent(formFields);
        setTextField("");
      }
    }
  };

  const deleteFieldOption = (fieldName: string, optionIndex: number): void => {
    const formFields = [...formContent];
    const fieldIndex = formFields.findIndex((f) => f.name === fieldName);
    if (fieldIndex > -1) {
      formFields[fieldIndex].list.splice(optionIndex, 1);
      setFormContent(formFields);
    }
  };

  const token = localStorage.getItem("token");

  //This is to handle state for form name and form description.
  const handleForm = (e: any) => {
    let name, value;
    name = e.target.name;
    value = e.target.value;

    setCreateForm({ ...createForm, [name]: value });
  };

  const handleQuestions = (e: any) => {
    let name, value;
    name = e.target.name;
    value = e.target.value;

    setQuestions({ ...questions, [name]: value });
    setQuestionArray([questions]);
  };

  const handleAnswer = (e: any) => {
    let name, value;
    name = e.target.name;
    value = e.target.value;

    setAnswer({ ...answer, [name]: value });
    setAns_Array([answer]);
  };
  const id = useSelector((state: any) => state.person.perstate.id);
  const isloading = useSelector((state: any) => state.loading.isLoading);
  const dispatch = useDispatch();

  const handleFormSubmit = (e: any) => {
    // addQuestion();
    const {
      form_name,
      form_description,
      created_by,
      employee_deadline,
      manager_deadline,
    } = createForm;
    e.preventDefault();
    var data = {
      form_name: form_name,
      form_description: form_description,
      //   created_by: id,
      employee_deadline: empDate ? format(empDate.toDate(), "yyyy-MM-dd") : "",
      manager_deadline: managerDate
        ? format(managerDate.toDate(), "yyyy-MM-dd")
        : "",
      questions_old_format: formContent.map((form, index) => ({
        question: form.label,
        question_category: "General", //questionCategory[index]
        answer_type: form.question_type,
        answer_choices: form.list.map((value) => ({ answer_value: value })),
      })),
      // teams: sendTeams.map((value) => {
      //   return {
      //     team_id: value.team_id,
      //   };
      // }),
    };

    //post protocol for creating a new form
    try {
      const response = dispatch2(postData(CreateFormUrl, data))
        .then((response) => {
          handleShowToast(
            `${data.form_name} Created Successfully !`,
            "success",
            true
          );
          dispatch(postDataSuccess(null));

          Navigate("/dashboard");
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleTeamSend = (e: any, team_id: string) => {
    e.preventDefault();
    setSendTeams((prevTeams) => {
      const updatedTeams = prevTeams.filter((team) => team.team_id !== "");
      return [...updatedTeams, { team_id: team_id }];
    });
  };

  useEffect(() => {
    try {
      dispatch(setIsLoading(true));
      const response = dispatch2(fetchData(showTeams));
      setGetTeamsData(response as any);
      dispatch(setIsLoading(false));
    } catch (error) {
      console.log(error);
      dispatch(setIsLoading(false));
    }
  }, []);

  if (isloading) {
    return (
      <>
        <Skeletons />
      </>
    );
  }
  return (
    <>
      <Box>
        <Navbar />
      </Box>

      <Container
        sx={{ mt: 12, mb: 10, display: "flex", justifyContent: "center" }}
      >
        {/* <ThemeProvider theme={theme}>
          <Stepper
            sx={{
              "& .MuiStepConnector-active": {
                "& .MuiStepConnector-line": {
                  borderColor: theme.palette.primary.main,
                },
              },
            }}
            activeStep={activeStep}
          >
            <Step>
              <StepLabel>Create Form</StepLabel>
            </Step>
            <Step>
              <StepLabel>Select People to send </StepLabel>
            </Step>
          </Stepper>
        </ThemeProvider> */}
        <Box component="form" onSubmit={handleFormSubmit}>
          {/* {activeStep === 0 && ( */}
          <Box>
            <Container
              sx={
                {
                  // mt: 12,
                  // mb: 30,
                  //   overflow : 'auto'
                }
              }
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: {
                    xs: "fit-content",
                    sm: "fit-content",
                    md: "100%",
                  },
                }}
              >
                <Container
                  sx={{
                    // justifyContent: "right",
                    // alignItems: "center",
                    flexDirection: "column",
                    flex: 1,
                    // overflow: "auto",
                    //   mb: 4,
                    width: "75vw",
                  }}
                >
                  <Card
                    elevation={11}
                    sx={{
                      m: 2,
                      p: 2,
                      display: "flex",
                      justifyContent: "center",
                      // width: {
                      //   xs: "fit-content",
                      //   sm: "fit-content",
                      //   md: "100%",
                      // },
                      borderRadius: "5px",
                      boxShadow: "0px 0px 10px 2px rgba(0, 100, 0, 0.5)",
                    }}
                  >
                    <Box sx={{ width: "90%" }}>
                      <TextField
                        name="form_name"
                        required
                        fullWidth
                        variant="standard"
                        size="small"
                        placeholder="Form Heading"
                        sx={{ p: 1 }}
                        
                        value={createForm.form_name}
                        onChange={(e) => {
                          handleForm(e);
                        }}
                      />
                      <TextField
                        fullWidth
                        name="form_description"
                        variant="standard"
                        size="small"
                        placeholder="Form Description"
                        multiline
                        rows="2"
                        value={createForm.form_description}
                        sx={{ p: 1 }}
                        
                        onChange={(e) => {
                          handleForm(e);
                        }}
                      />
                    </Box>
                  </Card>
                  <Card
                    elevation={11}
                    sx={{
                      m: 2,
                      p: 2,
                      flex: 1,
                      flexShrink: 1,
                      // width: {
                      //   xs: "fit-content",
                      //   sm: "fit-content",
                      //   md: "100%",
                      // },
                      borderRadius: "5px",
                      boxShadow: "0px 0px 10px 2px rgba(0, 100, 0, 0.5)",
                    }}
                  >
                    <Box>
                      {formContent.map((field, index) => {
                        return (
                          <Container key={field.id} sx={{ width: "100%" }}>
                            <Box sx={{ width: "100%" }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  // m: 2,
                                  // p: 1,
                                  width: "100%",
                                }}
                              >
                                <Box
                                  key={field.name}
                                  sx={{ width: "90%", mr: 2 }}
                                >
                                  <Box sx={{ m: 2 }}>
                                    <InputLabel htmlFor="question_input">
                                      Question {index + 1}
                                    </InputLabel>
                                    <TextField
                                      id="question_input"
                                      name="question_input"
                                      required
                                      fullWidth
                                      size="small"
                                      variant="standard"
                                      multiline
                                      //   value={field.label}
                                      autoFocus
                                      
                                      value={field.label}
                                      onChange={(e) => {
                                        editField(field.name, e.target.value);
                                        handleQuestions(e);
                                      }}
                                      onBlur={() => {
                                        setOnEdit(false);
                                        setEditedField("");
                                      }}
                                      sx={{ m: 1, p: 1 }}
                                    />
                                  </Box>
                                </Box>
                                <Box>
                                  <Select
                                    value={field.question_type}
                                    size="small"
                                    // value={questions.answer_type}
                                    
                                    onChange={(e) =>
                                      editFieldType(
                                        field.name,
                                        e.target.value as string
                                      )
                                    }
                                    sx={{ height: "2.5rem", width: "10.5rem" }}
                                  >
                                    <MenuItem
                                      value="short_answer"
                                      onClick={() => {
                                        setQuestions({
                                          ...questions,
                                          answer_type: "descriptive",
                                        });
                                      }}
                                    >
                                      Short Answer
                                    </MenuItem>
                                    {/* <MenuItem value="paragraph">Paragraph</MenuItem> */}
                                    <MenuItem
                                      value="multichoice"
                                      onClick={() => {
                                        setQuestions({
                                          ...questions,
                                          answer_type: "choice",
                                        });
                                      }}
                                    >
                                      Multichoice
                                    </MenuItem>
                                  </Select>
                                </Box>
                                <Box sx={{ m: 2 }}>
                                  <IconButton
                                    onClick={() => {
                                      deleteQuestion(index);
                                    }}
                                  >
                                    <DeleteIcon
                                      fontSize="large"
                                      color="primary"
                                    />
                                  </IconButton>
                                </Box>
                              </Box>
                              {/* <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  m: 2,
                                }}
                              >
                                <InputLabel htmlFor="question_category">
                                  Question Category :
                                </InputLabel>
                                <TextField
                                  name="question_category"
                                  size="small"
                                  variant="standard"
                                  onChange={(e) => {
                                    handleQuestionCategoryChange(index, e);
                                  }}
                                />
                              </Box> */}
                              <Box>
                                {field.question_type === "multichoice" && (
                                  <Container sx={{ p: 1 }}>
                                    {field.list.map((item, index) => (
                                      <Box
                                        key={item}
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Radio
                                          size="small"
                                          
                                          disabled
                                        />
                                        <TextField
                                          id="standard-basic"
                                          label="Standard"
                                          fullWidth
                                          size="small"
                                          variant="standard"
                                          value={item}
                                          disabled
                                          
                                          sx={{ m: 1 }}
                                        />
                                        <Button
                                          onClick={() =>
                                            deleteFieldOption(field.name, index)
                                          }
                                        >
                                          <DeleteIcon
                                            color="primary"
                                          />
                                        </Button>
                                      </Box>
                                    ))}
                                    <Container>
                                      <TextField
                                        name="answer_value"
                                        variant="standard"
                                        // required={field.question_type === "multichoice" && field.list.length === 0}
                                        size="small"
                                        onChange={(e) => {
                                          e.preventDefault();
                                          setTextField(e.target.value);
                                          handleAnswer(e);
                                        }}
                                        value={textField}
                                        placeholder="Add an option"
                                        sx={{ m: 1 }}
                                        
                                      />
                                      <Button
                                        size="small"
                                        onClick={() =>
                                          addFieldOption(field.name, textField)
                                        }
                                        sx={{ m: 1,  }}
                                      >
                                        Add Option
                                      </Button>
                                    </Container>
                                  </Container>
                                )}
                              </Box>
                            </Box>
                          </Container>
                        );
                      })}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "right",
                          m: 1,
                        }}
                      >
                        <Button
                          variant="text"
                          onClick={() => addQuestion()}
                          size="small"
                          
                          // sx={{
                          //   background: "#198d19",
                          //   "&:hover": { background: "#4da64d" },
                          // }}
                        >
                          {/* <AddCircleOutlineIcon /> */}
                          Add Question
                        </Button>
                      </Box>
                      <Box
                        sx={{
                          display: { xs: "block", sm: "block", md: "flex" },
                          justifyContent: "center",
                        }}
                      >
                        <Box sx={{ m: 1, p: 1 }}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer
                              components={["DatePicker", "DatePicker"]}
                            >
                              <DatePicker
                                label="Employee DeadLine"
                                value={empDate}
                                sx={{ color: "green" }}
                                disablePast
                                onChange={(newValue) => setEmpDate(newValue)}
                                format="YYYY-MM-DD"
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </Box>
                        <Box sx={{ m: 1, p: 1 }}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer
                              components={["DatePicker", "DatePicker"]}
                            >
                              <DatePicker
                                label="Manager DeadLine "
                                value={managerDate}
                                disablePast
                                onChange={(newValue) =>
                                  setManagerDate(newValue)
                                }
                                format="YYYY-MM-DD"
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          m: 2,
                          p: 2,
                        }}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          
                          //   onClick={()=>{

                          //   }}
                        >
                          Create Form
                        </Button>
                      </Box>
                    </Box>
                  </Card>
                </Container>
              </Box>
            </Container>
            {/* <Box
                sx={{ display: "flex", justifyContent: "right", m: 1, pr: 5.9 }}
              >
                <Button
                  size="small"
                  variant="contained"
                  
                  onClick={handleNext}
                >
                  Next
                </Button>
              </Box> */}
          </Box>
          {/* )} */}
          {/* {activeStep === 1 && ( */}

          {/* )} */}
        </Box>
      </Container>
      <Box>
        <Footer />
      </Box>
    </>
  );
};

export default AddForm;
