import { PureComponent } from "react";
import {
	LineChart,
	Line,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	ResponsiveContainer,
} from "recharts";
import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import { DataItem } from "../../types";
import iwanthue from "iwanthue";
import { CustomTooltip } from "../utilization/utils";

interface LineChartProps {
	data: DataItem[];
	YAxisLabel: string;
	selectedEmployees?: string[];
	onEmployeeSelection?: (employeeId: string) => void;
}

export default class LineChartComponent extends PureComponent<LineChartProps> {
	static defaultProps = {
		data: [],
	};

	// Generate a consistent color map
	getColorMap = (employeeNames: (string | { name: string })[]) => {
		// Ensure we extract names as strings
		const namesAsStrings = employeeNames.map((name) =>
			typeof name === "string" ? name : name.name
		);

		const colorCount = Math.max(namesAsStrings.length, 2); // Ensure at least 2 colors
		const colors = iwanthue(colorCount, {
			colorSpace: "all",
			clustering: "force-vector",
			seed: "employee-colors",
			quality: 100,
		});

		const colorMap: Record<string, string> = {};
		namesAsStrings.forEach((name, index) => {
			colorMap[name] = colors[index];
		});

		return colorMap;
	};

	transformData = (data: DataItem[]) => {
		const { selectedEmployees } = this.props;
		const filteredData = data.filter((item) =>
			selectedEmployees?.includes(item.employee_name)
		);

		const transformedData: any = [];
		filteredData.forEach((item) => {
			let weekData = transformedData.find((d: any) => d.week === item.week);

			if (!weekData) {
				weekData = { week: item.week };
				transformedData.push(weekData);
			}

			weekData[item.employee_name] = item.percent;
		});

		return transformedData;
	};

	render() {
		const { data, YAxisLabel, selectedEmployees, onEmployeeSelection } =
			this.props;

		const transformedData = this.transformData(data);

		const employeeNames = Array.from(
			data
				.reduce((acc, item) => {
					if (!acc.has(item.employee_name)) {
						acc.set(item.employee_name, {
							name: item.employee_name,
							id: item.employee_id,
						});
					}
					return acc;
				}, new Map())
				.values()
		);

		const employeeColorMap = this.getColorMap(employeeNames);

		return (
			<>
				<ResponsiveContainer width="100%" aspect={3}>
					<LineChart data={transformedData} margin={{ top: 5, right: 30 }}>
						<CartesianGrid strokeDasharray="3 3" />
						<XAxis dataKey="week" />
						<YAxis label={{ value: YAxisLabel, angle: -90 }} />
						<Tooltip
							cursor={{ fill: "transparent" }}
							content={<CustomTooltip />}
						/>
						{selectedEmployees?.map((employeeName, index) => (
							<Line
								key={employeeName}
								type="monotone"
								dataKey={employeeName}
								stroke={employeeColorMap[employeeName]}
								strokeWidth={2}
							/>
						))}
					</LineChart>
				</ResponsiveContainer>
				<Grid
					container
					display="flex"
					alignItems="start"
					justifyContent="center"
				>
					{employeeNames.map((employee, index) => (
						<FormControlLabel
							key={index}
							control={
								<Checkbox
									checked={selectedEmployees?.includes(employee?.name)}
									onChange={() => {
										if (onEmployeeSelection) {
											onEmployeeSelection(employee?.name);
										}
									}}
								/>
							}
							label={employee?.name}
							sx={{
								color: employeeColorMap[employee?.name],
							}}
						/>
					))}
				</Grid>
			</>
		);
	}
}
