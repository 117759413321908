import React from "react";
import { useUpdateCheck } from "react-update-notification";
import { Modal, Card, Typography, Box, Button } from "@mui/material";

const style = {
	position: "absolute" as "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 4,
	borderRadius: 2,
};

const NotificationContainer = () => {
	const { status, reloadPage } = useUpdateCheck({
		type: "interval",
		interval: 10000,
	});

	if (status === "checking" || status === "current") {
		return null;
	}

	return (
		<Modal
			open={status === "available"}
			aria-labelledby="update-modal-title"
			aria-describedby="update-modal-description"
			slotProps={{
				backdrop: {
					style: {
						backgroundColor: "transparent",
					},
				},
			}}
		>
			<Card elevation={11} sx={style}>
				<Typography
					id="update-modal-title"
					variant="h6"
					component="h2"
					fontWeight="bold"
					sx={{ mb: 2 }}
				>
					Update Available
				</Typography>
				<Typography id="update-modal-description" sx={{ mt: 2 }}>
					A new version of the application is available. Please refresh to
					update.
				</Typography>
				<Box
					sx={{
						display: "flex",
						justifyContent: "flex-end",
						mt: 3,
					}}
				>
					<Button id="refresh_button" variant="contained" onClick={reloadPage}>
						Refresh Now
					</Button>
				</Box>
			</Card>
		</Modal>
	);
};

export default NotificationContainer;
