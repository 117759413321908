import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  Switch,
  SwitchProps,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";

import Navbar from "../../components/Page Component/Navbar";
import Footer from "../../components/Page Component/Footer";
import { enqueueSnackbar } from "notistack";
import useAxiosInterceptor from "../../components/layout/AxiosInstance";
import {
  CreateAppraisalForm,
  CreateFormUrl,
  deleteCategoryAPI,
  deleteOptionAPI,
  deleteQuestionAPI,
  deleteSectionAPI,
  editForm,
  editHierarchicalForm,
  viewForm,
  viewHierarchicalForm,
} from "../../Service/Apis";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs, { Dayjs } from "dayjs";
import { date } from "../Form Add/AddForm";
import { format } from "date-fns";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Category } from "@mui/icons-material";
import { setIsLoading } from "../../store/LoadingSlice";
import Skeletons from "../../components/Skeleton/Skeletons";
import {
  ToastVariant,
  useCustomToast,
} from "../../components/Methods/SnackBar";
import {
  FetchDataFailureAction,
  FetchDataSuccessAction,
  deleteData,
  fetchData,
  patchData,
  postData,
  putData,
} from "../../store/actions";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../../store/Store";
import { log } from "util";
import { postDataSuccess } from "../../store/AxiosSlice";

interface OptionValue {
  [sectionIndex: number]: {
    [categoryIndex: number]: {
      [questionIndex: number]: {
        value: string;
      };
    };
  };
}

interface AnswerChoicesError {
  [sectionIndex: number]: {
    [categoryIndex: number]: {
      [questionIndex: number]: {
        value: boolean;
      };
    };
  };
}

const initialOptionValue: OptionValue = {};
const initialAnswerChoice: AnswerChoicesError = {};
interface AppraisalForm {
  sections: {
    section: string;
    section_id: string;
    categories: {
      category_id: string;
      category: string;
      questions: {
        question_id: string;
        question: string;
        answer_type: string;
        answer_choices: {
          answer_value: string;
          option_id: string;
        }[];
      }[];
    }[];
  }[];
}

const initialState: AppraisalForm = {
  sections: [
    {
      section: "",
      section_id: "",
      categories: [
        {
          category: "",
          category_id: "",
          questions: [
            {
              question: "",
              question_id: "",
              answer_type: "short_answer",
              answer_choices: [],
            },
          ],
        },
      ],
    },
  ],
};

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor:
          theme.palette.mode === "dark" ? "#2ECA45" : theme.palette.primary,
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const AppraisalEditForm = () => {
  const [appraisalFormContent, setAppraisalFormContent] =
    useState<AppraisalForm>(initialState);

  const [formName, setFormName] = useState<string>("");
  const [formDescription, setFormDescription] = useState<string>("");
  const [answerChoicesError, setAnswerChoicesError] =
    useState<AnswerChoicesError>(initialAnswerChoice);
  const [optionValue, setOptionValue] =
    useState<OptionValue>(initialOptionValue);
  const [managerResponseVisibility, setManagerResponseVisibility] =
    useState<boolean>(true);

  const [flag, setFlag] = useState<boolean>(false);

  const [empDate, setEmpDate] = useState<Dayjs | null>(dayjs(`${date}`));
  const [managerDate, setManagerDate] = useState<Dayjs | null>(
    dayjs(`${date}`)
  );

  const [deleteSectionIs, setDeleteSectionIs] = useState<number | null>(null);
  const [deleteCategoryIs, setDeleteCategoryIs] = useState<{
    sectionIndex: number | null;
    categoryIndex: number | null;
  }>({ sectionIndex: null, categoryIndex: null });
  const [deleteQuestionIs, setDeleteQuestionIs] = useState<{
    sectionIndex: number | null;
    categoryIndex: number | null;
    questionIndex: number | null;
  }>({ sectionIndex: null, categoryIndex: null, questionIndex: null });

  const axiosInstance = useAxiosInterceptor();

  // const success = useSelector((state: any) => state.http.data);

  const token = localStorage.getItem("token");

  const Navigate = useNavigate();
  const showToast = useCustomToast();
  const dispatch = useDispatch();

  const isloading = useSelector((state: any) => state.loading.isLoading);

  const handleShowToast = (
    message: string,
    variant: ToastVariant,
    isCloseable: boolean
  ) => {
    showToast(message, variant, isCloseable);
  };
  const formId =
    useSelector((state: any) => state.form.id) ||
    localStorage.getItem("formid");

  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();

  const handleOpenSectionDelete = (sectionIndex: number) => {
    setDeleteSectionIs(sectionIndex);
  };

  const handleCloseSectionDelete = () => {
    setDeleteSectionIs(null);
  };

  const handleOpenCategoryDelete = (
    sectionIndex: number,
    categoryIndex: number
  ) => {
    setDeleteCategoryIs({
      sectionIndex: sectionIndex,
      categoryIndex: categoryIndex,
    });
  };

  const handleCloseCategoryDelete = () => {
    setDeleteCategoryIs({ sectionIndex: null, categoryIndex: null });
  };

  const handleOpenQuestionDelete = (
    sectionIndex: number,
    categoryIndex: number,
    questionIndex: number
  ) => {
    setDeleteQuestionIs({
      sectionIndex: sectionIndex,
      categoryIndex: categoryIndex,
      questionIndex: questionIndex,
    });
  };

  const handleCloseQuestionDeleter = () => {
    setDeleteQuestionIs({
      sectionIndex: null,
      categoryIndex: null,
      questionIndex: null,
    });
  };

  const addSection = () => {
    const newSection = {
      section: "",
      section_id: "",
      categories: [
        {
          category: "",
          category_id: "",
          questions: [
            {
              question: "",
              question_id: "",
              answer_type: "short_answer",
              answer_choices: [],
            },
          ],
        },
      ],
    };

    const updatedFormContent = {
      sections: [...appraisalFormContent.sections, newSection],
    };

    setAppraisalFormContent(updatedFormContent);
  };

  const addCategory = (sectionIndex: number) => {
    const updatedSection = { ...appraisalFormContent.sections[sectionIndex] };
    updatedSection.categories.push({
      category: "",
      category_id: "",
      questions: [
        {
          question: "",
          question_id: "",
          answer_type: "short_answer",
          answer_choices: [],
        },
      ],
    });

    const updatedFormContent = { ...appraisalFormContent };
    updatedFormContent.sections[sectionIndex] = updatedSection;

    setAppraisalFormContent(updatedFormContent);
  };

  const addQuestion = (sectionIndex: number, categoryIndex: number) => {
    const updatedCategory = {
      ...appraisalFormContent.sections[sectionIndex].categories[categoryIndex],
    };
    const newQuestion = {
      question: "",
      question_id: "",
      answer_type: "short_answer",
      answer_choices: [], // Remove the initial null option
    };

    updatedCategory.questions.push(newQuestion);

    const updatedFormContent = { ...appraisalFormContent };
    updatedFormContent.sections[sectionIndex].categories[categoryIndex] =
      updatedCategory;

    setAppraisalFormContent(updatedFormContent);
  };

  const deleteQuestion = (
    sectionIndex: number,
    categoryIndex: number,
    questionIndex: number,
    question_id: string
  ) => {
    const totalQuestions =
      appraisalFormContent.sections[sectionIndex].categories[categoryIndex]
        .questions.length;

    if (questionIndex > -1 && totalQuestions > 1) {
      const updatedFormContent = { ...appraisalFormContent };
      const updatedCategory = {
        ...appraisalFormContent.sections[sectionIndex].categories[
          categoryIndex
        ],
      };

      if (question_id !== "") {
        try {
          const response = dispatch2(
            deleteData(`${deleteQuestionAPI}${question_id}`)
          )
            .then((response) => {
              handleShowToast("Question Deleted", "success", true);
              updatedCategory.questions.splice(questionIndex, 1);
              updatedFormContent.sections[sectionIndex].categories[
                categoryIndex
              ] = updatedCategory;
              setAppraisalFormContent(updatedFormContent);
            })
            .catch((error) => {
              console.log(error);
            });
        } catch (error) {
          console.log(error);
        }
      } else if (question_id === "") {
        updatedCategory.questions.splice(questionIndex, 1);
        updatedFormContent.sections[sectionIndex].categories[categoryIndex] =
          updatedCategory;
        setAppraisalFormContent(updatedFormContent);
      }
    } else if (totalQuestions <= 1) {
      handleShowToast("Cannot Delete Last Question.", "info", true);
    }
  };

  const editQuestionType = (
    sectionIndex: number,
    categoryIndex: number,
    questionIndex: number,
    questionType: string
  ): void => {
    const updatedFormContent = { ...appraisalFormContent };
    const question =
      updatedFormContent.sections[sectionIndex].categories[categoryIndex]
        .questions[questionIndex];
    question.answer_type = questionType;

    setAppraisalFormContent(updatedFormContent);
  };

  const handleSetOptionValue = (
    sectionIndex: number,
    categoryIndex: number,
    questionIndex: number,
    value: string
  ) => {
    setOptionValue((prevOptionValue) => {
      // Create a copy of the current state to avoid modifying it directly
      const updatedOptionValue = { ...prevOptionValue };

      // Check if the sectionIndex exists in the state
      if (!updatedOptionValue.hasOwnProperty(sectionIndex)) {
        updatedOptionValue[sectionIndex] = {};
      }

      // Check if the categoryIndex exists in the state
      if (!updatedOptionValue[sectionIndex].hasOwnProperty(categoryIndex)) {
        updatedOptionValue[sectionIndex][categoryIndex] = {};
      }

      // Update the value for the specified questionIndex
      updatedOptionValue[sectionIndex][categoryIndex][questionIndex] = {
        value: value,
      };

      // Return the updated state
      return updatedOptionValue;
    });
  };

  const handleAnswerChoice = (
    sectionIndex: number,
    categoryIndex: number,
    questionIndex: number,
    value: number
  ) => {
    setAnswerChoicesError((prevAnswerError) => {
      const updatedAnswerError = { ...answerChoicesError };
      const sectionError = updatedAnswerError[sectionIndex] || {};
      const categoryError = sectionError[categoryIndex] || {};
      const questionError = categoryError[questionIndex] || {
        value: false,
      };

      questionError.value = value < 2;

      categoryError[questionIndex] = questionError;
      sectionError[categoryIndex] = categoryError;
      updatedAnswerError[sectionIndex] = sectionError;

      return updatedAnswerError;
    });
  };

  const addOption = (
    sectionIndex: number,
    categoryIndex: number,
    questionIndex: number
  ) => {
    const updatedFormContent = { ...appraisalFormContent };
    const question =
      updatedFormContent.sections[sectionIndex].categories[categoryIndex]
        .questions[questionIndex];
    const newOption = {
      answer_value:
        optionValue?.[sectionIndex]?.[categoryIndex]?.[questionIndex]?.value,
      option_id: "", // Add the necessary logic to generate a unique option ID
    };
    question.answer_choices.push(newOption);
    setAppraisalFormContent(updatedFormContent);
    handleSetOptionValue(sectionIndex, categoryIndex, questionIndex, "");
    handleAnswerChoice(
      sectionIndex,
      categoryIndex,
      questionIndex,
      question.answer_choices.length
    );
  };

  const deleteOption = (
    sectionIndex: number,
    categoryIndex: number,
    questionIndex: number,
    optionIndex: number,
    option_id: string
  ) => {
    const updatedFormContent = { ...appraisalFormContent };
    const question =
      updatedFormContent.sections[sectionIndex].categories[categoryIndex]
        .questions[questionIndex];
    if (option_id !== "") {
      try {
        const response = dispatch2(deleteData(`${deleteOptionAPI}${option_id}`))
          .then((response) => {
            handleShowToast("Option Deleted", "success", true);
            question.answer_choices.splice(optionIndex, 1);
            setAppraisalFormContent(updatedFormContent);
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    } else if (option_id === "") {
      handleAnswerChoice(
        sectionIndex,
        categoryIndex,
        questionIndex,
        question.answer_choices.length
      );
      question.answer_choices.splice(optionIndex, 1);
      setAppraisalFormContent(updatedFormContent);
    }
  };

  const deleteCategory = (
    sectionIndex: number,
    categoryIndex: number,
    category_id: string
  ) => {
    const totalCategories =
      appraisalFormContent.sections[sectionIndex].categories.length;
    if (categoryIndex > -1 && totalCategories > 1) {
      const updatedFormContent = { ...appraisalFormContent };

      if (category_id !== "") {
        try {
          const response = dispatch2(
            deleteData(`${deleteCategoryAPI}${category_id}`)
          )
            .then((response) => {
              handleShowToast("Category Deleted", "success", true);
              updatedFormContent.sections[sectionIndex].categories.splice(
                categoryIndex,
                1
              );
              setAppraisalFormContent(updatedFormContent);
            })
            .catch((error) => {
              console.log(error);
            });
        } catch (error) {
          console.log(error);
        }
      } else if (category_id === "") {
        updatedFormContent.sections[sectionIndex].categories.splice(
          categoryIndex,
          1
        );
        setAppraisalFormContent(updatedFormContent);
      }
      // return;
    } else if (totalCategories <= 1) {
      handleShowToast("Cannot Delete Last Category.", "info", true);
    }
  };

  const deleteSection = (sectionIndex: number, section_id: string) => {
    const totalSections = appraisalFormContent.sections.length;
    if (sectionIndex > -1 && totalSections > 1) {
      const updatedFormContent = { ...appraisalFormContent };
      if (section_id !== "") {
        try {
          const response = dispatch2(
            deleteData(`${deleteSectionAPI}${section_id}`)
          );
          handleShowToast("Section Deleted", "success", true);
          updatedFormContent.sections.splice(sectionIndex, 1);
          setAppraisalFormContent(updatedFormContent);
        } catch (error) {
          console.log(error);
        }
      } else if (section_id === "") {
        updatedFormContent.sections.splice(sectionIndex, 1);
        setAppraisalFormContent(updatedFormContent);
      }
    } else if (totalSections <= 1) {
      handleShowToast("Cannot Delete Last Section.", "info", true);
    }
  };

  // const handleSectionNameChange = (
  //   sectionIndex: number,
  //   value: string
  // ): void => {
  //   const updatedFormContent = { ...appraisalFormContent };
  //   updatedFormContent.sections[sectionIndex].section = value;

  //   setAppraisalFormContent(updatedFormContent);
  // };

  const handleCategoryNameChange = (
    sectionIndex: number,
    categoryIndex: number,
    value: string
  ): void => {
    const updatedFormContent = { ...appraisalFormContent };
    updatedFormContent.sections[sectionIndex].categories[
      categoryIndex
    ].category = value;

    setAppraisalFormContent(updatedFormContent);
  };

  const handleQuestionChange = (
    sectionIndex: number,
    categoryIndex: number,
    questionIndex: number,
    value: string
  ): void => {
    const updatedFormContent = { ...appraisalFormContent };
    updatedFormContent.sections[sectionIndex].categories[
      categoryIndex
    ].questions[questionIndex].question = value;

    setAppraisalFormContent(updatedFormContent);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const { sections } = appraisalFormContent;

    var data = {
      form_name: formName,
      form_description: formDescription,
      employee_deadline: empDate ? format(empDate.toDate(), "yyyy-MM-dd") : "",
      manager_deadline: managerDate
        ? format(managerDate.toDate(), "yyyy-MM-dd")
        : "",
      manager_fields_visible: managerResponseVisibility,
      sections,
    };

    // API Call for editing the form.
    try {
      const response = dispatch2(
        patchData(`${editHierarchicalForm}${formId}`, data)
      )
        .then((response) => {
          handleShowToast(
            `${formName} Created Successfully !`,
            "success",
            true
          );
          dispatch(postDataSuccess(null));

          Navigate("/createdforms");
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  // API call for getting the particular form.
  useEffect(() => {
    const response = axiosInstance
      .get(`${viewHierarchicalForm}${formId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setAppraisalFormContent({
          ...appraisalFormContent,
          sections: res.data[0].sections.map((section: any) => ({
            section: section.section,
            section_id: section.section_id,
            categories: section.categories.map((category: any) => ({
              category_id: category.category_id,
              category: category.category,
              questions: category.questions.map((question: any) => ({
                question_id: question.question_id,
                question: question.question,
                answer_type: question.answer_type,
                answer_choices: question.answer_choices.map((choice: any) => ({
                  answer_value: choice.answer_value,
                  option_id: choice.option_id,
                })),
              })),
            })),
          })),
        });
        setFormName(res.data[0].form_name);
        setFormDescription(res.data[0].form_description);
        setEmpDate(dayjs(res.data[0].employee_deadline));
        setManagerDate(dayjs(res.data[0].manager_deadline));
        setManagerResponseVisibility(res.data[0].manager_fields_visible);
        dispatch(setIsLoading(false));
      })
      .catch((error) => {
        dispatch(setIsLoading(false));
        console.log(error);
      });
  }, []);

  if (isloading) {
    return (
      <>
        <Skeletons />
      </>
    );
  }

  return (
    <>
      <Box>
        <Navbar />
      </Box>
      <Container sx={{ mt: 12, mb: 10 }}>
        <Box>
          <FormControlLabel
            control={
              <IOSSwitch
                sx={{ m: 1 }}
                checked={managerResponseVisibility}
                onChange={(e) => setManagerResponseVisibility(e.target.checked)}
              />
            }
            label="Manager Response Visibility"
          />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box component="form" onSubmit={handleSubmit}>
            <Box sx={{ width: "75vw" }}>
              <Card
                elevation={4}
                sx={{
                  m: 2,
                  p: 2,
                  display: "flex",
                  justifyContent: "center",
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", // Add boxShadow here
                  border: "1px solid #ccc",
                }}
              >
                <Box sx={{ width: "90%" }}>
                  <TextField
                    name="form_name"
                    required
                    fullWidth
                    variant="standard"
                    size="small"
                    placeholder="Form Heading"
                    maxRows={4}
                    multiline
                    sx={{ p: 1, overflow: "auto" }}
                    value={formName}
                    onChange={(e) => {
                      setFormName(e.target.value);
                    }}
                  />
                  <TextField
                    fullWidth
                    required
                    variant="standard"
                    size="small"
                    placeholder="Form Description"
                    multiline
                    minRows={2}
                    maxRows={4}
                    value={formDescription}
                    onChange={(e) => {
                      setFormDescription(e.target.value);
                    }}
                    sx={{ p: 1, overflow: "auto" }}
                  />
                </Box>
              </Card>
            </Box>
            <Box>
              <Box>
                {appraisalFormContent.sections.map((section, sectionIndex) => {
                  return (
                    <Card
                      key={sectionIndex}
                      elevation={4}
                      sx={{
                        m: 2,
                        p: 2,
                        flex: 1,
                        flexShrink: 1,
                        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", // Add boxShadow here
                        border: "1px solid #ccc",
                      }}
                    >
                      <Box sx={{ m: 2, p: 0 }}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <InputLabel
                            sx={{ width: "10rem", fontWeight: "bold" }}
                            htmlFor="section_name"
                          >
                            Section{" "}
                            {String.fromCharCode(
                              sectionIndex + "A".charCodeAt(0)
                            )}{" "}
                            :
                          </InputLabel>
                          <TextField
                            name="section_name"
                            variant="standard"
                            required
                            multiline
                            maxRows={4}
                            sx={{ overflow: "auto" }}
                            fullWidth
                            value={section.section}
                            onChange={(e) => {
                              const updatedFormContent = {
                                ...appraisalFormContent,
                              };
                              updatedFormContent.sections[
                                sectionIndex
                              ].section = e.target.value;
                              setAppraisalFormContent(updatedFormContent);
                            }}
                          />
                          <IconButton
                            onClick={() => {
                              handleOpenSectionDelete(sectionIndex);
                            }}
                          >
                            <DeleteIcon fontSize="medium" color="primary" />
                          </IconButton>
                          <Dialog
                            open={deleteSectionIs === sectionIndex}
                            onClose={handleCloseSectionDelete}
                            // style={{width:"45rem"}}
                            sx={{
                              m: 1,
                              "& .MuiDialog-paper": {
                                width: "30rem",
                              },
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "flex-end",
                              }}
                            >
                              <IconButton
                                onClick={() => handleCloseSectionDelete()}
                              >
                                <CloseIcon />
                              </IconButton>
                            </Box>
                            <DialogTitle>Confirmation</DialogTitle>
                            <DialogContent>
                              <Typography>Do you want to delete?</Typography>
                            </DialogContent>
                            <DialogActions>
                              <Button onClick={handleCloseSectionDelete}>
                                No
                              </Button>
                              <Button
                                variant="contained"
                                onClick={() => {
                                  deleteSection(
                                    sectionIndex,
                                    section.section_id
                                  );
                                  handleCloseSectionDelete();
                                }}
                              >
                                Yes
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </Box>
                      </Box>
                      {section.categories.map((category, categoryIndex) => {
                        return (
                          <Box sx={{ m: 4 }} key={categoryIndex}>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                p: 0,
                              }}
                            >
                              <InputLabel
                                sx={{ width: "15rem", fontWeight: "bold" }}
                                htmlFor="category_name"
                              >
                                Category{" "}
                                {String.fromCharCode(
                                  categoryIndex + "a".charCodeAt(0)
                                )}{" "}
                                :
                                {/* {String.fromCharCode(
                                  categoryIndex + "a".charCodeAt(0)
                                ) + ")"}{" "} */}
                              </InputLabel>
                              <TextField
                                name="category_name"
                                fullWidth
                                required
                                multiline
                                maxRows={4}
                                sx={{ overflow: "auto" }}
                                variant="standard"
                                value={category.category}
                                size="small"
                                onChange={(e) => {
                                  handleCategoryNameChange(
                                    sectionIndex,
                                    categoryIndex,
                                    e.target.value as string
                                  );
                                }}
                              />
                              <IconButton
                                onClick={() => {
                                  handleOpenCategoryDelete(
                                    sectionIndex,
                                    categoryIndex
                                  );
                                }}
                              >
                                <DeleteIcon fontSize="medium" color="primary" />
                              </IconButton>
                              <Dialog
                                open={
                                  deleteCategoryIs.sectionIndex ===
                                    sectionIndex &&
                                  deleteCategoryIs.categoryIndex ===
                                    categoryIndex
                                }
                                onClose={handleCloseCategoryDelete}
                                // style={{width:"45rem"}}
                                sx={{
                                  m: 1,
                                  "& .MuiDialog-paper": {
                                    width: "30rem",
                                  },
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "flex-end",
                                  }}
                                >
                                  <IconButton
                                    onClick={() => handleCloseCategoryDelete()}
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                </Box>
                                <DialogTitle>Confirmation</DialogTitle>
                                <DialogContent>
                                  <Typography>
                                    Do you want to delete?
                                  </Typography>
                                </DialogContent>
                                <DialogActions>
                                  <Button onClick={handleCloseCategoryDelete}>
                                    No
                                  </Button>
                                  <Button
                                    variant="contained"
                                    onClick={() => {
                                      deleteCategory(
                                        sectionIndex,
                                        categoryIndex,
                                        category.category_id
                                      );
                                      handleCloseCategoryDelete();
                                    }}
                                  >
                                    Yes
                                  </Button>
                                </DialogActions>
                              </Dialog>
                            </Box>
                            <Box>
                              {category.questions.map(
                                (question, questionIndex) => {
                                  return (
                                    <Box sx={{ m: 4 }} key={questionIndex}>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            width: "70%",
                                          }}
                                        >
                                          <InputLabel
                                            sx={{
                                              width: "10rem",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Question {questionIndex + 1} :
                                          </InputLabel>
                                          <TextField
                                            id={`question_${questionIndex}_ofCategory_${categoryIndex}_ofsection${sectionIndex}`}
                                            size="small"
                                            fullWidth
                                            required
                                            multiline
                                            maxRows={4}
                                            sx={{ overflow: "auto" }}
                                            variant="standard"
                                            value={question.question}
                                            onChange={(e) => {
                                              handleQuestionChange(
                                                sectionIndex,
                                                categoryIndex,
                                                questionIndex,
                                                e.target.value as string
                                              );
                                            }}
                                          />
                                        </Box>
                                        <Box sx={{ m: 2 }}>
                                          <Select
                                            size="small"
                                            value={question.answer_type}
                                            sx={{
                                              height: "2.5rem",
                                              width: "10.5rem",
                                            }}
                                            onChange={(e) => {
                                              editQuestionType(
                                                sectionIndex,
                                                categoryIndex,
                                                questionIndex,
                                                e.target.value as string
                                              );
                                            }}
                                          >
                                            <MenuItem value="short_answer">
                                              Short Answer
                                            </MenuItem>
                                            <MenuItem value="multichoice">
                                              Multichoice
                                            </MenuItem>
                                          </Select>
                                          <IconButton
                                            onClick={() => {
                                              handleOpenQuestionDelete(
                                                sectionIndex,
                                                categoryIndex,
                                                questionIndex
                                              );
                                            }}
                                          >
                                            <DeleteIcon
                                              fontSize="medium"
                                              color="primary"
                                            />
                                          </IconButton>
                                          <Dialog
                                            open={
                                              deleteQuestionIs.sectionIndex ===
                                                sectionIndex &&
                                              deleteQuestionIs.categoryIndex ===
                                                categoryIndex &&
                                              deleteQuestionIs.questionIndex ===
                                                questionIndex
                                            }
                                            onClose={handleCloseQuestionDeleter}
                                            // style={{width:"45rem"}}
                                            sx={{
                                              m: 1,
                                              "& .MuiDialog-paper": {
                                                width: "30rem",
                                              },
                                            }}
                                          >
                                            <Box
                                              sx={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                alignItems: "flex-end",
                                              }}
                                            >
                                              <IconButton
                                                onClick={() =>
                                                  handleCloseQuestionDeleter()
                                                }
                                              >
                                                <CloseIcon />
                                              </IconButton>
                                            </Box>
                                            <DialogTitle>
                                              Confirmation
                                            </DialogTitle>
                                            <DialogContent>
                                              <Typography>
                                                Do you want to delete?
                                              </Typography>
                                            </DialogContent>
                                            <DialogActions>
                                              <Button
                                                onClick={
                                                  handleCloseQuestionDeleter
                                                }
                                              >
                                                No
                                              </Button>
                                              <Button
                                                variant="contained"
                                                onClick={() => {
                                                  deleteQuestion(
                                                    sectionIndex,
                                                    categoryIndex,
                                                    questionIndex,
                                                    question.question_id
                                                  );
                                                  handleCloseQuestionDeleter();
                                                }}
                                              >
                                                Yes
                                              </Button>
                                            </DialogActions>
                                          </Dialog>
                                        </Box>
                                      </Box>
                                      <Box>
                                        {question.answer_type ===
                                          "multichoice" && (
                                          <Container sx={{ p: 1 }}>
                                            {question.answer_choices.map(
                                              (answerChoice, index) => (
                                                <Box
                                                  sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <Radio
                                                    size="small"
                                                    disabled
                                                  />
                                                  <TextField
                                                    id={`option_${index}question${questionIndex}_of_category_${categoryIndex}_of_Section${sectionIndex}`}
                                                    label="Standard"
                                                    fullWidth
                                                    multiline
                                                    maxRows={4}
                                                    sx={{
                                                      overflow: "auto",
                                                      m: 1,
                                                    }}
                                                    size="small"
                                                    variant="standard"
                                                    value={
                                                      answerChoice.answer_value
                                                    }
                                                    disabled
                                                  />
                                                  <Button
                                                    onClick={() =>
                                                      deleteOption(
                                                        sectionIndex,
                                                        categoryIndex,
                                                        questionIndex,
                                                        index,
                                                        answerChoice.option_id
                                                      )
                                                    }
                                                  >
                                                    <DeleteIcon />
                                                  </Button>
                                                </Box>
                                              )
                                            )}
                                            <Container>
                                              <TextField
                                                id={`question_${questionIndex}_of_category_${categoryIndex}_of_Section${sectionIndex}`}
                                                name="answer_value"
                                                variant="standard"
                                                size="small"
                                                multiline
                                                maxRows={4}
                                                sx={{
                                                  overflow: "auto",
                                                  m: 1,
                                                  width: "40%",
                                                }}
                                                value={
                                                  optionValue[sectionIndex]?.[
                                                    categoryIndex
                                                  ]?.[questionIndex]?.value
                                                }
                                                onChange={(e) =>
                                                  // setNewOptionValue(
                                                  // 	e.target.value
                                                  // )
                                                  {
                                                    handleSetOptionValue(
                                                      sectionIndex,
                                                      categoryIndex,
                                                      questionIndex,
                                                      e.target.value
                                                    );
                                                  }
                                                }
                                                required={
                                                  question.answer_type ===
                                                    "multichoice" &&
                                                  question.answer_choices
                                                    .length === 0
                                                }
                                                placeholder="Add an option"
                                                helperText={
                                                  answerChoicesError[
                                                    sectionIndex
                                                  ]?.[categoryIndex]?.[
                                                    questionIndex
                                                  ]?.value &&
                                                  flag && (
                                                    <Typography color={"error"}>
                                                      Please give minimum two
                                                      options.
                                                    </Typography>
                                                  )
                                                }
                                              />
                                              <Button
                                                size="small"
                                                onClick={() => {
                                                  addOption(
                                                    sectionIndex,
                                                    categoryIndex,
                                                    questionIndex
                                                  );
                                                }}
                                                sx={{ m: 1 }}
                                              >
                                                Add Option
                                              </Button>
                                            </Container>
                                          </Container>
                                        )}
                                      </Box>
                                    </Box>
                                  );
                                }
                              )}
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "right",
                                  m: 1,
                                }}
                              >
                                <Button
                                  variant="outlined"
                                  onClick={() =>
                                    addQuestion(sectionIndex, categoryIndex)
                                  }
                                  size="small"

                                  // sx={{
                                  //   background: "#198d19",
                                  //   "&:hover": { background: "#4da64d" },
                                  // }}
                                >
                                  {/* <AddCircleOutlineIcon /> */}
                                  Add Question
                                </Button>
                              </Box>
                            </Box>
                          </Box>
                        );
                      })}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "right",
                          m: 2,
                        }}
                      >
                        <Button
                          variant="contained"
                          size="small"
                          onClick={(e) => {
                            addCategory(sectionIndex);
                          }}
                        >
                          Add Category
                        </Button>
                      </Box>
                    </Card>
                  );
                })}
              </Box>
            </Box>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "right",
                  m: 2,
                }}
              >
                <Button size="small" variant="outlined" onClick={addSection}>
                  Add Section
                </Button>
              </Box>
            </Box>
            <Box
              sx={{
                display: { xs: "block", sm: "block", md: "flex" },
                justifyContent: "center",
              }}
            >
              <Box sx={{ m: 1, p: 1 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker", "DatePicker"]}>
                    <DatePicker
                      label="Employee DeadLine"
                      value={empDate}
                      sx={{ color: "green" }}
                      disablePast
                      onChange={(newValue) => setEmpDate(newValue)}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Box>
              <Box sx={{ m: 1, p: 1 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker", "DatePicker"]}>
                    <DatePicker
                      label="Manager DeadLine "
                      value={managerDate}
                      disablePast
                      onChange={(newValue) => setManagerDate(newValue)}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button variant="contained" type="submit">
                Update Form
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
      <Box>
        <Footer />
      </Box>
    </>
  );
};

export default AppraisalEditForm;
