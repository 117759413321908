import { ThunkDispatch } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/Store";
import {
  FetchDataSuccessAction,
  FetchDataFailureAction,
  fetchData,
} from "../../../store/actions";
import {
  FormControl,
  Typography,
  Divider,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Rating,
  Button,
  Box,
  CircularProgress,
} from "@mui/material";
import Footer from "../../../components/Page Component/Footer";
import Navbar from "../../../components/Page Component/Navbar";
import { ViewAllResponses, getResponseId } from "../../../Service/Apis";
import { setIsLoading } from "../../../store/LoadingSlice";
import { setResponseId } from "../../../store/FormSlice";
import NothingToDisplayComponent from "../../../components/Data/NothingToShowComponent";
import Loading from "../../../components/Loading/Loading";

interface ResponseType {
  response_id: string;
  form_team_id: string;
  form_id: string;
  form_name: string;
  form_description: string;
  team_id: string;
  project_name: string;
  manager_name: string;
  employee_id: string;
  roles: string[];
  employee_name: string;
  employee_deadline: string;
  employee_code: string;
  employee_designation: string;
  manager_deadline: string;
  is_appraisal: boolean;
  is_hierarchical: boolean;
  final_submit_done: boolean;
  manager_final_submit_done: boolean;
  admin_final_submit_done: boolean;
  peer_one_final_submit_done: boolean;
  peer_two_final_submit_done: boolean;
  peer_three_final_submit_done: boolean;
  peer_four_final_submit_done: boolean;
  peer_five_final_submit_done: boolean;
  employee_feedback: string;
  response: [
    {
      response_question_id: string;
      question_id: string;
      question_name: string;
      answer_type: string;
      answer_choices: [
        {
          answer_value: string;
          option_id: string;
        }
      ];
      employee_rating: string;
      employee_response: string;
      employee_comment: string;
    }
  ]; // Update this to use Section[] instead of [{...}]
}

interface Section {
  section_id: string;
  section: string;
  categories: Category[];
}

interface Category {
  category_id: string;
  category: string;
  questions: Question[];
}

interface Question {
  question_id: string;
  question_name: string;
  employee_rating: string;
  employee_response: string;
  employee_comment: string;
  manager_response: string;
  manager_comment: string;
  organization_leader_response: string;
  organization_leader_comment: string;
  peer_one_response: string;
  peer_one_comment: string;
  peer_two_response: string;
  peer_two_comment: string;
  peer_three_response: string;
  peer_three_comment: string;
  peer_four_response: string;
  peer_four_comment: string;
  peer_five_response: string;
  peer_five_comment: string;
  answer_type: string;
  answer_choices: {
    option_id: string;
    answer_value: string;
  }[];
}
const ShowFeedBackFormReview = () => {
  const [viewResponse, setViewResponse] = useState<ResponseType[]>([]);
  const [responseId, setResponseId] = useState<string | null>(
    localStorage.getItem("responseId")
  );
  const dispatch = useDispatch();
  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();

  const formTeamId =
    useSelector((state: any) => state.form.formTeamId) ||
    localStorage.getItem("formTeamId");
  const isLoading = useSelector((state: any) => state.loading.isLoading);

  useEffect(() => {
    const fetchResponses = async () => {
      try {
        dispatch(setIsLoading(true));
        const response = await dispatch2(
          fetchData(`${ViewAllResponses}?response_id=${responseId}`)
        );
        setViewResponse(response as ResponseType[]);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setIsLoading(false));
      }
    };

    if (responseId) {
      fetchResponses();
    }
  }, [responseId]);

  useEffect(() => {
    const fetchResponseId = async () => {
      try {
        dispatch(setIsLoading(true));
        const response = await dispatch2(
          fetchData(`${getResponseId}${formTeamId}`)
        );
        var response_id = response as any;
        if (response_id.response_id) {
          localStorage.setItem("responseId", response_id.response_id);
          setResponseId(response_id.response_id);
        } else {
          localStorage.removeItem("responseId");
          setResponseId(null);
        }
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setIsLoading(false));
      }
    };
    if (formTeamId) {
      fetchResponseId();
    }
  }, []);

  if (isLoading) {
    return (
      <>
        <Loading isLoading={isLoading as boolean} />
      </>
    );
  }
  return (
    <>
      <Box>
        <Navbar />
      </Box>
      <Box sx={{ mt: 12, mb: 10, display: "flex", justifyContent: "center" }}>
        <Box sx={{ maxWidth: "80vw" }}>
          {viewResponse.length === 0 ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <NothingToDisplayComponent />
              </Box>
            </>
          ) : (
            <>
              {" "}
              {viewResponse.map((res, index) => {
                return (
                  <Box key={index} component="form">
                    <FormControl>
                      <Box
                        sx={{
                          mt: 1,
                          pt: 2,
                          display: "flex",
                          justifyContent: "center",
                          width: "100% ",
                        }}
                      >
                        <Typography
                          fontWeight={"bold"}
                          letterSpacing={3}
                          variant="h4"
                          sx={{ textDecoration: "underline" }}
                          id="form_name"
                        >
                          {res.form_name}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          m: 1,
                          mt: 2,
                          mb: 3,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="h5" id="form_description">
                          {res.form_description
                            ? `(${res.form_description})`
                            : "(No description)"}
                        </Typography>
                      </Box>

                      <Divider />

                      <Box sx={{ m: 2, p: 2, width: "75vw" }}>
                        {res.response.map((question, questionIndex) => {
                          return (
                            <Box key={question.question_id}>
                              <Box>
                                <Box>
                                  <Typography
                                    fontWeight={"bold"}
                                    fontSize={19}
                                    variant="h6"
                                    id={`question_${questionIndex + 1}`}
                                  >
                                    {questionIndex + 1 + "."}{" "}
                                    {question.question_name}
                                  </Typography>
                                </Box>
                                <Box sx={{ ml: 2, mr: 2 }}>
                                  {question.answer_type === "short_answer" ? (
                                    <Box>
                                      <TextField
                                        required
                                        id={`employee_response_question_${
                                          questionIndex + 1
                                        }`}
                                        name={`employee_response`}
                                        size="small"
                                        maxRows={4}
                                        fullWidth
                                        multiline
                                        value={question.employee_response}
                                        // rows={1}
                                        sx={{
                                          m: 2,
                                          overflow: "auto",
                                        }}
                                        style={{ height: "auto" }}
                                        InputProps={{
                                          readOnly: true,
                                          style: {
                                            minHeight: "unset",
                                          },
                                        }}
                                      />
                                    </Box>
                                  ) : question.answer_type === "multichoice" ? (
                                    <Box>
                                      <Box
                                        sx={{
                                          display: "block",
                                          alignItems: "center",
                                          m: 1,
                                        }}
                                      >
                                        <RadioGroup
                                          id={`employee_response_question_${
                                            questionIndex + 1
                                          }`}
                                          name={`employee_response_radio`}
                                          value={
                                            question.employee_response
                                              ? question.employee_response
                                              : null
                                          }
                                        >
                                          {question.answer_choices &&
                                            question.answer_choices.map(
                                              (
                                                ans: any,
                                                answerValueIndex: number
                                              ) => (
                                                <FormControlLabel
                                                  id={`employee_response_question_${
                                                    questionIndex + 1
                                                  }_answer_value_${
                                                    answerValueIndex + 1
                                                  }`}
                                                  required
                                                  key={ans.option_id}
                                                  value={ans.answer_value}
                                                  control={
                                                    <Radio
                                                      id={`employee_response_question_${
                                                        questionIndex + 1
                                                      }_answer_value_${
                                                        answerValueIndex + 1
                                                      }_radio_button`}
                                                    />
                                                  }
                                                  label={ans.answer_value}
                                                />
                                              )
                                            )}
                                        </RadioGroup>
                                      </Box>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          m: 1,
                                          p: 1,
                                        }}
                                      >
                                        <Typography
                                          fontWeight={"bold"}
                                          paragraph
                                          sx={{ width: "11rem" }}
                                        >
                                          Comments :{" "}
                                        </Typography>
                                        <TextField
                                          id={`employee_comment_question_${
                                            questionIndex + 1
                                          }`}
                                          name="employee_comment"
                                          fullWidth
                                          multiline
                                          maxRows={4}
                                          // rows={2}
                                          style={{
                                            height: "auto",
                                            overflow: "auto",
                                          }}
                                          InputProps={{
                                            readOnly: true,
                                          }}
                                          value={question?.employee_comment}
                                        />
                                      </Box>
                                    </Box>
                                  ) : (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        m: 1,
                                        p: 1,
                                      }}
                                    >
                                      <Rating
                                        id={`employee_rating_question_${
                                          questionIndex + 1
                                        }`}
                                        size="large"
                                        precision={0.5}
                                        value={parseFloat(
                                          question?.employee_rating
                                        )}
                                        className="rating-color"
                                        readOnly
                                      />
                                    </Box>
                                  )}
                                </Box>
                              </Box>
                            </Box>
                          );
                        })}
                      </Box>
                    </FormControl>
                    <Box
                      sx={{
                        display: "flex",
                        m: 1,
                        p: 1,
                      }}
                    >
                      <Typography
                        fontWeight={"bold"}
                        paragraph
                        sx={{ width: "11rem" }}
                      >
                        Feedback :{" "}
                      </Typography>
                      <TextField
                        id={`employee_feedback`}
                        name="employee_comment"
                        fullWidth
                        multiline
                        maxRows={4}
                        // rows={2}
                        style={{
                          height: "auto",
                          overflow: "auto",
                        }}
                        InputProps={{
                          readOnly: true,
                        }}
                        value={res.employee_feedback}
                      />
                    </Box>
                  </Box>
                );
              })}
            </>
          )}
        </Box>
      </Box>
      <Box>
        <Footer />
      </Box>
    </>
  );
};

export default ShowFeedBackFormReview;
