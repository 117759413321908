import React, { useEffect, useState } from "react";

import { CredentialResponse, GoogleLogin } from "@react-oauth/google";
import jwt from "jwt-decode";

import {
  Alert,
  Box,
  Button,
  CardMedia,
  Container,
  FormControl,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Link as RouteLink,
  Typography,
  Modal,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import Yoda from "../../asset/logo-removebg-preview.png";
import Yodaplus from "../../asset/home-logo1.png";

import { enqueueSnackbar, useSnackbar } from "notistack";

import { clearToken, setIsLoggedIn, setToken } from "../../store/AuthSlice";
import { setFields } from "../../store/Personslice";
import {
  Login,
  ViewUser,
  chnageGoogleToken,
  forgotPasswordEmail,
} from "../../Service/Apis";
import useAxiosInterceptor from "../../components/layout/AxiosInstance";
import {
  ToastVariant,
  useCustomToast,
} from "../../components/Methods/SnackBar";
import { setInPageLoading, setIsLoading } from "../../store/LoadingSlice";
import Navbar from "../../components/Page Component/Navbar";
import Footer from "../../components/Page Component/Footer";
// import  handleShowToast  from "../../components/Methods/Toast";

const LogIn = () => {
  const [isLogged, setIsLogged] = useState(false);
  const [login, setLogin] = useState({
    email: "",
    password: "",
  }); //states that are entered while logging in
  const [openForgotPasswordModal, setOpenForgotPasswordModal] =
    useState<boolean>(false);
  const [error, setError] = useState(""); //displaying error message on the page
  const [error2, setError2] = useState(false); //displaying error message on the page
  const [showPassword, setShowPassword] = useState(false); //to show or hide password
  const [passwordFocused, setPasswordFocused] = useState(false);
  const [forgotPasswordEmailState, setForgotPasswordEmailState] =
    useState<string>("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const showToast = useCustomToast();

  const axiosInstance = useAxiosInterceptor();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const Isloading = useSelector((state: any) => state.loading.isLoading);

  const responseMessage = (response: CredentialResponse) => {
    // const accessToken = response.accessToken;
    const tokenn = response.credential as string;
    const decode: any = jwt(tokenn);
    // var data = {
    // 	clientId:response.clientId,
    // 	token: response.credential
    // };
    var data = {
      email: decode.email,
    };

    const res = axiosInstance
      .post(chnageGoogleToken, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        dispatch(setIsLoggedIn(true));
        const token = res.data;

        dispatch(setToken(token));
        localStorage.setItem("token", token.access);
        localStorage.setItem("refresh", token.refresh);

        localStorage.setItem("profileImage", decode.picture);
        dispatch(setIsLoggedIn(true));

        if (token) {
          // dispatch(setEmail(email));
          //   handleperson();
          localStorage.setItem("email", decode.email);
          // enqueueSnackbar(`Logged in as ${email}`, {
          // 	variant: "success",
          // 	anchorOrigin: { vertical: "top", horizontal: "left" },
          // 	style: {
          // 		marginTop: "3rem",
          // 	},
          // 	autoHideDuration: 1500,
          // });
          handlehowToast(`Logged in as ${decode.email}`, "success", true);
          // fetchData();
          navigate("/dashboard");
        }
      })

      .catch((error) => {
        console.error(error);
        handlehowToast(`${error.response.data.message}`, "error", true);
      });
  };
  //to show or hide password
  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const handlehowToast = (
    message: string,
    variant: ToastVariant,
    isCloseable: boolean
  ) => {
    showToast(message, variant, isCloseable);
  };
  //  Handle Form Input Change

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let name, value;
    name = e.target.name;
    value = e.target.value;

    setLogin({ ...login, [name]: value });
  };

  var emailid: string;

  // Handle Login Submit

  const handleOpenForgotPasswordModal = () => setOpenForgotPasswordModal(true);
  const handleCloseForgotPasswordModal = () =>
    setOpenForgotPasswordModal(false);

  const handlePasswordFocus = () => {
    setPasswordFocused(true);
  };

  const handlePasswordBlur = () => {
    setPasswordFocused(false);
  };

  const token = localStorage.getItem("token");

  //post for logging in
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError("");

    const { email, password } = login;

    var data = {
      email: email,
      password: password,
    };
    const res = await axiosInstance
      .post(Login, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const token = res.data.tokens;
        dispatch(setToken(token));
        dispatch(setIsLoggedIn(true));

        localStorage.setItem("token", token.access);
        localStorage.setItem("refresh", token.refresh);

        if (token) {
          // dispatch(setEmail(email));
          //   handleperson();
          localStorage.setItem("email", email);
          // enqueueSnackbar(`Logged in as ${email}`, {
          // 	variant: "success",
          // 	anchorOrigin: { vertical: "top", horizontal: "left" },
          // 	style: {
          // 		marginTop: "3rem",
          // 	},
          // 	autoHideDuration: 1500,
          // });
          handlehowToast(`Logged in as ${email}`, "success", true);
          // fetchData();
          navigate("/dashboard");
        }
      })

      .catch((error) =>
        handlehowToast(`${error.response.data.detail}`, "error", true)
      );
    emailid = email;
  };

  const sendEmail = async (e: any) => {
    e.preventDefault();
    if (forgotPasswordEmailState === "") {
      dispatch(setIsLoading(false));
      setError2(true);
      return;
    }
    var data = {
      email: forgotPasswordEmailState,
    };
    try {
      dispatch(setIsLoading(true));
      const response = await axios
        .post(`${forgotPasswordEmail}`, data)
        .then((res) => {
          handlehowToast(`Email sent successfully!`, "success", true);
          handleCloseForgotPasswordModal();
          dispatch(setIsLoading(false));
        })
        .catch((error) => {
          console.log(error);
          handlehowToast(`${error.response.data.message}`, "error", true);
          handleCloseForgotPasswordModal();
          dispatch(setIsLoading(false));
        });
    } catch (error) {
      console.log(error);
    }
  };
  //if a user is on login couldn't go back to pages
  useEffect(() => {
    dispatch(setIsLoggedIn(false));
    dispatch(setIsLoading(false));
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.clear();
    dispatch(clearToken());
  }, []);

  //   if (Isloading) {
  //     return (
  //       <>
  //         <CircularProgress
  //           size={50}
  //
  //           sx={{
  //             top: 300,
  //             left: "50%",
  //             position: "relative",
  //             backdropFilter: "blur(5px)", // Apply a blur effect to the background
  //             backgroundColor: "rgba(255, 255, 255, 0.7)", // Semi-transparent white background for the blur effect
  //             display: "flex",
  //             alignItems: "center",
  //             justifyContent: "center",
  //             zIndex: 9999, // Make sure it's above other content
  //           }}
  //         />
  //       </>
  //     );
  //   }

  return (
    <>
      {Isloading && (
        <Box
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backdropFilter: "blur(5px)", // Adjust the blur intensity as needed
            zIndex: 9999,
          }}
        />
      )}

      <Box
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 10000,
        }}
      >
        {Isloading && <CircularProgress size={50} />}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          minHeight: "100vh",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "center", sm: "center", md: "space-between" },
          }}
        >
          {/* <Paper> */}
          <Box
            sx={{
              display: { xs: "none", sm: "none", md: "flex" },
              alignItems: "center",
              mr: 10,
            }}
          >
            <CardMedia
              id="yodapluslogo"
              component="img"
              src={Yodaplus}
              sx={{
                height: "150px",
              }}
            ></CardMedia>
          </Box>
          {/* </Paper> */}
          <Box sx={{ ml: { md: 30 } }}>
            <Paper
              elevation={11}
              sx={{
                // display: "flex",
                justifyContent: "center",
                // boxSizing: "border-box",
                width: "22rem",
                height: { xs: "fir-content", sm: "fit-content", md: "23rem" },
                m: 4,
                p: 2,
                backgroundColor: "#F4F4F4",
                borderRadius: "10%",

                // boxShadow: "0px 0px 10px 2px rgba(0, 100, 0, 0.5)",
              }}
            >
              <Box
                sx={{
                  display: { xs: "flex", sm: "flex", md: "none" },
                  justifyContent: "center",
                }}
              >
                <CardMedia
                  id="miniYodaplusLogo"
                  component="img"
                  src={Yoda}
                  sx={{ height: "6rem", width: "6rem" }}
                ></CardMedia>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center", m: 3 }}>
                <Box>
                  <Box
                    component="form"
                    sx={{}}
                    onSubmit={(e) => handleSubmit(e)}
                  >
                    <FormControl>
                      <TextField
                        id="Email_id"
                        data-testid="Email_id"
                        name="email"
                        size="small"
                        type="email"
                        label="Enter your Email here"
                        variant="outlined"
                        sx={{ m: 2, background: "white", width: "18rem" }}
                        value={login.email}
                        onChange={handleChange}
                        required
                      />
                      <TextField
                        id="filled-password-input"
                        name="password"
                        size="small"
                        label="Password"
                        type={showPassword ? "text" : "password"}
                        autoComplete="current-password"
                        variant="outlined"
                        value={login.password}
                        onChange={handleChange}
                        onFocus={handlePasswordFocus}
                        onBlur={handlePasswordBlur}
                        sx={{ m: 2, background: "white", width: "18rem" }}
                        required
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {passwordFocused && (
                                <IconButton
                                  id="visibility-btn"
                                  onClick={handleTogglePassword}
                                  edge="end"
                                  onMouseDown={(e) => e.preventDefault()}
                                >
                                  {showPassword ? (
                                    <VisibilityOffIcon className="visibility-Off" />
                                  ) : (
                                    <VisibilityIcon className="visibility-on" />
                                  )}
                                </IconButton>
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Box
                        sx={{ m: 2, justifyContent: "center", display: "flex" }}
                      >
                        <Button
                          id="login"
                          variant="contained"
                          // onClick={(e) => {
                          //   handleSubmit(e);
                          // }}
                          sx={{
                            width: "90%",
                            borderRadius: "15px",
                          }}
                          type="submit"
                        >
                          LogIn
                        </Button>
                      </Box>
                    </FormControl>
                  </Box>
                  <Box sx={{ m: 2, justifyContent: "center", display: "flex" }}>
                    <GoogleLogin
                      onSuccess={responseMessage}
                      ux_mode="popup"
                      // cookiePolicy={"single_host_origin"}
                      // onError={errorMessage}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      "&:hover": { cursor: "pointer" },
                    }}
                  >
                    {/* <Link to="/forgotpassword"> */}
                    <RouteLink
                      onClick={() => {
                        handleOpenForgotPasswordModal();
                      }}
                    >
                      Forgot Password ?
                    </RouteLink>
                    <Dialog
                      open={openForgotPasswordModal}
                      onClose={() => {
                        handleCloseForgotPasswordModal();
                      }}
                    >
                      <Box
                        component="form"
                        onSubmit={(e) => {
                          sendEmail(e);
                        }}
                      >
                        <FormControl>
                          <DialogTitle sx={{ textAlign: "center" }}>
                            {"Forgot Password"}
                          </DialogTitle>
                          <DialogContent>
                            <Box sx={{ width: 370, margin: "1vw" }}>
                              <TextField
                                required
                                InputProps={{ sx: { borderRadius: 5 } }}
                                label="Please enter your registered email"
                                variant="outlined"
                                type="email"
                                fullWidth
                                style={{ marginBottom: "-1rem" }}
                                name="email"
                                onChange={(e) => {
                                  setForgotPasswordEmailState(e.target.value);
                                }}
                                helperText={
                                  error2 &&
                                  forgotPasswordEmailState.length === 0 && (
                                    <Typography color={"error"}>
                                      Please fill out this field.
                                    </Typography>
                                  )
                                }
                              />
                            </Box>
                          </DialogContent>
                          <DialogActions>
                            <Box>
                              <Button
                                sx={{
                                  width: "9rem",
                                  margin: "1vw",
                                  "&:hover": {
                                    boxShadow: "10",
                                  },
                                }}
                                type="submit"
                                variant="contained"

                                // onClick={() => {
                                //   sendEmail();
                                // }}
                              >
                                Send Mail
                              </Button>
                            </Box>
                          </DialogActions>
                        </FormControl>
                      </Box>
                    </Dialog>
                    {/* </Link> */}
                    {/* <RouteLink>Forgot Password ?</RouteLink> */}
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default LogIn;
