import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { format } from "date-fns";
import dayjs, { Dayjs } from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  ShowForms,
  createFeedbackForm,
  deleteOptionAPI,
  deleteQuestionAPI,
  listFeedbackForm,
  updateFeedBackForm,
} from "../../../Service/Apis";
import {
  ToastVariant,
  useCustomToast,
} from "../../../components/Methods/SnackBar";
import { setInPageLoading, setIsLoading } from "../../../store/LoadingSlice";
import { RootState } from "../../../store/Store";
import {
  FetchDataFailureAction,
  FetchDataSuccessAction,
  deleteData,
  fetchData,
  postData,
  putData,
} from "../../../store/actions";
import axios from "axios";
import { date } from "../../Form Add/AddForm";
import Navbar from "../../../components/Page Component/Navbar";
import Footer from "../../../components/Page Component/Footer";
import Loading from "../../../components/Loading/Loading";

interface FeedbackEditForm {
  form_id: string;
  form_description: string;
  form_name: string;
  created_by: string;
  employee_deadline: string;
  manager_deadline: string;
  is_uploaded: boolean;
  is_appraisal: boolean;
  is_hierarchical: boolean;
  questions_old_format: {
    question_id: string;
    question: string;
    answer_type: string;
    answer_choices: {
      answer_value: string;
      answer_point: number;
      option_id: string;
    }[];
  }[];
}

interface OptionValue {
  [questionIndex: number]: {
    value: string;
  };
}

interface AnswerWeight {
  [questionIndex: number]: {
    value: number;
  };
}

interface AnswerChoicesError {
  [questionIndex: number]: {
    value: boolean;
  };
}

const initialOptionValue: OptionValue = {};
const initialAnswerWeight: AnswerWeight = {};
const initialAnswerChoice: AnswerChoicesError = {};

const initialState: FeedbackEditForm = {
  form_id: "",
  form_name: "",
  form_description: "",
  employee_deadline: "",
  manager_deadline: "",
  created_by: "",
  is_appraisal: false,
  is_hierarchical: false,
  is_uploaded: false,
  questions_old_format: [
    {
      question: "",
      question_id: "",
      answer_type: "short_answer",
      answer_choices: [],
    },
  ],
};

const EditFeedBackForm = () => {
  const [formContent, setFormContent] =
    useState<FeedbackEditForm>(initialState); //To manage the appraisal form content.

  const [formName, setFormName] = useState<string>("");
  const [formDescription, setFormDescription] = useState<string>("");

  const [optionValue, setOptionValue] =
    useState<OptionValue>(initialOptionValue);
  const [answerWeight, setAnswerWeight] =
    useState<AnswerWeight>(initialAnswerWeight);
  const [forceUpdateKey, setForceUpdateKey] = useState(0);

  const [empDate, setEmpDate] = useState<Dayjs | null>(dayjs(`${date}`));
  const [managerDate, setManagerDate] = useState<Dayjs | null>(
    empDate?.add(1, "day") as Dayjs
  );

  const [deleteQuestionIs, setDeleteQuestionIs] = useState<{
    questionIndex: number | null;
  }>({ questionIndex: null }); //To open confirmation box for deleting question.

  const [answerChoicesError, setAnswerChoicesError] =
    useState<AnswerChoicesError>(initialAnswerChoice);

  const [flag, setFlag] = useState<boolean>(false); //To display message for rating validation after the form submission.

  const showToast = useCustomToast();

  const dispatch = useDispatch();

  const token = localStorage.getItem("token");
  const formId =
    useSelector((state: any) => state.form.id) ||
    localStorage.getItem("formid");
  const isloading = useSelector((state: any) => state.loading.isLoading);

  const Navigate = useNavigate();

  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();

  const handleShowToast = (
    message: string,
    variant: ToastVariant,
    isCloseable: boolean
  ) => {
    showToast(message, variant, isCloseable);
  };

  const handleOpenQuestionDelete = (questionIndex: number) => {
    setDeleteQuestionIs({
      questionIndex: questionIndex,
    });
  };

  const handleCloseQuestionDeleter = () => {
    setDeleteQuestionIs({
      questionIndex: null,
    });
  };

  const addQuestion = () => {
    const newQuestion = {
      question: "",
      question_id: "",
      answer_type: "short_answer",
      answer_choices: [],
    };

    // Create a copy of the current state
    const updatedFormContent = { ...formContent };

    // Add the new question to the copy
    updatedFormContent.questions_old_format.push(newQuestion);

    // Update the state with the new form content
    setFormContent(updatedFormContent);
  };

  const editQuestionType = (
    questionIndex: number,
    questionType: string
  ): void => {
    const updatedFormContent = { ...formContent };
    const question = updatedFormContent.questions_old_format[questionIndex];
    question.answer_type = questionType;

    setFormContent(updatedFormContent);
  };

  const handleSetOptionValue = (questionIndex: number, value: string) => {
    setOptionValue((prevOptionValue) => {
      // Create a copy of the current state to avoid modifying it directly
      const updatedOptionValue = { ...prevOptionValue };

      // Update the value for the specified questionIndex
      updatedOptionValue[questionIndex] = {
        value: value,
      };

      // Return the updated state
      return updatedOptionValue;
    });
  };

  const handleSetAnswerChoiceWeight = async (
    questionIndex: number,
    value: number
  ) => {
    setAnswerWeight((prevOptionValue) => {
      return {
        ...prevOptionValue,

        [questionIndex]: {
          value: value,
        },
      };
    });
  };

  //To Keep Minimum 2 fields for one mulltiple choice question validation.
  const handleAnswerChoice = (questionIndex: number, value: number) => {
    setAnswerChoicesError((prevAnswerError) => {
      const updatedAnswerError = { ...answerChoicesError };

      const questionError = updatedAnswerError[questionIndex] || {
        value: false,
      };

      questionError.value = value < 2;
      updatedAnswerError[questionIndex] = questionError;

      return updatedAnswerError;
    });
  };

  const addOption = (questionIndex: number) => {
    const updatedFormContent = { ...formContent };
    const question = updatedFormContent.questions_old_format[questionIndex];
    if (
      optionValue?.[questionIndex]?.value === "" ||
      optionValue?.[questionIndex]?.value === undefined
    ) {
      handleShowToast("Please fill out Option", "error", true);
      return;
    }
    const newOption = {
      answer_value: optionValue?.[questionIndex]?.value, //newOptionValue,
      option_id: "",
      answer_point:
        answerWeight?.[questionIndex]?.value === undefined
          ? 0
          : answerWeight?.[questionIndex]?.value,
    };
    setFormContent((prevFormContent) => {
      const updatedFormContent = { ...prevFormContent };
      question.answer_choices.push(newOption);
      return updatedFormContent;
    });

    handleSetOptionValue(questionIndex, "");

    handleSetAnswerChoiceWeight(questionIndex, 0);

    setForceUpdateKey((prevKey) => prevKey + 1);

    handleAnswerChoice(questionIndex, question.answer_choices.length);
  };

  const deleteOption = (questionIndex: number, optionIndex: number) => {
    const updatedFormContent = { ...formContent };
    const question: any =
      updatedFormContent.questions_old_format[questionIndex];
    const option_id = question.answer_choices[optionIndex].option_id;

    if (option_id) {
      try {
        const response = dispatch2(
          deleteData(`${deleteOptionAPI}${option_id}`)
        );
        handleAnswerChoice(questionIndex, question.answer_choices.length);
        question.answer_choices.splice(optionIndex, 1);
        setFormContent(updatedFormContent);
      } catch (error) {
        console.log(error);
      }
    } else {
      question.answer_choices.splice(optionIndex, 1);
      setFormContent(updatedFormContent);
      handleAnswerChoice(questionIndex, question.answer_choices.length);
    }
  };

  const deleteQuestions = (questionIndex: number) => {
    const totalQuestions = formContent.questions_old_format.length;
    if (questionIndex > -1 && totalQuestions > 1) {
      const updatedFormContent = { ...formContent };
      const question_id =
        updatedFormContent.questions_old_format[questionIndex].question_id;

      if (question_id !== "") {
        try {
          const response = dispatch2(
            deleteData(`${deleteQuestionAPI}${question_id}`)
          )
            .then((response) => {
              handleShowToast("Question Deleted", "success", true);
              updatedFormContent.questions_old_format.splice(questionIndex, 1);
              setFormContent(updatedFormContent);
            })
            .catch((error) => {
              console.log(error);
            });
        } catch (error) {
          console.log(error);
        }
      } else if (question_id === "") {
        updatedFormContent.questions_old_format.splice(questionIndex, 1);
        setFormContent(updatedFormContent);
      }
    } else if (totalQuestions <= 1) {
      handleShowToast("Cannot Delete Last Question", "info", true);
    }
  };

  const handleQuestionChange = (questionIndex: number, value: string): void => {
    const updatedFormContent = { ...formContent };
    updatedFormContent.questions_old_format[questionIndex].question = value;

    setFormContent(updatedFormContent);
  };

  //To create a form.
  const handleSubmit = async (e: any) => {
    e.preventDefault();

    let isAnyAnswerChoiceError = false;

    //Check for an error for answer choices have minimum 2 options show error and return.
    Object.values(answerChoicesError).forEach((categories) => {
      Object.values(categories).forEach((questions_old_format: any) => {
        Object.values(questions_old_format).forEach((error: any) => {
          if (error.value) {
            isAnyAnswerChoiceError = true;
          }
        });
      });
    });

    if (isAnyAnswerChoiceError) {
      setFlag(true);
      return;
    }

    const { questions_old_format } = formContent;
    var data = {
      form_name: formName,
      form_description: formDescription,
      employee_deadline: empDate ? format(empDate.toDate(), "yyyy-MM-dd") : "",
      manager_deadline: managerDate
        ? format(managerDate.toDate(), "yyyy-MM-dd")
        : "",
      teams: [],
      is_hierarchical: false,
      is_360: false,
      is_appraisal: false,
      // manager_fields_visible: managerResponseVisibility,
      questions_old_format,
    };

    // API call for creating a form.
    try {
      const response = await dispatch2(
        putData(`${updateFeedBackForm}${formId}`, data)
      )
        .then((response) => {
          setFormContent(initialState);
          handleShowToast(
            `${formName} Created Successfully !`,
            "success",
            true
          );
          setFormContent(initialState);
          // dispatch(postDataSuccess(null));
          Navigate("/createdforms");
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  // View particular form.
  useEffect(() => {
    dispatch(setIsLoading(true));
    const response = axios
      .get(`${listFeedbackForm}&form_id=${formId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (formId !== "") {
          setFormContent({
            ...formContent,
            questions_old_format: res.data[0].questions_old_format.map(
              (question: any) => ({
                question_id: question.question_id,
                question: question.question,
                answer_type: question.answer_type,
                answer_choices: question.answer_choices.map((choice: any) => ({
                  answer_value: choice.answer_value,
                  option_id: choice.option_id,
                  answer_point: choice.answer_point,
                })),
              })
            ),
          });
          setFormName(res.data[0].form_name);
          setFormDescription(res.data[0].form_description);
        }
        dispatch(setIsLoading(false));
      })
      .catch((error) => {
        dispatch(setIsLoading(false));
        console.log(error);
      });
  }, [formId]);

  if (isloading) {
    return (
      <>
        <Loading isLoading={isloading as boolean} />
      </>
    );
  }

  return (
    <>
      <Box>
        <Navbar />
      </Box>
      <Container sx={{ mt: 12, mb: 10 }}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box component="form" onSubmit={handleSubmit}>
            <Box sx={{ width: "75vw" }}>
              <Card
                elevation={4}
                sx={{
                  m: 2,
                  p: 2,
                  display: "flex",
                  justifyContent: "center",
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", // Add boxShadow here
                  border: "1px solid #ccc",
                }}
              >
                <Box sx={{ width: "90%" }}>
                  <TextField
                    id="form_name"
                    name="form_name"
                    required
                    fullWidth
                    multiline
                    maxRows={4}
                    sx={{ overflow: "auto", p: 1 }}
                    variant="standard"
                    size="small"
                    placeholder="Form Heading"
                    value={formName}
                    onChange={(e) => {
                      setFormName(e.target.value);
                    }}
                  />
                  <TextField
                    id="description"
                    fullWidth
                    required
                    variant="standard"
                    size="small"
                    placeholder="Form Description"
                    multiline
                    maxRows={4}
                    minRows={2}
                    sx={{ overflow: "auto", p: 1 }}
                    value={formDescription}
                    onChange={(e) => {
                      setFormDescription(e.target.value);
                    }}
                  />
                </Box>
              </Card>
            </Box>
            <Box>
              <Box>
                {formContent?.questions_old_format?.map(
                  (question, questionIndex) => {
                    return (
                      <Card
                        elevation={4}
                        sx={{
                          m: 2,
                          p: 2,
                          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", // Add boxShadow here
                          border: "1px solid #ccc",
                        }}
                      >
                        <Box sx={{ m: 2, width: "100%" }} key={questionIndex}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                width: "77%",
                              }}
                            >
                              <InputLabel
                                sx={{
                                  width: "10rem",
                                  fontWeight: "bold",
                                }}
                              >
                                Question {questionIndex + 1} :
                              </InputLabel>
                              <TextField
                                id={`question-${questionIndex}`}
                                size="small"
                                fullWidth
                                multiline
                                maxRows={4}
                                sx={{ overflow: "auto" }}
                                value={question.question}
                                required
                                variant="standard"
                                onChange={(e) => {
                                  handleQuestionChange(
                                    questionIndex,
                                    e.target.value as string
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ m: 2 }}>
                              <Select
                                id="question-type"
                                size="small"
                                value={question.answer_type}
                                sx={{
                                  height: "2.5rem",
                                  width: "10.5rem",
                                }}
                                onChange={(e) => {
                                  editQuestionType(
                                    questionIndex,
                                    e.target.value as string
                                  );
                                }}
                              >
                                <MenuItem
                                  id="short-answer"
                                  value="short_answer"
                                >
                                  Short Answer
                                </MenuItem>
                                <MenuItem id="multichoice" value="multichoice">
                                  Multichoice
                                </MenuItem>
                                <MenuItem id="starrating" value="starrating">
                                  Start Rating
                                </MenuItem>
                              </Select>
                              <IconButton
                                onClick={() => {
                                  handleOpenQuestionDelete(questionIndex);
                                }}
                              >
                                <DeleteIcon fontSize="medium" color="primary" />
                              </IconButton>
                              <Dialog
                                open={
                                  deleteQuestionIs.questionIndex ===
                                  questionIndex
                                }
                                onClose={handleCloseQuestionDeleter}
                                // style={{width:"45rem"}}
                                sx={{
                                  m: 1,
                                  "& .MuiDialog-paper": {
                                    width: "30rem",
                                  },
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "flex-end",
                                  }}
                                >
                                  <IconButton
                                    onClick={() => handleCloseQuestionDeleter()}
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                </Box>
                                <DialogTitle>Confirmation</DialogTitle>
                                <DialogContent>
                                  <Typography>
                                    Do you want to delete?
                                  </Typography>
                                </DialogContent>
                                <DialogActions>
                                  <Button
                                    id="no"
                                    onClick={handleCloseQuestionDeleter}
                                  >
                                    No
                                  </Button>
                                  <Button
                                    id="yes"
                                    variant="contained"
                                    onClick={() => {
                                      deleteQuestions(questionIndex);
                                      handleCloseQuestionDeleter();
                                    }}
                                  >
                                    Yes
                                  </Button>
                                </DialogActions>
                              </Dialog>
                            </Box>
                          </Box>
                          <Box>
                            {question.answer_type === "multichoice" && (
                              <Container sx={{ p: 1 }}>
                                {question.answer_choices.map(
                                  (answerChoice, index) => (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                      key={index}
                                    >
                                      <Radio size="small" disabled />
                                      <TextField
                                        id={`answer_choices-question${questionIndex}-${index}`}
                                        // label="Standard"
                                        fullWidth
                                        multiline
                                        maxRows={4}
                                        sx={{
                                          overflow: "auto",
                                          m: 1,
                                        }}
                                        size="small"
                                        variant="standard"
                                        value={answerChoice.answer_value}
                                        disabled
                                      />
                                      <Button
                                        id={`deleteOption-${index}`}
                                        onClick={() =>
                                          deleteOption(questionIndex, index)
                                        }
                                      >
                                        <DeleteIcon />
                                      </Button>
                                    </Box>
                                  )
                                )}
                                <Box sx={{ display: "flex" }}>
                                  <TextField
                                    id={`answer_value-question-${questionIndex}`}
                                    name="answer_value"
                                    variant="standard"
                                    size="small"
                                    multiline
                                    maxRows={4}
                                    sx={{
                                      overflow: "auto",
                                      m: 1,
                                      width: "40%",
                                    }}
                                    value={optionValue[questionIndex]?.value}
                                    onChange={(e) => {
                                      handleSetOptionValue(
                                        questionIndex,
                                        e.target.value
                                      );
                                    }}
                                    required={
                                      question.answer_type === "multichoice" &&
                                      question.answer_choices.length === 0
                                    }
                                    placeholder="Add an option"
                                    helperText={
                                      <>
                                        {answerChoicesError?.[questionIndex]
                                          ?.value &&
                                          flag && (
                                            <Typography
                                              color={"error"}
                                              id="minimum-two-options-error"
                                            >
                                              Please give minimum two options.
                                            </Typography>
                                          )}
                                      </>
                                    }
                                  />
                                  <Button
                                    id={`add-option`}
                                    size="small"
                                    onClick={() => addOption(questionIndex)}
                                    sx={{ m: 1 }}
                                  >
                                    Add Option
                                  </Button>
                                </Box>
                              </Container>
                            )}
                          </Box>
                        </Box>
                      </Card>
                    );
                  }
                )}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "right",
                    m: 1,
                  }}
                >
                  <Button
                    id="addQuestion"
                    variant="outlined"
                    onClick={() => addQuestion()}
                    size="small"
                  >
                    Add Question
                  </Button>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                display: { xs: "block", sm: "block", md: "flex" },
                justifyContent: "center",
              }}
            >
              <Box id="employee_date_picker" sx={{ m: 1, p: 1 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker", "DatePicker"]}>
                    <DatePicker
                      data-testid="employee_date_pick"
                      className="emp_date_picker"
                      label="Employee DeadLine"
                      value={empDate}
                      sx={{ color: "green" }}
                      disablePast
                      format="YYYY-MM-DD"
                      onChange={(newValue) => {
                        setEmpDate(newValue);
                        setManagerDate(newValue?.add(1, "day") as Dayjs);
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button id="CreateFormButton" variant="contained" type="submit">
                Update Form
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
      <Box>
        <Footer />
      </Box>
    </>
  );
};

export default EditFeedBackForm;
