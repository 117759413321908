import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
	Box,
	Button,
	TextField,
	Autocomplete,
	Grid,
	Checkbox,
	CircularProgress,
	Badge,
	Chip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import axios, { AxiosError } from "axios";
import { RootState } from "../../store/Store";
import {
	fetchData,
	FetchDataFailureAction,
	FetchDataSuccessAction,
} from "../../store/actions";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import {
	getAllSkills,
	getEmployeeSkillDocument,
	skillDocumentUpload,
	skillstoemployee,
	viewUsers,
} from "../../Service/Apis";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import Loading from "../../components/Loading/Loading";
import NothingToDisplayComponent from "../../components/Data/NothingToShowComponent";
import {
	ToastVariant,
	useCustomToast,
} from "../../components/Methods/SnackBar";
import dayjs from "dayjs";

// Define interfaces for our data types
interface Skill {
	skill_id: string;
	skill_name: string;
	created_at: string;
}

interface Employee {
	id: string;
	employee_code: string;
	first_name: string;
	last_name: string;
	gender: string;
	email: string;
	roles: string[];
	teams: string[];
	designation: string;
	date_of_birth: string;
	date_of_joining: string;
	date_of_confirmation: string;
	date_of_resignation: string | null;
	date_of_exit: string | null;
	is_active: boolean;
	is_superuser: boolean;
	is_staff: boolean;
	last_login: string;
}

interface Document {
	document_id: string;
	document_name: string;
}

interface FormValues {
	skill: Skill | null;
	employee: Employee[];
	date: Date | null;
}

const validationSchema = Yup.object({
	skill: Yup.object().nullable().required("Skill is required"),
	employee: Yup.array().min(1, "At least one employee is required"),
	date: Yup.date().required("Completion date is required").nullable(),
});

const SkillDocument = () => {
	const [skills, setSkills] = useState<Skill[]>([]);
	const [employees, setEmployees] = useState<Employee[]>([]);
	const [documents, setDocuments] = useState<Document[]>([]);
	const [selectedDocuments, setSelectedDocuments] = useState<string[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	const [selectedSkillId, setSelectedSkillId] = useState("");
	const [documentLoading, setDocumentLoading] = useState(false);
	const showToast = useCustomToast();
	const handleShowToast = (
		message: string,
		variant: ToastVariant,
		isCloseable: boolean
	) => {
		showToast(message, variant, isCloseable);
	};
	const dispatch2: ThunkDispatch<
		RootState,
		null,
		FetchDataSuccessAction | FetchDataFailureAction
	> = useDispatch();

	const [isPageLoading, setIsPageLoading] = useState(true); // For initial page load
	const [isSkillLoading, setIsSkillLoading] = useState(false); // For skill data loading
	const [isEmployeeLoading, setIsEmployeeLoading] = useState(false); // For employee data loading
	const [isDocumentUploading, setIsDocumentUploading] = useState(false); // For document upload
	const [isFormSubmitting, setIsFormSubmitting] = useState(false); // For form submission

	const fetchSkills = async () => {
		try {
			setIsSkillLoading(true);
			dispatch2(fetchData(`${getAllSkills}`))
				.then((res) => {
					if (res && Array.isArray(res)) {
						setSkills(res);
					} else {
						console.error("Invalid response format:", res);
					}
				})
				.catch((error) => {
					console.error("Error fetching skills:", error);
					handleShowToast("Error fetching data", "error", true);
				})
				.finally(() => {
					setIsSkillLoading(false);
				});
		} catch (e) {
			console.error(e);
			setIsSkillLoading(false);
		}
	};

	const fetchEmployees = async () => {
		try {
			setIsEmployeeLoading(true);
			dispatch2(fetchData(`${viewUsers}`))
				.then((res) => {
					if (res && Array.isArray(res)) {
						setEmployees(res);
					} else {
						console.error("Invalid response format:", res);
					}
				})
				.catch((error) => {
					console.error("Error fetching employees:", error);
				})
				.finally(() => {
					setIsEmployeeLoading(false);
				});
		} catch (e) {
			console.error(e);
			setIsEmployeeLoading(false);
		}
	};

	const getData = async () => {
		try {
			setIsLoading(true);
			await fetchSkills();
			await fetchEmployees();
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		getData();
	}, []);

	const fetchDocuments = async (skillId: string) => {
		try {
			setDocumentLoading(true);
			const data = { skill_id: skillId };
			dispatch2(fetchData(`${getEmployeeSkillDocument}`, data))
				.then((res) => {
					if (res && Array.isArray(res)) {
						setDocuments(res);
					} else {
						console.error("Invalid response format:", res);
					}
				})
				.catch((error) => {
					console.error("Error fetching documents:", error);
					handleShowToast("Error fetching documents", "error", true);
				});
		} catch (e) {
			console.error(e);
		} finally {
			setDocumentLoading(false);
		}
	};

	const handleSubmit = async (values: FormValues, { resetForm }: any) => {
		if (!values.skill) return;

		setIsFormSubmitting(true);
		const formData = new FormData();
		formData.append("skill_id", values.skill.skill_id);
		values.employee.forEach((emp) => {
			formData.append("employee_ids", emp.id);
		});
		selectedDocuments.forEach((docId) =>
			formData.append("document_ids", docId)
		);
		formData.append("hr_completion_date", values.date?.toISOString() || "");

		try {
			await axios
				.post(skillstoemployee, formData, {
					headers: {
						"Content-Type": "multipart/form-data",
						Authorization: `Bearer ${localStorage.getItem("token")}`,
					},
				})
				.then((res) => {
					handleShowToast("Data submitted successfully", "success", true);
					resetForm();
					setSelectedDocuments([]);
					setDocuments([]);
				});
		} catch (error) {
			const axiosError = error as AxiosError<{ error?: string }>;

			// Handle both object and string error formats
			const errorMessage =
				axiosError.response?.data?.error ||
				axiosError.response?.data?.toString() ||
				"An error occurred";

			handleShowToast(errorMessage, "error", true);
			console.error(error);
		} finally {
			setIsFormSubmitting(false);
		}
	};

	const handleSkillChange = useCallback(
		(setFieldValue: (field: string, value: any) => void) =>
			(event: any, newValue: Skill | null) => {
				setFieldValue("skill", newValue);
				// Clear data when skill is cleared
				if (!newValue) {
					// Reset any grid data state
					setDocuments([]); // Assuming you have a setRows state setter
					// Clear other dependent states if needed
				}
				if (newValue) {
					fetchDocuments(newValue.skill_id);
					setSelectedSkillId(newValue.skill_id);
				}
			},
		[]
	);

	const columns: GridColDef[] = useMemo(
		() => [
			{
				field: "selection",
				headerName: "Select",
				width: 100,
				renderCell: (params) => (
					<Checkbox
						checked={selectedDocuments.includes(params.row.document_id)}
						onChange={(e) => {
							const docId = params.row.document_id;
							setSelectedDocuments((prev) =>
								e.target.checked
									? [...prev, docId]
									: prev.filter((id) => id !== docId)
							);
						}}
					/>
				),
			},
			{ field: "document_name", headerName: "Document Name", width: 300 },
		],
		[selectedDocuments]
	);

	async function handleFileUpload(event: React.ChangeEvent<HTMLInputElement>) {
		if (!event.target.files?.length) return;

		const files = Array.from(event.target.files);
		setIsDocumentUploading(true);

		try {
			const uploadPromises = files.map(async (file) => {
				const formData = new FormData();
				formData.append("files", file);
				formData.append("skill", selectedSkillId);

				const response = await axios
					.post(skillDocumentUpload, formData, {
						headers: {
							"Content-Type": "multipart/form-data",
							Authorization: `Bearer ${localStorage.getItem("token")}`,
						},
					})
					.then((res) => {
						handleShowToast("File uploaded successfully", "success", true);
					});
				return response;
			});

			await Promise.all(uploadPromises);
			fetchDocuments(selectedSkillId);
		} catch (error) {
			handleShowToast("Error uploading files", "error", true);
			console.error("Error uploading files:", error);
		} finally {
			setIsDocumentUploading(false);
			event.target.value = "";
		}
	}

	const MemoizedDataGrid = memo(
		({
			documents,
			columns,
		}: {
			documents: Document[];
			columns: GridColDef[];
		}) => {
			const getRowId = useCallback((row: Document) => row.document_id, []);

			return (
				<Box>
					<DataGrid rows={documents} columns={columns} getRowId={getRowId} />
				</Box>
			);
		}
	);

	if (isLoading) {
		return <Loading isLoading={isLoading} />;
	}

	const initialValues: FormValues = {
		skill: null,
		employee: [],
		date: null,
	};

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={handleSubmit}
			>
				{({ values, setFieldValue, errors, touched }) => (
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							marginLeft: "21rem",
							flexDirection: "column",
						}}
					>
						<Form>
							<Grid container spacing={2} justifyContent="center">
								<Grid item xs={12} sm={6}>
									<Autocomplete<Skill, false>
										options={skills}
										getOptionLabel={(option) => option?.skill_name || ""}
										value={values.skill}
										onChange={handleSkillChange(setFieldValue)}
										sx={{ maxWidth: "300" }}
										loading={isSkillLoading}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Select Skill"
												error={touched.skill && Boolean(errors.skill)}
												helperText={touched.skill && errors.skill}
												InputProps={{
													...params.InputProps,
													endAdornment: (
														<>
															{isSkillLoading ? (
																<CircularProgress color="inherit" size={20} />
															) : null}
															{params.InputProps.endAdornment}
														</>
													),
												}}
											/>
										)}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Autocomplete<Employee, true>
										multiple
										options={employees}
										getOptionLabel={(option) =>
											option ? `${option.first_name} ${option.last_name}` : ""
										}
										value={values.employee}
										onChange={(_, newValue) => {
											setFieldValue("employee", newValue);
										}}
										sx={{ width: 300 }}
										loading={isEmployeeLoading}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Select Employees"
												error={touched.employee && Boolean(errors.employee)}
												helperText={
													touched.employee && errors.employee?.toString()
												}
												InputProps={{
													...params.InputProps,
													endAdornment: (
														<>
															{isEmployeeLoading ? (
																<CircularProgress color="inherit" size={20} />
															) : null}
															{params.InputProps.endAdornment}
														</>
													),
												}}
											/>
										)}
										renderTags={(tagValue, getTagProps) =>
											tagValue.map((option, index) => (
												<Chip
													label={`${option.first_name} ${option.last_name}`}
													{...getTagProps({ index })}
													deleteIcon={<CloseIcon />}
													onDelete={() => {
														const newValue = values.employee.filter(
															(emp) => emp.id !== option.id
														);
														setFieldValue("employee", newValue);
													}}
													sx={{ margin: "2px" }}
												/>
											))
										}
									/>
									{values.employee.length > 0 && (
										<Badge
											badgeContent={values.employee.length}
											color="primary"
											sx={{ position: "absolute", right: 30, top: 10 }}
										/>
									)}
								</Grid>
								<Grid item xs={12} sm={6}>
									<DatePicker
										label="Completion Date"
										value={values.date}
										minDate={new Date()}
										sx={{ width: 300 }}
										onChange={(newValue) => setFieldValue("date", newValue)}
										slotProps={{
											textField: {
												error: touched.date && Boolean(errors.date),
												helperText: touched.date && errors.date,
											},
										}}
									/>
								</Grid>
							</Grid>

							<Box m={2}>
								{isDocumentUploading ? (
									<CircularProgress />
								) : documents.length === 0 ? (
									<Box>
										<NothingToDisplayComponent />
									</Box>
								) : (
									<MemoizedDataGrid documents={documents} columns={columns} />
								)}
							</Box>

							<Grid item xs={12} sm={6}>
								<Box
									sx={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<label htmlFor="upload-file">
										<Button
											sx={{
												margin: 2,
											}}
											variant="contained"
											color="primary"
											component="span"
											disabled={isDocumentUploading || isFormSubmitting}
										>
											{isDocumentUploading ? (
												<CircularProgress size={24} />
											) : (
												"Upload Document"
											)}
											<input
												type="file"
												id="upload-file"
												multiple
												style={{ display: "none" }}
												onChange={handleFileUpload}
											/>
										</Button>
									</label>
									<Button
										variant="contained"
										color="primary"
										type="submit"
										disabled={isFormSubmitting}
									>
										{isFormSubmitting ? (
											<CircularProgress size={24} />
										) : (
											"Submit"
										)}
									</Button>
								</Box>
							</Grid>
						</Form>
					</Box>
				)}
			</Formik>
		</LocalizationProvider>
	);
};

export default SkillDocument;
