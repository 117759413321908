import { useEffect, useMemo, useState } from "react";
import {
	Box,
	CircularProgress,
	IconButton,
	MenuItem,
	Paper,
	Select,
	Typography,
	Switch, // Add Switch import
} from "@mui/material";
import LineChartComponent from "../../charts/LineChart";
import {
	fetchData,
	FetchDataFailureAction,
	FetchDataSuccessAction,
} from "../../../store/actions";
import { getUtilizationData } from "../../../Service/Apis";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../../../store/Store";
import { useDispatch, useSelector } from "react-redux";
import MonthButtonGroup from "../../utilization/MonthsButtonGroup";
import { APIDataItem } from "../../../types";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { saveAs } from "file-saver";
import { useCustomToast } from "../../Methods/SnackBar";

const UtilizationChart = () => {
	const dispatch2: ThunkDispatch<
		RootState,
		null,
		FetchDataSuccessAction | FetchDataFailureAction
	> = useDispatch();
	const [utilizationData, setUtilizationData] = useState<any>([]);
	const [teamName, setTeamName] = useState<string>("");
	const [month, setMonth] = useState<string>("1");
	const [selectedFormat, setSelectedFormat] = useState<string>("excel");
	const showToast = useCustomToast();
	const [selectedEmployees, setSelectedEmployees] = useState<string[]>([]);
	const [loading, setLoading] = useState(false);
	const [downloading, setDownloading] = useState(false);
	const [selectAll, setSelectAll] = useState(true);

	const nrole = useSelector((state: any) => state.auth.selectedRole);
	const paramsData = {
		role: nrole,
	};

	const fetchUtilizationData = async (selectedMonth: string) => {
		try {
			setLoading(true);
			const res = await dispatch2(
				fetchData(`${getUtilizationData}${selectedMonth}`, paramsData)
			);
			setMonth(selectedMonth);
			setUtilizationData(res);

			// Set all employee IDs as selected by default
			const allEmployeeNames = res?.teams
				? res?.teams?.flatMap((team: any) =>
						team.employees.map((item: any) => item.employee_name)
				  ) || []
				: res?.utilizations?.flatMap((item: any) => item.employee_name) || [];

			setSelectedEmployees(allEmployeeNames);
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchUtilizationData("1");
	}, []);

	const handleMonthChange = (selectedMonth: string) => {
		fetchUtilizationData(selectedMonth);
	};

	const buttonData = [
		{ label: "1M", onClick: () => handleMonthChange("1") },
		{ label: "3M", onClick: () => handleMonthChange("3") },
		{ label: "6M", onClick: () => handleMonthChange("6") },
		{ label: "1Y", onClick: () => handleMonthChange("12") },
	];

	const computedData = useMemo(() => {
		const selectedTeam =
			utilizationData?.teams?.find(
				(team: any) => team.team_name === teamName
			) || utilizationData?.teams?.[0];

		setTeamName(selectedTeam?.team_name);

		return selectedTeam
			? selectedTeam?.utilizations?.map((item: any) => ({
					employee_name: item.employee_name,
					employee_id: item.employee_id,
					week: item.week_end_date,
					percent: item.utilization,
			  })) || []
			: utilizationData?.utilizations?.map((item: APIDataItem) => ({
					employee_name: item.employee_name,
					employee_id: item.employee_id,
					week: item.week_end_date,
					percent: item.utilization,
			  })) || [];
	}, [utilizationData, teamName]);

	const onDownload = async () => {
		try {
			setDownloading(true);
			if (nrole.toLowerCase() !== "employee") {
				if (!teamName) {
					console.log("Please select a team");
					throw new Error("Please select a team");
				}
			}
			const downloadParams = {
				...paramsData,
				download: selectedFormat,
			};
			const res = await dispatch2(
				fetchData(`${getUtilizationData}${month}`, downloadParams, {
					responseType: "blob",
				})
			);
			saveAs(
				res,
				`utilization.${selectedFormat === "excel" ? "xlsx" : selectedFormat}`
			);
		} catch (error: any) {
			console.error("Error downloading file:", error);
			showToast(error, "error", true);
		} finally {
			setDownloading(false);
		}
	};

	const handleEmployeeSelection = (employeeName: string) => {
		const newSelectedEmployees = selectedEmployees.includes(employeeName)
			? selectedEmployees.filter((name) => name !== employeeName)
			: [...selectedEmployees, employeeName];

		setSelectedEmployees(newSelectedEmployees);

		// Update selectAll state based on whether all employees are selected
		const allEmployeeNames = getAllEmployeeNames();
		const uniqueEmployees = Array.from(
			new Set(computedData.map((item: any) => item.employee_name))
		);

		if (newSelectedEmployees.length === uniqueEmployees.length) {
			setSelectAll(true);
		}
		if (newSelectedEmployees.length === 0) {
			setSelectAll(false);
		}
	};

	// Helper function to get all employee names
	const getAllEmployeeNames = () => {
		return utilizationData?.teams
			? utilizationData?.teams?.flatMap((team: any) =>
					team.employees.map((employee: any) => employee.employee_name)
			  ) || []
			: utilizationData?.data?.map((item: any) => item.employee_name) || [];
	};

	// Update the handleSwitchChange function to simply select all or none
	const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const checked = event.target.checked;
		setSelectAll(checked);

		if (checked) {
			// Select all employees
			setSelectedEmployees(getAllEmployeeNames());
		} else {
			// Deselect all employees
			setSelectedEmployees([]);
		}
	};

	return (
		<>
			<Paper sx={{ m: 2, p: 4 }}>
				<Box display="flex" justifyContent="space-between" alignItems="center">
					<Box
						display="flex"
						justifyContent="center"
						alignItems="center"
						flexDirection="column"
						gap={2}
					>
						<Typography variant="h4">Utilization Chart</Typography>
						<MonthButtonGroup buttonData={buttonData} />
					</Box>
					<Box
						display="flex"
						justifyContent="center"
						alignItems="center"
						flexDirection="column"
						gap={2}
					>
						{utilizationData?.teams && (
							<Box
								display="flex"
								justifyContent="center"
								alignItems="center"
								gap={2}
							>
								<Typography
									variant="h6"
									width={200}
									sx={{
										fontSize: "1rem",
										display: "inline",
									}}
								>
									Select Team
								</Typography>
								<Select
									labelId="team-select-label"
									id="team-select"
									value={teamName}
									label="Team"
									size="small"
									onChange={(e) => setTeamName(e.target.value)}
									sx={{
										width: "100%",
									}}
								>
									{utilizationData?.teams?.map((team: any) => (
										<MenuItem key={team.team_id} value={team.team_name}>
											{team.team_name}
										</MenuItem>
									))}
								</Select>
							</Box>
						)}

						<Box
							display="flex"
							justifyContent="center"
							gap={2}
							alignItems="center"
						>
							{downloading ? (
								<Box
									display="flex"
									justifyContent="center"
									alignItems="center"
									gap={2}
								>
									<CircularProgress size="1rem" />
									<Typography>Downloading...</Typography>
								</Box>
							) : (
								<Box
									display="flex"
									justifyContent="center"
									alignItems="center"
									gap={2}
								>
									<Box
										display="flex"
										justifyContent="center"
										alignItems="center"
										gap={2}
									>
										<Typography
											variant="h6"
											width={200}
											sx={{
												fontSize: "1rem",
												display: "inline",
											}}
										>
											Select format
										</Typography>
										<Select
											labelId="format-select-label"
											id="forma-select"
											value={selectedFormat}
											label="format"
											size="small"
											onChange={(e) => setSelectedFormat(e.target.value)}
											sx={{
												width: "100%",
											}}
										>
											<MenuItem key="excel" value="excel">
												excel
											</MenuItem>
											<MenuItem key="csv" value="csv">
												csv
											</MenuItem>
											<MenuItem key="json" value="json">
												json
											</MenuItem>
										</Select>
									</Box>
									<IconButton
										onClick={() => {
											onDownload();
										}}
									>
										<DownloadForOfflineIcon />
									</IconButton>
								</Box>
							)}
						</Box>
					</Box>
				</Box>
				<Box
					display="flex"
					justifyContent="flex-end"
					alignItems="center"
					gap={2}
					m={2}
					width="100%"
				>
					<Typography>Select All Employees</Typography>
					<Switch
						checked={selectAll}
						onChange={handleSwitchChange}
						color="primary"
					/>
				</Box>
				{loading ? (
					<Box
						display="flex"
						justifyContent="center"
						alignItems="center"
						width="100%"
						height={200}
					>
						<CircularProgress />
					</Box>
				) : (
					<Box
						display="flex"
						justifyContent="center"
						alignItems="center"
						flexDirection="column"
					>
						<LineChartComponent
							data={computedData || []}
							YAxisLabel="Percentage (%)"
							selectedEmployees={selectedEmployees}
							onEmployeeSelection={handleEmployeeSelection}
						/>
						{/* Displaying the average utilization of the selected team */}
						{utilizationData?.teams && teamName && (
							<Box
								display="flex"
								justifyContent="flex-end"
								alignItems="center"
								width="100%"
								mt={2}
								sx={{
									borderTop: "1px solid #ccc",
									paddingTop: 2,
								}}
							>
								<Typography
									variant="h6"
									sx={{
										fontSize: "1rem",
										fontWeight: "bold",
									}}
								>
									Average Utilization: &nbsp;
									{parseFloat(
										utilizationData.teams.find(
											(team: any) => team.team_name === teamName
										)?.average_utilization
									).toFixed(2) || "N/A"}
									%
								</Typography>
							</Box>
						)}
					</Box>
				)}
			</Paper>
		</>
	);
};

export default UtilizationChart;
