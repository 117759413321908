import { useEffect, useState } from "react";

import {
  AccordionProps,
  AccordionSummaryProps,
  Box,
  Button,
  CircularProgress,
  Fab,
  Typography,
} from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";

import { styled } from "@mui/material/styles";

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import EditIcon from "@mui/icons-material/Edit";

import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";

import { EmployeeSkillSetType } from "./Types/SkillTypes";

import { RootState } from "../../store/Store";
import {
  FetchDataSuccessAction,
  FetchDataFailureAction,
  fetchData,
} from "../../store/actions";
import { skillQuestionsDetail } from "../../Service/Apis";
import { setInPageLoading } from "../../store/LoadingSlice";
import NothingToDisplayComponent from "../../components/Data/NothingToShowComponent";
import Loading from "../../components/Loading/Loading";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .00)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const SkillsView = () => {
  const [skillData, setSkillData] = useState<EmployeeSkillSetType[]>([]);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();

  const isLoading = useSelector((state: any) => state.loading.inPageLoading);

  //API to fetch all skill details.
  useEffect(() => {
    const fetchAllSkills = async () => {
      try {
        dispatch(setInPageLoading(true));
        const response = await dispatch2(fetchData(`${skillQuestionsDetail}`));
        setSkillData(response as EmployeeSkillSetType[]);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setInPageLoading(false));
      }
    };
    fetchAllSkills();
  }, []);

  if (isLoading) {
    return (
      <>
        <Loading isLoading={isLoading as boolean} />
      </>
    );
  }

  return (
    <>
      <Box sx={{ width: { md: "85vw" } }}>
        <Box sx={{ display: "flex", justifyContent: "right", m: 1, p: 0 }}>
          <Button
            id="add-skill-button"
            variant="contained"
            size="small"
            onClick={() => {
              navigate("/addoreditskills");
              localStorage.removeItem("skillId");
            }}
          >
            Add Skill
          </Button>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", m: 2, p: 2 }}>
          <Box>
            {skillData.length === 0 ? (
              <>
                <Box
                  sx={{
                    mt: 9,
                    left: "50%",
                    // transform: "translate(-50%, -50%)",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <NothingToDisplayComponent />
                </Box>
              </>
            ) : (
              <>
                {skillData.map((emp, index) => {
                  return (
                    <Box sx={{ width: "75vw", m: 1, p: 1 }} key={index}>
                      <Accordion>
                        <AccordionSummary>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <Box>
                              <Typography
                                id={`skill-name-${index}`}
                                variant="h5"
                                fontWeight={"bold"}
                              >
                                {emp.skill_name}
                              </Typography>
                            </Box>
                            <Box>
                              <Fab
                                id={`edit-icon-${index}`}
                                size="small"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  localStorage.setItem("skillId", emp.skill_id);
                                  navigate("/addoreditskills");
                                }}
                                sx={{ zIndex: 0 }}
                                color="primary"
                              >
                                <EditIcon fontSize="small" />
                              </Fab>
                            </Box>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                          {emp.questions.map((que, questionIndex) => {
                            return (
                              <Box key={questionIndex} sx={{ m: 2, p: 1 }}>
                                <Box>
                                  <Typography
                                    id={`skill-${index}-question-${questionIndex}`}
                                    variant="h6"
                                    fontSize={18}
                                    fontWeight={"bold"}
                                  >
                                    {questionIndex + 1 + ") "}
                                    {que.question}
                                  </Typography>
                                </Box>
                              </Box>
                            );
                          })}
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  );
                })}
              </>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SkillsView;
