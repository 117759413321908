import React, { useEffect, useState } from "react";

import { FormControl } from "@mui/base";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  InputAdornment,
  InputLabel,
  Slide,
  TextField,
  Typography,
  Autocomplete,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import dayjs, { Dayjs } from "dayjs";

import { format } from "date-fns";

import FileUploadComponent from "../../components/Excel/FileUpload";
import { date } from "../Form Add/AddForm";

import { TransitionProps } from "@mui/material/transitions";
import { ThunkDispatch } from "@reduxjs/toolkit";
import {
  DeleteEmp,
  registerUser,
  updateEmployee,
  viewUsers,
  getAllDesignations,
} from "../../Service/Apis";
import {
  ToastVariant,
  useCustomToast,
} from "../../components/Methods/SnackBar";
import Footer from "../../components/Page Component/Footer";
import Navbar from "../../components/Page Component/Navbar";
import { postDataSuccess } from "../../store/AxiosSlice";
import { setCounts, setIsLoading } from "../../store/LoadingSlice";
import { setisOpen } from "../../store/ModalSlice";
import { RootState } from "../../store/Store";
import {
  DataPayload,
  FetchDataFailureAction,
  FetchDataSuccessAction,
  deleteData,
  fetchData,
  patchData,
  postData,
} from "../../store/actions";
import Loading from "../../components/Loading/Loading";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  //   border: "2px solid #000",
  boxShadow: "0px 0px 10px 2px rgba(0, 100, 0, 0.5)",

  p: 4,
};

type initialemp = {
  first_name: string;
  last_name: string;
  role: string[];
  email: string;
  password: string;
};

const initialStateEmployee = {
  id: "",
  employee_code: "",
  first_name: "",
  last_name: "",
  gender: "",
  roles: [],
  designation: "",
  date_of_birth: "",
  date_of_joining: "",
  date_of_confirmation: "",
  date_of_exit: "",
  email: "",
  password: "",
};

interface Designation {
  id: string;
  designation_name: string;
}

const AddEmployee = () => {
  const [open, setOpen] = React.useState(false); //Open Add Employee Modal.
  const [addEmployee, setAddEmployee] = useState(initialStateEmployee); //State for add employee.
  const [roles, setRoles] = useState<string[]>([]); // Set Roles.
  const [getEmployee, setGetEmployee] = useState<
    (typeof initialStateEmployee)[]
  >([]); // State to get the employee details.
  const [dateOfJoining, setDateOfJoining] = React.useState<Dayjs | null>(
    dayjs(`${date}`)
  );
  const [dateOfBirth, setDateOfBirth] = React.useState<Dayjs | null>(
    dayjs(`${date}`)
  );

  const [showPassword, setShowPassword] = useState(false); //Show Password.
  const [passwordFocused, setPasswordFocused] = useState(false); //Focus Password.
  const [isValidPassword, setIsValidPassword] = useState<{
    specialCharacter: boolean;
    oneUpperCase: boolean;
    oneLowerCase: boolean;
    oneNumber: boolean;
    minCharacter: boolean;
    spacesError: boolean;
  }>({
    specialCharacter: false,
    oneUpperCase: false,
    oneLowerCase: false,
    oneNumber: false,
    minCharacter: false,
    spacesError: false,
  }); // Validation for password.

  const [count, setCount] = useState(0); // To call API again.

  const [otherGender, setOtherGender] = useState(""); //To store Other Gender.

  const dispatch = useDispatch();
  const Navigate = useNavigate();

  const [isSpecialCharError, setIsSpecialCharError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isS, setIsS] = useState(false); //Password Validation Error.

  const [nameError, setNameError] = useState<boolean[]>([]); // Name Validation.
  const [anchorEl, setAnchorEl] = useState(null);

  const isloading = useSelector((state: any) => state.loading.isLoading);
  const counts = useSelector((state: any) => state.loading.count);

  const isOpen = useSelector((state: any) => state.modal.isOpen);
  // const success = useSelector((state: any) => state.axios.data);
  const ispageloading = useSelector(
    (state: any) => state.loading.inPageLoading
  );

  const showToast = useCustomToast();

  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();

  const [designations, setDesignations] = useState<Designation[]>([]);
  const [selectedDesignation, setSelectedDesignation] = useState<Designation | null>(null);
  const [newDesignation, setNewDesignation] = useState("");

  const handleClose = () => {
    setOpen(false);
    setIsSpecialCharError(false);
    setRoles([]);
  };

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const handleShowToast = (
    message: string,
    variant: ToastVariant,
    isCloseable: boolean
  ) => {
    showToast(message, variant, isCloseable);
  };

  const handlePasswordFocus = (event: any) => {
    setPasswordFocused(true);
    setAnchorEl(event.currentTarget);
  };

  const handlePasswordBlur = () => {
    setPasswordFocused(false);
    setAnchorEl(null);
  };

  //console.log(token);

  //To add role
  const handleRoles = (event: SelectChangeEvent<typeof roles>) => {
    const selectedRoles = event.target.value as string[];

    setRoles(selectedRoles);
  };
  //To delete role.
  const handleDeleteRole = (role: string) => {
    const updatedRoles = roles.filter((r) => r !== role);
    setRoles(updatedRoles);
  };

  const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  //To handle Add State.
  const handleChange = (event: any, index: number) => {
    event.preventDefault();
    let name, value;
    name = event.target.name;
    value = event.target.value;
    const regex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;
    const hasSpecialChar = regex.test(value);
    const nameRegex = /^[a-zA-Z' ]*$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const passwordRegex =
      /^(?!\s)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+,\-.;:'"<>/?])(?=.*\d)[^\s]{8,}$/;

    const specialCharacter = /^(?=.*[!@#$%^&*()_+,\-.;:'"<>/?])/;
    const oneUpperCase = /^(?=.*[A-Z])/;
    const oneLowerCase = /^(?=.*[a-z])/;
    const oneNumber = /^(?=.*\d)/;
    const noSpaces = /^[^\s]+$/;

    if (name === "password") {
      setIsValidPassword({
        specialCharacter: specialCharacter.test(value),
        oneUpperCase: oneUpperCase.test(value),
        oneLowerCase: oneLowerCase.test(value),
        oneNumber: oneNumber.test(value),
        spacesError: !noSpaces.test(value),
        minCharacter: value.length < 8,
      });
    }

    if (name === "first_name" || "last_name") {
      setIsSpecialCharError(false);
      const isNameValid = !nameRegex.test(value);
      setNameError((prevNameError) => {
        const updatedNameError = [...prevNameError];
        updatedNameError[index] = isNameValid;
        return updatedNameError;
      });
    }
    // setIsSpecialCharError(hasSpecialChar);
    setAddEmployee({ ...addEmployee, [name]: value });
    if (name === "email") {
      setIsSpecialCharError(false);
      const isEmailValid = !emailRegex.test(value);
      setNameError((prevNameError) => {
        const updatedNameError = [...prevNameError];
        updatedNameError[index] = isEmailValid;
        return updatedNameError;
      });
    }

    setErrorMessage("");
  };

  const validatePassword = (value: any) => {
    const isValid: any = {
      specialCharacter: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(value),
      oneUpperCase: /[A-Z]/.test(value),
      oneLowerCase: /[a-z]/.test(value),
      oneNumber: /\d/.test(value),
      spacesError: /^[^\s]+$/.test(value),
      minCharacter: value.length >= 8,
    };

    setIsValidPassword(isValid);

    const firstError = Object.keys(isValid).find((key) => !isValid[key]);
    if (firstError) {
      switch (firstError) {
        case "specialCharacter":
          setErrorMessage(
            "Password must contain at least one special character."
          );
          setIsS(true);
          break;
        case "oneUpperCase":
          setErrorMessage(
            "Password must contain at least one uppercase letter."
          );
          setIsS(true);

          break;
        case "oneLowerCase":
          setErrorMessage(
            "Password must contain at least one lowercase letter."
          );
          setIsS(true);

          break;
        case "oneNumber":
          setErrorMessage("Password must contain at least one number.");
          setIsS(true);

          break;
        case "minCharacter":
          setErrorMessage("Password should be at least 8 characters long.");
          setIsS(true);

          break;
        case "spacesError":
          setErrorMessage("Password should not contain any spaces.");
          setIsS(true);

          break;
        default:
          setErrorMessage("");
      }
    } else {
      setErrorMessage("");
    }
  };

  //adding a new  employee
  const handleSubmit = async (event: any) => {
    event.preventDefault();

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const {
      employee_code,
      first_name,
      last_name,
      gender,
      email,
      password,
    } = addEmployee;

    const validEmail = emailPattern.test(email);

    validatePassword(password);

    if (errorMessage.length !== 0 && isS === true) {
      return;
    }

    const passwordRegex =
      /^(?!\s)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+,\-.;:'"<>/?])(?=.*\d).{8,}$/;

    var data = {
      employee_code: employee_code,
      first_name: first_name,
      last_name: last_name,
      gender: gender === "Other" ? otherGender : gender,
      roles: roles.map((data) => {
        return {
          role: data,
        };
      }),
      designation: selectedDesignation?.id,
      date_of_birth: dateOfBirth
        ? format(dateOfBirth.toDate(), "yyyy-MM-dd")
        : "",
      date_of_joining: dateOfJoining
        ? format(dateOfJoining.toDate(), "yyyy-MM-dd")
        : "",
      email: email,
      password: password,
    };
    try {
      const response = dispatch2(postData(`${registerUser}`, data))
        .then((response) => {
          setCount(count + 1);
          handleShowToast("Employee Added Successfully", "success", true);
          setCount(count + 1);
          setAddEmployee(initialStateEmployee);
          setRoles([]);
          setNameError([]);
          setOpen(false);
          setErrorMessage("");
          setIsValidPassword({
            spacesError: false,
            specialCharacter: false,
            minCharacter: false,
            oneLowerCase: false,
            oneNumber: false,
            oneUpperCase: false,
          });
          dispatch(setCounts(counts + 1));
          dispatch(postDataSuccess(null));

          Navigate("/employeeinformation");
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  //for showing all the employeess
  useEffect(() => {
    const fetchUserDetails = async () => {
      dispatch(setIsLoading(true));
      try {
        const response: DataPayload = await dispatch2(
          fetchData(`${viewUsers}`)
        );
        dispatch(setIsLoading(false));
        setGetEmployee(response as (typeof initialStateEmployee)[]);
      } catch (error) {
        console.log(error);
        dispatch(setIsLoading(false));
        setRoles([]);
        setAddEmployee(initialStateEmployee);
        setDateOfBirth(dayjs(date));
        setDateOfJoining(dayjs(date));
        setNameError([]);
      }
    };

    fetchUserDetails();
  }, [count]);

  useEffect(() => {
    handleClose();
    dispatch(setisOpen(false));
    setCount(count + 1);
  }, [isOpen]);

  useEffect(() => {
    setRoles(["Employee"]);
  }, []);

  useEffect(() => {
    const fetchDesignations = async () => {
      try {
        const response = await dispatch2(fetchData(`${getAllDesignations}`));
        setDesignations(response as Designation[]);
      } catch (error) {
        console.error("Error fetching designations:", error);
      }
    };
    fetchDesignations();
  }, []);

  const handleCreateDesignation = async (designationName: string) => {
    try {
      const response = await dispatch2(
        postData(`${getAllDesignations}`, {
          designation_name: designationName,
        })
      );
      setDesignations([...designations, response as unknown as Designation]);
      setSelectedDesignation(response  as unknown as Designation);
      handleShowToast("New designation created successfully!", "success", true);
    } catch (error) {
      console.error("Error creating designation:", error);
      handleShowToast("Error creating new designation", "error", true);
    }
  };

  if (ispageloading) {
    return (
      <>
        <Loading isLoading={ispageloading as boolean} />
      </>
    );
  }
  return (
    <>
      <Box>
        <Navbar />
      </Box>
      <Box sx={{ width: "100%", mt: 12, mb: 10 }}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Typography
            fontWeight={"bold"}
            id={`add_employee_heading`}
            variant="h4"
            sx={{ mb: 2 }}
          >
            Add Employee
          </Typography>
        </Box>
        <Box
          component="form"
          onSubmit={(e) => {
            handleSubmit(e);
            // handleClose();
          }}
          sx={{
            display: "flex",
            justifyContent: "space-around",
            width: "100%",
          }}
        >
          <FormControl>
            {isSpecialCharError && (
              <Typography color="error" sx={{ m: 2 }}>
                Special Characters are not allowed.
              </Typography>
            )}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                p: 1,
                width: "100%",
              }}
            >
              <InputLabel
                sx={{ p: 0.5, width: "10rem" }}
                htmlFor="employee_code"
              >
                Employee Code :
              </InputLabel>
              <TextField
                id="employee_code"
                required
                name="employee_code"
                variant="outlined"
                size="small"
                value={addEmployee.employee_code}
                onChange={(e) => {
                  handleChange(e, 0);
                }}
                sx={{ m: 1, width: "35rem" }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                p: 1,
                width: "100%",
              }}
            >
              <InputLabel sx={{ p: 0.5, width: "10rem" }} htmlFor="first_name">
                First name :
              </InputLabel>
              <TextField
                id="first_name"
                required
                name="first_name"
                variant="outlined"
                size="small"
                value={addEmployee.first_name}
                onChange={(e) => {
                  handleChange(e, 1);
                }}
                sx={{ m: 1, width: "35rem" }}
                helperText={
                  nameError[1] && (
                    <Typography fontSize={14} color="error">
                      Please enter valid name.
                    </Typography>
                  )
                }
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                p: 1,
                width: "100%",
              }}
            >
              <InputLabel sx={{ p: 0.5, width: "10rem" }} htmlFor="last_name">
                {" "}
                Last name :
              </InputLabel>
              <TextField
                id="last_name"
                required
                name="last_name"
                size="small"
                variant="outlined"
                value={addEmployee.last_name}
                onChange={(e) => {
                  handleChange(e, 2);
                }}
                sx={{ m: 1, width: "35rem" }}
                helperText={
                  nameError[2] && (
                    <Typography fontSize={14} color="error">
                      Please enter valid name.
                    </Typography>
                  )
                }
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                p: 1,
                width: "100%",
              }}
            >
              <InputLabel sx={{ p: 0.5, width: "10rem" }} htmlFor="gender ">
                {" "}
                Gender :
              </InputLabel>
              <Select
                id="gender"
                required
                labelId="select-label"
                label="gender"
                size="small"
                name="gender"
                value={addEmployee.gender}
                onChange={(e) => {
                  handleChange(e, 3);
                }}
                sx={{ m: 1, width: "35rem" }}
              >
                <MenuItem id="male" value={"Male"}>
                  Male
                </MenuItem>
                <MenuItem id="female" value={"Female"}>
                  Female
                </MenuItem>
                <MenuItem id="other" value={"Other"}>
                  Other
                </MenuItem>
              </Select>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "right",
                mr: 1,
                width: "100%",
              }}
            >
              {addEmployee.gender === "Other" && (
                <TextField
                  id="otherGender"
                  placeholder="Other.."
                  name="gender"
                  size="small"
                  variant="outlined"
                  value={otherGender}
                  onChange={(e) => {
                    setOtherGender(e.target.value);
                  }}
                  sx={{ m: 2, width: "35rem" }}
                />
              )}
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                p: 1,
                width: "100%",
              }}
            >
              <InputLabel sx={{ p: 0.5, width: "10rem" }} id="select-label">
                Role :
              </InputLabel>

              <Select
                id="roles"
                // required
                size="small"
                name="roles"
                placeholder="Please select role"
                multiple
                // defaultValue={["Employee"]}
                value={roles}
                onChange={handleRoles}
                sx={{ m: 1, width: "35rem" }}
                renderValue={(selected: string[]) => `${selected.join(",")}`}
              >
                <MenuItem id="hr" value={"HR"}>
                  HR
                </MenuItem>
                <MenuItem id="manager" value={"Manager"}>
                  Manager
                </MenuItem>
                <MenuItem id="admin" value={"Admin"}>
                  Admin
                </MenuItem>
              </Select>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                p: 1,
                width: "100%",
              }}
            >
              <InputLabel sx={{ p: 0.5, width: "10rem" }} htmlFor="designation">
                Designation :
              </InputLabel>
              <Autocomplete
                id="designation"
                options={designations}
                getOptionLabel={(option) => 
                  typeof option === 'string' ? option : option.designation_name
                }
                value={selectedDesignation}
                onChange={(event, newValue) => {
                  if (typeof newValue === 'string') {
                    // Create a new designation
                    handleCreateDesignation(newValue);
                  } else {
                    setSelectedDesignation(newValue);
                    setAddEmployee({
                      ...addEmployee,
                      designation: newValue?.id || "",
                    });
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = options.filter((option) =>
                    option.designation_name
                      .toLowerCase()
                      .includes(params.inputValue.toLowerCase())
                  );

                  if (params.inputValue !== "" && !filtered.length) {
                    filtered.push({
                      id: "new",
                      designation_name: `Add "${params.inputValue}"`,
                      inputValue: params.inputValue,
                    } as Designation);
                  }

                  return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    size="small"
                    sx={{ m: 1, width: "35rem" }}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props}>
                    {option.id === "new" ? (
                      <strong>{option.designation_name}</strong>
                    ) : (
                      option.designation_name
                    )}
                  </li>
                )}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                p: 1,
                width: "100%",
              }}
            >
              <InputLabel
                sx={{ p: 0.5, width: "10.5rem" }}
                htmlFor="Date Of Birth"
              >
                Date Of Birth :
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker", "DatePicker"]}>
                  <DatePicker
                    className="datePicker"
                    sx={{ width: "35rem" }}
                    label="Date Of Birth"
                    value={dateOfBirth}
                    disableFuture
                    onChange={(newValue) => {
                      setDateOfBirth(newValue);
                    }}
                    format="YYYY-MM-DD"
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                p: 1,
                width: "100%",
              }}
            >
              <InputLabel
                sx={{ p: 0.5, width: "10.5rem" }}
                htmlFor="Date Of Joining"
              >
                Date Of Joining :
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker", "DatePicker"]}>
                  <DatePicker
                    className="DOJ"
                    sx={{ width: "35rem" }}
                    label="Date Of Joining"
                    value={dateOfJoining}
                    disableFuture
                    onChange={(newValue) => {
                      setDateOfJoining(newValue);
                    }}
                    format="YYYY-MM-DD"
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                p: 1,
                width: "100%",
              }}
            >
              <InputLabel sx={{ p: 0.5, width: "10rem" }} htmlFor="email">
                {" "}
                Email :
              </InputLabel>
              <TextField
                required
                id="email"
                name="email"
                type="email"
                // label = "Email"
                size="small"
                variant="outlined"
                value={addEmployee.email}
                onChange={(e) => {
                  handleChange(e, 5);
                }}
                sx={{ m: 1, width: "35rem" }}
                helperText={
                  nameError[5] && (
                    <Typography fontSize={14} color="error">
                      Please enter valid Email.
                    </Typography>
                  )
                }
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                p: 1,
                width: "100%",
              }}
            >
              <InputLabel sx={{ p: 0.5, width: "10rem" }} htmlFor="password">
                Password :
              </InputLabel>
              <TextField
                id="password"
                name="password"
                size="small"
                // label="Password"
                type={showPassword ? "text" : "password"}
                autoComplete="current-password"
                variant="outlined"
                value={addEmployee.password}
                onChange={(e) => {
                  handleChange(e, 6);
                  // validatePassword(e.target.value);
                }}
                onFocus={handlePasswordFocus}
                onBlur={handlePasswordBlur}
                required
                sx={{ m: 1, width: "35rem" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {passwordFocused && (
                        <IconButton
                          id="toggleIcon"
                          onClick={handleTogglePassword}
                          edge="end"
                          onMouseDown={(e) => e.preventDefault()}
                        >
                          {showPassword ? (
                            <VisibilityOffIcon className="offIcon" />
                          ) : (
                            <VisibilityIcon className="onIcon" />
                          )}
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
                helperText={
                  <>
                    {errorMessage && (
                      <Typography color={"error"}>{errorMessage}</Typography>
                    )}
                  </>
                }
              />
            </Box>
            <Divider textAlign="center" sx={{ m: 2 }}>
              OR
            </Divider>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <FileUploadComponent />
            </Box>
            <Box
              sx={{
                display: "flex",
                m: 1,
                alignItems: "end",
                justifyContent: "right",
              }}
            >
              <Button
                id="back_button"
                onClick={() => {
                  Navigate("/employeeinformation");
                }}
              >
                Back
              </Button>
              <Button
                id="add_button"
                type="submit"
                disabled={!nameError.some((error) => error)}
              >
                Add
              </Button>
            </Box>
          </FormControl>
        </Box>
      </Box>
      <Box>
        <Footer />
      </Box>
    </>
  );
};

export default AddEmployee;
