import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Rating,
  Typography,
} from "@mui/material";
import { GridColDef, DataGrid, GridToolbar } from "@mui/x-data-grid";
import { ThunkDispatch } from "@reduxjs/toolkit";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  viewUsers,
  allEmployeeSkiillResponses,
  viewEmployeeResponsesDataPoint,
} from "../../Service/Apis";
import CustomNoRowsOverlay from "../../components/Data/NoRowsOverlayComponent";
import NothingToDisplayComponent from "../../components/Data/NothingToShowComponent";
import Loading from "../../components/Loading/Loading";
import { setIsLoading, setInPageLoading } from "../../store/LoadingSlice";
import { RootState } from "../../store/Store";
import {
  FetchDataSuccessAction,
  FetchDataFailureAction,
  fetchData,
} from "../../store/actions";
import { DataPointViewPreviousEmployeeResponses } from "./Types/DataPointsTypes";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { startOfMonth } from "date-fns";
import NumberInput from "../../components/NumberInput/NumberInput";
import { filter } from "@chakra-ui/react";

const EmployeeViewDataPoint = () => {
  const [data, setData] = useState<DataPointViewPreviousEmployeeResponses[]>(
    []
  );
  const [filterDate, setFilterDate] = useState<Dayjs | null>(null);
  const [openDialog, setOpenDialog] = useState(false);

  const [selectedDataPoint, setSelectedDataPoint] =
    useState<DataPointViewPreviousEmployeeResponses | null>(null);

  // Redux Hooks
  const dispatch = useDispatch();
  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();

  const isLoading = useSelector((state: any) => state.loading.inPageLoading);

  // Open Dialog Function
  const handleDialogOpen = (dataPoint: any) => {
    setSelectedDataPoint(dataPoint);
    setOpenDialog(true);
  };

  // Close Dialog Function
  const handleDialogClose = () => {
    setSelectedDataPoint(null);
    setOpenDialog(false);
  };

  const rows = useMemo(() => {
    return data.map((question, index) => ({
      ...question,
      questionIndex: index + 1,
    }));
  }, [data, selectedDataPoint]);

  const columns: GridColDef[] = [
    {
      field: "questionIndex",
      headerName: "Sr. No",
      renderHeader: () => (
        <strong className="custom-header" style={{ textAlign: "center" }}>
          Sr No.
        </strong>
      ),
      //   flex: 1,
      width: 80,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "employee_name",
      headerName: "Employee Name",
      renderHeader: () => (
        <strong className="custom-header" style={{ textAlign: "center" }}>
          Employee Name
        </strong>
      ),
      //   flex: 1,
      width: 160,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "data_point_name",
      headerName: " Data Point Name",
      width: 170,
      // flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
      renderHeader: () => (
        <strong className="custom-header" style={{ textAlign: "center" }}>
          Data Point Name
        </strong>
      ),
    },
    {
      field: "department_name",
      headerName: " Question",
      width: 170,
      // flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
      renderHeader: () => (
        <strong className="custom-header" style={{ textAlign: "center" }}>
          Department Name
        </strong>
      ),
    },
    {
      field: "project_name",
      headerName: "Project Name",
      renderHeader: () => (
        <strong className="custom-header" style={{ textAlign: "center" }}>
          Project Name
        </strong>
      ),
      width: 170,
      //   flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "date",
      headerName: "Date",
      renderHeader: () => (
        <strong className="custom-header" style={{ textAlign: "center" }}>
          Date
        </strong>
      ),
      //   flex: 1,
      width: 130,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
      renderCell: (params: any) => (
        <Box>{dayjs(params.row.date).format("MMM-YYYY")}</Box>
      ),
    },
    {
      field: "view",
      headerName: " View",
      width: 185,
      //   flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
      renderHeader: () => (
        <strong className="custom-header" style={{ textAlign: "center" }}>
          View
        </strong>
      ),
      renderCell: (params: any) => (
        <>
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              handleDialogOpen(params.row);
              console.log(params.row);
            }}
          >
            View
          </Button>
          <Dialog
            id={`fullscreen-dialog-${params.row.index}`}
            open={openDialog}
            onClose={handleDialogClose}
            fullScreen
            sx={{ overflow: "auto" }}
          >
            {selectedDataPoint && (
              <>
                {/* Dialog Title */}
                <DialogTitle
                  id={`dialog-title-${params.row.index}`}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    m: 2,
                    p: 2,
                  }}
                >
                  <Typography
                    id={`dialog-title-typography-${params.row.index}`}
                    fontWeight={"bold"}
                    variant="h4"
                    color={"#007300"}
                  >
                    {`Questions for ${selectedDataPoint.data_point_name}`}
                  </Typography>
                </DialogTitle>
                {/* Dialog Content */}
                <DialogContent
                  id={`dialog-content-${params.row.index}`}
                  sx={{ ml: 5, mr: 5, mt: 2, p: 2 }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "right",
                      mb: 2,
                      // p: 1,
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <DatePicker
                          label="From"
                          views={["month", "year"]}
                          sx={{
                            ".MuiInputLabel-root": {
                              transform: "translate(14px, 8px) scale(1)",
                            },
                            ".MuiInputLabel-shrink": {
                              transform: "translate(14px, -6px) scale(0.75)",
                            },
                            ".MuiInputBase-root .MuiInputBase-input": {
                              /* Your styles here */
                              height: "0.55rem",
                              width: "8rem",
                            },
                            mx: 1,
                          }}
                          value={dayjs(selectedDataPoint.date)}
                          readOnly
                          disableFuture
                        />
                      </Box>
                    </LocalizationProvider>
                  </Box>
                  {selectedDataPoint?.data_point_questions?.map(
                    (question: any, questionIndex: number) => (
                      // Question Card
                      <Card
                        key={questionIndex}
                        id={`question-card-${params.row.index}-${questionIndex}`}
                        sx={{
                          mb: 2,
                          p: 2,
                          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", // Add boxShadow here
                          border: "1px solid #ccc",
                        }}
                        elevation={5}
                      >
                        {/* Question Text */}
                        <Box
                          id={`question-text-${params.row.index}-${questionIndex}`}
                        >
                          <Typography fontWeight={"bold"} variant="h6">
                            {questionIndex + 1 + ") "}
                            {question.data_point_question}
                          </Typography>
                        </Box>
                        {/* Rating */}
                        <Box
                          id={`rating-box-${params.row.index}-${questionIndex}`}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            m: 1.5,
                            p: 1,
                          }}
                        >
                          <NumberInput
                            // min={0}
                            value={question.data_point_response}
                            // max={100}
                            readOnly
                          />
                        </Box>
                      </Card>
                    )
                  )}
                </DialogContent>
                {/* Dialog Actions */}
                <DialogActions
                  id={`dialog-actions-${params.row.index}`}
                  sx={{ m: 2, p: 2 }}
                >
                  <Button
                    id={`close-button-${params.row.index}`}
                    variant="outlined"
                    onClick={handleDialogClose}
                  >
                    Close
                  </Button>
                </DialogActions>
              </>
            )}
          </Dialog>
        </>
      ),
    },
  ];

  useEffect(() => {
    // API call to get the employee skill responses.
    const fetchEmployeesDataPointResponse = async () => {
      try {
        dispatch(setInPageLoading(true));
        const response = await dispatch2(
          fetchData(
            `${viewEmployeeResponsesDataPoint}${
              filterDate
                ? `?date=${filterDate.format("YYYY-MM-DD").toString()}`
                : ""
            }`
          )
        );
        setData(response as DataPointViewPreviousEmployeeResponses[]);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setInPageLoading(false));
      }
    };

    fetchEmployeesDataPointResponse();
  }, [filterDate]);

  // Loading Spinner Component
  if (isLoading) {
    return (
      <>
        <Loading isLoading={isLoading as boolean} />
      </>
    );
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "85vw",
        }}
      >
        <Box>
          <Box sx={{ mt: 2, pt: 2 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "right",
                mb: 2,
                // p: 1,
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <DatePicker
                    label="Date"
                    views={["month", "year"]}
                    sx={{
                      ".MuiInputLabel-root": {
                        transform: "translate(14px, 8px) scale(1)",
                      },
                      ".MuiInputLabel-shrink": {
                        transform: "translate(14px, -6px) scale(0.75)",
                      },
                      ".MuiInputBase-root .MuiInputBase-input": {
                        /* Your styles here */
                        height: "0.55rem",
                        width: "8rem",
                      },
                      mx: 1,
                    }}
                    value={filterDate}
                    onChange={(newValue) =>
                      setFilterDate(
                        newValue ? dayjs(startOfMonth(newValue.toDate())) : null
                      )
                    }
                    disableFuture
                  />
                  <Button size="small" onClick={() => setFilterDate(null)}>
                    Clear
                  </Button>
                </Box>
              </LocalizationProvider>
            </Box>
            <Box sx={{ m: 1, p: 1, width: "100%" }}>
              <Paper sx={{ width: "fit-content" }}>
                <DataGrid
                  autoHeight
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 15,
                      },
                    },
                  }}
                  disableColumnMenu
                  sx={{
                    width: "100%",
                    height: "100%",
                    "--DataGrid-overlayHeight": "50vh",
                  }}
                  pagination
                  pageSizeOptions={[5, 10, 15, 20]}
                  rows={rows}
                  columns={columns}
                  disableColumnSelector
                  slots={{
                    toolbar: GridToolbar,
                    noRowsOverlay: CustomNoRowsOverlay,
                    noResultsOverlay: CustomNoRowsOverlay,
                  }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                      style: { margin: "0.5rem" },
                    },
                  }}
                  getRowId={(row) => row.questionIndex}
                />
              </Paper>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default EmployeeViewDataPoint;
